import DeviceInfo from "utils/DeviceInfo";
import { PersistentStorage } from "utils/PersistentStorage";
import AppConfig from "utils/AppConfig";
import { staticGetState } from "reduxLocal/store";

export class EnvUtils {
  public static get appVersionPlusCapability(): string {
    return `${AppConfig.marketingVersion}.${AppConfig.capabilityNumber}`;
  }

  public static async extractEnvData(
    useLastSavedData: boolean,
  ): Promise<string> {
    let res = "";
    try {
      const userInfo = EnvUtils._getAccountData(useLastSavedData);

      const version = this.appVersionPlusCapability;
      res += `${userInfo}\nversion: ${version}\n\n=======\n`;

      const applicationInfo = `${DeviceInfo.applicationName}, version: ${DeviceInfo.version}`;
      res += `\napplication: ${applicationInfo}`;

      const osInfo = `${DeviceInfo.systemName}, version: ${DeviceInfo.systemVersion}`;
      res += `\nos: ${osInfo}`;

      const deviceInfo = `${DeviceInfo.brand}, model: ${DeviceInfo.model}, deviceId: ${DeviceInfo.getDeviceId}`;
      res += `\nbrand: ${deviceInfo}`;

      const deviceName = await DeviceInfo.deviceName;
      res += `\ndevice name: ${deviceName}`;

      const uniqueId = PersistentStorage.cachedDeviceId;
      res += `\nuniqueId: ${uniqueId}`;
    } catch (error) {
      console.warn("x in extractEnvData", error);
      res += "\n\n\nerror in extractEnvData";
    }

    return res;
  }

  private static _getAccountData(useLastSavedData: boolean): string {
    if (useLastSavedData) {
      return `username: ${PersistentStorage.getLastLoggedAccountUsername()}\naccount_id: ${PersistentStorage.getLastLoggedAccountId()}\nauthorized: ${
        PersistentStorage.getLastLoggedProfileStatus() ?? "empty"
      }`;
    }

    const state = staticGetState();
    const sportsBookState = state.sportsBook;
    return state.app.authenticated
      ? `username: ${sportsBookState.profile.username}\naccount_id: ${sportsBookState.profile.user_id}\nfull name: ${sportsBookState.profile.personal_info.first_name} ${sportsBookState.profile.personal_info.last_name}`
      : "unauthorized";
  }
}
