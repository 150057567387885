import React, { PropsWithChildren, useCallback, useState } from "react";

import SportsChannelsContext from "./SportsChannelsContext";

import Constants from "constants/Constants";
import ChannelUtils from "utils/ChannelUtils";
import CoreSelectors from "reduxLocal/core/core.selectors";
import { MemoryStorage } from "utils/MemoryStorage";
import { HeraldBackend } from "server/herald/HeraldBackend";
import { staticGetState } from "reduxLocal/store";

import { Data__SB_Channel } from "server/legacyCore/data/objects";
import { FeedFocusUtils } from "utils/FeedFocusUtils";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import qs from "query-string";
import { useSelector } from "react-redux";

const SportsChannelsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const liveChannels = useSelector(CoreSelectors.Channels.liveChannels);
  const [selectedChannelId, setSelectedChannelId] = useState<number>(
    Constants.homeChannelId,
  );
  const [lastSelectedChannelId, setLastSelectedChannelId] = useState(
    Constants.homeChannelId,
  );
  const [selectedSubChannelId, setSelectedSubChannelId] = useState<number>(0);
  const [catchallChannel, setCatchallChannel] =
    useState<Data__SB_Channel | null>(null);

  const handleSelectChannelFromFeed = useCallback(
    (id: number): number =>
      requestAnimationFrame(() => setSelectedChannelId(id)),
    [],
  );

  const handleSelectCatchallChannel = useCallback(
    (id: number): number => {
      const channelFromAllSports = ChannelUtils.getChannelById(
        CoreSelectors.Channels.allSportsChannels(staticGetState()),
        id,
      );
      const isChannelExistsInFeedChannel = ChannelUtils.getChannelById(
        CoreSelectors.Channels.dashboardChannels(staticGetState()),
        id,
      );

      if (channelFromAllSports && !isChannelExistsInFeedChannel) {
        return requestAnimationFrame(() => {
          setSelectedChannelId(id);
          setCatchallChannel(channelFromAllSports);
        });
      }
      return handleSelectChannelFromFeed(id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleSelectChannelFromFeed],
  );

  const subscribeForChannelId = useCallback((channelId: number) => {
    const prevChannelId = MemoryStorage.focusedChannelId;

    if (prevChannelId) {
      HeraldBackend.unsubscribeForChannelId(prevChannelId);
    }

    if (channelId) {
      MemoryStorage.setFocusedChannelId(channelId);
      HeraldBackend.subscribeForChannelId(channelId);
    }
  }, []);

  const pushSubChannelIdToURL = useCallback(
    (id: number) => {
      return requestAnimationFrame(() => {
        const query = qs.stringify({
          channelId: Constants.liveChannelId,
          liveChannelId: id,
        });
        history.push(`/channels?${query}`);
      });
    },
    [history],
  );

  const handleSubChannelSelect = useCallback(
    (id: number): void => {
      if (id !== 0) {
        subscribeForChannelId(id);
        pushSubChannelIdToURL(id);
      }
      requestAnimationFrame(() => setSelectedSubChannelId(id));
    },
    [pushSubChannelIdToURL, subscribeForChannelId],
  );

  const handleChannelSelect = useCallback(
    async (id: number, fromAllSports?: boolean): Promise<void | number> => {
      if (fromAllSports) {
        const isError = await FeedFocusUtils.handleSelectChannelFromAllSports();
        if (isError) {
          HeraldBackend.unsubscribeForChannelId(id);
          handleChannelSelect(Constants.homeChannelId);
          return;
        }
        handleSelectCatchallChannel(id);
      } else {
        handleSelectChannelFromFeed(id);
      }
      requestAnimationFrame(() => {
        history.replace(`/channels?channelId=${id}`);
      });

      setLastSelectedChannelId(id);

      if (id === Constants.homeChannelId) {
        if (selectedSubChannelId) {
          handleSubChannelSelect(0);
        }
        return subscribeForChannelId(id);
      }

      const isLiveChannel = id === Constants.liveChannelId;
      const isOffersChannel = id === Constants.offersChannelId;

      if (isLiveChannel || isOffersChannel) {
        const subChannels = isOffersChannel
          ? CoreSelectors.Channels.offersChannels(staticGetState())
          : CoreSelectors.Channels.liveChannels(staticGetState());

        const firstSubChannelId = subChannels[0]?.id;

        if (selectedSubChannelId) {
          pushSubChannelIdToURL(selectedSubChannelId);
          setSelectedSubChannelId(selectedSubChannelId);
          return;
        }
        if (firstSubChannelId) {
          subscribeForChannelId(firstSubChannelId);
          setSelectedSubChannelId(firstSubChannelId);
        } else {
          subscribeForChannelId(id);
        }

        return;
      }

      subscribeForChannelId(id);
    },
    [
      pushSubChannelIdToURL,
      handleSelectCatchallChannel,
      handleSelectChannelFromFeed,
      handleSubChannelSelect,
      history,
      selectedSubChannelId,
      subscribeForChannelId,
    ],
  );

  const getSubChannelIdFromPathname = () => {
    const { liveChannelId } = queryString.parse(location.search);
    const asNumber = Number(liveChannelId);

    if (isNaN(asNumber)) {
      return 0;
    }

    if (ChannelUtils.getChannelById(liveChannels, asNumber)) {
      return asNumber;
    }

    return 0;
  };

  const getChannelIdFromPathname = () => {
    const response = matchPath(location.pathname, {
      path: "/channels",
      exact: true,
      strict: false,
    });
    if (response && response.isExact) {
      const params = queryString.parse(location.search);
      setLastSelectedChannelId(Number(params.channelId));

      return Number(params.channelId);
    }
    return Constants.homeChannelId;
  };

  return (
    <SportsChannelsContext.Provider
      value={{
        handleChannelSelect,
        selectedChannelId,
        handleSubChannelSelect,
        selectedSubChannelId,
        catchallChannel,

        lastSelectedChannelId,
        getChannelIdFromPathname,
        getSubChannelIdFromPathname,
      }}>
      {children}
    </SportsChannelsContext.Provider>
  );
};

export default SportsChannelsProvider;
