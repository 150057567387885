import { Buffer } from "buffer";
import { EventEmitter } from "events";
import WebSocket from "isomorphic-ws";
import pako from "pako";

import { HeraldBackend } from "server/herald/HeraldBackend";
import { TAnyAlias } from "src/types";
import Logger from "utils/Logger";

import {
  FWebSocketState,
  IEndpoint,
  IOnEndpointConnectedCallback,
} from "../types";
import { FliffWebSocketStats } from "server/herald/fliffSocketImpl/FliffWebSocketStats";

export class FliffWebSocket extends EventEmitter implements IEndpoint {
  // 2022-01-12 / Ivan / track all kind of low level stats, reported back to server
  public static stats = new FliffWebSocketStats();
  public callback: IOnEndpointConnectedCallback;

  private readonly _address: string;
  private _xstate = FWebSocketState.IDLE;
  private _reconnectInterval = 100;
  private _socket!: WebSocket;
  private _enableDebugLog = false;

  constructor(address: string, callback: IOnEndpointConnectedCallback) {
    super();

    this._address = address;
    this.callback = callback;

    this._connect = this._connect.bind(this);
    this._connect();
  }

  public get address(): string {
    return this._address;
  }
  // 2022-01-12 / Ivan / track retries and other stats
  public get stats(): FliffWebSocketStats {
    return FliffWebSocket.stats;
  }

  public onOpen() {
    try {
      FliffWebSocket.stats.c_12_open++;

      // send connect / reconnect announcement
      this.setState(FWebSocketState.ACTIVE);

      const messages = this.callback.getMessagesOnConnect(this);
      for (let i = 0; i < messages.length; i++) {
        const frame = HeraldBackend.isGzipEnabled
          ? Buffer.from(pako.deflate(messages[i]))
          : Buffer.from(messages[i], "utf8");
        this._socket.send(frame);
        FliffWebSocket.stats.c_23_frames_out_sent++;
        FliffWebSocket.stats.c_29_bytes_out += frame.length;
      }
    } catch (error) {
      Logger.warnAny("  x in socket/onOpen", error);
    }
  }

  public onClose() {
    FliffWebSocket.stats.c_14_on_close++;

    if (this._xstate === FWebSocketState.CLOSED) {
      return;
    }

    this.setState(FWebSocketState.SCHEDULE_RECONNECT);
    this._reconnectInterval *= 2;
    if (this._reconnectInterval > 2000) {
      this._reconnectInterval = 2000;
    }

    setTimeout(this._connect, this._reconnectInterval);
  }

  public error() {
    try {
      FliffWebSocket.stats.c_13_error++;
      this._socket.close();
    } catch (error) {
      Logger.warnAny("  x in socket/error", error);
    }
  }

  public onMessage(wsmes: ArrayBuffer | TAnyAlias) {
    try {
      // let's reset reconnect interval on first received message
      this._reconnectInterval = 100;

      if (!(wsmes.data instanceof ArrayBuffer)) {
        this.error();
        return;
      }

      const frame = Buffer.from(wsmes.data);
      if (frame.length === 0) {
        this.error();
        return;
      }

      const message = HeraldBackend.isGzipEnabled
        ? pako.inflate(frame, { to: "string" })
        : frame.toString();

      FliffWebSocket.stats.c_31_frames_in++;
      FliffWebSocket.stats.c_39_bytes_in += frame.length;

      this.emit("message", this, message);
    } catch (error) {
      Logger.warnAny("  x in socket/onMessage", error);
    }
  }

  public close() {
    try {
      if (this._xstate !== FWebSocketState.CLOSED) {
        this.setState(FWebSocketState.CLOSED);

        if (
          this._socket.readyState === this._socket.CONNECTING ||
          this._socket.readyState === this._socket.OPEN
        ) {
          this._socket.close();
        }

        this.emit("terminated", this);
      }
    } catch (error) {
      Logger.warnAny("  x in socket/close", error);
    }
  }

  public send(message: string): boolean {
    try {
      if (this._xstate !== FWebSocketState.ACTIVE) {
        FliffWebSocket.stats.c_22_frames_out_skipped++;
        // increase some stats
        return false;
      }

      const frame = HeraldBackend.isGzipEnabled
        ? Buffer.from(pako.deflate(message))
        : Buffer.from(message, "utf8");
      this._socket.send(frame);
      FliffWebSocket.stats.c_23_frames_out_sent++;
      FliffWebSocket.stats.c_29_bytes_out += frame.length;

      return true;
    } catch (error) {
      Logger.warnAny("  x in socket/send", error);
    }

    return false;
  }

  public setEnableDebugLog(enableDebugLog: boolean): void {
    this._enableDebugLog = enableDebugLog;
  }

  clog(mes: string) {
    if (!this._enableDebugLog) {
      return;
    }
    console.log(mes);
  }

  public setState(xstate: FWebSocketState): void {
    const oldState = this._xstate;
    this._xstate = xstate;

    if (
      oldState === FWebSocketState.ESTABLISHING_CONNECTION &&
      xstate === FWebSocketState.ACTIVE
    ) {
      this.emit("hiccuped", this);
    }

    if (xstate === FWebSocketState.SCHEDULE_RECONNECT) {
      this.emit("reconnecting", this);
    }
  }

  private _connect() {
    // The socket was already closed, abort
    if (this._xstate === FWebSocketState.CLOSED) {
      return;
    }
    FliffWebSocket.stats.c_11_connect++;
    this._socket = new WebSocket(this._address);
    this._socket.binaryType = "arraybuffer";
    this._socket.onopen = this.onOpen.bind(this);
    this._socket.onclose = this.onClose.bind(this);
    this._socket.onmessage = this.onMessage.bind(this);

    this.setState(FWebSocketState.ESTABLISHING_CONNECTION);
  }
}
