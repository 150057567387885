import groupBy from "lodash/groupBy";
import { staticGetState } from "reduxLocal/store";
import { getSportsBookConfig } from "selectors/sportsBook";
import { PickType, UIConstants } from "server/legacyCore/data/constants";
import {
  Data__SB_Pick_Selection,
  Data__SB_SportEvent,
  TProposalsMap,
} from "server/legacyCore/data/objects";
import {
  Data__SB_ShoppingCart,
  Data__SB_ShoppingCartProposalItem,
  Data__SB_SuperMarket,
} from "server/legacyCore/data/objectsLocal";
import { IStringMap } from "src/types";
import { AppUtils } from "utils/AppUtils";
import ProposalUtils from "./ProposalUtils";

/*
all amounts below are raw / unformatted (in cents)

usa_coeff_to_eu_coeff
    * converts usa coeff to eur coeff
recalc_raw_amount_for_coeff
    * calculates payout amount for given coeff and amount
calc_raw_total_bet_amount_impl
    * calculates total bet amount (depending on mode)
calc_raw_payout_amount_for_cart_item
    * calculates payout amount for given coeff and cart item (straight mode)
calc_raw_payout_amount_for_whole_cart
    * calculates payout amount for the whole cart (parley mode)
calc_raw_payout_amount_impl
    * calculates payout amount for the whole cart (for a cart item or whole cart)
*/

export default class PickUtils {
  public static usaCoeffToEuCoeff(usaCoeff: number): number {
    if (usaCoeff < 100 && usaCoeff >= -100) {
      throw Error(
        "usa_coeff_to_eu_coeff - unexpected usaCoeff [" + usaCoeff + "]",
      );
    }

    let euCoeff: number;

    if (usaCoeff > 0) {
      euCoeff = (usaCoeff + 100) / 100;
    } else {
      euCoeff = (-usaCoeff + 100) / -usaCoeff;
    }

    return euCoeff;
  }

  public static euCoeffToUsaCoeff(euCoeff: number): number {
    if (euCoeff <= 0) {
      throw Error(
        "eu_coeff_to_usa_coeff - unexpected euCoeff [" + euCoeff + "]",
      );
    }

    let usaCoeff: number;

    if (euCoeff >= 2.0) {
      usaCoeff = (euCoeff - 1) * 100;
    } else {
      usaCoeff = -100 / (euCoeff - 1);
    }

    usaCoeff = Math.floor(usaCoeff);
    return usaCoeff;
  }

  public static recalcRawAmountForCoeff(
    rawAmount: number,
    coeff: number,
  ): number {
    // expected behavior when rawAmount is negative is not clear, for the time being consider it invalid data
    if (rawAmount < 0) {
      throw Error("unexpected (rawAmount < 0) [" + rawAmount + "]");
    }

    rawAmount = rawAmount * coeff;
    rawAmount = Math.floor(rawAmount);
    return rawAmount;
  }

  public static calcRawTotalBetAmountImpl(
    shoppingCart: Data__SB_ShoppingCart,
    currencyMode: number,
    pickType: PickType,
  ): number {
    if (
      pickType === PickType.CONST_82_PARLAY ||
      pickType === PickType.CONST_83_SAME_GAME_PARLAY
    ) {
      return currencyMode !== UIConstants.CONST__331__CASH
        ? shoppingCart.totalPlacedFliffCashAmount
        : shoppingCart.totalPlacedFliffCashAmount;
    }

    let totalAmount = 0;
    for (const item of Object.values(shoppingCart.items)) {
      const rawPlacedAmount =
        currencyMode !== UIConstants.CONST__331__CASH
          ? item.placed_amount__gold_coins
          : item.placed_amount__fliff_cash;

      totalAmount += rawPlacedAmount;
    }

    return totalAmount;
  }

  public static calcRawPayoutAmountForCartItem(
    currencyMode: number,
    cartItem: Data__SB_ShoppingCartProposalItem,
  ): number {
    const rawAmount =
      currencyMode !== UIConstants.CONST__331__CASH
        ? cartItem.placed_amount__gold_coins
        : cartItem.placed_amount__fliff_cash;

    return PickUtils.calcRawPayoutAmountForCartItemImpl(cartItem, rawAmount);
  }

  public static calcRawPayoutAmountForSGPPickType(
    rawAmount: number,
    coeff: number,
  ): number {
    const euCoeff = PickUtils.usaCoeffToEuCoeff(coeff);
    return PickUtils.recalcRawAmountForCoeff(rawAmount, euCoeff);
  }

  public static getCartItemForReplacement = (
    betSupermarket: Data__SB_SuperMarket["shelves_by_conflict_fkey"],
    proposalsMap: TProposalsMap,
    {
      event,
      market_fkey: marketFkey,
      proposal_type: proposalType,
      placed_amount__fliff_cash: placedFliffCash,
      placed_amount__gold_coins: placedGoldCoins,
      createdStamp,
    }: Data__SB_ShoppingCartProposalItem,
    eventByFkey: Data__SB_SportEvent | null,
  ) => {
    const cellRenderMarket = ProposalUtils.getMarketByFkey(
      betSupermarket[event.conflict_fkey]?.markets,
      marketFkey,
    );
    if (!cellRenderMarket) {
      return null;
    }
    const cellRenderMarketProposals =
      ProposalUtils.getProposalsFromSingleMarket(cellRenderMarket);
    const cellRenderProposalForReplacement = cellRenderMarketProposals.find(
      proposalForReplacement => {
        const mainBetProposalForReplacement =
          proposalsMap[proposalForReplacement.proposal_fkey];
        return (
          !ProposalUtils.isProposalOTB(
            mainBetProposalForReplacement?.status,
            eventByFkey?.live_status,
          ) &&
          proposalForReplacement.type === proposalType &&
          mainBetProposalForReplacement &&
          mainBetProposalForReplacement.leadership_flag > 0
        );
      },
    );
    if (!cellRenderProposalForReplacement) {
      return null;
    }
    const mainProposalForReplacement =
      proposalsMap[cellRenderProposalForReplacement.proposal_fkey];
    if (mainProposalForReplacement) {
      return new Data__SB_ShoppingCartProposalItem(
        event,
        mainProposalForReplacement,
        true,
        createdStamp,
        { cash: placedFliffCash, coins: placedGoldCoins },
      );
    }

    return null;
  };

  public static calcRawPayoutAmountForCartItemImpl(
    cartItem: Data__SB_ShoppingCartProposalItem,
    rawAmount: number,
  ): number {
    const euCoeff = PickUtils.usaCoeffToEuCoeff(cartItem.coeff);

    return PickUtils.recalcRawAmountForCoeff(rawAmount, euCoeff);
  }
  public static calcRawPayoutAmountForWholeCart(
    shoppingCart: Data__SB_ShoppingCart,
    currencyMode: number,
  ): number {
    const rawPlacedAmount =
      currencyMode !== UIConstants.CONST__331__CASH
        ? shoppingCart.totalPlacedGoldCoinsAmount
        : shoppingCart.totalPlacedFliffCashAmount;
    return PickUtils.calcRawPayoutAmountForWholeCartImpl(
      shoppingCart,
      rawPlacedAmount,
    );
  }

  public static calcUsaCoeffForWholeCart(
    shoppingCart: Data__SB_ShoppingCart,
  ): number {
    const cartItemsValues = Object.values(shoppingCart.items);
    if (cartItemsValues.length === 1) {
      return cartItemsValues[0].coeff;
    }
    let euCoeff = 1;
    for (const item of cartItemsValues) {
      euCoeff = euCoeff * PickUtils.usaCoeffToEuCoeff(item.coeff);
    }

    return PickUtils.euCoeffToUsaCoeff(euCoeff);
  }

  public static calcRawPayoutAmountForWholeCartImpl(
    shoppingCart: Data__SB_ShoppingCart,
    rawPlacedAmount: number,
  ): number {
    // 2020-04-06 / Ivan / need to ensure that this works the same way in client and server for float arithmetic
    let euCoeff = 1;
    // 2020-04-18 / Ivan / quick hack because of signature with undefined
    const cartItemsValues = Object.values(shoppingCart.items);
    for (const item of cartItemsValues) {
      //      const option = item.market_bet_offer_option;
      euCoeff = euCoeff * PickUtils.usaCoeffToEuCoeff(item.coeff);
    }

    return PickUtils.recalcRawAmountForCoeff(rawPlacedAmount, euCoeff);
  }

  public static calcRawPayoutAmountImpl(
    cartItem: Data__SB_ShoppingCartProposalItem | null,
    shoppingCart: Data__SB_ShoppingCart,
    currencyMode: number,
  ): number {
    if (cartItem !== null) {
      return PickUtils.calcRawPayoutAmountForCartItem(currencyMode, cartItem);
    }
    return PickUtils.calcRawPayoutAmountForWholeCart(
      shoppingCart,
      currencyMode,
    );
  }

  public static getParlayOddsCoeff(
    shoppingCart: Data__SB_ShoppingCart,
  ): number {
    const coeff = PickUtils.calcUsaCoeffForWholeCart(shoppingCart);
    if (isFinite(coeff)) {
      return coeff;
    }
    return 0;
  }

  public static parseCoeff(coeff: number): string {
    if (coeff > 0) {
      return `+ ${coeff}`;
    }
    return `${coeff}`;
  }

  public static isPlacedBettingEventsAvailable = (
    cartItems: Data__SB_ShoppingCart["items"],
    events: Data__SB_SportEvent[],
  ): boolean =>
    Object.values(cartItems).every(
      ({ event: { conflict_fkey } }) =>
        !!AppUtils.getEventByConflictKey(events, conflict_fkey),
    );

  public static calcRawAmount(rawAmount: number, coeff: number): number {
    if (rawAmount < 0) {
      throw Error("unexpected (rawAmount < 0) [" + rawAmount + "]");
    }

    rawAmount = rawAmount * coeff;
    rawAmount = Math.floor(rawAmount);
    return rawAmount;
  }

  public static isSGPAllowed = (
    cartItems: IStringMap<Data__SB_ShoppingCartProposalItem>,
  ): boolean => {
    const cartItemsByConflictFkey = groupBy(
      Object.values(cartItems),
      item => item.event.conflict_fkey,
    );
    const values = Object.values(cartItemsByConflictFkey);
    if (values.length === 0 || values.length > 1) {
      return false;
    }
    return values[0].every(cartItem => cartItem.isSGPSupported);
  };

  public static groupPickSelectionsByConflictKey = (
    selections: Data__SB_Pick_Selection[],
  ) => {
    return selections.reduce<Data__SB_Pick_Selection[][]>(
      (acc: Data__SB_Pick_Selection[][], curr) => {
        const index = acc.findIndex(
          (item: Data__SB_Pick_Selection[]) =>
            item[0].conflict_fkey === curr.conflict_fkey,
        );
        if (index === -1) {
          acc.push([curr]);
        } else {
          acc[index].push(curr);
        }
        return acc;
      },
      [],
    );
  };

  public static isParlayAllowed = (
    cartItems: IStringMap<Data__SB_ShoppingCartProposalItem>,
  ): boolean => {
    const proposalsWithSameParlayIds = groupBy(
      Object.values(cartItems),
      item => item.parlay_id,
    );
    const isParlayAllowed = Object.values(proposalsWithSameParlayIds).every(
      group => group.length <= 1,
    );

    const isParlayCalibrateExceededMap: IStringMap<boolean> = {};

    for (const key in proposalsWithSameParlayIds) {
      isParlayCalibrateExceededMap[key] =
        proposalsWithSameParlayIds[key].reduce(
          (acc, curr) => (acc += curr.parlayCalibrate),
          0,
        ) < 2;
    }
    const isTotalParlayAmountExceeded = Object.values(
      isParlayCalibrateExceededMap,
    ).every(el => el);
    const isParlayForceDisabled = PickUtils.isParlayForceDisabled(cartItems);

    return (
      (isParlayAllowed || isTotalParlayAmountExceeded) && !isParlayForceDisabled
    );
  };

  public static isSingleCartItemForceDisabled = (
    cartItem: Data__SB_ShoppingCartProposalItem,
  ): boolean => cartItem.parlays_not_allowed > 0;

  public static isParlayForceDisabled = (
    cartItems: IStringMap<Data__SB_ShoppingCartProposalItem>,
  ): boolean =>
    Object.values(cartItems).filter(PickUtils.isSingleCartItemForceDisabled)
      .length > 0;

  public static isSingleSelectionInvalid = (
    selection: Data__SB_ShoppingCartProposalItem | undefined,
    selectedSelections: Data__SB_ShoppingCart["items"],
    pickType: PickType,
  ): boolean => {
    if (!selection) {
      return false;
    }
    if (pickType === PickType.CONST_81_STRAIGHT) {
      return false;
    }
    if (pickType === PickType.CONST_82_PARLAY) {
      if (PickUtils.isParlayAllowed(selectedSelections)) {
        return false;
      }
      if (PickUtils.isSingleCartItemForceDisabled(selection)) {
        return true;
      }
      return (
        PickUtils._getDuplicatedPicksParlayIds(selectedSelections).indexOf(
          selection.parlay_id,
        ) !== -1
      );
    }
    if (pickType === PickType.CONST_83_SAME_GAME_PARLAY) {
      if (Object.keys(selectedSelections).length === 1) {
        return false;
      }
      return !PickUtils.isSGPAllowed(selectedSelections);
    }
    return false;
  };

  public static getStraightMinBetAmountInCurrencies = () => {
    const config = getSportsBookConfig(staticGetState());

    return {
      [UIConstants.CONST__331__CASH]: 0,
      [UIConstants.CONST__333__FLIFF_COINS]:
        config.credits_straight__min_pick_amount_in_cents,
    };
  };

  private static _getDuplicatedPicksParlayIds(
    cartItems: IStringMap<Data__SB_ShoppingCartProposalItem>,
  ): string[] {
    const picksParlayIds = Object.values(cartItems).map(item => item.parlay_id);
    return picksParlayIds.filter((parlayId, index, self) => {
      if (self.indexOf(parlayId) !== index) {
        return true;
      }
      return PickUtils.isParlayAllowed(cartItems);
    });
  }
}
