import { combineReducers } from "redux";

import appUIReducer from "./appUI/appUI.reducer";
import appReducer from "./app/app.reducer";
import coreReducer from "./core/core.reducer";
import socialReducer from "./social/social.reducer";
import { AppLogoutAction } from "reduxLocal/app/app.actions";
import { TAnyAlias } from "src/types";

const combinedReducer = combineReducers({
  coreAppUI: appUIReducer,
  app: appReducer,
  sportsBook: coreReducer,
  social: socialReducer,
});

export type TGlobalAppState = ReturnType<typeof combinedReducer>;

const rootReducer = (state: TGlobalAppState | undefined, action: TAnyAlias) => {
  if (action.type === AppLogoutAction.type) {
    return combinedReducer(undefined, action);
  }
  return combinedReducer(state, action);
};

export default rootReducer;
