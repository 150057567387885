import Button from "components/atoms_new/desktop/Button";
import Inputs from "components/atoms_new/shared/Inputs";
import ModalHeader from "components/molecules_new/desktop/Modal/atoms/ModalHeader";
import usePhoneVerificationTimer from "hooks/usePhoneVerificationTimer";
import { forwardRef, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  AppUIHideModalProgressIndicator,
  AppUIShowModalDialogAction,
} from "reduxLocal/appUI/appUI.actions";
import { getCustomerProfile } from "selectors/sportsBook";
import { FliffException } from "server/common/FliffException";
import { SportsBookBIF } from "server/legacyCore/server/SportsBookBIF";
import { SportsBookSignInBIF } from "server/legacyCore/server/SportsBookSignInBIF";
import { DevConstants } from "src/DevConstants";
import { TVerifyPhoneNumberMode } from "src/types";
import { AppUtils } from "utils/AppUtils";
import ProfileVerificationUtils from "utils/ProfileVerificationUtils";
import "./styles.scss";
import { IProps } from "./types";

const EnterSMSCode = forwardRef<HTMLDivElement, IProps>(
  (
    { onClose, openPrevious, openNext, phoneNumber, secondsToResend, mode },
    forwardedRef,
  ) => {
    const history = useHistory();
    const [localSecondsToResend, setLocalSecondsToResend] = useState<
      number | undefined
    >(secondsToResend);
    const [smsCode, setSmsCode] = useState<string>("");
    const [isVerificationSucceeded, setVerificationSucceeded] =
      useState<boolean>(false);
    const { isTicking, timeFormat } =
      usePhoneVerificationTimer(localSecondsToResend);
    const customerProfile = useSelector(getCustomerProfile);

    const smsCodeClean = smsCode.replace(/\s/gi, "");
    const isSubmitDisabled = smsCodeClean.length !== 6;
    const isPhoneNumberVerifyOrAuth = mode === 2 || mode === 2.5;

    const handleContinuePress = useCallback(async (): Promise<void> => {
      if (isSubmitDisabled) {
        return;
      }
      try {
        const response = isPhoneNumberVerifyOrAuth
          ? await SportsBookSignInBIF.signInWithFliffAccount({
              login_token: "phone:" + phoneNumber,
              password: smsCodeClean,
            })
          : await SportsBookBIF.blocking_confirm_primary_phone_number({
              verification_code: smsCodeClean,
            });
        if (response.is_error) {
          return;
        }
        if (isPhoneNumberVerifyOrAuth) {
          const authResponse =
            await SportsBookSignInBIF.blockingAuthenticateWithSavedTokensOnStartup();
          if (authResponse.is_error) {
            if (
              FliffException.isProfileNotCompletedError(authResponse.exception)
            ) {
              openNext("completeProfile");
            } else if (
              FliffException.isPrimaryPhoneNumberRequired(
                authResponse.exception,
              )
            ) {
              openNext("signInPhone", { mode: 1 });
            } else if (
              FliffException.isTermsOfUseAcceptanceRequired(
                authResponse.exception,
              )
            ) {
              console.log("Terms of use acceptance required");
              // TODO: Terms of use screen to be implemented. Probably will be a modal?
              // history.replace("/terms-of-use");
            }
            return;
          }
          if (authResponse.is_error) {
            console.log("Error authenticating with saved tokens on startup");
            return;
          }

          // Success
          AppUIHideModalProgressIndicator.dispatchHideModalProgressIndicator();
          onClose();
          return;
        }
        setVerificationSucceeded(true);
      } catch (err) {
        AppUIShowModalDialogAction.dispatchShowErrorDialogForException(err, "");
      }
    }, [
      isPhoneNumberVerifyOrAuth,
      isSubmitDisabled,
      phoneNumber,
      smsCodeClean,
      openNext,
      onClose,
    ]);

    const handleSendNewCode = async (): Promise<void> => {
      const response = isPhoneNumberVerifyOrAuth
        ? await SportsBookSignInBIF.signInWithFliffAccount({
            login_token: "phone:" + phoneNumber,
            password: smsCodeClean,
          })
        : await SportsBookBIF.blocking_claim_primary_phone_number({
            phone_number: phoneNumber || "",
            dont_send_sms: DevConstants.dontSendSms,
          });
      if (
        isPhoneNumberVerifyOrAuth &&
        FliffException.isLoginClaimVerificationCodeSuccess(response.exception)
      ) {
        const currentSecondsToResend =
          FliffException.getSecondsFromVerificationCodeError(
            response.exception,
          );
        if (currentSecondsToResend) {
          setLocalSecondsToResend(currentSecondsToResend);
        }
      }
    };

    if (isVerificationSucceeded) {
      ProfileVerificationUtils.handlePhoneNumberSuccessVerificationSubmit(
        mode as TVerifyPhoneNumberMode,
        history,
        customerProfile?.user_id,
      );
      onClose();
    }

    const handleGoBack = () => {
      openPrevious();
    };

    return (
      <div className="enter-code" ref={forwardedRef}>
        <ModalHeader
          title={"Enter Code"}
          onClose={mode !== 0 ? onClose : undefined}
          onGoBack={handleGoBack}
        />

        <p className="enter-code__description">
          {`We sent you a 6 digit code to your phone number: ${AppUtils.maskPhoneNumber(
            phoneNumber || "",
          )}`}
        </p>

        <Inputs.Label htmlFor="sms-code" label="Enter the 6-digit code" />
        <Inputs.Code value={smsCode} onChangeText={setSmsCode} id="sms-code" />

        <div className="enter-code__time-wrapper">
          {isTicking ? (
            <>
              <p className="enter-code__time-left">{timeFormat}</p>
              <p>Until a new code can be sent.</p>
            </>
          ) : (
            <button
              onClick={handleSendNewCode}
              className="enter-code__send-new-code">
              Send New Code
            </button>
          )}
        </div>

        <Button
          onClick={handleContinuePress}
          label={"Continue"}
          disabled={isSubmitDisabled}
        />
      </div>
    );
  },
);

EnterSMSCode.displayName = "EnterSMSCode";

export default EnterSMSCode;
