import isEqual from "react-fast-compare";
import { staticGetState } from "reduxLocal/store";
import {
  CONST_TOTAL_AMOUNT_PARLAY,
  PickType,
  UIConstants,
} from "server/legacyCore/data/constants";
import {
  DataReq__SB_Pick,
  DataReq__SB_PlacePickRequest,
  DataReq__SB_Proposal_PickSelection,
} from "server/legacyCore/data/objects";
import {
  Data__SB_ShoppingCart,
  Data__SB_ShoppingCartProposalItem,
} from "server/legacyCore/data/objectsLocal";
import { TCurrencyMode } from "src/types";
import PickUtils from "utils/PickUtils";
import ProposalUtils from "utils/ProposalUtils";

// 2020-05-29 / Ivan / some helper methods in order to generate 'place picks' request
// from current data in shopping card (redux store)
export class PlacePickUtils {
  //TODO: make more convenient mechanism of handling different types of request. (real place pick, requesting SGP coeff).
  public static prepareRequest(
    shoppingCart: Data__SB_ShoppingCart,
    pickType: PickType,
    currencyMode: TCurrencyMode,
    sgpConfirmedCoeff: number,
  ): DataReq__SB_PlacePickRequest {
    let picks: DataReq__SB_Pick[];
    const isParlayPickType =
      pickType === PickType.CONST_82_PARLAY ||
      pickType === PickType.CONST_83_SAME_GAME_PARLAY;

    if (isParlayPickType) {
      picks = [
        PlacePickUtils.preparePicksForParlayMode(
          shoppingCart,
          currencyMode,
          pickType,
          sgpConfirmedCoeff,
        ),
      ];
    } else {
      picks = PlacePickUtils._preparePicksForStraightMode(
        shoppingCart,
        currencyMode,
      );
    }

    if (shoppingCart.sourcePick && picks) {
      const sourcePick = shoppingCart.sourcePick;
      picks = picks.map(pick => {
        if (
          isEqual(
            sourcePick.proposalFKeys.sort(),
            pick.selections.map(({ proposal_fkey }) => proposal_fkey).sort(),
          )
        ) {
          return { ...pick, copied_from_pick_fkey: sourcePick.pickFKey };
        }

        return pick;
      });
    }

    return {
      unique_shopping_cart_id: shoppingCart.unique_shopping_cart_id,
      verify_mode: 0,
      picks,
    };
  }

  public static isCurrentPickValid = (): boolean => {
    const state = staticGetState();
    const cartItems = state.sportsBook.shopping_cart.items;
    const events = state.sportsBook.events;
    const proposalsMap = state.sportsBook.proposals;
    const betSupermarket =
      state.sportsBook.bet_supermarket.shelves_by_conflict_fkey;
    const pickType = state.sportsBook.shopping_cart.pickType;
    const hasChangesInCart = ProposalUtils.detectChanges(
      cartItems,
      proposalsMap,
      betSupermarket,
      events,
      pickType,
    );
    const isPlacedBettingAvailable = PickUtils.isPlacedBettingEventsAvailable(
      cartItems,
      events,
    );

    return !hasChangesInCart && isPlacedBettingAvailable;
  };

  public static prepareSGPCoeffRequest = (
    shoppingCart: Data__SB_ShoppingCart,
    currencyMode: TCurrencyMode,
  ): DataReq__SB_PlacePickRequest => {
    const totalAmountPlaced = PlacePickUtils.extractAmountPlaced(
      shoppingCart,
      CONST_TOTAL_AMOUNT_PARLAY + "",
      currencyMode,
    );

    // 2020-05-30 / Ivan / quick hack / use some existing calc solution, needs to be moved to separate utils class
    const expectedPayoutAmount = PickUtils.calcRawPayoutAmountForWholeCartImpl(
      shoppingCart,
      totalAmountPlaced,
    );

    const pick: DataReq__SB_Pick = {
      type: PickType.CONST_83_SAME_GAME_PARLAY,
      currency_code: currencyMode,
      risk_amount: totalAmountPlaced,
      confirmed_server_quote_coeff: 0,
      expected_payout_amount: expectedPayoutAmount,
      selections: PlacePickUtils._prepareSelectionsForParlayPick(
        shoppingCart.items,
      ),
      is_same_game_parlay_mode: true,
    };

    return {
      unique_shopping_cart_id: shoppingCart.unique_shopping_cart_id,
      verify_mode: 0,
      picks: [pick],
    };
  };

  public static preparePicksForParlayMode(
    shoppingCart: Data__SB_ShoppingCart,
    currencyMode: TCurrencyMode,
    pickType: PickType,
    sgpConfirmedCoeff: number,
  ): DataReq__SB_Pick {
    // parlay mode - single pick
    const totalAmountPlaced = PlacePickUtils.extractAmountPlaced(
      shoppingCart,
      CONST_TOTAL_AMOUNT_PARLAY + "",
      currencyMode,
    );

    // 2020-05-30 / Ivan / quick hack / use some existing calc solution, needs to be moved to separate utils class
    const expectedPayoutAmount = PickUtils.calcRawPayoutAmountForWholeCartImpl(
      shoppingCart,
      totalAmountPlaced,
    );

    const confirmedServerQuoteCoeff =
      pickType === PickType.CONST_82_PARLAY ? 0 : sgpConfirmedCoeff;

    return {
      type: pickType,
      currency_code: currencyMode,
      risk_amount: totalAmountPlaced,
      confirmed_server_quote_coeff: confirmedServerQuoteCoeff,
      expected_payout_amount: expectedPayoutAmount,
      selections: PlacePickUtils._prepareSelectionsForParlayPick(
        shoppingCart.items,
      ),
      is_same_game_parlay_mode: true,
    };
  }

  public static extractAmountPlaced(
    shoppingCart: Data__SB_ShoppingCart,
    cartItemId: string,
    currencyMode: TCurrencyMode,
  ): number {
    if (cartItemId === CONST_TOTAL_AMOUNT_PARLAY + "") {
      if (currencyMode !== UIConstants.CONST__331__CASH) {
        return shoppingCart.totalPlacedGoldCoinsAmount;
      }
      return shoppingCart.totalPlacedFliffCashAmount;
    }

    const item = shoppingCart.items[cartItemId];

    // should never happen ?
    if (item === undefined) {
      return 0;
    }

    if (currencyMode !== UIConstants.CONST__331__CASH) {
      return item.placed_amount__gold_coins;
    }
    return item.placed_amount__fliff_cash;
  }

  public static countPlayThroughAmountOnSubmit(
    shoppingCart: Data__SB_ShoppingCart,
    estimatedParlayPayout: number,
    playableAmount: number,
  ): number {
    if (shoppingCart.pickType === PickType.CONST_81_STRAIGHT) {
      let sum = 0;
      for (const key in shoppingCart.items) {
        const cartItem = shoppingCart.items[key];
        sum += this._calcMaxProgressPerPick(
          cartItem.placed_amount__fliff_cash,
          PickUtils.recalcRawAmountForCoeff(
            cartItem.placed_amount__fliff_cash,
            PickUtils.usaCoeffToEuCoeff(cartItem.coeff),
          ),
          playableAmount,
        );
      }

      return sum;
    }

    return this._calcMaxProgressPerPick(
      shoppingCart.totalPlacedFliffCashAmount,
      estimatedParlayPayout,
      playableAmount,
    );
  }

  private static _calcMaxProgressPerPick(
    riskAmount: number,
    estimatedPayoutAmount: number,
    playableAmount: number,
  ): number {
    if (estimatedPayoutAmount === 0) {
      return 0;
    }

    let maxProgressPerPick = Math.min(riskAmount, playableAmount);
    const diff = estimatedPayoutAmount - riskAmount;

    if (maxProgressPerPick > diff) {
      maxProgressPerPick = diff;
    }

    return maxProgressPerPick;
  }

  private static _preparePicksForStraightMode(
    shoppingCart: Data__SB_ShoppingCart,
    currencyMode: TCurrencyMode,
  ): DataReq__SB_Pick[] {
    const picks: DataReq__SB_Pick[] = [];

    // 2020-05-29 / Ivan / seems to be the best type safe solution
    for (const key in shoppingCart.items) {
      const item = shoppingCart.items[key];

      const selections: DataReq__SB_Proposal_PickSelection[] = [];
      let itemKey = null;

      const citem = item as Data__SB_ShoppingCartProposalItem;
      itemKey = citem.group_id;
      const selobj: DataReq__SB_Proposal_PickSelection = {
        proposal_fkey: citem.proposal_fkey,
        coeff: item.coeff,
      };
      selections.push(selobj);

      const riskAmount = PlacePickUtils.extractAmountPlaced(
        shoppingCart,
        itemKey,
        currencyMode,
      );

      // 2020-05-30 / Ivan / quick hack / use some existing calc solution, needs to be moved to separate utils class
      const expectedPayoutAmount = PickUtils.calcRawPayoutAmountForCartItemImpl(
        item,
        riskAmount,
      );

      const pick: DataReq__SB_Pick = {
        type: PickType.CONST_81_STRAIGHT,
        currency_code: currencyMode,
        risk_amount: riskAmount,
        confirmed_server_quote_coeff: 0,
        expected_payout_amount: expectedPayoutAmount,
        selections: selections,
        is_same_game_parlay_mode: true,
      };
      picks.push(pick);
    }

    return picks;
  }

  private static _prepareSelectionsForParlayPick = (
    cartItems: Data__SB_ShoppingCart["items"],
  ): DataReq__SB_Proposal_PickSelection[] => {
    const selections = [];

    // 2020-05-29 / Ivan / seems to be the best type safe solution
    for (const key in cartItems) {
      const citem = cartItems[key];
      const selobj: DataReq__SB_Proposal_PickSelection = {
        proposal_fkey: citem.proposal_fkey,
        coeff: citem.coeff,
      };
      selections.push(selobj);
    }

    return selections;
  };
}
