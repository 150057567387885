export class FliffWebSocketStats {
  public c_11_connect = 0;
  public c_12_open = 0;
  public c_13_error = 0;
  public c_14_on_close = 0;

  public c_18_connect_delay = -1;
  public c_19_open_delay = -1;

  public c_22_frames_out_skipped = 0;
  public c_23_frames_out_sent = 0;
  public c_29_bytes_out = 0;

  public c_31_frames_in = 0;
  public c_39_bytes_in = 0;

  public c_41_paused = 0;
  public c_42_resumed = 0;

  public lastReconnectStampMillis = 0; // last successfully connect
  public lastReconnectOutMessageStampMillis = 0; // local stamp for a first outgoing message after last successfully connect
  public lastReconnectInMessageServerStampMillis = 0; // server (message) stamp for a first incoming message after last successfully connect
  public lastReconnectInMessageLocalStampMillis = 0; // local stamp for a first incoming message after last successfully connects

  public lastCloseStampMillis = 0;
  public lastConnectStampMillis = 0;

  public errorWSSend = 0; // track errors in FliffWebSocket.send
  public errorWSOnMessage = 0; // track errors in FliffWebSocket.onMessage
  public errorWSOnOpen = 0; // track errors in FliffWebSocket.onOpen
  public errorWSClose = 0; // track errors in FliffWebSocket.close
  public errorWSError = 0; // track errors in FliffWebSocket.error

  public errorFSHeartbeat = 0; // track errors in FliffYSub.heartbeat
  public errorFSSend = 0; // track errors in FliffYSub.xrecv

  public errorHBOnIncomingMessage = 0; // track errors in HeraldBackend.onIncomingMessage
  public errorHBHeartbeat = 0; // track errors in HeraldBackend.heartbeat
  public errorHBSubscribe = 0; // track errors in HeraldBackend.subscribe
  public errorHBUnsubscribe = 0; // track errors in HeraldBackend.unsubscribe
  public errorHBReconnect = 0; // track errors in HeraldBackend.reconnect
  public errorHBDisconnect = 0; // track errors in HeraldBackend.disconnect

  public errorHUDecode = 0; // track errors in HeraldUtils.decodeSerializedMessage
}
