import {
  SocialAccessLevel,
  SocialPrivacyLevel,
} from "server/social/data/constants";
import { ValidationUtils } from "server/common/ValidationUtils";
import { FliffException } from "server/common/FliffException";
import * as I from "server/social/data/objects";
import { SocialUserType } from "server/social/data/constants";
import { TAnyAlias } from "src/types";

export const validate_SocialAccessLevel = (
  jsondata: TAnyAlias,
  debugInfo: string,
): SocialAccessLevel => {
  const nn: number = ValidationUtils.assertPositiveInteger(jsondata, debugInfo);

  if (!(nn in SocialAccessLevel)) {
    throw new FliffException(
      FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
      "unexpected TPickType [" + nn + "] [" + debugInfo + "]",
    );
  }

  return nn;
};

export const validate_SocialPrivacyLevel = (
  jsondata: TAnyAlias,
  debugInfo: string,
): SocialPrivacyLevel => {
  const nn: number = ValidationUtils.assertPositiveInteger(jsondata, debugInfo);

  if (!(nn in SocialPrivacyLevel)) {
    throw new FliffException(
      FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
      "unexpected TPickType [" + nn + "] [" + debugInfo + "]",
    );
  }

  return nn;
};

export const validate_StatsScoreSize = <T extends object>(
  jsonData: TAnyAlias,
  debugInfo: string,
): T[keyof T] => {
  if (!Object.values(I.IFSMUserStatsInfoCardScoreSize).includes(jsonData)) {
    throw new FliffException(
      FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
      "validateEnum unexpected [" + jsonData + "] [" + debugInfo + "]",
    );
  }

  return jsonData;
};

export const validate_UserType = <T extends object>(
  jsonData: TAnyAlias,
  debugInfo: string,
): T[keyof T] => {
  if (!Object.values(SocialUserType).includes(jsonData)) {
    throw new FliffException(
      FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
      "validateEnum unexpected [" + jsonData + "] [" + debugInfo + "]",
    );
  }

  return jsonData;
};
