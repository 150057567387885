import { FliffException } from "server/common/FliffException";
import { LogUploaderMemoryStorage } from "utils/Logger/LogUploaderMemoryStorage";
import { PersistentStorage } from "utils/PersistentStorage";

const LOG_ENTRY_FIRST_ID = "LOG_ENTRY_FIRST_ID" as const;
const LOG_ENTRY_NEXT_ID = "LOG_ENTRY_NEXT_ID" as const;
const LOG_ENTRY_DATA_PREFIX = "LOG_ENTRY_DATA_PREFIX_" as const;

export class LogUploaderPersistentStorage extends LogUploaderMemoryStorage {
  private _logEntryFirstId = 0;
  private _logEntryNextId = 0;

  public init(): void {
    return this._storageInit();
  }

  public getLogKey(key: string): string {
    return PersistentStorage.get(key);
  }

  public setLogKey(key: string, value: string): void {
    return PersistentStorage.set(key, value);
  }

  public getNextSnippetForUpload(): [number, string] {
    return super.getNextSnippetForUpload();
  }

  public append(message: string): void {
    super.append(message);
    this._storageAppend(message);
  }

  public removeOldest(count: number): void {
    super.removeOldest(count);
    this._storageRemoveOldest(count);
  }

  private _removeLogKey(key: string): void {
    try {
      PersistentStorage.remove(key);
    } catch (error) {
      console.log(" x in LogUploaderPersistentStorage.storage_remove", error);
    }
  }

  private _storageInit(): void {
    try {
      this._storageInitIndexes();
      const oldMessages = this._storageLoadOldMessages();
      this._storageSaveNewPendingMessages();

      // merge old data from storage at the beginning of the array
      this.pendingMessages = oldMessages.concat(this.pendingMessages);
    } catch (error) {
      // there is something wrong with storage, fallback to memory approach
      this._saveErrorInMemory(
        "storageInit",
        "",
        error as Error | FliffException,
      );
    }
  }

  private _storageInitIndexes(): void {
    const [min, max] = this._getMinMaxEntries();
    // throws Exception
    this._logEntryFirstId = min;
    this._logEntryNextId = max;
  }

  private _getMinMaxEntries(): [number, number] {
    const logsEntries = PersistentStorage.getAllKeys()
      .filter(key => key.includes(LOG_ENTRY_DATA_PREFIX))
      .map(el => Number(el.replace(LOG_ENTRY_DATA_PREFIX, "")));
    const min = Math.min(...logsEntries);
    const max = Math.max(...logsEntries);

    return [isFinite(min) ? min : 0, isFinite(max) ? max : 0];
  }

  private _storageLoadOldMessages(): string[] {
    // throws Exception
    const res: string[] = [];

    // load all 'old' messages, saved in persistent storage
    for (let id = this._logEntryFirstId; id < this._logEntryNextId; id++) {
      const message = this.getLogKey(LOG_ENTRY_DATA_PREFIX + id);
      if (message) {
        res.push(`${message}`);
      }
    }

    return res;
  }

  private _storageSaveNewPendingMessages(): void {
    // throws Exception
    const len = this.pendingMessages.length;

    // save all pending items at the end of the queue
    for (let i = 0; i < len; i++) {
      const message = this.pendingMessages[i];
      this.setLogKey(
        LOG_ENTRY_DATA_PREFIX + (this._logEntryNextId + i),
        "" + message,
      );
    }

    // update index for item at the end of the queue
    this._logEntryNextId += len;
    this.setLogKey(LOG_ENTRY_NEXT_ID, "" + this._logEntryNextId);
  }

  private _storageAppend(message: string) {
    try {
      // first save the data in next storage cell
      const id = this._logEntryNextId;
      this.setLogKey(LOG_ENTRY_DATA_PREFIX + id, "" + message);

      // then update index
      // in case of error, we'll may lose the log message, but won't have any empty cells
      this._logEntryNextId++;
      this.setLogKey(LOG_ENTRY_NEXT_ID, "" + this._logEntryNextId);
    } catch (error) {
      // there is something wrong with storage, fallback to memory approach
      this._saveErrorInMemory(
        "storageAppend",
        "",
        error as Error | FliffException,
      );
    }
  }

  private _storageRemoveOldest(count: number): void {
    try {
      // first - save updated index
      const firstId = this._logEntryFirstId;
      this._logEntryFirstId += count;

      this.setLogKey(LOG_ENTRY_FIRST_ID, `${this._logEntryFirstId}`);

      // the deleted the actual items
      for (let i = 0; i < count; i++) {
        this._removeLogKey(LOG_ENTRY_DATA_PREFIX + (firstId + i));
      }
    } catch (error) {
      // there is something wrong with storage, fallback to memory approach
      this._saveErrorInMemory(
        "storageRemoveOldest",
        "",
        error as Error | FliffException,
      );
    }
  }

  private _saveErrorInMemory(
    debugPrefix: string,
    message: string,
    error: Error | FliffException,
  ) {
    const info =
      "  * saveErrorInMemory - [" +
      debugPrefix +
      " / " +
      message +
      "] error: " +
      error;

    console.log(info);
    super.append(info);
  }
}
