import { FliffException } from "server/common/FliffException";
import { ValidationUtils } from "server/common/ValidationUtils";

import {
  BetOrBetGroupStatus,
  ChannelType,
  ConflictType,
  DiffUpdateType,
  GiftCardRedemptionConfigType,
  MarketOfferSelectionChangeType,
  PickSelectionState,
  PickType,
  PublicChallengeType,
  RawPeriodType,
  UnfinishedOrderStateCategory,
  UIConstants,
  ServerUIMode,
  IdentityVerificationStatus,
  PlacePicksOperationStatus,
  UserAccountDetailsStatus,
  HeraldProtocolVersion,
  MoreMarketsChatCode,
  PlacePickPlayThroughMode,
  GiftCardsStatus,
} from "./constants";
import { BundleBonusSkin, BundleOfferSkin } from "server/core/data/constants";

import { TCurrencyMode } from "src/types";

export const validateChannelType = (
  jsonData: unknown,
  debugInfo: string,
): ChannelType =>
  ValidationUtils.validateEnum(
    ChannelType as unknown as ChannelType,
    jsonData,
    debugInfo,
  );

export const validateMoreMarketsChatMode = (
  jsonData: unknown,
  debugInfo: string,
): MoreMarketsChatCode =>
  ValidationUtils.validateEnum(
    MoreMarketsChatCode as unknown as MoreMarketsChatCode,
    jsonData,
    debugInfo,
  );

export const validateMarketOfferSelectionChangeType = (
  jsonData: unknown,
  debugInfo: string,
): MarketOfferSelectionChangeType =>
  ValidationUtils.validateEnum(
    MarketOfferSelectionChangeType as unknown as MarketOfferSelectionChangeType,
    jsonData,
    debugInfo,
  );

export const validatePickSelectionState = (
  jsonData: unknown,
  debugInfo: string,
): PickSelectionState => {
  let nn: number = ValidationUtils.assertPositiveIntegerOrZero(
    jsonData,
    debugInfo,
  );

  if (nn === 0) {
    nn = 501;
  }

  if (!(nn in PickSelectionState)) {
    throw new FliffException(
      FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
      "unexpected TPickSelectionState [" + nn + "] [" + debugInfo + "]",
    );
  }

  return nn as PickSelectionState;
};

export const validatePickType = (
  jsonData: unknown,
  debugInfo: string,
): PickType =>
  ValidationUtils.validateEnum(
    PickType as unknown as PickType,
    jsonData,
    debugInfo,
  );

export const validatePickTypeOrNull = (
  jsonData: unknown,
  debugInfo: string,
): PickType | null => {
  const nn = ValidationUtils.assertPositiveIntegerOrZero(jsonData, debugInfo);

  if (nn === 0 || nn === 9999) {
    return null;
  }

  if (!(nn in PickType)) {
    throw new FliffException(
      FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
      "unexpected TPickType [" + nn + "] [" + debugInfo + "]",
    );
  }

  return nn as PickType;
};

export const validatePickCurrency = (
  jsonData: unknown,
  debugInfo: string,
): TCurrencyMode => {
  const nn = ValidationUtils.assertPositiveInteger(jsonData, debugInfo);

  if (
    ![
      UIConstants.CONST__331__CASH,
      UIConstants.CONST__333__FLIFF_COINS,
    ].includes(nn)
  ) {
    throw new FliffException(
      FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
      "unexpected TPickCurrency [" + nn + "] [" + debugInfo + "]",
    );
  }

  return nn as TCurrencyMode;
};

export const validateRawPeriodType = (
  jsonData: unknown,
  debugInfo: string,
): RawPeriodType =>
  ValidationUtils.validateEnum(
    RawPeriodType as unknown as RawPeriodType,
    jsonData,
    debugInfo,
  );

export const validatePublicChallengeType = <
  ChallengeType extends PublicChallengeType,
>(
  jsonData: unknown,
  debugInfo: string,
): ChallengeType =>
  ValidationUtils.validateEnum(
    PublicChallengeType as unknown as ChallengeType,
    jsonData,
    debugInfo,
  );

export const validateConflictType = (
  jsonData: unknown,
  debugInfo: string,
): ConflictType =>
  ValidationUtils.validateEnum(
    ConflictType as unknown as ConflictType,
    jsonData,
    debugInfo,
  );

export const validateConflictTypeOrNull = (
  jsonData: unknown,
  debugInfo: string,
): ConflictType | null => {
  const nn: number = ValidationUtils.assertPositiveIntegerOrZero(
    jsonData,
    debugInfo,
  );

  if (nn === 0 || nn === 9999) {
    return null;
  }

  if (!(nn in ConflictType)) {
    throw new FliffException(
      FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
      "unexpected TConflictType [" + nn + "] [" + debugInfo + "]",
    );
  }

  return nn as ConflictType;
};

export const validateBetOrBetGroupStatus = (
  jsonData: unknown,
  debugInfo: string,
): BetOrBetGroupStatus =>
  ValidationUtils.validateEnum(
    BetOrBetGroupStatus as unknown as BetOrBetGroupStatus,
    jsonData,
    debugInfo,
  );

export const validateDiffUpdateType = (
  jsonData: unknown,
  debugInfo: string,
): DiffUpdateType =>
  ValidationUtils.validateEnum(
    DiffUpdateType as unknown as DiffUpdateType,
    jsonData,
    debugInfo,
  );

export const validateUnfinishedOrderState = (
  jsonData: unknown,
  debugInfo: string,
): UnfinishedOrderStateCategory =>
  ValidationUtils.validateEnum(
    UnfinishedOrderStateCategory as unknown as UnfinishedOrderStateCategory,
    jsonData,
    debugInfo,
  );

export const validateGiftCardRedemptionConfigType = (
  jsonData: unknown,
  debugInfo: string,
): GiftCardRedemptionConfigType =>
  ValidationUtils.validateEnum(
    GiftCardRedemptionConfigType as unknown as GiftCardRedemptionConfigType,
    jsonData,
    debugInfo,
  );

export const validateServerUIMode = (
  jsonData: unknown,
  debugInfo: string,
): ServerUIMode =>
  ValidationUtils.validateEnum(
    ServerUIMode as unknown as ServerUIMode,
    jsonData,
    debugInfo,
  );

export const validateIdentityVerificationStatus = (
  jsonData: unknown,
  debugInfo: string,
): IdentityVerificationStatus =>
  ValidationUtils.validateEnum(
    IdentityVerificationStatus as unknown as IdentityVerificationStatus,
    jsonData,
    debugInfo,
  );

export const validatePlacePicksOperationStatus = (
  jsonData: unknown,
  debugInfo: string,
): PlacePicksOperationStatus =>
  ValidationUtils.validateEnum(
    PlacePicksOperationStatus as unknown as PlacePicksOperationStatus,
    jsonData,
    debugInfo,
  );

export const validateBundleOfferSkin = (
  jsonData: unknown,
  debugInfo: string,
): BundleOfferSkin =>
  ValidationUtils.validateEnum(
    BundleOfferSkin as unknown as BundleOfferSkin,
    jsonData,
    debugInfo,
  );

export const validateBundleBonusSkin = (
  jsonData: unknown,
  debugInfo: string,
): BundleBonusSkin =>
  ValidationUtils.validateEnum(
    BundleBonusSkin as unknown as BundleBonusSkin,
    jsonData,
    debugInfo,
  );

export const validateUserAccountDetailsStatus = (
  jsonData: unknown,
  debugInfo: string,
): UserAccountDetailsStatus =>
  ValidationUtils.validateEnum(
    UserAccountDetailsStatus as unknown as UserAccountDetailsStatus,
    jsonData,
    debugInfo,
  );

export const validateHeraldProtocolVersion = (
  jsonData: unknown,
  debugInfo: string,
): HeraldProtocolVersion =>
  ValidationUtils.validateEnum(
    HeraldProtocolVersion as unknown as HeraldProtocolVersion,
    jsonData,
    debugInfo,
  );

export const validatePlacePickPlayThroughMode = (
  jsonData: unknown,
  debugInfo: string,
): PlacePickPlayThroughMode =>
  ValidationUtils.validateEnum(
    PlacePickPlayThroughMode as unknown as PlacePickPlayThroughMode,
    jsonData,
    debugInfo,
  );

export const validateGiftCardsStatus = (
  jsonData: unknown,
  debugInfo: string,
): GiftCardsStatus =>
  ValidationUtils.validateEnum(
    GiftCardsStatus as unknown as GiftCardsStatus,
    jsonData,
    debugInfo,
  );
