import { FliffWebSocketStats } from "server/herald/fliffSocketImpl/FliffWebSocketStats";
import { TAnyAlias } from "src/types";

export enum FWebSocketState {
  IDLE,
  SCHEDULE_RECONNECT,
  ESTABLISHING_CONNECTION,
  ACTIVE,
  CLOSED,
}

export interface IEndpoint {
  get address(): string;
  get stats(): FliffWebSocketStats;

  send(msg: string): boolean;
  close(): void;
  removeListener(
    event: string | symbol,
    listener: (...args: TAnyAlias[]) => void,
  ): this;
  on(event: string | symbol, listener: (...args: TAnyAlias[]) => void): this;
}

export interface IOnIncomingMessageCallBack {
  onIncomingMessage(message: string): void;
}

export interface IOnEndpointConnectedCallback {
  // temporary any, until we remove the zmq code
  getMessagesOnConnect(endpoint: TAnyAlias): string[];
}
