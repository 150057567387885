import { useCallback, useMemo, useState } from "react";

import "./styles.scss";

import Checkbox from "components/atoms_new/shared/Checkbox";
import Inputs from "components/atoms_new/shared/Inputs";
import SkipValidations from "components/atoms_new/shared/Inputs/atoms/SkipValidations";
import ModalHeader from "components/molecules_new/desktop/Modal/atoms/ModalHeader";
import { FliffException } from "server/common/FliffException";
import { SportsBookBIF } from "server/legacyCore/server/SportsBookBIF";
import { SportsBookSignInBIF } from "server/legacyCore/server/SportsBookSignInBIF";
import { IStringMap, TAnyAlias } from "src/types";
import FormValidationUtils, {
  IValidationReturnType,
} from "utils/FormValidationUtils";
import Logger from "utils/Logger";
import { alphaNumericReplacement } from "utils/validators";
import React from "react";
import Button from "components/atoms_new/desktop/Button";
import { IModalControls } from "components/atoms_new/desktop/Modals/AuthenticationModal/types";

interface IState {
  username: IValidationReturnType;
  email: IValidationReturnType;
  password: IValidationReturnType;
  termsAccepted: boolean;
  age: IValidationReturnType;
  isTermsAcceptedValid: boolean;
  couponCode: IValidationReturnType;
  verifiedUsernames: IStringMap<boolean>;
  verifiedEmails: IStringMap<boolean>;
}

const SignUpWithEmail = React.forwardRef<HTMLDivElement, IModalControls>(
  ({ onClose, openNext, openPrevious }, forwardedRef) => {
    const [state, setState] = useState<IState>({
      username: { value: "", isValid: true, validationError: "", state: -1 },
      email: { value: "", isValid: true, validationError: "", state: -1 },
      password: { value: "", isValid: true, validationError: "", state: -1 },
      termsAccepted: false,
      age: { value: "", isValid: true, validationError: "", state: -1 },
      couponCode: { value: "", isValid: true, validationError: "", state: -1 },
      isTermsAcceptedValid: true,
      verifiedUsernames: {},
      verifiedEmails: {},
    });

    const validateUsername = useCallback(
      () =>
        FormValidationUtils.validateUsername(
          state.username.value,
          typeof state.verifiedUsernames[state.username.value] !==
            "undefined" && state.verifiedUsernames[state.username.value],
        ),
      [state],
    );

    const validateAge = useCallback(
      () => FormValidationUtils.validateAge(state.age.value),
      [state],
    );

    const validateEmail = useCallback(
      () =>
        FormValidationUtils.validateEmail(
          state.email.value,
          typeof state.verifiedEmails[state.email.value] !== "undefined" &&
            state.verifiedEmails[state.email.value],
        ),
      [state],
    );

    const validatePassword = useCallback(
      () => FormValidationUtils.validateOldPassword(state.password.value),
      [state],
    );

    const handlePasswordChange = useCallback(
      (value: string) =>
        setState(prevState => ({
          ...prevState,
          password: { ...prevState.password, value },
        })),
      [],
    );

    const handleCouponCodeChange = useCallback(
      (value: string) =>
        setState(prevState => ({
          ...prevState,
          couponCode: {
            ...prevState.couponCode,
            value: alphaNumericReplacement(value),
          },
        })),
      [setState],
    );

    const isFormValid = useMemo(() => {
      return (
        validateUsername().isValid &&
        validateEmail().isValid &&
        validatePassword().isValid &&
        validateAge().isValid &&
        state.termsAccepted
      );
    }, [
      validateUsername,
      validateEmail,
      validatePassword,
      validateAge,
      state.termsAccepted,
    ]);

    const handlePasswordBlur = useCallback(
      () =>
        setState(prevState => ({ ...prevState, password: validatePassword() })),
      [validatePassword, setState],
    );

    const handleTermsChanged = useCallback(
      () =>
        setState(prevState => ({
          ...prevState,
          termsAccepted: !prevState.termsAccepted,
        })),
      [setState],
    );

    const handleAgeChanged = useCallback(
      (value: string) => {
        setState(prevState => ({
          ...prevState,
          age: FormValidationUtils.validateAge(value),
        }));
      },
      [setState],
    );

    const handleBackgroundValidation = useCallback(
      async (type: "username" | "email") => {
        try {
          const username = state.username.value;
          const email = state.email.value;
          const { is_error, resultCode } =
            await SportsBookBIF.background_validate_registration_data({
              email: type === "email" ? email : "",
              username: type === "username" ? username : "",
              is_background_check: true,
            });
          const usernameAlreadyExists = resultCode === 30351;
          const emailAlreadyExists = resultCode === 30352;

          if (is_error && resultCode !== 30351 && resultCode !== 30352) {
            return;
          }

          setState(prevState => {
            const newState = {
              ...prevState,
              verifiedUsernames: {
                ...prevState.verifiedUsernames,
                [username]: usernameAlreadyExists
                  ? false
                  : !usernameAlreadyExists,
              },
              verifiedEmails: {
                ...prevState.verifiedEmails,
                [email]: emailAlreadyExists ? false : !emailAlreadyExists,
              },
            };

            if (type === "username") {
              // Re-run validations after updating verification status
              newState.username = FormValidationUtils.validateUsername(
                newState.username.value,
                typeof newState.verifiedUsernames[newState.username.value] !==
                  "undefined" &&
                  newState.verifiedUsernames[newState.username.value],
              );
            }
            if (type === "email") {
              newState.email = FormValidationUtils.validateEmail(
                newState.email.value,
                typeof newState.verifiedEmails[newState.email.value] !==
                  "undefined" && newState.verifiedEmails[newState.email.value],
              );
            }

            return newState;
          });
        } catch (err: TAnyAlias) {
          Logger.logMessage("[SignUpWithEmailScreen] " + err.message);
        }
      },
      [state],
    );

    const handleUsernameChange = useCallback((value: string) => {
      setState(prevState => ({
        ...prevState,
        username: { ...prevState.username, value },
      }));
    }, []);

    const handleEmailChange = useCallback((value: string) => {
      setState(prevState => ({
        ...prevState,
        email: { ...prevState.email, value },
      }));
    }, []);

    const handleAgeBlur = useCallback(
      () => setState(prevState => ({ ...prevState, age: validateAge() })),
      [validateAge],
    );

    const onCreatePressed = useCallback(async (): Promise<void> => {
      if (!isFormValid) {
        return;
      }

      const username = state.username.value.trim();
      const response = await SportsBookBIF.blocking_validate_registration_data({
        username,
        email: state.email.value.trim(),
        is_background_check: false,
      });

      if (response.is_error) {
        return;
      }

      const signUpFilledData = {
        username,
        email: state.email.value.trim(),
        password: state.password.value.trim(),
        accepted_version_of_terms: 1,
        //TODO: Be filled on Branch replacement.
        referrer_hash: "",
      };

      SportsBookSignInBIF.signupViaEmailWithAutoSignIn({
        ...signUpFilledData,
        login_token: "email:" + signUpFilledData.email,
        bonus_code: state.couponCode.value,
      }).then(opres => {
        if (opres.is_error) {
          onClose();
          return;
        }

        if (
          opres.resultCode ===
          FliffException.ERROR_1903__SESSION_EXPIRED__PROFILE_NOT_COMPLETED
        ) {
          openNext("completeProfile");
        } else if (
          opres.resultCode ===
          FliffException.ERROR_1905__SESSION_EXPIRED__NEED_VERIFIED_PRIMARY_PHONE_NUMBER
        ) {
          openNext("signInPhone", { mode: 1 });
        } else if (
          opres.resultCode ===
          FliffException.ERROR_1906__SESSION_EXPIRED__NEED_ACCEPTED_TERMS_OF_USE
        ) {
          // history.replace("/terms-of-use");
          // TODO: Navigate to terms of use modal once its implemented
        } else {
          onClose();
          throw new Error("unexpected case");
        }
      });
    }, [state, isFormValid, openNext, onClose]);

    const handleUsernameBlur = useCallback(() => {
      setState(prevState => ({
        ...prevState,
        username: validateUsername(),
      }));
      handleBackgroundValidation("username");
    }, [validateUsername, handleBackgroundValidation]);

    const handleEmailBlur = useCallback(() => {
      setState(prevState => ({
        ...prevState,
        email: validateEmail(),
      }));
      handleBackgroundValidation("email");
    }, [validateEmail, handleBackgroundValidation]);

    return (
      <div className="sign-up-with-email" ref={forwardedRef}>
        <ModalHeader
          title={"Get Started"}
          onClose={onClose}
          onGoBack={openPrevious}
        />
        <p className="sign-up-with-email__description">
          Discover a groundbreaking social sportsbook packed with exciting
          features, including rewards, customizable avatars, and the chance to
          connect with friends!
        </p>

        <Inputs.Label htmlFor="username" label="Username" />
        <Inputs.RegularText
          maxLength={32}
          onBlur={handleUsernameBlur}
          onChangeText={handleUsernameChange}
          placeholder={"Enter Username"}
          validationState={state.username}
          id="username"
          theme="dark"
        />

        <Inputs.Label htmlFor="age" label="Date of Birth" />
        <Inputs.DateInput
          value={state.age.value}
          onChangeText={handleAgeChanged}
          onBlur={handleAgeBlur}
          validationState={state.age}
          id="age"
        />

        <Inputs.Label htmlFor="email" label="Email" />
        <Inputs.RegularText
          validationState={state.email}
          onChangeText={handleEmailChange}
          onBlur={handleEmailBlur}
          placeholder={"Enter Email"}
          type={"email"}
          id="email"
          theme="dark"
        />

        <Inputs.Label htmlFor="password" label="Password" />
        <Inputs.RegularText
          autoCapitalize={"none"}
          type={"password"}
          onBlur={handlePasswordBlur}
          onChangeText={handlePasswordChange}
          validationState={state.password}
          placeholder={"Enter Password"}
          id="password"
          theme="dark"
        />
        <Inputs.Label htmlFor="coupon" label="Referrer Code (Optional)" />
        <SkipValidations
          autoCapitalize={"none"}
          onChangeText={handleCouponCodeChange}
          value={state.couponCode.value}
          maxLength={20}
          placeholder={"Enter Promo Code"}
          id="coupon"
          theme="dark"
        />
        <Checkbox
          type={"terms"}
          isValid={state.isTermsAcceptedValid}
          isChecked={state.termsAccepted}
          onChange={handleTermsChanged}
          className="sign-up-with-email__checkbox"
          theme="dark"
        />
        <Button
          label={"Create Account"}
          onClick={onCreatePressed}
          disabled={!isFormValid}
        />
      </div>
    );
  },
);

SignUpWithEmail.displayName = "SignUpWithEmail";

export default SignUpWithEmail;
