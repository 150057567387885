import { IFliffInvocation, IFliffRequest } from "server/common/data/objects";
import { IInternalProtocolRequestBuilder } from "server/common/interfaces";
import {
  IFCMProtocolRequest,
  IFSMProtocolRequestHeader,
  IFSMProtocolRequestSlotsMeta,
} from "server/social/data/objects";

import { DevConstants } from "src/DevConstants";
import { PersistentStorage } from "utils/PersistentStorage";
import { staticGetState } from "reduxLocal/store";
import SocialUtils from "utils/SocialUtils";
import AppTokensManager from "server/common/AppTokensManager";
import CommonLocationManager from "utils/LocationManagers/Common";
import AppSelectors from "reduxLocal/app/app.selectors";
import DeviceInfo from "utils/DeviceInfo";

export class InternalProtocolRequestBuilderImpl
  implements IInternalProtocolRequestBuilder<IFliffRequest> {
  public buildProtocolRequest<Request extends IFliffRequest>(
    request: Request,
    xRequests: IFliffRequest[] | null,
  ): IFCMProtocolRequest<Request> {
    return {
      header: this._buildHeader(),
      invocation: this._buildInvocation(request),
      x_invocations: this._buildInvocations(xRequests),
      x_meta: this._buildSlotMeta(),
    };
  }

  private _buildHeader(): IFSMProtocolRequestHeader {
    // For now, we use logged-in user fKey as a token.
    const authToken = staticGetState().sportsBook.profile.user_id;
    const helper = AppTokensManager.apiExecutorTokensHelper;
    const accessToken = helper.tokens.accessToken;

    return {
      device_x_id: `${DeviceInfo.OS}.${PersistentStorage.cachedDeviceId}`,
      app_x_version: DevConstants.appVersionPlusCapability,
      app_install_token: PersistentStorage.cachedInstallToken,
      // "Distinguishing" between public/private. However, under the hood they are all public.
      auth_token:
        authToken === -1 ? "" : SocialUtils.toValidUserFkey(authToken),
      conn_id: 0,
      access_token: accessToken,
      platform: DevConstants.isDevMode ? "dev" : "prod",
      usa_state_code: CommonLocationManager.lastSetRegionCode,
      usa_state_code_source: CommonLocationManager.debugSourceInfo,
    };
  }

  private _buildInvocation<Request extends IFliffRequest>(
    request: Request,
  ): IFliffInvocation<Request> {
    return { request };
  }

  private _buildInvocations<Request extends IFliffRequest>(
    xRequests: Request[] | null,
  ): IFliffInvocation<Request>[] | null {
    if (xRequests === null) {
      return null;
    }

    return xRequests.map(this._buildInvocation);
  }

  private _buildSlotMeta(): IFSMProtocolRequestSlotsMeta | null {
    const coreState = staticGetState();
    const isAuthenticated = AppSelectors.isAuthenticated(coreState);

    if (isAuthenticated) {
      return {
        config_obj_version: coreState.social.socialConfig.config_version,
        // TODO: Add mapping after https://fliff.atlassian.net/browse/SF-1243 is done
        logged_user_profie_obj_version: -1,
      };
    }

    return {
      config_obj_version: -1,
      // TODO: Add mapping after https://fliff.atlassian.net/browse/SF-1243 is done
      logged_user_profie_obj_version: -1,
    };
  }
}
