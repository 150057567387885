import { FliffException } from "./FliffException";
import { TAnyAlias } from "src/types";
import { AppUtils } from "utils/AppUtils";

export class ValidationUtils {
  public static assertNoNNullMap<Data>(data: unknown, debugInfo: string): Data {
    ValidationUtils.assertNonNullData(data, debugInfo);

    if (data !== Object(data)) {
      throw new FliffException(
        FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
        "assert_non_null_map- (data !== Object(data)) [" +
          data +
          "] for [" +
          debugInfo +
          "]",
      );
    }

    return data as Data;
  }

  public static assertNonNullData<Data>(
    data: unknown,
    debugInfo: string,
  ): Data {
    if (typeof data === "undefined") {
      throw new FliffException(
        FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
        "assert_non_null_data - (detected undefined) [" +
          data +
          "] for [" +
          debugInfo +
          "]",
      );
    }
    if (data === null) {
      throw new FliffException(
        FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
        "assert_non_null_data - (detected null) [" +
          data +
          "] for [" +
          debugInfo +
          "]",
      );
    }

    return data as Data;
  }

  public static assertNonEmptyString<Data extends string>(
    data: unknown,
    debugInfo: string,
  ): Data {
    return ValidationUtils._commonAssertString(data, false, debugInfo);
  }

  public static assertOptionalString<Data extends string>(
    data: unknown,
    debugInfo: string,
  ): Data {
    return ValidationUtils._commonAssertString(data, true, debugInfo);
  }

  public static assertAnyInteger<Data extends number>(
    data: unknown,
    debugInfo: string,
  ): Data {
    return ValidationUtils._commonAssertInteger(data, debugInfo);
  }

  public static assertOptionalInteger<Data extends number>(
    data: unknown,
    debugInfo: string,
  ): Data {
    return ValidationUtils._commonAssertInteger(data, debugInfo, true);
  }

  public static assertPositiveInteger<Data extends number>(
    data: unknown,
    debugInfo: string,
  ): Data {
    const odata = data;

    data = ValidationUtils._commonAssertInteger(data, debugInfo);

    if ((data as Data) <= 0) {
      throw new FliffException(
        FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
        "assert_positive_integer - (data <= 0) [" +
          odata +
          "] for [" +
          debugInfo +
          "]",
      );
    }

    return data as Data;
  }

  public static assertPositiveFloatOrNull(
    data: TAnyAlias,
    debugInfo: string,
  ): number | null {
    if (data === null) {
      return null;
    }

    const odata = data;

    data = ValidationUtils._commonAssertFloat(data, debugInfo);

    if (data < 0) {
      throw new FliffException(
        FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
        "assert_positive_float_or_zero - (data < 0) [" +
          odata +
          "] for [" +
          debugInfo +
          "]",
      );
    }

    return data;
  }

  public static assertPositiveIntegerOrZero<Data extends number>(
    data: unknown,
    debugInfo: string,
  ): Data {
    const odata = data;

    data = ValidationUtils._commonAssertInteger(data, debugInfo);

    if ((data as Data) < 0) {
      throw new FliffException(
        FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
        "assert_positive_integer_or_zero - (data < 0) [" +
          odata +
          "] for [" +
          debugInfo +
          "]",
      );
    }

    return data as Data;
  }

  public static assertPositiveIntegerOrNull<Data extends number>(
    data: unknown,
    debugInfo: string,
  ): Data | null {
    if (AppUtils.isNullable(data)) {
      return null;
    }

    const odata = data;

    data = ValidationUtils._commonAssertInteger(data, debugInfo);

    if ((data as Data) <= 0) {
      throw new FliffException(
        FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
        "assert_positive_integer_or_null - (data <= 0) [" +
          odata +
          "] for [" +
          debugInfo +
          "]",
      );
    }

    return data as Data;
  }

  public static assertBoolean<Data extends boolean>(
    data: unknown,
    debugInfo: string,
  ): Data {
    const odata = data;

    ValidationUtils.assertNonNullData(data, debugInfo);

    if (data !== true && data !== false) {
      throw new FliffException(
        FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
        "assert_boolean - (non boolean data) [" +
          odata +
          "] for [" +
          debugInfo +
          "]",
      );
    }

    return data as Data;
  }

  public static assertNonNullArray<Data extends unknown[]>(
    data: unknown,
    debugInfo: string,
  ): Data {
    ValidationUtils.assertNonNullData(data, debugInfo);

    if (!Array.isArray(data)) {
      throw new FliffException(
        FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
        "assert_non_null_array - (!Array.isArray(data)) [" +
          data +
          "] for [" +
          debugInfo +
          "]",
      );
    }

    return data as Data;
  }

  public static validateEnum<Enum>(
    inputEnum: Enum,
    data: unknown,
    debugInfo: string,
  ): Enum {
    const nn = ValidationUtils.assertAnyInteger(data, debugInfo);

    if (typeof inputEnum !== "object" || !inputEnum || !(nn in inputEnum)) {
      throw new FliffException(
        FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
        `unexpected [${nn}] [${debugInfo}]`,
      );
    }

    return nn as Enum;
  }

  private static _commonAssertString<Data extends string>(
    data: unknown,
    isEmptyStringAllowed: boolean,
    debugInfo: string,
  ): Data {
    const odata = data;

    ValidationUtils.assertNonNullData(data, debugInfo);

    data = String(data);
    data = (data as Data).trim();

    if (!isEmptyStringAllowed) {
      if ((data as Data).length === 0) {
        throw new FliffException(
          FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
          "assert_non_empty_string - (data.length == 0) [" +
            odata +
            "] for [" +
            debugInfo +
            "]",
        );
      }
    }

    return data as Data;
  }

  private static _commonAssertInteger<Data extends number>(
    data: unknown,
    debugInfo: string,
    isEmptyIntegerAllowed?: boolean,
  ): Data {
    const odata = data;

    if (!isEmptyIntegerAllowed) {
      ValidationUtils.assertNonNullData(data, debugInfo);

      // convert anything to number
      data = +(data as Data);

      // check if valid number
      if (isNaN(data as Data)) {
        throw new FliffException(
          FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
          "common_assert_integer - (isNaN(data)) [" +
            odata +
            "] for [" +
            debugInfo +
            "]",
        );
      }

      // check if valid integer
      if (Math.floor(data as Data) !== data) {
        throw new FliffException(
          FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
          "common_assert_integer - (Math.floor(data) != data) [" +
            odata +
            "] for [" +
            debugInfo +
            "]",
        );
      }
    }

    return data as Data;
  }

  private static _commonAssertFloat(
    data: TAnyAlias,
    debugInfo: string,
  ): number {
    const odata = data;

    ValidationUtils.assertNonNullData(data, debugInfo);

    // convert anything to number
    data = +data;

    // check if valid number
    if (isNaN(data)) {
      throw new FliffException(
        FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
        "common_assert_float - (isNaN(data)) [" +
          odata +
          "] for [" +
          debugInfo +
          "]",
      );
    }

    return data;
  }
}
