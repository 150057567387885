import { IFliffMessage } from "server/common/data/objects";
import { ValidationUtils } from "server/common/ValidationUtils";
import { FliffException } from "server/common/FliffException";
import * as Adapters from "server/core/server/impl/serverAdapters";
import { TAnyAlias } from "src/types";

export class MessageDecoder {
  public static decodeMessage(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): IFliffMessage {
    const objectClassName = ValidationUtils.assertNonEmptyString(
      jsonData.__object_class_name,
      debugInfo + ".__object_class_name",
    );
    const nextDebugInfo = debugInfo + "." + objectClassName;

    if (objectClassName === "FCM__Ping__Response") {
      return Adapters.AdapterFCMPingResponse.decode(jsonData, nextDebugInfo);
    }
    if (objectClassName === "FCM__Refresh_User_Profile__Response") {
      return Adapters.AdapterFCMRefreshUserProfileResponse.decode(
        jsonData,
        nextDebugInfo,
      );
    }
    if (objectClassName === "FCM__Get_Referral_Report__Response") {
      return Adapters.AdapterFCMGetReferralReportResponse.decode(
        jsonData,
        nextDebugInfo,
      );
    }
    if (objectClassName === "FCM__Get_Transactions_Report__Response") {
      return Adapters.AdapterFCMGetTransactionsReportResponse.decode(
        jsonData,
        nextDebugInfo,
      );
    }
    if (objectClassName === "FCM__Initiate_TOU_Session__Response") {
      return Adapters.AdapterFCMInitiateTOUSession.decode(
        jsonData,
        nextDebugInfo,
      );
    }
    if (objectClassName === "FCM__Get_All_Payment_Tokens__Response") {
      return Adapters.AdaptersFCMGetPaymentTokensResponse.decode(
        jsonData,
        nextDebugInfo,
      );
    }
    if (objectClassName === "FCM__Disable_Payment_Token__Response") {
      return Adapters.AdaptersFCMDisablePaymentTokenResponse.decode(
        jsonData,
        nextDebugInfo,
      );
    }
    if (objectClassName === "FCM__Public_Feed_Sync__Response") {
      return Adapters.AdapterFCMPublicFeedSyncResponse.decode(
        jsonData,
        nextDebugInfo,
      );
    }
    if (objectClassName === "FCM__Upload_Mobile_Log__Response") {
      return Adapters.AdapterFCMUploadMobileLogResponse.decode(
        jsonData,
        nextDebugInfo,
      );
    }
    if (objectClassName === "FCM__Get_Contact_US_Subject_Line__Response") {
      return Adapters.AdaptersFCMGetContactUsSubjectLineResponse.decode(
        jsonData,
        nextDebugInfo,
      );
    }
    if (objectClassName === "FCM__Register_Radar_SDK_CallBack_Data__Response") {
      return Adapters.AdapterFCMRegisterRadarSDKCallBackDataResponse.decode(
        jsonData,
        nextDebugInfo,
      );
    }
    if (objectClassName === "FCM__Obtain_Bundle__Response") {
      return Adapters.AdapterObtainBundleResponse.decode(
        jsonData,
        nextDebugInfo,
      );
    }
    if (objectClassName === "FCM__Initiate_Bundle_Purchase__Response") {
      return Adapters.AdapterInitiateBundleResponse.decode(
        jsonData,
        nextDebugInfo,
      );
    }
    if (objectClassName === "FCM__Get_Pods_Report__Response") {
      return Adapters.AdapterGetPodsReportResponse.decode(
        jsonData,
        nextDebugInfo,
      );
    }
    if (objectClassName === "FCM__Place_Pick__Response") {
      return Adapters.AdapterFCMPlacePickResponse.decode(
        jsonData,
        nextDebugInfo,
      );
    }
    if (objectClassName === "FCM__Get_Picks_Report__Response") {
      return Adapters.AdapterGetPicksReportResponse.decode(
        jsonData,
        nextDebugInfo,
      );
    }

    if (objectClassName === "FCM__Get_Public_Fantasy_Feed_Snapshot__Response") {
      return Adapters.AdapterFCMGetPublicFantasyFeedSnapshotResponse.decode(
        jsonData,
        nextDebugInfo,
      );
    }

    throw new FliffException(
      FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
      "unknown object_class_name [" + objectClassName + "]",
    );
  }
}
