import { PollFantasyRequestMode } from "server/common/data/constants";
import { ValidationUtils } from "server/common/ValidationUtils";

export const validatePollFantasyRequestMode = (
  jsonData: unknown,
  debugInfo: string,
): PollFantasyRequestMode =>
  ValidationUtils.validateEnum(
    PollFantasyRequestMode as unknown as PollFantasyRequestMode,
    jsonData,
    debugInfo,
  );
