import { TCurrencyMode } from "src/types";

// 2020-05-07 / Ivan / as usual - we use random funny constants
// they do not matter for enums and TypeScripts compile time checks work fine
// but hopefully can help us to easily identify some nasty logical errors when value of wrong attribute is passed around

// quick hack - special constant for internal communication of data

export const CONST_TOTAL_AMOUNT_PARLAY = -777;

export enum MarketOfferSelectionChangeType {
  CONST_121_NEW = 121,
  CONST_122_STILL_SAME = 122,
  CONST_129_MARKET_RESET = 129,
  CONST_131_OUTCOME_PARAM_1_UP = 131,
  CONST_132_OUTCOME_PARAM_1_DOWN = 132,
  CONST_135_COEFF_UP = 135,
  CONST_136_COEFF_DOWN = 136,
}

export enum PickType {
  CONST_81_STRAIGHT = 81,
  CONST_82_PARLAY = 82,
  CONST_83_SAME_GAME_PARLAY = 83,
}

export enum PickSelectionState {
  CONST_501_PENDING = 501,
  CONST_511_SETTLED_AS_WIN = 511,
  CONST_512_SETTLED_AS_LOSS = 512,
  CONST_513_SETTLED_AS_PUSH = 513,
  CONST_514_SETTLED_AS_NO_ACTION = 514,
  CONST_539_ERROR_IN_SETTLE = 539,
}

export class UIConstants {
  // 2020-04-15 / Ivan / as usual, we will use random constants - just for fun
  public static readonly CONST__331__CASH = 331;
  public static readonly CONST__333__FLIFF_COINS = 333;

  public static isModeCash(currencyMode: number): boolean {
    return currencyMode === UIConstants.CONST__331__CASH;
  }

  public static getModeOnToggle(uiCurrencyMode: TCurrencyMode): TCurrencyMode {
    if (uiCurrencyMode === UIConstants.CONST__331__CASH) {
      return UIConstants.CONST__333__FLIFF_COINS;
    }
    return UIConstants.CONST__331__CASH;
  }
}

export enum RawNativeFeed {
  // Ivan / as usual / use random funny constants for mobile-server protocol
  CONST_201_RESPONSE_GET_AVAILABLE_PURCHASES = 201,
  CONST_202_RESPONSE_GET_PRODUCTS = 202,
  CONST_203_RESPONSE_REQUEST_PURCHASE = 203,

  CONST_240_CALLBACK_ON_PURCHASE_UPDATED = 240,

  CONST_301_ERROR_GET_AVAILABLE_PURCHASES = 301,
  CONST_302_ERROR_GET_PRODUCTS = 302,
  CONST_303_ERROR_REQUEST_PURCHASE = 303,

  CONST_340_CALLBACK_ON_PURCHASE_ERROR = 341,

  CONST_351_ERROR_INIT_CONNECTION = 351,
  CONST_352_ERROR_INIT_LISTENERS = 352,

  CONST_401_FINISH_TRANSACTION_REQUEST_SENT = 401,
  CONST_402_FINISH_TRANSACTION_REQUEST_ERROR = 402,
}

export enum ChannelType {
  CONST_990_NONE = 990,
  CONST_991_12_HOME_FIRST = 991,
  CONST_992_12_AWAY_FIRST = 992,
  CONST_993_1_X_2_HOME_FIRST = 993,
  CONST_994_1_X_2_AWAY_FIRST = 994,
}

export enum ChannelSkins {
  SB_CHANNEL_ICON_7300_HOME = 7300,
  SB_CHANNEL_ICON_7301_BASKETBALL = 7301,
  SB_CHANNEL_ICON_7302_FOOTBALL = 7302,
  SB_CHANNEL_ICON_7303_HOCKEY = 7303,
  SB_CHANNEL_ICON_7304_SOCCER = 7304,
  SB_CHANNEL_ICON_7305_BASEBALL = 7305,
  SB_CHANNEL_ICON_7306_MMA = 7306,
  SB_CHANNEL_ICON_7307_TENNIS = 7307,
  SB_CHANNEL_ICON_7308_BOXING = 7308,
  SB_CHANNEL_ICON_7309_ESPORTS = 7309,
  SB_CHANNEL_ICON_7310_GOLF = 7310,
  SB_CHANNEL_ICON_7311_HORSE_RACING = 7311,
  SB_CHANNEL_ICON_7312_LACROSSE = 7312,
  SB_CHANNEL_ICON_7341_OLYMPICS = 7341,
  SB_CHANNEL_ICON_7386_SUPER_BOWL = 7386,
  SB_CHANNEL_ICON_7387_BOOSTED_POKER = 7387,
  SB_CHANNEL_ICON_7388_OFFERS = 7388,
  SB_CHANNEL_ICON_7389_LIVE = 7389,
  SB_CHANNEL_ICON_7390_OTHER_1 = 7390,
  SB_CHANNEL_ICON_7391_MARCH_MADNESS = 7391,
  SB_CHANNEL_ICON_7392_POKER = 7392,
  SB_CHANNEL_ICON_7500_BOOSTED = 7500,
  SB_CHANNEL_ICON_7501_NEW_USER = 7501,
  SB_CHANNEL_ICON_7502_CREATOR = 7502,
  SB_CHANNEL_ICON_7503_USER = 7503,
}

export enum MarketChangesTimestamps {
  CONST_CHANGE_TIME_DIFFERENCE = 1000 * 40,
  CONST_DISPLAY_MARKET_ICONS_TIME = 1000, // 1 second
  CONST_UPDATE_MARKETS_DIFF = 20 * 1000, // 10 seconds
}

export enum RawPeriodType {
  // 2020-07-31 / Ivan / quick hack in order to request both weekly and monthly report before screen is opened
  // need to design better solution later
  CONST_0_UNSET = 0,
  CONST_49999_INIT_BOTH_WEEK_AND_MONTH = 49999,
  CONST_41101_DAY_START_IN_USA_EASTERN = 41101,
  CONST_41102_WEEK_START_IN_USA_EASTERN = 41102,
  CONST_41103_MONTH_START_IN_USA_EASTERN = 41103,
}

export enum ConflictType {
  CONST_333_LEGACY_SPORT_EVENT = 333,
  CONST_444_PRE_MATCH_CONFLICT = 444,
  CONST_555_LIVE_CONFLICT = 555,
  CONST_654_BET_OFFERS_CONFLICT = 654,
}

export enum PublicChallengeType {
  CONST_17001_PLACE_PICK_TYPE = 17001,
  CONST_17002_PLACE_PICK_SELECTION_NO_CONFLICT = 17002,
  CONST_17003_PLACE_PICK_SELECTION_FOR_CONFLICT = 17003,
}

//amount_iap_promo_tokens

export enum UserActionOnRateUsNag {
  ACTION_145_DECLINED_ENJOY = 145,
  ACTION_146_DECLINED_RATE = 146,
  ACTION_147_AGREED_TO_RATE = 147,
}

export enum UnfinishedOrderStateCategory {
  CODE_1_TIMEOUT = -1,
  CODE_30301_IN_PROGRESS = 30301,
  CODE_30311_GENERAL_ERROR = 30311,
  CODE_30316_REQUIRED_VERIFIED_PHONE_NUMBER_ERROR = 30316,
  CODE_30317_PAYMENT_ERROR = 30317,
}

export enum GiftCardRedemptionConfigType {
  CONST_7101_IN_RESTAURANT_ONLY = 7101,
  CONST_7102_IN_STORE_ONLY = 7102,
  CONST_7103_IN_STORE_OR_ONLINE_ONLY = 7103,
  CONST_7104_ONLINE_ONLY = 7104,
  // 2022-01-17 / Ivan / added
  CONST_7105_APP_ONLY = 7105,
}

export enum BetOrBetGroupStatus {
  CONST_733_ACTIVE = 733,
  CONST_748_SUSPENDED = 748,
}

export enum EventStatus {
  CONST_733_ACTIVE = 733,
  CONST_748_SUSPENDED = 748,
}

export enum PropertyType {
  CONST_7790_ROW_HEADER = 7790,
}

export enum DiffUpdateType {
  CONST_137_REPLACE = 137,
  CONST_138_INCREMENTAL = 138,
  CONST_139_NONE = 139,
  CONST_140_RESET = 140,
}

export enum BetProposalTypes {
  CONST_7711_OVER = 7711,
  CONST_7712_UNDER = 7712,
  // special type to recognise Moneyline proposal
  CONST_7791 = 7791,
  CONST_7801_BOOSTED_OFFER = 7801,
}

export enum OwnershipAttributeCode {
  CONST_9121_PRIMARY_PHONE_NUMBER = 9121,
  CONST_9122_PRIMARY_EMAIL = 9122,
  CONST_9124_LOGIN_EMAIL = 9124,
  CONST_9125_USERNAME = 9125,
}

export enum WithdrawalMethod {
  CONST_7302_BANK_WIRE = 7302,
  CONST_7303_CASH_APP = 7303,
  CONST_7304_SKRILL = 7304,
  CONST_7305_BITCOIN = 7305,
  // 2021-10-19 / Ivan / initial support for Mazooma withdraw requests
  CONST_7306_PAYMENT_TOKEN = 7306,
  CONST_7307_MAZOOMA_DIRECT = 7307,
  CONST_7308_FISERV_PAYMENT_TOKEN = 7308,
  CONST_7321_INITIATE_MAZOOMA_TOKEN_OBV = 7321,
  CONST_7322_INITIATE_FISERV_TOKEN_OBV = 7322,
  CONST_7331_INITIATE_PERSONA_L1_VERIFICATION = 7331,
  CONST_7332_INITIATE_PERSONA_L2_VERIFICATION = 7332,
}

export enum WithdrawalBankAccountType {
  CONST_7401_CHECKING = 7401,
  CONST_7402_SAVINGS = 7402,
}

export enum MazoomaWebViewExistState {
  CODE_31731_VERIFIED_AND_ACTIVE = 31731,
  CODE_31719_VERIFICATION_CANCELED = 31719,
  CODE_72818_VERIFIED_AND_ACTIVE = 72818,
}

export enum FiservWebViewExistState {
  CODE_33701_PENDING = 33701,
  CODE_33702_VENDOR_API_CREATE_CALL_IN_PROGRESS = 33702,
  CODE_33703_VENDOR_API_CREATE_CALL_ERROR = 33703,

  CODE_33711_VERIFICATION_IN_PROGRESS = 33711,
  CODE_33719_VERIFICATION_CANCELED = 33719,
  CODE_33733_VERIFIED_AND_ACTIVE = 33733,

  CODE_33741_DISABLED = 33741,
  CODE_33759_EMERGENCY_EXIT = 33759,
}

// 100 => android minimal (startup), 250 => iOS ONLY light ui mode => 200 => android standard (former light ui), 300 => ios (full ui)
export enum ServerUIMode {
  CODE_100_LIGHT_UI_MODE = 100,
  CODE_250_IOS_LIGHT_UI_MODE = 250,
  CODE_280_ANDROID_LIGHT_UI_MODE = 280,
  CODE_300_FULL_UI_MODE = 300,
}

export enum UpdatePersonalDataMode {
  CONST_UNSET_SYNC_ON_STARTUP = -1,
  CONST_1071_SYNC_ON_STARTUP = 1071,
  CONST_1072_UPDATE_ACCOUNT_DETAILS = 1072,
  CONST_1073_UPDATE_AVATAR = 1073,
}

export enum IdentityVerificationStatus {
  CONST_7000_LEVEL_0 = 7000,
  CONST_7001_LEVEL_1 = 7001,
  CONST_7002_LEVEL_2 = 7002,
  CONST_7011_BANNED = 7011,
  CONST_7012_REJECTED_BY_PERSONA = 7012,
  CONST_7013_UNDER_REVIEW = 7013,
}

export enum PlacePicksOperationStatus {
  CONST_8300_SUCCESS = 8300,
  CONST_8301_REQUIRES_DELAYED_RESUBMIT = 8301,
  CONST_8302_REQUIRES_CONFIRMATION_FOR_SERVER_QUOTE_COEFF = 8302,
}

// 2022-12-20 / Ivan / introduce server side control over account details verification
export enum UserAccountDetailsStatus {
  CONST_UNSET_ACCOUNT_DETAILS_COMPLETED_READ_ONLY = 0,
  CONST_30121_ACCOUNT_DETAILS_COMPLETED_READ_ONLY = 30121,
  CONST_30122_ACCOUNT_DETAILS_NOT_COMPLETED_USE_IN_APP_UI = 30122,
  CONST_30123_ACCOUNT_DETAILS_NOT_COMPLETED_USE_PERSONA_VERIFICATION_MODE_3 = 30123,
}

export enum HeraldProtocolVersion {
  CONST_0_PLAIN_TEXT_SERIALIZED_JSON = 0,
  CONST_701_GZIP_SERIALIZED_JSON = 701,
}

export enum MoreMarketsChatCode {
  CONST_7131_CHAT_NOT_AVAILABLE = 7131, // hidden
  CONST_7132_CHAT_READ_ONLY = 7132, //we may threaten it as hidden for a first version
  CONST_7137_CHAT_FULLY_ACCESSIBLE = 7137,
}

export enum PlacePickPlayThroughMode {
  CONST_4501_CAN_PLACE_PICK_PLAYABLE_AND_REDEEMABLE = 4501,
  CONST_4502_CAN_PLACE_PICK_ONLY_PLAYABLE = 4502,
  CONST_4503_CAN_PLACE_PICK_ONLY_PLAYABLE_SHOW_RESTRICTION = 4503,
}

export enum GiftCardsStatus {
  CONST_1_HIDDEN = 1,
  CONST_2_AVAILABLE = 2,
  CONST_3_UNVERIFIED = 3,
  CONST_4_UNAVAILABLE = 4,
  CONST_5_UNAUTHENTICATED = 5,
}

export enum PaysafeWebViewExistState {
  CODE_24321_VERIFIED_AND_ACTIVE = 24321,
  CODE_24314_VERIFICATION_CANCELED = 24314,
}

// TODO: Remove as part of Desktop transition
export enum BundleOfferV8Skin {
  CONST_701_SKIN_FREE_HOURLY_NO_FC_BONUS = 701,
  CONST_702_SKIN_FREE_DAILY_NO_FC_BONUS = 702,
  CONST_703_SKIN_FREE_HOURLY_WITH_FC_BONUS = 703,
  CONST_704_SKIN_FREE_DAILY_WITH_FC_BONUS = 704,

  CONST_801_SKIN_SOCIAL_COINS_PRICE_LEVEL_1 = 801,
  CONST_802_SKIN_SOCIAL_COINS_PRICE_LEVEL_2 = 802,
  CONST_803_SKIN_SOCIAL_COINS_PRICE_LEVEL_3 = 803,
  CONST_804_SKIN_SOCIAL_COINS_PRICE_LEVEL_4 = 804,
  CONST_805_SKIN_SOCIAL_COINS_PRICE_LEVEL_5 = 805,
  CONST_806_SKIN_SOCIAL_COINS_PRICE_LEVEL_6 = 806,

  CONST_821_SKIN_SOCIAL_COINS_2X_PRICE_LEVEL_1 = 821,
  CONST_822_SKIN_SOCIAL_COINS_2X_PRICE_LEVEL_2 = 822,
  CONST_823_SKIN_SOCIAL_COINS_2X_PRICE_LEVEL_3 = 823,
  CONST_824_SKIN_SOCIAL_COINS_2X_PRICE_LEVEL_4 = 824,
  CONST_825_SKIN_SOCIAL_COINS_2X_PRICE_LEVEL_5 = 825,
  CONST_826_SKIN_SOCIAL_COINS_2X_PRICE_LEVEL_6 = 826,

  CONST_901_SKIN_FLIFF_CASH_PRICE_LEVEL_1 = 901,
  CONST_902_SKIN_FLIFF_CASH_PRICE_LEVEL_2 = 902,
  CONST_903_SKIN_FLIFF_CASH_PRICE_LEVEL_3 = 903,
  CONST_904_SKIN_FLIFF_CASH_PRICE_LEVEL_4 = 904,
  CONST_905_SKIN_FLIFF_CASH_PRICE_LEVEL_5 = 905,
  CONST_906_SKIN_FLIFF_CASH_PRICE_LEVEL_6 = 906,

  CONST_921_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_1 = 921,
  CONST_922_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_2 = 922,
  CONST_923_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_3 = 923,
  CONST_924_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_4 = 924,
  CONST_925_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_5 = 925,
  CONST_926_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_6 = 926,
}
