import * as I from "server/social/data/objects";
import {
  SocialPrivacyLevel,
  SocialUserModifyRelationshipOperationCode,
  SocialUserRelationStatus,
} from "server/social/data/constants";

import Api from "server/social/server/Api";
import {
  SocialSetMultipleProfilesAction,
  SocialSetUserAction,
} from "reduxLocal/social/social.actions";
import { TActionCode, TFliffResult } from "server/common/data/objects";
import { CoreGetEventStatsAction } from "reduxLocal/core/core.actions";
import SocialSelectors from "reduxLocal/social/social.selectors";
import { staticGetState } from "reduxLocal/store";
import { ICopiedPick } from "server/social/data/objects";

class SocialFeedAPI {
  public async safeBlockingFollowUser(
    userFkey: string,
  ): Promise<TFliffResult<I.IFSMModifyRelationshipResponse>> {
    const result = await Api.safeExecuteBlockingRequest<
      I.IFSMModifyRelationshipRequest,
      I.IFSMModifyRelationshipResponse
    >({
      __object_class_name: "FSM__Modify_Relationship__Request",
      anchor_user_fkey: userFkey,
      operation_code:
        SocialUserModifyRelationshipOperationCode.CONST_3863_AUTO_FOLLOW_USER,
    });
    return SocialSetMultipleProfilesAction.dispatchSetSocialMultipleProfiles(
      result,
    );
  }

  public async safeBlockingUnFollowUser(
    userFkey: string,
  ): Promise<TFliffResult<I.IFSMModifyRelationshipResponse>> {
    const result = await Api.safeExecuteBlockingRequest<
      I.IFSMModifyRelationshipRequest,
      I.IFSMModifyRelationshipResponse
    >({
      __object_class_name: "FSM__Modify_Relationship__Request",
      anchor_user_fkey: userFkey,
      operation_code:
        SocialUserModifyRelationshipOperationCode.CONST_3864_UNFOLLOW_USER,
    });
    return SocialSetMultipleProfilesAction.dispatchSetSocialMultipleProfiles(
      result,
    );
  }

  public async safeBlockingBlockUser(
    userFkey: string,
  ): Promise<TFliffResult<I.IFSMModifyRelationshipResponse>> {
    return await Api.safeExecuteBlockingRequest<
      I.IFSMModifyRelationshipRequest,
      I.IFSMModifyRelationshipResponse
    >({
      __object_class_name: "FSM__Modify_Relationship__Request",
      anchor_user_fkey: userFkey,
      operation_code:
        SocialUserModifyRelationshipOperationCode.CONST_3861_BLOCK_USER,
    });
  }

  public async safeBlockingUnBlockUser(
    userFkey: string,
  ): Promise<TFliffResult<I.IFSMModifyRelationshipResponse>> {
    return await Api.safeExecuteBlockingRequest<
      I.IFSMModifyRelationshipRequest,
      I.IFSMModifyRelationshipResponse
    >({
      __object_class_name: "FSM__Modify_Relationship__Request",
      anchor_user_fkey: userFkey,
      operation_code:
        SocialUserModifyRelationshipOperationCode.CONST_3862_UNBLOCK_USER,
    });
  }

  public async safeBlockingGetUserProfile(
    userFkey: string,
    legacyUsername: string,
  ): Promise<TFliffResult<I.IFSMGetUserProfileResponse>> {
    const result = await Api.safeExecuteBlockingRequest<
      I.IFSMGetUserProfileRequest,
      I.IFSMGetUserProfileResponse
    >({
      __object_class_name: "FSM__Get_User_Profile__Request",
      anchor_user_fkey: userFkey,
      provide_extended_info: true,
      legacy_username: legacyUsername,
    });

    return SocialSetUserAction.dispatchSetSocialUser(result);
  }

  public async safeBackgroundGetUserProfile(
    userFkey: string,
    legacyUsername: string,
  ): Promise<TFliffResult<I.IFSMGetUserProfileResponse>> {
    const result = await Api.safeExecuteBackgroundRequest<
      I.IFSMGetUserProfileRequest,
      I.IFSMGetUserProfileResponse
    >({
      __object_class_name: "FSM__Get_User_Profile__Request",
      anchor_user_fkey: userFkey,
      provide_extended_info: true,
      legacy_username: legacyUsername,
    });

    return SocialSetUserAction.dispatchSetSocialUser(result);
  }

  public async safeBlockingRequestActivityFeed(
    actionCode: TActionCode,
    maxKnownPickKey: string,
    minKnownPickKey: string,
    arenaCode: string,
  ): Promise<TFliffResult<I.IFSMGetActivityFeedDataResponse>> {
    return await Api.safeExecuteBlockingTransparentNoErrorModalRequest<
      I.IFSMGetActivityFeedDataRequest,
      I.IFSMGetActivityFeedDataResponse
    >({
      __object_class_name: "FSM__Get_Activity_Feed_Data__Request",
      arena_code: arenaCode,
      action_code: actionCode,
      newest_known_feed_key: maxKnownPickKey,
      oldest_known_feed_key: minKnownPickKey,
    });
  }

  public async safeBackgroundRequestActivityFeed(
    actionCode: TActionCode,
    maxKnownPickKey: string,
    minKnownPickKey: string,
    arenaCode: string,
  ): Promise<TFliffResult<I.IFSMGetActivityFeedDataResponse>> {
    return await Api.safeExecuteBackgroundRequest<
      I.IFSMGetActivityFeedDataRequest,
      I.IFSMGetActivityFeedDataResponse
    >({
      __object_class_name: "FSM__Get_Activity_Feed_Data__Request",
      arena_code: arenaCode,
      action_code: actionCode,
      newest_known_feed_key: maxKnownPickKey,
      oldest_known_feed_key: minKnownPickKey,
    });
  }

  public async safeBackgroundUpdateProfilePrivacyLevel(
    privacyLevel: SocialPrivacyLevel,
  ) {
    const result = await Api.safeExecuteBackgroundRequest<
      I.IFSMUpdateUserProfileRequest,
      I.IFSMGetUserProfileResponse
    >({
      __object_class_name: "FSM__Update_User_Profile_V2__Request",
      privacy_level: privacyLevel,
    });

    return SocialSetUserAction.dispatchSetSocialUser(result);
  }

  public async safeBackgroundUpdateProfileReactions(value: boolean) {
    const result = await Api.safeExecuteBackgroundRequest<
      I.IFSMUpdateUserProfileRequest,
      I.IFSMGetUserProfileResponse
    >({
      __object_class_name: "FSM__Update_User_Profile_V2__Request",
      show_personal_pick_reactions: value,
    });

    return SocialSetUserAction.dispatchSetSocialUser(result);
  }

  public async safeBlockingUpdateProfileBio(
    bio: string,
  ): Promise<TFliffResult<I.IFSMGetUserProfileResponse>> {
    const response = await Api.safeExecuteBlockingRequest<
      I.IFSMUpdateUserProfileRequest,
      I.IFSMGetUserProfileResponse
    >({
      __object_class_name: "FSM__Update_User_Profile_V2__Request",
      bio,
    });

    return SocialSetUserAction.dispatchSetSocialUser(response);
  }

  public async safeBlockingUpdateProfileAvatar(
    avatarUrl: string,
    rpmId?: string,
  ): Promise<TFliffResult<I.IFSMGetUserProfileResponse>> {
    const result = await Api.safeExecuteBlockingRequest<
      I.IFSMUpdateUserProfileRequest,
      I.IFSMGetUserProfileResponse
    >({
      __object_class_name: "FSM__Update_User_Profile_V2__Request",
      avatar_url: avatarUrl,
      rpm_id: rpmId,
    });

    return SocialSetUserAction.dispatchSetSocialUser(result);
  }

  public async safeBlockingUpdateProfileAvatarCustomization(
    rpmBgColor: string,
    expression: string,
    pose: string,
  ): Promise<TFliffResult<I.IFSMGetUserProfileResponse>> {
    const response = await Api.safeExecuteBlockingRequest<
      I.IFSMUpdateUserProfileRequest,
      I.IFSMGetUserProfileResponse
    >({
      __object_class_name: "FSM__Update_User_Profile_V2__Request",
      rpm_bgcolor: rpmBgColor,
      expression,
      pose,
    });
    return SocialSetUserAction.dispatchSetSocialUser(response);
  }

  public async safeBackgroundSearchUsers(
    username: string,
    nextPageNumber: number,
  ): Promise<TFliffResult<I.IFSMSearchUserResponse>> {
    return await Api.safeExecuteBackgroundRequest<
      I.IFSMSearchUserRequest,
      I.IFSMSearchUserResponse
    >({
      __object_class_name: "FSM__Search_User__Request",
      user_name_prefix: username,
      since: nextPageNumber,
      limit: 20,
    });
  }

  public async safeBackgroundGetUsersForRelation(
    relation: SocialUserRelationStatus,
    nextPageNumber: number,
  ): Promise<TFliffResult<I.IFSMGetUsersForRelationshipResponse>> {
    return await Api.safeExecuteBackgroundRequest<
      I.IFSMGetUsersForRelationshipRequest,
      I.IFSMGetUsersForRelationshipResponse
    >({
      __object_class_name: "FSM__Get_Users_For_Relationship__Request",
      relation,
      since: nextPageNumber,
      limit: 40,
    });
  }

  public safeBackgroundGetActivityFeedMeta(
    arenaCode: string,
  ): Promise<TFliffResult<I.IFSMGetActivityFeedMetaResponse>> {
    return Api.safeExecuteBackgroundRequest<
      I.IFSMGetActivityFeedMetaRequest,
      I.IFSMGetActivityFeedMetaResponse
    >({
      __object_class_name: "FSM__Get_Activity_Feed_Meta__Request",
      arena_code: arenaCode,
    });
  }

  public async safeBlockingGetLeaderboard(
    arenaCode: string,
    period: I.ILeaderboardFilterPeriod,
  ): Promise<TFliffResult<I.IFSMGetLeaderboardResponse>> {
    return await Api.safeExecuteBlockingRequest<
      I.IFSMGetLeaderboardRequest,
      I.IFSMGetLeaderboardResponse
    >({
      __object_class_name: "FSM__Get_Leaderboard__Request",
      arena_code: arenaCode,
      period,
    });
  }

  public async safeBackgroundGetLeaderboard(
    arenaCode: string,
    period: I.ILeaderboardFilterPeriod,
  ): Promise<TFliffResult<I.IFSMGetLeaderboardResponse>> {
    return await Api.safeExecuteBackgroundRequest<
      I.IFSMGetLeaderboardRequest,
      I.IFSMGetLeaderboardResponse
    >({
      __object_class_name: "FSM__Get_Leaderboard__Request",
      arena_code: arenaCode,
      period,
    });
  }

  public async safeBlockingGetBadgeExtendedInfo(
    userFkey: string,
    badgeCode: string,
  ): Promise<TFliffResult<I.IFSMGetUserBadgesResponse>> {
    return await Api.safeExecuteBlockingRequest<
      I.IFSMGetUserBadgesRequest,
      I.IFSMGetUserBadgesResponse
    >({
      __object_class_name: "FSM__Get_User_Badges__Request",
      badge_code: badgeCode,
      anchor_user_fkey: userFkey,
    });
  }

  public async safeGetPlayerLevelsLeaderboard(
    arenaCode: string,
    period: I.ILeaderboardFilterPeriod,
  ): Promise<TFliffResult<I.IFSMGetPlayerLevelLeaderboardResponse>> {
    return await Api.safeExecuteBlockingRequest<
      I.IFSMGetPlayerLevelLeaderboardRequest,
      I.IFSMGetPlayerLevelLeaderboardResponse
    >({
      __object_class_name: "FSM__Get_Player_Level_Leaderboard_V2__Request",
      arena_code: arenaCode,
      period,
    });
  }

  public async safeGetPlayerLevelsLeaderboardInBackground(
    arenaCode: string,
    period: I.ILeaderboardFilterPeriod,
  ): Promise<TFliffResult<I.IFSMGetPlayerLevelLeaderboardResponse>> {
    return await Api.safeExecuteBlockingTransparentNoErrorModalRequest<
      I.IFSMGetPlayerLevelLeaderboardRequest,
      I.IFSMGetPlayerLevelLeaderboardResponse
    >({
      __object_class_name: "FSM__Get_Player_Level_Leaderboard_V2__Request",
      arena_code: arenaCode,
      period,
    });
  }

  public async safeBackgroundGetSuggestedFollowers(): Promise<
    TFliffResult<I.IFSMGetSuggestedFollowsResponse>
  > {
    return await Api.safeExecuteBackgroundRequest<
      I.IFSMGetSuggestedFollowsRequest,
      I.IFSMGetSuggestedFollowsResponse
    >({ __object_class_name: "FSM__Get_Suggested_Follows__Request" });
  }

  public async safeBackgroundGetEventStats(
    eventFKey: string,
  ): Promise<TFliffResult<I.IFSMGetEventStatsDetailResponse>> {
    const result = await Api.safeExecuteBackgroundRequest<
      I.IFSMGetEventStatsDetailRequest,
      I.IFSMGetEventStatsDetailResponse
    >({
      __object_class_name: "FSM__Get_Event_Stats_Detail__Request",
      event_fkey: eventFKey,
    });

    if (result.response) {
      CoreGetEventStatsAction.dispatchGetEventStats(result.response);
    }

    return result;
  }

  public async safeBlockingGetTopEventStats(): Promise<
    TFliffResult<I.IFSMGetTopEventStatsResponse>
  > {
    return await Api.safeExecuteBlockingTransparentNoErrorModalRequest<
      I.IFSMGetTopEventStatsRequest,
      I.IFSMGetTopEventStatsResponse
    >({ __object_class_name: "FSM__Get_Top_Event_Stats__Request" });
  }

  public async safeBackgroundGetTopEventStats(): Promise<
    TFliffResult<I.IFSMGetTopEventStatsResponse>
  > {
    return await Api.safeExecuteBackgroundRequest<
      I.IFSMGetTopEventStatsRequest,
      I.IFSMGetTopEventStatsResponse
    >({ __object_class_name: "FSM__Get_Top_Event_Stats__Request" });
  }

  public async safeBackgroundGetPickCopyUsers(
    userId: string,
    pickFKey: string,
  ): Promise<TFliffResult<I.IFSMGetCopyPickUsersResponse>> {
    return await Api.safeExecuteBackgroundRequest<
      I.IFSMGetCopyPickUsersRequest,
      I.IFSMGetCopyPickUsersResponse
    >({
      __object_class_name: "FSM__Get_Copy_Pick_Users__Request",
      requestor_fkey: userId,
      pick_fkey: pickFKey,
    });
  }

  public async safeBackgroundGetPickCopyCount(
    pickIds: number[],
    startStampMilliseconds: number,
    endStampMilliseconds: number,
  ): Promise<TFliffResult<I.IFSMGetCopyPickCountResponse>> {
    const user = SocialSelectors.combinedAuthenticatedUserProfile(
      staticGetState(),
    );
    return await Api.safeExecuteBackgroundRequest<
      I.IFSMGetCopyPickCountRequest,
      I.IFSMGetCopyPickCountResponse
    >({
      __object_class_name: "FSM__Get_Copy_Pick_Count__Request",
      requestor_fkey: user.user_fkey,
      pick_ids: pickIds,
      start_stamp_millis_utc: startStampMilliseconds,
      end_stamp_millis_utc: endStampMilliseconds,
    });
  }

  public backgroundGetChatToken(): Promise<I.IFSMGetTokenResponse> {
    const user = SocialSelectors.combinedAuthenticatedUserProfile(
      staticGetState(),
    );

    return Api.executeBackgroundRequest<
      I.IFSMGetTokenRequest,
      I.IFSMGetTokenResponse
    >({
      __object_class_name: "FSM__Get_Chat_Token__Request",
      requestor_fkey: user.user_fkey,
    });
  }

  public safeBlockingGetPickData(
    pickFkey: string,
    pickCreatedTimestampMsUtc: number,
  ): Promise<TFliffResult<I.IFSMGetActivityFeedItemDataResponse>> {
    const user = SocialSelectors.combinedAuthenticatedUserProfile(
      staticGetState(),
    );

    return Api.safeExecuteBlockingTransparentNoErrorModalRequest<
      I.IFSMGetActivityFeedItemDataRequest,
      I.IFSMGetActivityFeedItemDataResponse
    >({
      __object_class_name: "FSM__Get_Pick__Request",
      requestor_fkey: user.user_fkey,
      pick_fkey: pickFkey,
      created_stamp_millis_utc: pickCreatedTimestampMsUtc,
    });
  }

  public async safeBackgroundReactToPick(
    pickKey:
      | { pick_fkey: string; pick_id?: never }
      | { pick_id: string; pick_fkey?: never },
    pickCreationTimestamp: number,
    emoji?: string,
  ): Promise<TFliffResult<I.IFSMReactPickResponse>> {
    const user = SocialSelectors.combinedAuthenticatedUserProfile(
      staticGetState(),
    );

    return await Api.safeExecuteBackgroundRequest<
      I.IFSMReactPickRequest,
      I.IFSMReactPickResponse
    >({
      __object_class_name: "FSM__React_Pick__Request",
      ...pickKey,
      emoji,
      requestor_fkey: user.user_fkey,
      original_created: pickCreationTimestamp,
    });
  }

  public async safeBackgroundCopyPick(
    copiedPicks: ICopiedPick[],
  ): Promise<TFliffResult<I.IFSMCopyPickResponse>> {
    const user = SocialSelectors.combinedAuthenticatedUserProfile(
      staticGetState(),
    );
    return await Api.safeExecuteBackgroundRequest<
      I.IFSMCopyPickRequest,
      I.IFSMCopyPickResponse
    >({
      __object_class_name: "FSM__Copy_Pick__Request",
      requestor_fkey: user.user_fkey,
      copied_picks: copiedPicks,
    });
  }
}

export default new SocialFeedAPI();
