import { ValidationUtils } from "server/common/ValidationUtils";
import {
  AdapterEvent,
  AdapterFeedDiffUpdate,
} from "server/common/data/serverAdapters";
import { AdapterHelper } from "server/common/AdapterHelper";

import {
  IDataHeraldFeedDiffUpdate,
  IDataHeraldMessageHeartbeat,
  IDataHeraldMessageWelcome,
} from "./objects";
import { Data__SB_SportEvent } from "server/legacyCore/data/objects";
import { TAnyAlias } from "src/types";

export class AdapterHeraldMessageHeartbeat {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): IDataHeraldMessageHeartbeat {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      message_name: ValidationUtils.assertNonEmptyString(
        jsonData.message_name,
        debugInfo + ".message_name",
      ),
      server_stamp_millis: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.server_stamp_millis,
        debugInfo + ".server_stamp_millis",
      ),
      seq_no: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.seq_no,
        debugInfo + ".seq_no",
      ),
    };
  }
}

export class AdapterHeraldMessageWelcome {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): IDataHeraldMessageWelcome {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      message_name: ValidationUtils.assertNonEmptyString(
        jsonData.message_name,
        debugInfo + ".message_name",
      ),
      text: ValidationUtils.assertNonEmptyString(
        jsonData.text,
        debugInfo + ".text",
      ),
    };
  }
}

export class AdapterHeraldMessageFeedDiffUpdate {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): IDataHeraldFeedDiffUpdate {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      message_name: ValidationUtils.assertNonEmptyString(
        jsonData.message_name,
        debugInfo + ".message_name",
      ),
      prematch_conflicts: AdapterHelper.nullOrDecodeArray<Data__SB_SportEvent>(
        AdapterEvent.decode,
        jsonData.prematch_conflicts,
        debugInfo + ".prematch_conflicts",
      ),

      prematch_subfeeds_update: AdapterFeedDiffUpdate.decode(
        jsonData.prematch_subfeeds_update?.packed_subfeed_updates,
        debugInfo + ".prematch_subfeeds_update",
      ),

      inplay_conflicts: AdapterHelper.nullOrDecodeArray<Data__SB_SportEvent>(
        AdapterEvent.decode,
        jsonData.inplay_conflicts,
        debugInfo + ".inplay_conflicts",
      ),

      inplay_subfeeds_update: AdapterFeedDiffUpdate.decode(
        jsonData.inplay_subfeeds_update?.packed_subfeed_updates,
        debugInfo + ".inplay_subfeeds_update",
      ),
      server_timestamp: ValidationUtils.assertPositiveInteger(
        jsonData.server_timestamp,
        debugInfo + ".server_timestamp",
      ),
    };
  }
}
