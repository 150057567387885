// TODO: Move from markets ref change to return NEW, ONLY when it's needed. atm we change it in any case,even if data in market didn't change =>
// TODO: Cause every component that uses markets directly (from shelf) or supermarket shelf to RE-RENDER.

import { FliffException } from "server/common/FliffException";
import {
  Data__SB_FeedDiffItem_BetMarket_Available,
  Data__SB_FeedDiffItem_BetMarket_Deactivated,
  Data__SB_FeedDiff_Shelf_Update,
  TProposalsMap,
} from "server/legacyCore/data/objects";
import {
  Data__SB_ShoppingCart,
  Data__SB_SuperMarket,
  Data__SB_SuperMarketConflictShelf,
  Data__SB_SuperMarketConflictSubfeed,
  IBetMarketLocal,
} from "server/legacyCore/data/objectsLocal";
import ProposalUtils from "./ProposalUtils";
import { DiffUpdateType } from "server/legacyCore/data/constants";

class FeedMergeUtils {
  public mergeDataFeedUpdate = (
    prevSupermarket: Data__SB_SuperMarket,
    dataDeedUpdate: Data__SB_FeedDiff_Shelf_Update[],
  ): Data__SB_SuperMarket => {
    const supermarket = {
      ...prevSupermarket,
      shelves_by_conflict_fkey: { ...prevSupermarket.shelves_by_conflict_fkey },
    };

    // apply changes
    for (const item of dataDeedUpdate) {
      this._feedUpdateShelfSubFeed(supermarket, item);
    }

    return supermarket;
  };

  public mapPublicUpdatesToProposalMap = (
    prevProposalsMap: TProposalsMap,
    isTicketProcessing: boolean,
    cartItems: Data__SB_ShoppingCart["items"],
    updates: Data__SB_FeedDiff_Shelf_Update[],
  ): TProposalsMap => {
    let proposalsMap: TProposalsMap = { ...prevProposalsMap };

    updates.forEach(update => {
      if (update.update_type === DiffUpdateType.CONST_139_NONE) {
        return;
      }

      if (update.update_type === DiffUpdateType.CONST_140_RESET) {
        return;
      }

      update.diff_data.forEach(diffUpdate => {
        if (diffUpdate.item_type === 101) {
          if (diffUpdate.deactivate_reason === 0) {
            proposalsMap = {
              ...this._replaceProposals(
                prevProposalsMap,
                isTicketProcessing,
                cartItems,
                diffUpdate,
              ),
            };
          } else {
            proposalsMap = {
              ...this._deleteProposals(prevProposalsMap, diffUpdate),
            };
          }
        } else {
          throw new FliffException(
            FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
            "unexpected item_type [" + diffUpdate.item_type + "]",
          );
        }
      });
    });

    return proposalsMap;
  };

  private _ensureSubFeed = (
    supermarket: Data__SB_SuperMarket,
    shelfDiff: Data__SB_FeedDiff_Shelf_Update<IBetMarketLocal>,
  ): [
    Data__SB_SuperMarketConflictShelf<IBetMarketLocal>,
    Data__SB_SuperMarketConflictSubfeed<IBetMarketLocal>,
  ] => {
    const conflictFkey = shelfDiff.conflict_fkey;

    // ensure shelf for conflict
    if (!(conflictFkey in supermarket.shelves_by_conflict_fkey)) {
      supermarket.shelves_by_conflict_fkey[conflictFkey] = {
        conflict_fkey: conflictFkey,
        subfeeds: {},
        markets: [],
      };
    } else {
      // 2020-11-01 / Ivan / this method is called when we plan to update the shelf
      // we will create copy of the shelf structure using the spread method to ensure
      // that original object is left unchanged
      const shelf = supermarket.shelves_by_conflict_fkey[conflictFkey];
      supermarket.shelves_by_conflict_fkey[conflictFkey] = { ...shelf };
    }

    const shelf = supermarket.shelves_by_conflict_fkey[conflictFkey];

    if (!shelf.subfeeds) {
      shelf.subfeeds = {};
    }

    let shouldResetSubFeed =
      shelfDiff.update_type === DiffUpdateType.CONST_140_RESET ||
      shelfDiff.update_type === DiffUpdateType.CONST_137_REPLACE;

    if (
      shelfDiff.subfeed_code in shelf.subfeeds &&
      shelf.subfeeds[shelfDiff.subfeed_code].revision_code !==
        shelfDiff.revision_code
    ) {
      shouldResetSubFeed = true;
    }

    // ensure subfeed
    if (shouldResetSubFeed || !(shelfDiff.subfeed_code in shelf.subfeeds)) {
      shelf.subfeeds[shelfDiff.subfeed_code] = {
        revision_code: shelfDiff.revision_code,
        subfeed_code: shelfDiff.subfeed_code,
        max_revision_id: -1,
        markets: [],
      };
    }

    const subFeed = shelf.subfeeds[shelfDiff.subfeed_code];
    return [shelf, subFeed];
  };

  private _replaceMarket = (
    markets: IBetMarketLocal[],
    marketDiff: IBetMarketLocal,
  ): IBetMarketLocal[] => {
    const nn = markets.findIndex(m => m.market_fkey === marketDiff.market_fkey);
    if (nn === -1) {
      return [...markets, marketDiff];
    }
    // in rare cases, local info may be newer then diff update - in such case we should ignore the update
    if (markets[nn].revision_id < marketDiff.revision_id) {
      return markets.map((market, index) => {
        if (index === nn) {
          return marketDiff;
        }
        return market;
      });
    }

    return markets;
  };

  private _deleteMarket = (
    markets: IBetMarketLocal[],
    marketDiff: IBetMarketLocal,
  ): IBetMarketLocal[] => {
    const nn = markets.findIndex(
      market => market.market_fkey === marketDiff.market_fkey,
    );
    if (nn === -1) {
      return markets;
    }
    // in rare cases, local info may be newer then diff update - in such case we should ignore the update
    if (markets[nn].revision_id < marketDiff.revision_id) {
      return markets.filter((_, index) => index !== nn);
    }
    return markets;
  };

  private _rebuildCompatibilityArrays = (
    shelf: Data__SB_SuperMarketConflictShelf<IBetMarketLocal>,
  ) => {
    let markets: IBetMarketLocal[] = [];
    if (!shelf.subfeeds) {
      return;
    }

    Object.values(shelf.subfeeds).forEach(subFeed => {
      markets = markets.concat(subFeed.markets);
    });
    shelf.markets = markets;
  };

  private _feedUpdateShelfSubFeed = (
    supermarket: Data__SB_SuperMarket,
    shelfDiff: Data__SB_FeedDiff_Shelf_Update,
  ) => {
    const nextShelfDiff = {
      ...shelfDiff,
      diff_data: ProposalUtils.toBetLocalMarkets(shelfDiff.diff_data),
    };
    const [shelf, subFeed] = this._ensureSubFeed(supermarket, nextShelfDiff);

    if (nextShelfDiff.update_type === DiffUpdateType.CONST_139_NONE) {
      this._rebuildCompatibilityArrays(shelf);
      // no changes - nothing to do
      return;
    }

    if (nextShelfDiff.update_type === DiffUpdateType.CONST_140_RESET) {
      this._rebuildCompatibilityArrays(shelf);
      // already reset - nothing to do
      return;
    }

    // 2022-03-20 / Ivan / introducing concept for subfeeds
    for (const item of nextShelfDiff.diff_data) {
      if (item.item_type === 101) {
        if (item.deactivate_reason === 0) {
          // process market update
          subFeed.markets = this._replaceMarket(subFeed.markets, item);
          subFeed.max_revision_id = nextShelfDiff.revision_id;
        } else {
          // process market delete
          subFeed.markets = this._deleteMarket(subFeed.markets, item);
          subFeed.max_revision_id = nextShelfDiff.revision_id;
        }
      } else {
        throw new FliffException(
          FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
          "unexpected item_type [" + item.item_type + "]",
        );
      }
    }

    this._rebuildCompatibilityArrays(shelf);
  };

  private _replaceProposals(
    prevProposalsMap: TProposalsMap,
    isTicketProcessing: boolean,
    cartItems: Data__SB_ShoppingCart["items"],
    marketDiff: Data__SB_FeedDiffItem_BetMarket_Available,
  ): TProposalsMap {
    const marketProposals = ProposalUtils.staleProposalsUpdates(
      ProposalUtils.getProposalsFromSingleMarket(marketDiff),
      cartItems,
      isTicketProcessing,
    );

    return marketProposals.reduce<TProposalsMap>((acc, curr) => {
      const prevProposal = prevProposalsMap[curr.proposal_fkey];
      const nextProposal = ProposalUtils.toMainLocalProposal(curr);
      if (typeof prevProposal === "undefined") {
        acc[nextProposal.proposal_fkey] = nextProposal;
        return acc;
      }
      if (prevProposal.proposal_fkey === nextProposal.proposal_fkey) {
        acc[prevProposal.proposal_fkey] = nextProposal;
      }
      if (
        prevProposal.proposal_fkey === nextProposal.proposal_fkey &&
        nextProposal.revision_id > prevProposal.revision_id
      ) {
        acc[prevProposal.proposal_fkey] = nextProposal;
      }
      return acc;
    }, prevProposalsMap);
  }

  private _deleteProposals(
    prevProposalsMap: TProposalsMap,
    marketDiff: Data__SB_FeedDiffItem_BetMarket_Deactivated,
  ): TProposalsMap {
    const prevProposals = prevProposalsMap;
    if (!prevProposals) {
      return {};
    }

    const response: TProposalsMap = { ...prevProposals };

    for (const key in prevProposals) {
      const prevProposal = prevProposals[key];
      if (typeof prevProposal === "undefined") {
        continue;
      }
      if (prevProposal.market_fkey !== marketDiff.market_fkey) {
        continue;
      }
      if (prevProposal.revision_id < marketDiff.revision_id) {
        response[prevProposal.proposal_fkey] = undefined;
      }
    }

    return response;
  }
}

export default new FeedMergeUtils();
