import { AbstractBIF } from "server/legacyCore/AbstractBIF";
import {
  DataBIFResponse,
  DefaultBIFResponse,
} from "server/legacyCore/data/objectsCore";
import AppConfig from "utils/AppConfig";
import { staticGetState } from "reduxLocal/store";
import {
  extract__Data__SB_Diamonds_Transaction_PeriodReport,
  extract__Data__SB_Initiated_Persona_Verification_Response,
  extract__Data__SB_Initiated_Withdrawal_Response,
  extract__Data__SB_Pick_PeriodReport,
  extract__Data__SB_Public_Challenges_Report,
  extract__Data__SB_Unfinished_Order,
  extract__Data__SB_v2_Place_Picks_Operation_Status,
  extract__Data__SB_Validate_Register_Result,
  extract__Data__Slide_All_Gift_Card_Brands,
  extract__DataResponse__SB_cancel_withdrawal_data,
  extract__nothing,
} from "server/legacyCore/data/server_action_helpers";
import { ApiSportsBook } from "./ApiSportsBook";
import {
  SportsBookRequest,
  SportsBookRequestFactory,
  SportsBookResponse,
} from "./x_objects_protocol";
import {
  Data__SB_Points_Transaction_PeriodReport,
  Data__SB_Public_Challenges_Report,
  Data__SB_v2_Place_Picks_Operation_Status,
  Data__SB_Validate_Register_Result,
  DataReq__SB_cancel_withdrawal_data,
  DataReq__SB_Claim_Primary_Email_Address,
  DataReq__SB_Claim_Primary_Phone_Number,
  DataReq__SB_ClientControlledPeriod,
  DataReq__SB_Confirm_Primary_Phone_Number,
  DataReq__SB_Initiate_Persona_Verification,
  DataReq__SB_PlacePickRequest,
  DataReq__SB_RateUsInfo,
  DataReq__SB_ServerControlledPeriod,
  DataReq__SB_Sync_Feed_For_Proposals_Request,
  DataReq__SB_Update_Personal_Data,
  DataReq__SB_Validate_Register,
  DataReq__SB_WithdrawRequest,
  DataReq__SB_Order_v2_Slide_Gift_Card,
  DataResponse__SB_cancel_withdrawal_data,
  IDataSBInitiatedWithdrawalResponse,
  IDataSBInitiatedPersonaVerificationResponse,
  IDataSlideAllGiftCardBrands,
  Data__SB_Pick_PeriodReport,
} from "server/legacyCore/data/objects";
import { PlacePicksOperationStatus } from "server/legacyCore/data/constants";

import { MemoryStorage } from "utils/MemoryStorage";
import { AppUtils } from "utils/AppUtils";
import {
  CoreSetTicketProcessingStateAction,
  CoreStateAction,
} from "reduxLocal/core/core.actions";
import { IDataSBUnfinishedOrder } from "server/common/data/objects";
import { PlacePickUtils } from "utils/PlacePicksUtils";

// eslint-disable-next-line @typescript-eslint/naming-convention
export type CallbackFinalVerificationBeforeCallToServer =
  () => Promise<boolean>;

/*
2019-11-03 / Ivan / BIF is just a random suffix taken from first google search result for 'mixing ui with network requests'
https://medium.com/hackernoon/frontend-in-the-backend-a-pattern-for-cleaner-code-b497c92d0b49

I don't want to use overused terms like 'controller' etc here
*/

// eslint-disable-next-line @typescript-eslint/naming-convention
type SportsBookResponseProcessor<T> = (
  psores: SportsBookResponse,
) => DataBIFResponse<T>;

export class SportsBookBIF extends AbstractBIF {
  public static async blocking_claim_primary_email_address(
    primary_email_address_data: DataReq__SB_Claim_Primary_Email_Address,
  ): Promise<DefaultBIFResponse> {
    const psoreq: SportsBookRequest =
      SportsBookRequestFactory.create_for_OPERATION__32__CLAIM_PRIMARY_EMAIL_ADDRESS(
        primary_email_address_data,
      );
    return await SportsBookBIF.no_dispatch_blocking_operation(
      "claim_primary_email_address",
      psoreq,
    );
  }

  public static async blocking_claim_primary_phone_number(
    primary_phone_number_data: DataReq__SB_Claim_Primary_Phone_Number,
  ): Promise<DefaultBIFResponse> {
    const psoreq: SportsBookRequest =
      SportsBookRequestFactory.create_for_OPERATION__33__CLAIM_PRIMARY_PHONE_NUMBER(
        primary_phone_number_data,
      );
    return await SportsBookBIF.no_dispatch_blocking_operation(
      "claim_primary_phone_number",
      psoreq,
    );
  }

  public static async blocking_confirm_primary_phone_number(
    confirm_primary_phone_number_data: DataReq__SB_Confirm_Primary_Phone_Number,
  ): Promise<DefaultBIFResponse> {
    const psoreq: SportsBookRequest =
      SportsBookRequestFactory.create_for_OPERATION__34__CONFIRM_PRIMARY_PHONE_NUMBER(
        confirm_primary_phone_number_data,
      );
    return await SportsBookBIF.no_dispatch_blocking_operation(
      "confirm_primary_phone_number",
      psoreq,
    );
  }

  public static async blocking_place_pick(
    placePickRequestData: DataReq__SB_PlacePickRequest,
    operationName: string,
    pickDelayInMs = 0,
  ): Promise<DataBIFResponse<Data__SB_v2_Place_Picks_Operation_Status>> {
    const callbackBeforeServerCall: CallbackFinalVerificationBeforeCallToServer =
      async () => {
        const SINGLE_SLEEP_MS = 100;
        const n = pickDelayInMs / SINGLE_SLEEP_MS;

        let i = 1;
        for (i; i <= n; i++) {
          await AppUtils.sleep(SINGLE_SLEEP_MS);
          const isPickValid = PlacePickUtils.isCurrentPickValid();
          if (!isPickValid) {
            return false;
          }
        }
        return true;
      };

    const psoreq =
      SportsBookRequestFactory.create_for_OPERATION__21__PLACE_PICK(
        placePickRequestData,
      );

    const response =
      await SportsBookBIF.no_dispatch_blocking_data_operation_MODAL_PROGRESS_BAR_MODAL_TRANSPARENT(
        operationName,
        psoreq,
        extract__Data__SB_v2_Place_Picks_Operation_Status,
        false,
        callbackBeforeServerCall,
      );

    if (
      response.resultObject?.status ===
      PlacePicksOperationStatus.CONST_8301_REQUIRES_DELAYED_RESUBMIT
    ) {
      return await SportsBookBIF.blocking_place_pick(
        placePickRequestData,
        operationName,
        response.resultObject.required_resubmit_delay_millis,
      );
    }

    return response;
  }

  public static async blocking_request_withdraw(
    withdraw_request_data: DataReq__SB_WithdrawRequest,
  ): Promise<DataBIFResponse<IDataSBInitiatedWithdrawalResponse>> {
    const psoreq: SportsBookRequest =
      SportsBookRequestFactory.create_for_OPERATION__36__REQUEST_WITHDRAW(
        withdraw_request_data,
      );
    return await SportsBookBIF.no_dispatch_blocking_data_operation(
      "request_withdraw",
      psoreq,
      extract__Data__SB_Initiated_Withdrawal_Response,
      // private vs public api - true => public
      false,
    );
  }

  public static async blocking_update_personal_data(
    personal_data: DataReq__SB_Update_Personal_Data,
  ): Promise<DefaultBIFResponse> {
    const psoreq: SportsBookRequest =
      SportsBookRequestFactory.create_for_operation_42__UPDATE_PERSONAL_DATA(
        personal_data,
      );
    return await SportsBookBIF.no_dispatch_blocking_operation(
      "update_personal_data",
      psoreq,
    );
  }

  public static async blocking_initiate_persona_verify(
    initiate_verification_data: DataReq__SB_Initiate_Persona_Verification,
  ): Promise<DataBIFResponse<IDataSBInitiatedPersonaVerificationResponse>> {
    const psoreq: SportsBookRequest =
      SportsBookRequestFactory.create_for_OPERATION__38__INITIATE_PERSONA_VERIFICATION(
        initiate_verification_data,
      );
    return await SportsBookBIF.no_dispatch_blocking_data_operation(
      "initiate_persona_verify",
      psoreq,
      extract__Data__SB_Initiated_Persona_Verification_Response,
      // private vs public api - true => public
      false,
    );
  }

  public static async blocking_load_points_transactions_report(
    request_id: string,
    start_stamp_seconds_utc: number,
    end_stamp_seconds_utc: number,
  ): Promise<DataBIFResponse<Data__SB_Points_Transaction_PeriodReport>> {
    const req_data: DataReq__SB_ClientControlledPeriod = {
      request_id,
      start_stamp_seconds_utc,
      end_stamp_seconds_utc,
    };
    const psoreq: SportsBookRequest =
      SportsBookRequestFactory.createSportsBookRequest_for__OPERATION__11__SYNC_LOAD_POINTS_TRANSACTIONS_REPORT(
        req_data,
      );
    return await SportsBookBIF.no_dispatch_blocking_data_operation(
      "load_points_transactions_report",
      psoreq,
      extract__Data__SB_Diamonds_Transaction_PeriodReport,
    );
  }

  public static async blocking_load_slide_card_brands(): Promise<
    DataBIFResponse<IDataSlideAllGiftCardBrands>
  > {
    const psoreq =
      SportsBookRequestFactory.createSportsBookRequest_for__OPERATION__70__PUBLIC_SLIDE_CARD_BRANDS();
    // 202-04-09 / Ivan / as discussed with Mario, we will use the standard 'display modal progress after 3 seconds' solution
    return await SportsBookBIF.noDispatchDataBlockingOperationNoErrorModalTransparentLoading(
      "load_slide_card_brands",
      psoreq,
      extract__Data__Slide_All_Gift_Card_Brands,
      // private vs public api - true => public
      true,
    );
  }

  public static async blocking_request_create_order_for_slide_gift_card(
    purchase_v2_order_data: DataReq__SB_Order_v2_Slide_Gift_Card,
  ): Promise<DataBIFResponse<IDataSBUnfinishedOrder>> {
    // 2021-03-26 / Ivan / lets introduce some local delay
    //    await u.sleep(772);

    const psoreq: SportsBookRequest =
      SportsBookRequestFactory.create_for_OPERATION__46__CREATE_ORDER_FOR_SLIDE_GIFT_CARD(
        purchase_v2_order_data,
      );
    return await SportsBookBIF.no_dispatch_blocking_data_operation_MODAL_PROGRESS_BAR_MODAL_TRANSPARENT(
      // return SportsBookBIF.no_dispatch_blocking_data_operation(
      "create_order_for_slide_gift_card",
      psoreq,
      extract__Data__SB_Unfinished_Order,
      false, // private
      null,
      // 2021-04-06 / Ivan / stupid constant / dont show default error dialog
      838,
    );
  }

  // 2021-01-05 / Ivan / standard blocking API call which should be called when user clicks on 'Continue' button
  public static async blocking_validate_registration_data(
    validate_register_data: DataReq__SB_Validate_Register,
  ): Promise<DataBIFResponse<Data__SB_Validate_Register_Result>> {
    const psoreq: SportsBookRequest =
      SportsBookRequestFactory.createSportsBookRequest_for__OPERATION__61__PUBLIC_VALIDATE_REGISTRATION_DATA(
        validate_register_data,
      );
    return await SportsBookBIF.no_dispatch_blocking_data_operation(
      "validate_registration_data",
      psoreq,
      extract__Data__SB_Validate_Register_Result,
      true, // public api
    );
  }

  // 2021-01-05 / Ivan / background API call which may be called periodically (after delay on key press)
  public static async background_validate_registration_data(
    validate_register_data: DataReq__SB_Validate_Register,
  ): Promise<DefaultBIFResponse> {
    try {
      const psoreq: SportsBookRequest =
        SportsBookRequestFactory.createSportsBookRequest_for__OPERATION__61__PUBLIC_VALIDATE_REGISTRATION_DATA(
          validate_register_data,
        );
      const psores: SportsBookResponse = await ApiSportsBook.sendPublicRequest(
        psoreq,
        "background_validate_registration_data",
      );

      if (psores.operationError) {
        return DataBIFResponse.create_for_error_code(
          psores.operationError.error_code,
        );
      }

      return DefaultBIFResponse.create_for_ok();
    } catch (error) {
      console.log("error", JSON.stringify(error, null, 2));
      return DataBIFResponse.create_for_error_code(-121);
    }
  }

  public static blocking_load_picks_report(
    request_id: string,
    start_stamp_seconds_utc: number,
    end_stamp_seconds_utc: number,
    other_user_id: number = 0,
  ): Promise<DataBIFResponse<Data__SB_Pick_PeriodReport>> {
    return SportsBookBIF.noDispatchDataBlockingOperationNoErrorModalTransparentLoading(
      "load_picks_report",
      SportsBookRequestFactory.createSportsBookRequest_for__OPERATION__5__SYNC_LOAD_PICKS_REPORT(
        {
          request_id,
          start_stamp_seconds_utc,
          end_stamp_seconds_utc,
          other_user_id,
        },
      ),
      extract__Data__SB_Pick_PeriodReport,
      false,
    );
  }

  public static async blocking_load_public_challenges_report(
    req_data: DataReq__SB_ServerControlledPeriod,
  ): Promise<DataBIFResponse<Data__SB_Public_Challenges_Report>> {
    const is_authenticated: boolean = staticGetState().app.authenticated;

    const operationName = "load_public_challenges_report";

    try {
      let psoreq: SportsBookRequest;
      if (is_authenticated) {
        psoreq =
          SportsBookRequestFactory.createSportsBookRequest_for__OPERATION__9__SYNC_PUBLIC_CHALLENGES_REPORT(
            req_data,
          );
      } else {
        psoreq =
          SportsBookRequestFactory.createSportsBookRequest_for__OPERATION__59__SYNC_PUBLIC_CHALLENGES_REPORT(
            req_data,
          );
      }

      let psores: SportsBookResponse;
      if (is_authenticated) {
        psores = await ApiSportsBook.sendPrivateRequest(
          psoreq,
          "operationName_" + operationName,
        );
      } else {
        psores = await ApiSportsBook.sendPublicRequest(
          psoreq,
          "operationName_" + operationName,
        );
      }

      return extract__Data__SB_Public_Challenges_Report(psores);
    } catch (error) {
      console.warn(` * [error] error in ${operationName}`, error);
      return DataBIFResponse.create_for_exception(error);
    }
  }

  public static async blocking_cancel_withdrawal(
    request_data: DataReq__SB_cancel_withdrawal_data,
  ): Promise<DataBIFResponse<DataResponse__SB_cancel_withdrawal_data>> {
    const psoreq: SportsBookRequest =
      SportsBookRequestFactory.create_for_OPERATION__39__CANCEL_WITHDRAW(
        request_data,
      );
    return await SportsBookBIF.no_dispatch_blocking_data_operation(
      "cancel_withdrawal",
      psoreq,
      extract__DataResponse__SB_cancel_withdrawal_data,
      // private vs public api - true => public
      false,
    );
  }

  // 2021-07-06 / Ivan / introduce activity feed
  public static async blocking_sync_feed_for_proposals(
    sync_feed_for_proposals_request_data: DataReq__SB_Sync_Feed_For_Proposals_Request,
  ): Promise<DefaultBIFResponse> {
    const psoreq: SportsBookRequest =
      SportsBookRequestFactory.createSportsBookRequest_for__OPERATION__81__SYNC_FEED_FOR_PROPOSALS(
        sync_feed_for_proposals_request_data,
      );

    return await SportsBookBIF.no_dispatch_blocking_data_operation(
      "sync_feed_for_proposals",
      psoreq,
      extract__nothing,
      // public api
      true,
    );
  }

  public static async no_dispatch_blocking_operation(
    operationName: string,
    psoreq: SportsBookRequest,
    usePublicAPI: boolean = false,
  ): Promise<DefaultBIFResponse> {
    return await SportsBookBIF.blocking_x_operation(
      operationName,
      AppConfig.modalProgressBarDefaultMode,
      0,
      psoreq,
      usePublicAPI,
    );
  }

  public static async noDispatchBlockingOperationNoErrorModalTransparentLoading(
    operationName: string,
    psoreq: SportsBookRequest,
    usePublicAPI: boolean = false,
  ) {
    return await SportsBookBIF.blocking_x_operation(
      operationName,
      AppConfig.modalProgressBarModalTransparent,
      838,
      psoreq,
      usePublicAPI,
    );
  }

  public static async noDispatchDataBlockingOperationNoErrorModalTransparentLoading<
    T,
  >(
    operationName: string,
    psoreq: SportsBookRequest,
    callback: SportsBookResponseProcessor<T>,
    usePublicAPI: boolean = false,
  ): Promise<DataBIFResponse<T>> {
    return await SportsBookBIF.blocking_y_data_operation(
      operationName,
      AppConfig.modalProgressBarModalTransparent,
      838,
      psoreq,
      callback,
      usePublicAPI,
    );
  }

  public static async blocking_operation(
    operationName: string,
    psoreq: SportsBookRequest,
  ): Promise<DefaultBIFResponse> {
    return await SportsBookBIF.blocking_x_operation(
      operationName,
      AppConfig.modalProgressBarDefaultMode,
      0,
      psoreq,
    );
  }

  // 2020-05-09 / Ivan / errorDialogMode allows us to stop the 'disaplay standard dialog on error' and let caller display branded UI
  //
  //  private static async blocking_xx_point_streak_operation(operationName: string, errorDialogMode: number, psoreq: SportsBookRequest, dispatch: Dispatch): Promise<DefaultBIFResponse> {
  //    return SportsBookBIF.blocking_x_operation(operationName, AppConfig.MODAL_PROGRESS_BAR_DEFAULT_MODE, errorDialogMode, psoreq, dispatch);
  //  }

  /*
  2019-12-03 / Ivan / introducing alt mode for error dialog display - via stupid constants - see common_x_blocking_operation
  */
  private static async blocking_x_operation(
    operationName: string,
    modalProgressBarMode: number,
    errorDialogMode: number,
    psoreq: SportsBookRequest,
    usePublicAPI: boolean = false,
  ): Promise<DefaultBIFResponse> {
    return await AbstractBIF.commonXBlockingOperation(
      modalProgressBarMode,
      errorDialogMode,
      operationName,
      async (): Promise<DefaultBIFResponse> => {
        let psores: SportsBookResponse;
        if (usePublicAPI) {
          psores = await ApiSportsBook.sendPublicRequest(
            psoreq,
            "operationName_" + operationName,
          );
        } else {
          psores = await ApiSportsBook.sendPrivateRequest(
            psoreq,
            "operationName_" + operationName,
          );
        }

        // 2019-11-18 / Ivan / first update UI with latest available data
        CoreStateAction.dispatchUpdateCoreState(psores);

        // 2019-11-18 / Ivan / then throw error if needed
        if (psores.operationError) {
          throw psores.operationError;
        }

        return DefaultBIFResponse.create_for_ok();
      },
    );
  }

  public static async background_rate_us_data(
    rateus_data: DataReq__SB_RateUsInfo,
  ): Promise<DefaultBIFResponse> {
    try {
      const psoreq: SportsBookRequest =
        SportsBookRequestFactory.create_for_OPERATION__43__RATE_US_DATA(
          rateus_data,
        );
      const psores: SportsBookResponse = await ApiSportsBook.sendPrivateRequest(
        psoreq,
        "rate_us_data",
      );
      CoreStateAction.dispatchUpdateCoreState(psores);
      return DefaultBIFResponse.create_for_ok();
    } catch (error) {
      // 2019-11-06 / Ivan / we may want better reporting
      console.warn(" * [error] error in background_rate_us_data", error);
      return DefaultBIFResponse.create_for_exception(error);
    }
  }

  // 2020-09-18 / Ivan / quick hack - we need to redeisgn the whole API module
  // instead of passing tens of parameters, we should have single Params object with default values
  private static async no_dispatch_blocking_data_operation_MODAL_PROGRESS_BAR_MODAL_TRANSPARENT<
    T,
  >(
    operationName: string,
    psoreq: SportsBookRequest,
    callback: SportsBookResponseProcessor<T>,
    usePublicAPI: boolean = false,
    callbackBeforeServerCall: CallbackFinalVerificationBeforeCallToServer | null = null,
    // 2021-04-06 / Ivan / allow caller to turn off default error dialog
    // need refactoring / create single object with all request properties
    errorDialogMode: number = 0,
  ): Promise<DataBIFResponse<T>> {
    return await SportsBookBIF.blocking_y_data_operation(
      operationName,
      AppConfig.modalProgressBarModalTransparent, // Ivan - this does not block UI
      //      AppConfig.MODAL_PROGRESS_BAR_ENFORCE_INDICATOR_MODE, // Ivan - this blocks the UI
      // 2021-04-06 / Ivan / need refactoring / create single object with all request properties
      errorDialogMode, // 0,
      psoreq,
      callback,
      usePublicAPI,
      callbackBeforeServerCall,
    );
  }

  private static async no_dispatch_blocking_data_operation<T>(
    operationName: string,
    psoreq: SportsBookRequest,
    callback: SportsBookResponseProcessor<T>,
    usePublicAPI: boolean = false,
    // 2021-04-06 / Ivan / allow caller to turn off default error dialog
    // need refactoring / create single object with all request properties
    errorDialogMode: number = 0,
  ): Promise<DataBIFResponse<T>> {
    return await SportsBookBIF.blocking_y_data_operation(
      operationName,
      AppConfig.modalProgressBarDefaultMode,
      // 2021-04-06 / Ivan / need refactoring / create single object with all request properties
      errorDialogMode, //0,
      psoreq,
      callback,
      usePublicAPI,
    );
  }

  /*
  2020-05-09 / Ivan / experiment - try to parametrize the response in order to allow us to return just anything to caller

  2019-12-03 / Ivan / introducing alt mode for error dialog display - via stupid constants - see common_x_blocking_operation
  */
  private static async blocking_y_data_operation<T>(
    operationName: string,
    modalProgressBarMode: number,
    errorDialogMode: number,
    psoreq: SportsBookRequest,
    callback: SportsBookResponseProcessor<T>,
    usePublicAPI: boolean = false,
    callbackBeforeServerCall: CallbackFinalVerificationBeforeCallToServer | null = null,
  ): Promise<DataBIFResponse<T>> {
    return await AbstractBIF.commonYBlockingOperation(
      modalProgressBarMode,
      errorDialogMode,
      operationName,
      async (): Promise<DataBIFResponse<T>> => {
        const isPlacePickRequest =
          operationName === "place_pick" &&
          // operation code for place picks.
          psoreq.operation.code === 21;
        if (callbackBeforeServerCall !== null) {
          // 2020-09-12 / Ivan / allow caller to inject delay + last minute check
          const last_minute_check_result = await callbackBeforeServerCall();
          if (!last_minute_check_result) {
            // 2021-08-08 / Ivan / we need to manually set the flag here since this is a valid exit scenario
            MemoryStorage.is_blocking_operation_in_progress = false;
            if (isPlacePickRequest) {
              CoreSetTicketProcessingStateAction.dispatchSetTicketProcessingState(
                false,
              );
            }

            return DataBIFResponse.create_for_error_code(-345);
          }
        }
        let psores: SportsBookResponse;
        if (usePublicAPI) {
          psores = await ApiSportsBook.sendPublicRequest(
            psoreq,
            "operationName_" + operationName,
          );
        } else {
          psores = await ApiSportsBook.sendPrivateRequest(
            psoreq,
            "operationName_" + operationName,
          );
        }

        if (
          isPlacePickRequest &&
          psores.place_picks_operation_status?.status !==
            PlacePicksOperationStatus.CONST_8301_REQUIRES_DELAYED_RESUBMIT
        ) {
          CoreSetTicketProcessingStateAction.dispatchSetTicketProcessingState(
            false,
          );
        }
        // 2019-11-18 / Ivan / first update UI with the latest available data
        CoreStateAction.dispatchUpdateCoreState(psores);

        // 2019-11-18 / Ivan / then throw error if needed
        if (psores.operationError) {
          CoreSetTicketProcessingStateAction.dispatchSetTicketProcessingState(
            false,
          );
          throw psores.operationError;
        }
        return callback(psores);
      },
      psoreq.operation.code,
    );
  }
}
