import { DevConstants } from "src/DevConstants";

// Disable runtime logs in prod
if (!DevConstants.isDevMode) {
  console.log = () => {
    return;
  };
  console.warn = () => {
    return;
  };
}

export {};
