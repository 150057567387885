import { AppUIShowModalDialogAction } from "reduxLocal/appUI/appUI.actions";
import { RestrictedModalService } from "components/molecules/mobile/Modals/RestrictedStateModal";
import {
  IFCMGetPaymentTokensResponse,
  IFCMGetTransactionsReportResponse,
  IFCMProtocolResponseSlots,
} from "server/core/data/objects";
import { IDataHeraldFeedDiffUpdate } from "server/herald/data/objects";
import { SportsBookResponse } from "server/legacyCore/server/x_objects_protocol";
import { PickType, UIConstants } from "server/legacyCore/data/constants";
import {
  Data__SB_ShoppingCart,
  Data__SB_ShoppingCartProposalItem,
} from "server/legacyCore/data/objectsLocal";
import { IFSMGetEventStatsDetailResponse } from "server/social/data/objects";
import { IStringMap, TAnyAlias, TCurrencyMode } from "src/types";
import AppUIModesManager from "utils/AppUIModesManager";
import CommonLocationManager from "utils/LocationManagers/Common";
import { ServerClock } from "utils/ServerClock";
import { staticDispatch } from "reduxLocal/store";

export class CoreStateAction {
  public static readonly type = "@type/UPDATE_CORE_STATE" as const;
  public static readonly payload: SportsBookResponse;
  public readonly type: typeof CoreStateAction.type;
  public readonly payload: typeof CoreStateAction.payload;

  public static dispatchUpdateCoreState(payload: typeof this.payload) {
    // 2020-06-09 / Ivan / quick hack - save the latest known server time
    ServerClock.installLatestKnownServerUtcStampMillis(
      payload.server_timestamp,
    );
    staticDispatch(this._action(payload));
  }

  private static _action(payload: typeof this.payload) {
    return { type: this.type, payload };
  }
}

export class CoreUpdateCurrencyMode {
  public static readonly type = "@type/UPDATE_CURRENCY_MODE" as const;
  public static readonly payload: TCurrencyMode | null;
  public readonly type: typeof CoreUpdateCurrencyMode.type;
  public readonly payload: TCurrencyMode | null;

  public static dispatchUpdateCurrencyMode(payload: typeof this.payload) {
    const isModeCash = UIConstants.isModeCash(payload!);
    if (AppUIModesManager.isExcludedMode && isModeCash) {
      this._handleExcludedRestrictions();
      return;
    }
    staticDispatch(this._action(payload));
  }

  private static _handleExcludedRestrictions() {
    if (CommonLocationManager.geocodeCheckResult.isPassed) {
      AppUIShowModalDialogAction.dispatchShowRestrictionInfoDialog();
      return;
    }
    RestrictedModalService.show(
      CommonLocationManager.ipCheckResult.regionCode,
      null,
      true,
    );
  }

  private static _action(payload: typeof this.payload) {
    return { type: this.type, payload };
  }
}

export class CoreUpdateCartItemAmountsAction {
  public static readonly type = "@type/UPDATE_CART_ITEM_AMOUNTS" as const;
  public static readonly payload: { amount: number; marketOfferId: string };
  public readonly type: typeof CoreUpdateCartItemAmountsAction.type;
  public readonly payload: typeof CoreUpdateCartItemAmountsAction.payload;

  public static dispatchUpdateCartItemAmounts(payload: typeof this.payload) {
    staticDispatch(this._action(payload));
  }

  private static _action(payload: typeof this.payload) {
    return { type: this.type, payload };
  }
}

export class CoreUpdateCartItemAction {
  public static readonly type = "@type/UPDATE_CART_ITEM" as const;
  public static payload: {
    actionCode: number;
    item: Data__SB_ShoppingCartProposalItem;
    existingItemKeyToReplace?: string;
  };
  public readonly type: typeof CoreUpdateCartItemAction.type;
  public readonly payload: typeof CoreUpdateCartItemAction.payload;

  public static dispatchToggleCartItem(
    item: Data__SB_ShoppingCartProposalItem,
  ) {
    staticDispatch(this._action({ item, actionCode: 9 }));
  }

  public static dispatchReplaceCartItem(
    item: Data__SB_ShoppingCartProposalItem,
    existingItemKeyToReplace: string,
  ) {
    staticDispatch(
      this._action({ item, existingItemKeyToReplace, actionCode: 123 }),
    );
  }

  public static dispatchRemoveCartItem(
    item: Data__SB_ShoppingCartProposalItem,
  ) {
    staticDispatch(this._action({ item, actionCode: -1 }));
  }

  public static dispatchResetCart() {
    //TODO: fix null as any.
    staticDispatch(this._action({ item: null as TAnyAlias, actionCode: -777 }));
  }

  public static dispatchRecalculateCartOnAcceptChanges() {
    //TODO: fix null as any.
    staticDispatch(this._action({ item: null as TAnyAlias, actionCode: -888 }));
  }

  private static _action(payload: typeof this.payload) {
    return { type: this.type, payload };
  }
}

export class CoreCopyPickAction {
  public static readonly type = "@type/COPY_PICK" as const;
  public static readonly payload: {
    items: IStringMap<Data__SB_ShoppingCartProposalItem>;
    sourcePick: Data__SB_ShoppingCart["sourcePick"];
  };
  public readonly type: typeof CoreCopyPickAction.type;
  public readonly payload: typeof CoreCopyPickAction.payload;

  public static dispatchCopyPick(payload: typeof this.payload) {
    staticDispatch(this._action(payload));
  }

  private static _action(payload: typeof this.payload) {
    return { type: this.type, payload };
  }
}

export class CoreCopyCartAction {
  public static readonly type = "@type/COPY_CART" as const;
  public static readonly payload: Data__SB_ShoppingCart;
  public readonly type: typeof CoreCopyCartAction.type;
  public readonly payload: typeof CoreCopyCartAction.payload;

  public static dispatchCopyCart(payload: typeof this.payload) {
    staticDispatch(this._action(payload));
  }

  private static _action(payload: typeof this.payload) {
    return { type: this.type, payload };
  }
}

export class CoreUpdateTicketPickTypeAction {
  public static readonly type = "@type/UPDATE_TICKET_PICK_TYPE" as const;
  public static readonly payload: PickType;
  public readonly type: typeof CoreUpdateTicketPickTypeAction.type;
  public readonly payload: typeof CoreUpdateTicketPickTypeAction.payload;

  public static dispatchUpdateTicketPickType(payload: typeof this.payload) {
    staticDispatch(this._action(payload));
  }

  private static _action(payload: typeof this.payload) {
    return { type: this.type, payload };
  }
}

export class CoreUpdateTicketParlayRiskAmountAction {
  public static readonly type =
    "@type/UPDATE_TICKET_PARLAY_RISK_AMOUNT" as const;
  public static readonly payload: PickType;
  public readonly type: typeof CoreUpdateTicketParlayRiskAmountAction.type;
  public readonly payload: typeof CoreUpdateTicketParlayRiskAmountAction.payload;

  public static dispatchUpdateTicketParlayRiskAmount(
    payload: typeof this.payload,
  ) {
    staticDispatch(this._action(payload));
  }

  private static _action(payload: typeof this.payload) {
    return { type: this.type, payload };
  }
}

export class CoreUpdateSGPCoeffSuccessAction {
  public static readonly type = "@type/UPDATE_SGP_COEFF_SUCCESS" as const;
  public static readonly payload: {
    hasChangedOnSubmit: boolean;
    coeff: number;
  };
  public readonly type: typeof CoreUpdateSGPCoeffSuccessAction.type;
  public readonly payload: typeof CoreUpdateSGPCoeffSuccessAction.payload;

  public static dispatchUpdateSGPCoeffSuccess(payload: typeof this.payload) {
    staticDispatch(this._action(payload));
  }

  private static _action(payload: typeof this.payload) {
    return { type: this.type, payload };
  }
}

export class CoreInjectProtocolSlotsAction {
  public static readonly type = "@type/INJECT_PROTOCOL_SLOTS" as const;
  public static readonly payload: IFCMProtocolResponseSlots;
  public readonly type: typeof CoreInjectProtocolSlotsAction.type;
  public readonly payload: typeof CoreInjectProtocolSlotsAction.payload;

  public static dispatchInjectProtocolSlots(payload: typeof this.payload) {
    staticDispatch(this._action(payload));
  }

  private static _action(
    payload: typeof CoreInjectProtocolSlotsAction.payload,
  ) {
    return { type: this.type, payload };
  }
}

export class CoreHeraldSubfeedsUpdateAction {
  public static readonly type = "@type/HERALD_SUBFEEDS_UPDATE" as const;
  public static readonly payload: Omit<
    IDataHeraldFeedDiffUpdate,
    "message_name"
  >;
  public readonly type: typeof CoreHeraldSubfeedsUpdateAction.type;
  public readonly payload: typeof CoreHeraldSubfeedsUpdateAction.payload;

  public static dispatchHeraldSubfeedsUpdate(payload: typeof this.payload) {
    staticDispatch(this._action(payload));
  }

  private static _action(payload: typeof this.payload) {
    return { type: this.type, payload };
  }
}

export class CoreGetLastTransactionsAction {
  public static readonly type = "@type/GET_LAST_TRANSACTIONS" as const;
  public static readonly payload: IFCMGetTransactionsReportResponse;
  public readonly type: typeof CoreGetLastTransactionsAction.type;
  public readonly payload: typeof CoreGetLastTransactionsAction.payload;

  public static dispatchGetLastTransactions(payload: typeof this.payload) {
    staticDispatch(this._action(payload));
  }

  private static _action(payload: typeof this.payload) {
    return { type: this.type, payload };
  }
}

export class CoreGetPaymentTokensAction {
  public static readonly type = "@type/GET_PAYMENT_TOKENS" as const;
  public static readonly payload: IFCMGetPaymentTokensResponse["payment_tokens"];
  public readonly type: typeof CoreGetPaymentTokensAction.type;
  public readonly payload: typeof CoreGetPaymentTokensAction.payload;

  public static dispatchGetPaymentTokens(payload: typeof this.payload) {
    staticDispatch(this._action(payload));
  }

  private static _action(payload: typeof this.payload) {
    return { type: this.type, payload };
  }
}

export class CoreGetEventStatsAction {
  public static readonly type = "@type/GET_EVENT_STATS" as const;
  public static readonly payload: IFSMGetEventStatsDetailResponse;
  public readonly type: typeof CoreGetEventStatsAction.type;
  public readonly payload: typeof CoreGetEventStatsAction.payload;

  public static dispatchGetEventStats(payload: typeof this.payload) {
    staticDispatch(this._action(payload));
  }

  private static _action(payload: typeof this.payload) {
    return { type: this.type, payload };
  }
}

export class CoreSetTicketProcessingStateAction {
  public static readonly type = "@type/SET_TICKET_PROCESSING_STATE" as const;
  public static readonly payload: boolean;
  public readonly type: typeof CoreSetTicketProcessingStateAction.type;
  public readonly payload: typeof CoreSetTicketProcessingStateAction.payload;

  public static dispatchSetTicketProcessingState(payload: typeof this.payload) {
    staticDispatch(this._action(payload));
  }

  private static _action(payload: typeof this.payload) {
    return { type: this.type, payload };
  }
}

export class CoreSetIsAltPropButtonEnabledAction {
  public static readonly type = "@type/SET_IS_ALT_PROP_BUTTON_ENABLED" as const;
  public static readonly payload: boolean;
  public readonly type: typeof CoreSetIsAltPropButtonEnabledAction.type;
  public readonly payload: typeof CoreSetIsAltPropButtonEnabledAction.payload;

  public static dispatchSetIsAltPropButtonEnabled(
    payload: typeof this.payload,
  ) {
    staticDispatch(this._action(payload));
  }

  private static _action(payload: typeof this.payload) {
    return { type: this.type, payload };
  }
}
