import {
  PlayerLevelLeaderboardUpgrading,
  SocialAccessLevel,
  SocialPrivacyLevel,
  SocialUserModifyRelationshipOperationCode,
  SocialUserRelationStatus,
  SocialUserType,
  StakeCategory,
} from "server/social/data/constants";
import {
  PickSelectionState,
  PickType,
  RawPeriodType,
} from "server/legacyCore/data/constants";
import {
  IFliffProtocolRequest,
  IFliffProtocolRequestHeader,
  IFliffRequest,
  IFliffResponse,
  TActionCode,
  TMergeCode,
} from "server/common/data/objects";
import { IStringMap, TCurrencyMode } from "src/types";

export interface IFSMProtocolRequestSlotsMeta {
  config_obj_version: number;
  logged_user_profie_obj_version: number;
}

export interface IFCMProtocolRequest<Request extends IFliffRequest>
  extends IFliffProtocolRequest<Request> {
  x_meta: IFSMProtocolRequestSlotsMeta | null;
}
export interface IFSMSocialConfig {
  supported_emojis: string[];
  hit_rate_icon: string;
  score_icon: string;
  leaderboard_banner: IBadgeBanner;
  rpm_bg_gradients: IGradientBackgroundConfig[];
  lobby_chat_id: string;
  config_version: number;
  enable_pick_reactions: boolean;
}

export interface IFSMProtocolRequestHeader extends IFliffProtocolRequestHeader {
  access_token: string;
}

export interface IFSMProtocolResponseSlots {
  social_config: IFSMSocialConfig | null;
}
export interface IGradientBackgroundConfig {
  name: string;
  colors: string[];
  modalColors: string[];
  sparkles: string;
}
export interface IPlayerLevelBanner {
  text: string;
  icon: string;
}

export interface IPlayerLevel {
  icon: string;
  level: number;
  name: string;
}

export interface IPlayerLevels {
  previous: IPlayerLevel | null;
  current: IPlayerLevel;
  next: IPlayerLevel | null;
}

export interface IRPMAvatar {
  avatar_url: string;
  rpm_avatar_full_body: string;
  rpm_avatar_half_body: string;
  rpm_avatar_nav_bar: string;
}

export interface IFSMDataUserInfo {
  user_fkey: string;
  username: string;
  first_name: string;
  last_name: string;
  rpm_avatar_full_body: string;
  rpm_avatar_half_body: string;
  rpm_avatar_nav_bar: string;
  rpm_bgcolor: string;
  rpm_bg_gradient: IGradientBackgroundConfig;
  rpm_avatars: IRPMAvatar[];
  bio: string;
  relation: SocialUserRelationStatus;
  count_followers: number;
  count_following: number;
  count_friends: number;
  access_level: SocialAccessLevel;
  privacy_level: SocialPrivacyLevel;
  user_type: SocialUserType;
  media_links: IFSMDataMediaLink[];
  total_picks: number;
  high_stakes_picks: number;
  global_rank: number;
  banner: IPlayerLevelBanner | null;
  player_levels: IPlayerLevels;
  show_personal_pick_reactions: boolean;
}

export interface IFSMDataSharedConnections {
  top_users: IFSMSearchUserInfo[];
  count_total: number;
}

export interface IFSMDataMediaLink {
  media_logo_url: string;
  link_url: string;
  link_text: string;
}

export interface IFSMModifyRelationshipRequest extends IFliffRequest {
  __object_class_name: "FSM__Modify_Relationship__Request";
  operation_code: SocialUserModifyRelationshipOperationCode;
  anchor_user_fkey: string;
}

export interface IFSMModifyRelationshipResponse extends IFliffResponse {
  __object_class_name: "FSM__Modify_Relationship__Response";
  observer_profile: IFSMDataUserInfo;
  anchor_profile: IFSMDataUserInfo;
}

export interface IFSMDataUserPickSelectionInfo {
  id: number;
  proposal_fkey: string;
  conflict_fkey: string;
  state: PickSelectionState;
  coeff: number;
  settled_timestamp_utc: number;
  channel_id: number;
  event_start_timestamp_utc: number;
  is_conflict_in_play: boolean;
  in_play_state_desc: string;
  t_121_event_info: string;
  t_131_market_name: string;
  t_132_market_note: string;
  t_141_selection_name: string;
  t_142_selection_param_1: string;
  market_fkey: string;
  is_copy_available: boolean;
  channel_name?: string;
}

export interface IFSMDataActivityFeedItem {
  pick_fkey: string;
  user_fkey: string;
  username: string;
  user_type: SocialUserType;
  rpm_avatar_half_body: string;
  rpm_bgcolor: string;
  rpm_bg_gradient: IGradientBackgroundConfig;
  created_timestamp_utc: number;
  settled_timestamp_utc: number;
  state: PickSelectionState;
  type: PickType;
  currency: TCurrencyMode;
  risk_amount: number;
  payout_amount: number;
  coeff: number;
  selections: IFSMDataUserPickSelectionInfo[];
  relation: SocialUserRelationStatus;
  stake_category: StakeCategory;
  player_level_icon: string;
  copy_count: number;
  reactions_count: number;
  emoji_mapping: TEmojiMapping;
}

// 2022-09-20 / Ivan / at the moment - only public feed
export interface IFSMGetActivityFeedDataRequest extends IFliffRequest {
  __object_class_name: "FSM__Get_Activity_Feed_Data__Request";
  // not used yet
  arena_code: string;
  action_code: TActionCode;
  // newest known feed object (top of feed list)
  newest_known_feed_key: string;
  // oldest known feed object (bottom of feed list)
  oldest_known_feed_key: string;
}

// For execute_request (where actual error can appear in try/catch) we expect in came entity only response, error is handled in catch block. (which is required for such request types)
export interface IFSMGetActivityFeedDataResponse extends IFliffResponse {
  __object_class_name: "FSM__Get_Activity_Feed_Data__Response";
  merge_code: TMergeCode;
  data: IFSMDataActivityFeedItem[];
}

export interface IFSMGetActivityFeedItemDataRequest extends IFliffRequest {
  __object_class_name: "FSM__Get_Pick__Request";
  requestor_fkey: string;
  pick_fkey: string;
  created_stamp_millis_utc?: number;
}
export interface IFSMGetActivityFeedItemDataResponse extends IFliffResponse {
  __object_class_name: "FSM__Get_Pick__Response";
  pick: IFSMDataActivityFeedItem;
}

export interface IFSMGetUserProfileRequest extends IFliffRequest {
  __object_class_name: "FSM__Get_User_Profile__Request";
  anchor_user_fkey: string;
  provide_extended_info?: boolean;
  legacy_username?: string;
}

export interface IStatsResponse {
  user_fkey: string;
  social_amount_won: number;
  social_count_picks_total: number;
  social_count_picks_high_stake: number;
  social_count_picks_lost: number;
  week_no: number;
  global_rank: number;
  regional_rank: number;
}

export interface IUserProfileResponse {
  users: IFSMSearchUserInfo[] | null;
}

export type TEmojiMapping = Record<string, IEmojiData> | null;

export interface IEmojiData {
  count: number;
  is_mine: boolean;
}

export interface IFSMBadge {
  code: string;
  count: number;
  created_timestamp_utc: number | null;
  description: string;
  image_url: string;
  name: string;
  text: string;
  weight: number;
  short: string;
  arena_code: string;
  details: string;
}

export interface IBadgeBannerMaximized {
  icon_url: string;
  text: string;
  title: string;
}

export interface IBadgeBannerMinimized {
  text: string;
  title: string;
}

export interface IBadgeBanner {
  arena_code?: string;
  maximized_banners: IBadgeBannerMaximized[];
  maximized_text: string;
  maximized_title: string;
}

export interface IFSMUserStatsInfoCardValueDataItem {
  type: "value";
  value: string;
  label: string;
  url?: string;
}

export interface IFSMUserStatsInfoCardImageDataItem {
  type: "image";
  url: string;
  background_color: string;
  rpm_bg_gradient?: IGradientBackgroundConfig;
}

export enum IFSMUserStatsInfoCardScoreSize {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  small = "small",
  // eslint-disable-next-line @typescript-eslint/naming-convention
  large = "large",
}

export interface IFSMUserStatsInfoCardScoreDataItem {
  type: "score";
  label: string;
  size: IFSMUserStatsInfoCardScoreSize;
  url: string;
  score_name: string;
}

type TFSMUserStatsInfoCardDataItem =
  | IFSMUserStatsInfoCardValueDataItem
  | IFSMUserStatsInfoCardImageDataItem
  | IFSMUserStatsInfoCardScoreDataItem;

export interface IFSMUserStatsInfoCard {
  type: "info";
  data: TFSMUserStatsInfoCardDataItem[];
}

export interface IFSMUserStatsGameCard {
  type: "game";
  name: string;
  url: string;
  data: TFSMUserStatsInfoCardDataItem[];
}

export interface IFSMUserStatsFavoriteCard {
  type: "favorite";
  name: string;
  value: string;
  url: string;
  align: "left" | "right";
}

export interface IFSMUserStatsStatisticsDetailedCardDetails {
  url: string;
  value: string;
  label: string;
}

export interface IFSMUserStatsStatisticsDetailedCardMarketDetails {
  value: string;
  label: string;
}

export interface IFSMUserStatsStatisticsGeneralCard {
  type: "statistics_general";
  name: string;
  description: string;
  url: string;
  data: TFSMUserStatsInfoCardDataItem[];
}

export interface IFSMUserStatsStatisticsDetailedCard
  extends Omit<IFSMUserStatsStatisticsGeneralCard, "type"> {
  type: "statistics_detailed";
  details: IFSMUserStatsStatisticsDetailedCardDetails[];
  market_details: IFSMUserStatsStatisticsDetailedCardMarketDetails[];
}

export type TFSMUserStatsCard =
  | IFSMUserStatsInfoCard
  | IFSMUserStatsGameCard
  | IFSMUserStatsFavoriteCard;

export type TFSMUserStatsStatisticsCard =
  | IFSMUserStatsStatisticsGeneralCard
  | IFSMUserStatsStatisticsDetailedCard;

export interface IFSMUserStatsDefaultSection {
  name: string;
  info: string;
  url: string;
  type: "vertical" | "horizontal";
  cards: TFSMUserStatsCard[];
}

export interface IFSMUserStatsStatisticsFilter {
  label: string;
  value: string;
}

export interface IFSMUserStatsStatisticsSection {
  name: string;
  info: string;
  url: string;
  type: "statistics";
  filters: IFSMUserStatsStatisticsFilter[];
  data: IStringMap<TFSMUserStatsStatisticsCard[]>;
}

export type TFSMUserStatsSection =
  | IFSMUserStatsDefaultSection
  | IFSMUserStatsStatisticsSection;

export interface IFSMUserStatsResponse {
  sections: TFSMUserStatsSection[];
}

export interface IFSMGetUserProfileResponse extends IFliffResponse {
  __object_class_name: "FSM__Get_User_Profile__Response";
  profile: IFSMDataUserInfo;
  user_stats_response: IFSMUserStatsResponse;
  stats_response: {
    stats_week_0: IStatsResponse;
    stats_week_m1: IStatsResponse;
  };
  feed_response: {
    merge_code: number;
    arena_code: string;
    data: IFSMDataActivityFeedItem[];
  };
  following: IUserProfileResponse;
  followers: IUserProfileResponse;
  shared_connections: IFSMDataSharedConnections;
  badges: IFSMBadge[];
  badge_banner_data: IBadgeBanner[];
}

export interface IFSMUpdateUserProfileRequest extends IFliffRequest {
  __object_class_name: "FSM__Update_User_Profile_V2__Request";
  bio?: string;
  avatar_url?: string;
  rpm_id?: string;
  rpm_bgcolor?: string;
  rpm_bg_gradient_name?: string;
  expression?: string;
  pose?: string;
  privacy_level?: SocialPrivacyLevel;
  show_personal_pick_reactions?: boolean;
}

export interface IFSMUpdateUserProfileResponse extends IFliffResponse {
  __object_class_name: "FSM__Update_User_Profile_V2__Response";
  profile: IFSMDataUserInfo;
}

export interface IFSMSearchUserRequest extends IFliffRequest {
  __object_class_name: "FSM__Search_User__Request";
  user_name_prefix: string;
  since: number;
  limit: number;
}

export interface IFSMSearchUserInfo
  extends Pick<
    IFSMDataUserInfo,
    | "user_fkey"
    | "username"
    | "rpm_avatar_half_body"
    | "rpm_bgcolor"
    | "rpm_bg_gradient"
    | "relation"
    | "count_following"
    | "count_followers"
    | "access_level"
    | "user_type"
  > {
  player_level_icon: string;
}

export interface IFSMSearchUserResponse extends IFliffResponse {
  __object_class_name: "FSM__Search_User__Response";
  users: IFSMSearchUserInfo[];
}

export interface IFSMGetUsersForRelationshipRequest extends IFliffRequest {
  __object_class_name: "FSM__Get_Users_For_Relationship__Request";
  relation: SocialUserRelationStatus;
  since: number;
  limit: number;
}

export interface IFSMGetUsersForRelationshipResponse extends IFliffResponse {
  __object_class_name: "FSM__Get_Users_For_Relationship__Response";
  users: IFSMSearchUserInfo[];
}

export interface IFSMGetActivityFeedMetaRequest extends IFliffRequest {
  __object_class_name: "FSM__Get_Activity_Feed_Meta__Request";
  arena_code: string;
}

export interface IFSMGetActivityFeedMetaResponse extends IFliffResponse {
  __object_class_name: "FSM__Get_Activity_Feed_Meta__Response";
  newest_feed_key: string;
}

export interface ILeaderboardFilterPeriod {
  period_type: RawPeriodType; // day, week, month - see TLeaderboardFilterPeriodType
  anchor_stamp_seconds_utc: number; // anchor -1 mean now
  offset: number; // offset in
}

export interface IFSMGetLeaderboardRequest extends IFliffRequest {
  __object_class_name: "FSM__Get_Leaderboard__Request";
  arena_code: string;
  period: ILeaderboardFilterPeriod;
}

export interface IFSMDataLeaderboardItem
  extends Pick<
    IFSMDataUserInfo,
    | "user_fkey"
    | "username"
    | "relation"
    | "rpm_avatar_full_body"
    | "rpm_avatar_half_body"
    | "rpm_bgcolor"
    | "rpm_bg_gradient"
    | "user_type"
  > {
  rank: number;
  hit_rate: number | null;
  score: string | null;
  social_amount_won: number;
  badge_icon_url: string;
  player_level_icon: string;
}

export interface IFSMLeaderboardPeriodMeta {
  // stamp for start of the period - not actually used by mobile app
  period_start_stamp_seconds_utc: number;
  // start/end dates in unified string format (example: 2020-07-21) - not actually used by mobile app
  period_visual_start_date: string;
  period_visual_end_date: string;

  // base stamp - logical meaning is 'today', implementation uses 'stamp for first second of (today)' - whatever today is
  // calculated from server on first call, should be send back to server / along with offset / on following calls
  report_anchor_stamp_seconds_utc: number;

  // offset from anchor_stamp - in days, weeks or months (depending on report type)
  report_anchor_offset: number;

  // if false - there is no more data in this direction,
  // if true - app can resend the request with offset +/- 1 and expect data for the next period in that direction
  has_prev: boolean;
  has_next: boolean;
}

export interface IFSMLeaderboardBanner extends IBadgeBanner {
  minimized_banner: IBadgeBannerMaximized;
}

export interface IFSMGetLeaderboardResponse extends IFliffResponse {
  __object_class_name: "FSM__Get_Leaderboard__Response";
  arena_code: string;
  data: IFSMDataLeaderboardItem[];
  period_meta: IFSMLeaderboardPeriodMeta;
  profile: IFSMDataUserInfo | null;
  current_user: IFSMDataLeaderboardItem | null;
  leaderboard_banner: IFSMLeaderboardBanner | null;
}

export interface IFSMGetUserBadgesRequest extends IFliffRequest {
  __object_class_name: "FSM__Get_User_Badges__Request";
  anchor_user_fkey: string;
  badge_code: string;
}

export interface IFSMGetUserBadgesResponse extends IFliffResponse {
  __object_class_name: "FSM__Get_User_Badges__Response";
  badges: IFSMBadge[];
}

export interface IFSMDataPlayerLevelLeaderboardItem
  extends Pick<IFSMDataUserInfo, "user_fkey" | "username" | "relation"> {
  rank: number;
  social_amount_won: number;
  score: string | null;
  hit_rate: number | null;
  upgrading: PlayerLevelLeaderboardUpgrading;
}

export interface IFSMGetPlayerLevelLeaderboardRequest extends IFliffRequest {
  __object_class_name: "FSM__Get_Player_Level_Leaderboard_V2__Request";
  arena_code: string;
  period: ILeaderboardFilterPeriod;
}

export interface IPlayerLevelDescription {
  icon_url: string;
  title: string;
}

export interface IPlayerLevelsBanner
  extends Omit<IBadgeBanner, "maximized_banners"> {
  maximized_banners: IPlayerLevelDescription[];
  minimized_banner: IBadgeBannerMinimized;
}

export interface IFSMGetPlayerLevelLeaderboardResponse extends IFliffResponse {
  __object_class_name: "FSM__Get_Player_Level_Leaderboard_V2__Response";
  arena_code: string;
  top_users: IFSMDataPlayerLevelLeaderboardItem[];
  middle_users: IFSMDataPlayerLevelLeaderboardItem[];
  bottom_users: IFSMDataPlayerLevelLeaderboardItem[];
  period_meta: IFSMLeaderboardPeriodMeta;
  profile: IFSMDataUserInfo | null;
  current_user: IFSMDataPlayerLevelLeaderboardItem | null;
  player_level_leaderboard_banner: IPlayerLevelsBanner;
}

export interface IFSMGetSuggestedFollowsRequest extends IFliffRequest {
  __object_class_name: "FSM__Get_Suggested_Follows__Request";
}

export interface IFSMGetSuggestedFollowsResponse extends IFliffResponse {
  __object_class_name: "FSM__Get_Suggested_Follows__Response";
  users: IFSMSearchUserInfo[];
}

export interface IFSMEventStatsGroupLine {
  line_category: number;
  line_header: string;
  option_name_1: string;
  option_name_2: string;
  option_value_1: number;
  option_value_2: number;
  skin: number;
}

export interface IFSMEventStatsGroup {
  group_category: number;
  group_header: string;
  lines: IFSMEventStatsGroupLine[];
}

export interface IFSMEventStatsProposal {
  event_info: string;
  event_fkey: string;
  event_start_timestamp_utc: number;
  market_fkey: string;
  market_name: string;
  channel_id: number;
  proposal_type: number;
  proposal_fkey: string;
  group_fkey: string;
  selection_name: string;
  total_social_coins_risk_amount: number;
  total_social_picks: number;
}

export interface IFSMEventStatsProposalsGroup {
  group_category: number;
  group_header: string;
  proposals: IFSMEventStatsProposal[];
}

export interface IFSMEventStatsInfo {
  away_team: string;
  channel_id: number;
  channel_name: string;
  event_fkey: string;
  event_info: string;
  event_start_timestamp_utc: number;
  home_team: string;
  is_home_visually_first: boolean;
}

export interface IFSMEventStats {
  total_social_picks: number;
  total_social_coins_risk_amount: number;
  info: IFSMEventStatsInfo;
}

export interface IFSMFriendsAndFollowsProposal {
  event_fkey: string;
  market_fkey: string;
  market_name: string;
  player_level_icon: string;
  proposal_type: number;
  proposal_fkey: string;
  group_fkey: string;
  relation: number;
  rpm_avatar_half_body: string;
  rpm_bgcolor: string;
  rpm_bg_gradient: IGradientBackgroundConfig;
  selection_name: string;
  submission_stamp_millis_utc: number;
  user_fkey: string;
  username: string;
}

export interface IFSMEventDetailsStats extends IFSMEventStats {
  groups: IFSMEventStatsGroup[];
}

export interface IFSMPopularParlayProposal {
  channel_id: number;
  channel_name: string;
  event_fkey: string;
  event_info: string;
  event_start_timestamp_utc: number;
  market_fkey: string;
  market_name: string;
  market_note: string;
  proposal_type: number;
  proposal_fkey: string;
  group_fkey: string;
  selection_name: string;
  sport_id: number;
}

export interface IFSMPopularParlay {
  pick_type: PickType;
  proposals: IFSMPopularParlayProposal[];
  total_social_coins_risk_amount: number;
  total_social_picks: number;
  us_coeff: number;
}

export interface IFSMGetEventStatsDetailRequest extends IFliffRequest {
  __object_class_name: "FSM__Get_Event_Stats_Detail__Request";
  event_fkey: string;
}

export interface IFSMGetEventStatsDetailResponse extends IFliffResponse {
  __object_class_name: "FSM__Get_Event_Stats_Detail__Response";
  stats_event: IFSMEventDetailsStats;
  proposals_groups: IFSMEventStatsProposalsGroup[];
  friends_and_follows_proposals: IFSMFriendsAndFollowsProposal[];
}

export interface IFSMGetTopEventStatsRequest extends IFliffRequest {
  __object_class_name: "FSM__Get_Top_Event_Stats__Request";
}

export interface IFSMGetTopEventStatsResponse extends IFliffResponse {
  __object_class_name: "FSM__Get_Top_Event_Stats__Response";
  proposals_groups: IFSMEventStatsProposalsGroup[];
  stats_events: IFSMEventStats[];
  popular_parlays: IFSMPopularParlay[];
}

export interface IFSMGetCopyPickUsersRequest extends IFliffRequest {
  __object_class_name: "FSM__Get_Copy_Pick_Users__Request";
  requestor_fkey: string;
  pick_fkey: string;
}

export interface IFSMGetCopyPickUsersResponse extends IFliffResponse {
  __object_class_name: "FSM__Get_Copy_Pick_Users__Response";
  users: IFSMSearchUserInfo[];
  hidden_copy_count: number;
}

export interface IFSMGetUserPickEngagementRequest extends IFliffRequest {
  __object_class_name: "FSM__Get_User_Pick_Engagement__Request";
  requestor_fkey: string;
  pick_ids: number[];
  start_stamp_millis_utc: number;
  end_stamp_millis_utc: number;
}

export interface IMyPickEmojiData {
  emoji_mapping: TEmojiMapping;
  count: number;
}

export interface IFSMGetUserPickEngagementResponse extends IFliffResponse {
  __object_class_name: "FSM__Get_User_Pick_Engagement__Response";
  reactions: IStringMap<IMyPickEmojiData>;
  copy_counters: IStringMap<number>;
}

export interface IFSMReactPickRequest extends IFliffRequest {
  __object_class_name: "FSM__React_Pick__Request";
  requestor_fkey: string;
  pick_fkey?: string;
  pick_id?: string;
  emoji?: string;
  original_created: number;
}

export interface IFSMReactPickResponse extends IFliffRequest {
  __object_class_name: "FSM__React_Pick__Response";
  pick_fkey: string;
  emoji?: string;
}

export interface IFSMGetTokenRequest extends IFliffRequest {
  __object_class_name: "FSM__Get_Chat_Token__Request";
  requestor_fkey: string;
}

export interface IFSMGetTokenResponse extends IFliffResponse {
  __object_class_name: "FSM__Get_Chat_Token__Response";
  token: string;
}

export interface IFSMGetCopyPickCountRequest extends IFliffRequest {
  __object_class_name: "FSM__Get_Copy_Pick_Count__Request";
  requestor_fkey: string;
  pick_ids: number[];
  start_stamp_millis_utc: number;
  end_stamp_millis_utc: number;
}

export interface IFSMGetCopyPickCountResponse extends IFliffResponse {
  __object_class_name: "FSM__Get_Copy_Pick_Count__Response";
  picks: IStringMap<number>;
}

export interface ICopiedPick {
  source_pick_fkey: string;
  target_pick_id: number;
  created_timestamp_utc: number;
}

export interface IFSMCopyPickRequest extends IFliffRequest {
  __object_class_name: "FSM__Copy_Pick__Request";
  requestor_fkey: string;
  copied_picks: ICopiedPick[];
}

export interface IFSMCopyPickResponse extends IFliffResponse {
  __object_class_name: "FSM__Copy_Pick__Response";
}
