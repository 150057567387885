import { FliffWebSocketStats } from "server/herald/fliffSocketImpl/FliffWebSocketStats";

export enum THealthState {
  CONST_DISCONNECTED = -1,
  CONST_CONNECTING = 0,
  CONST_CONNECTED = 1,
  CONST_RECONNECTING = 2,
  CONST_DISCONNECTING = 3,
}
const THealthStateVisualNames: Record<THealthState, string> = {
  [THealthState.CONST_DISCONNECTED]: "Disconnected",
  [THealthState.CONST_CONNECTING]: "Connecting",
  [THealthState.CONST_CONNECTED]: "Connected",
  [THealthState.CONST_RECONNECTING]: "Reconnecting",
  [THealthState.CONST_DISCONNECTING]: "Disconnecting",
};

class HeraldHealthMonitor {
  private _lastIpAddress = "unknown";
  private _lastAuthToken = "anon";
  private _healthState: THealthState = -1;
  private _incomeMessagesCount = 0;

  public get getHealthStateName(): string {
    return THealthStateVisualNames[this._healthState];
  }

  public get lastIpAddress(): string {
    return this._lastIpAddress;
  }
  public get lastAuthToken(): string {
    return this._lastAuthToken;
  }

  public setLastIpAddress(ipAddress: string): void {
    this._lastIpAddress = ipAddress;
  }
  public setLastAuthToken(authToken: string): void {
    this._lastAuthToken = authToken;
  }

  public handleHealthChange = (
    state: THealthState,
    stats: FliffWebSocketStats | null,
  ): void => {
    this._healthState = state;
    console.log(`on health state chang ${state}`, stats);
  };

  public handleIncomeMessage = (): void => {
    this._incomeMessagesCount = this._incomeMessagesCount + 1;
  };
}

export default new HeraldHealthMonitor();
