import { SocialApiCoreImpl } from "./ApiBase";

import { InternalProtocolRequestBuilderImpl } from "./impl/HelperInternalProtocolRequestBuilderImpl";
import { InternalProtocolResponseProcessorImpl } from "./impl/HelperInternalProtocolResponseProcessorImpl";
import { InternalNetworkConnectorImpl } from "./impl/HelperInternalNetworkConnectorImpl";
import {
  IFliffRequest,
  IFliffResponse,
  TFliffResult,
} from "server/common/data/objects";
import ApiUIHandler from "server/common/ApiUIHandler";

class SocialApiCore extends ApiUIHandler {
  private readonly _ImplInstance = new SocialApiCoreImpl(
    new InternalProtocolRequestBuilderImpl(),
    new InternalProtocolResponseProcessorImpl(),
    new InternalNetworkConnectorImpl(),
  );

  public executeBlockingRequest<
    Request extends IFliffRequest,
    Response extends IFliffResponse,
  >(request: Request): Promise<Response> {
    return this.blockingDefaultProgressBarRequest<Request, Response>(
      this._ImplInstance.executeRequest,
    )(request);
  }

  public safeExecuteBlockingRequest<
    Request extends IFliffRequest,
    Response extends IFliffResponse,
  >(request: Request): Promise<TFliffResult<Response>> {
    // never throws exception - errors are wrapped in FSM__Result
    return this.safeBlockingDefaultProgressBarRequest<Request, Response>(
      this._ImplInstance.safeExecuteRequest,
    )(request);
  }

  public safeExecuteBlockingTransparentNoErrorModalRequest<
    Request extends IFliffRequest,
    Response extends IFliffResponse,
  >(request: Request): Promise<TFliffResult<Response>> {
    // never throws exception - errors are wrapped in FSM__Result
    return this.safeBlockingTransparentNoErrorModalProgressBarRequest<
      Request,
      Response
    >(this._ImplInstance.safeExecuteRequest)(request);
  }

  public safeExecuteBackgroundRequest<
    Request extends IFliffRequest,
    Response extends IFliffResponse,
  >(request: Request): Promise<TFliffResult<Response>> {
    return this._ImplInstance.safeExecuteRequest(request);
  }

  public executeBackgroundRequest<
    Request extends IFliffRequest,
    Response extends IFliffResponse,
  >(request: Request): Promise<Response> {
    return this._ImplInstance.executeRequest(request);
  }
}

export default new SocialApiCore();
