import { IDataHeraldFeedDiffUpdate } from "server/herald/data/objects";

class HeraldFeedStats {
  public lastServerStampMillis = 0;
  public lastPreMatchConflictsCount = 0;
  public lastPreMatchConflictsServerStampMillis = 0;
  public lastInPlayConflictsCount = 0;
  public lastInPlayConflictsServerStampMillis = 0;
  public lastPreMatchSubFeedsUpdateServerStampMillis = 0;
  public lastInPlaySubFeedsUpdateServerStampMillis = 0;

  public onMessage(message: IDataHeraldFeedDiffUpdate): void {
    this.lastServerStampMillis = message.server_timestamp;
    if (message.prematch_conflicts && message.prematch_conflicts.length > 0) {
      this.lastPreMatchConflictsCount = message.prematch_conflicts.length;
      this.lastPreMatchConflictsServerStampMillis = message.server_timestamp;
    }
    if (message.inplay_conflicts && message.inplay_conflicts.length > 0) {
      this.lastInPlayConflictsCount = message.inplay_conflicts.length;
      this.lastInPlayConflictsServerStampMillis = message.server_timestamp;
    }
    if (
      message.prematch_subfeeds_update &&
      message.prematch_subfeeds_update.length > 0
    ) {
      this.lastPreMatchSubFeedsUpdateServerStampMillis =
        message.server_timestamp;
    }
    if (
      message.inplay_subfeeds_update &&
      message.inplay_subfeeds_update.length > 0
    ) {
      this.lastInPlaySubFeedsUpdateServerStampMillis = message.server_timestamp;
    }
  }
}

export default new HeraldFeedStats();
