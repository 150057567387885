import React, { useMemo } from "react";
import classNames from "classnames";
import Button from "components/atoms_new/desktop/Button";
import Inputs from "components/atoms_new/shared/Inputs";
import ModalHeader from "components/molecules_new/desktop/Modal/atoms/ModalHeader";
import usePhoneNumberVerification from "hooks/usePhoneNumberVerification";
import usePhoneVerificationTimer from "hooks/usePhoneVerificationTimer";
import { FliffException } from "server/common/FliffException";
import { SportsBookBIF } from "server/legacyCore/server/SportsBookBIF";
import { SportsBookSignInBIF } from "server/legacyCore/server/SportsBookSignInBIF";
import { DevConstants } from "src/DevConstants";
import "./styles.scss";
import { IProps } from "./types";

const VerifyPhoneNumber = React.forwardRef<HTMLDivElement, IProps>(
  ({ onClose, openPrevious, openNext, mode }, forwardedRef) => {
    const {
      isValid,
      formattedPhoneNumber,
      handlePhoneNumberChange,
      rawPhoneNumber,
    } = usePhoneNumberVerification("");

    const { isTicking, timeFormat } = usePhoneVerificationTimer();

    const isSubmitEnabled = isValid && (!isTicking || mode === 3);
    const isPhoneNumberVerifyOrAuth = mode === 2 || mode === 2.5;

    const handleSendVerificationCode = async (): Promise<void> => {
      if (!isSubmitEnabled) {
        return;
      }
      // formattedPhoneNumber expected to be non-null if isValidPhoneNumber is true.
      const response = isPhoneNumberVerifyOrAuth
        ? await SportsBookSignInBIF.signInWithFliffAccount({
            login_token: "phone:" + formattedPhoneNumber?.trim(),
            password: "",
          })
        : await SportsBookBIF.blocking_claim_primary_phone_number({
            phone_number: formattedPhoneNumber!,
            dont_send_sms: DevConstants.dontSendSms,
          });

      if (response.is_error) {
        if (
          FliffException.isLoginClaimVerificationCodeSentError(
            response.exception,
          )
        ) {
          const secondsToResend =
            FliffException.getSecondsFromVerificationCodeError(
              response.exception,
            );
          if (secondsToResend) {
            openNext("verifyCode", {
              phoneNumber: formattedPhoneNumber!,
              mode: mode,
              secondsToResend,
            });
            return;
          }
        }

        if (
          isPhoneNumberVerifyOrAuth &&
          FliffException.isLoginClaimVerificationCodeSuccess(response.exception)
        ) {
          const secondsToResend =
            FliffException.getSecondsFromVerificationCodeError(
              response.exception,
            );

          if (secondsToResend) {
            openNext("verifyCode", {
              phoneNumber: formattedPhoneNumber!,
              mode: mode,
              secondsToResend,
            });
            return;
          }
        }
        return;
      }

      openNext("verifyCode", {
        phoneNumber: formattedPhoneNumber!,
        mode: mode,
      });
    };

    const enterTitle = isPhoneNumberVerifyOrAuth
      ? "Enter your phone number to receive sign in code."
      : "Enter your phone number to receive verification code.";

    const shouldShowCheckMark = isSubmitEnabled;

    const renderTitleText = useMemo(() => {
      switch (mode) {
        case 2:
          return "Sign In With Phone Number";
        case 2.5:
          return "Enter Your Phone Number";
        default:
          return "Verify Your Number";
      }
    }, [mode]);

    const handleModalClose = (): void => {
      onClose();
    };

    const handleGoBack = (): void => {
      openPrevious("signIn");
    };

    const inputClasses = classNames("sign-in-phone__input", {
      "sign-in-phone__input--verified": shouldShowCheckMark,
    });

    return (
      <div ref={forwardedRef} className="sign-in-phone">
        <ModalHeader
          title={renderTitleText}
          onClose={mode !== 3 ? handleModalClose : undefined}
          onGoBack={mode !== 3 ? handleGoBack : undefined}
        />

        <p className={"sign-in-phone__description"}>
          To get started, please log in with your phone number. You will receive
          a code via text message which you will need to enter to continue.
        </p>
        <Inputs.Label label={enterTitle} htmlFor="phone" />
        <Inputs.Phone
          value={rawPhoneNumber}
          onChangeText={handlePhoneNumberChange}
          maskChar={"_"}
          placeholder="+1 Phone Number"
          className={inputClasses}
          id="phone"
          theme="dark"
        />
        {isTicking && mode !== 3 && (
          <span>{timeFormat} Until a new code can be sent.</span>
        )}
        <Button
          onClick={handleSendVerificationCode}
          label={"Send Code"}
          disabled={!isSubmitEnabled}
          className="sign-in-phone__button"
        />
      </div>
    );
  },
);

VerifyPhoneNumber.displayName = "VerifyPhoneNumber";

export default VerifyPhoneNumber;
