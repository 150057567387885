import {
  IFSMDataUserInfo,
  IFSMGetUserProfileResponse,
  IFSMModifyRelationshipResponse,
  IFSMProtocolResponseSlots,
  TEmojiMapping,
} from "server/social/data/objects";
import { TFliffResult } from "server/common/data/objects";
import { staticDispatch } from "reduxLocal/store";

export class SocialSetUserAction {
  public static readonly type = "@type/SOCIAL_SET_USER" as const;
  public static readonly payload: IFSMGetUserProfileResponse;
  public type: typeof SocialSetUserAction.type;
  public payload: typeof SocialSetUserAction.payload;

  public static dispatchGetSocialUser(
    data: IFSMGetUserProfileResponse,
  ): IFSMGetUserProfileResponse {
    staticDispatch(this._action(data));
    return data;
  }

  public static dispatchSetSocialUser(
    result: TFliffResult<IFSMGetUserProfileResponse>,
  ) {
    if (result.response) {
      this.dispatchGetSocialUser(result.response);
    }

    return result;
  }

  private static _action(payload: typeof this.payload) {
    return { type: this.type, payload };
  }
}

export class SocialSetMultipleProfilesAction {
  static readonly type = "@type/SOCIAL_SET_MULTIPLE_PROFILES" as const;
  static readonly payload: IFSMDataUserInfo[];
  public type: typeof SocialSetMultipleProfilesAction.type;
  public payload: typeof SocialSetMultipleProfilesAction.payload;

  public static dispatchSetSocialMultipleProfiles = (
    result: TFliffResult<IFSMModifyRelationshipResponse>,
  ) => {
    if (result.response) {
      staticDispatch(
        this._action([
          result.response.anchor_profile,
          result.response.observer_profile,
        ]),
      );
    }

    return result;
  };

  private static _action(payload: typeof this.payload) {
    return { type: this.type, payload };
  }
}

export class SocialInjectProtocolSlotsAction {
  public static readonly type = "@type/INJECT_SOCIAL_PROTOCOL_SLOTS" as const;
  public static readonly payload: IFSMProtocolResponseSlots;
  public readonly type: typeof SocialInjectProtocolSlotsAction.type;
  public readonly payload: typeof SocialInjectProtocolSlotsAction.payload;

  public static dispatchInjectProtocolSlots(payload: typeof this.payload) {
    staticDispatch(this._action(payload));
  }

  private static _action(
    payload: typeof SocialInjectProtocolSlotsAction.payload,
  ) {
    return { type: this.type, payload };
  }
}

export class SocialSetReactionAction {
  public static readonly type = "@type/SOCIAL_SET_REACTION" as const;
  public static readonly payload: {
    mapping: TEmojiMapping;
    pickFKey: string;
  };
  public type: typeof SocialSetReactionAction.type;
  public payload: typeof SocialSetReactionAction.payload;

  public static dispatchSetReaction = (payload: typeof this.payload) => {
    staticDispatch(this._action(payload));
  };

  private static _action(payload: typeof this.payload) {
    return { type: this.type, payload };
  }
}

export class SocialPopulateReactionsAction {
  public static readonly type = "@type/SOCIAL_POPULATE_REACTIONS" as const;
  public static readonly payload: Record<string, TEmojiMapping>;
  public type: typeof SocialPopulateReactionsAction.type;
  public payload: typeof SocialPopulateReactionsAction.payload;

  public static dispatchPopulateReactions = (payload: typeof this.payload) => {
    staticDispatch(this._action(payload));
  };

  private static _action(payload: typeof this.payload) {
    return { type: this.type, payload };
  }
}
