import {
  AppUIHideModalProgressIndicator,
  AppUIShowModalProgressIndicator,
} from "reduxLocal/appUI/appUI.actions";
import AppConfig from "utils/AppConfig";
import { AppUtils } from "utils/AppUtils";
import { PlacePickUtils } from "utils/PlacePicksUtils";

class CoreApiUtils {
  private readonly _singlePlacePickValidationSleepMs = 100 as const;

  public placePickValidation = async (
    pickDelayInMs: number,
  ): Promise<boolean> => {
    if (pickDelayInMs === 0) {
      return PlacePickUtils.isCurrentPickValid();
    }

    AppUIShowModalProgressIndicator.dispatchShowModalProgressIndicator(
      AppConfig.modalProgressBarTransparentMode,
    );
    const n = pickDelayInMs / this._singlePlacePickValidationSleepMs;

    let i = 1;
    for (i; i <= n; i++) {
      await AppUtils.sleep(this._singlePlacePickValidationSleepMs);
      const isPickValid = PlacePickUtils.isCurrentPickValid();
      if (!isPickValid) {
        AppUIHideModalProgressIndicator.dispatchHideModalProgressIndicator();
        return false;
      }
    }
    return true;
  };
}

export default new CoreApiUtils();
