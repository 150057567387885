import { ISocialState } from "./types";
import { AppUtils } from "utils/AppUtils";
import { IFSMSocialConfig } from "server/social/data/objects";

export const initialState: ISocialState = {
  users: {},
  reactions: {},
  socialConfig: AppUtils.zDeepFreeze<IFSMSocialConfig>({
    config_version: -1,
    score_icon: "",
    hit_rate_icon: "",
    leaderboard_banner: {
      arena_code: "",
      maximized_banners: [],
      maximized_text: "",
      maximized_title: "",
    },
    supported_emojis: [],
    rpm_bg_gradients: [],
    lobby_chat_id: "",
    enable_pick_reactions: true,
  }),
};
