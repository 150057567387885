import Mixpanel from "mixpanel-browser";

import MixPanelWrapper from ".";
import { DevConstants } from "src/DevConstants";
import { PickType, UIConstants } from "server/legacyCore/data/constants";

import { IPlayThroughBundle } from "server/legacyCore/data/objects";
import { CashAmountFormatters } from "utils/UIAmountsFormatters";
import { THomeScreenTabsRouteNames } from "components/atoms/mobile/TabBar/atoms/TabItem/types";
import { staticGetState } from "reduxLocal/store";
import * as sportsBookS from "selectors/sportsBook";

class MixPanelGeneral extends MixPanelWrapper {
  public trackSignUp = (userId: number): void => {
    if (userId >= DevConstants.minUserIDAsSignUp) {
      this.trackEvent("Account Registration");
    }
  };

  public trackAppOpen = (): void => this.trackEvent("App Open");

  public trackPlacePick = (pickCurrency: number, pickType: PickType): void => {
    const type = UIConstants.isModeCash(pickCurrency)
      ? "Fliff Cash"
      : "Fliff Coins";
    const mode =
      pickType === PickType.CONST_81_STRAIGHT
        ? "Straight"
        : pickType === PickType.CONST_82_PARLAY
        ? "Parlay"
        : "SGP";

    this.trackEvent("Pick Submitted", {
      ["Pick Type"]: type,
      ["Betting Mode"]: mode,
    });
  };

  public logOut = (): void => {
    if (!MixPanelWrapper._isInit) {
      return;
    }
    Mixpanel.reset();
  };

  public trackCoinsCashPurchase = (
    vendor: "paysafe" | "mazooma",
    bundle: IPlayThroughBundle,
  ): void => {
    const totalMoneyPurchaseCount = sportsBookS.getTotalMoneyPurchaseCount(
      staticGetState(),
    );
    const isFirstPurchase = totalMoneyPurchaseCount === 0;
    const isSecondPurchase = totalMoneyPurchaseCount === 1;
    const eventName = isFirstPurchase
      ? "First Coins Cash Purchase"
      : isSecondPurchase
      ? "Full Conversion (Purchase)"
      : "Coins Cash Purchase";

    this.trackEvent(eventName, {
      ["Product Price"]: CashAmountFormatters.fliffCentsAsNumber(
        bundle.price_in_cents,
      ),
      Vendor: vendor,
    });
  };

  public trackCoinsXPPurchase = (bundle: IPlayThroughBundle): void => {
    this.trackEvent("Coins XP Purchase", {
      ["Product Price"]: CashAmountFormatters.fliffCentsAsNumber(
        bundle.price_in_cents,
      ),
    });
  };

  public trackActivityFeedCopyPress = (customerId: number | string): void =>
    this.trackEvent("Activity Feed Copy Press", { customerId });

  public trackActivityFeedProfilePress = (customerId: string): void =>
    this.trackEvent("Activity Feed Profile Press", { customerId });

  public trackLeaderboardProfilePress = (customerId: number): void =>
    this.trackEvent("Leaderboard Profile Press", { customerId });

  public trackBottomTabItemPress = (name: THomeScreenTabsRouteNames): void =>
    this.trackEvent("Bottom Tab Bar Item Press", { Name: name });

  public trackOffersScreenFocus = (): void =>
    this.trackEvent("Offers Screen Focus");

  public trackOffersBrandsPress = (brandName: string): void =>
    this.trackEvent("Offers Brand Press", { ["Brand Name"]: brandName });

  public trackOffersReviewPress = (): void =>
    this.trackEvent("Offers Review Press");

  public trackAddFavoriteMarket = (): void =>
    this.trackEvent("Add Market To Favorites");

  public trackRemoveFavoriteMarket = (): void =>
    this.trackEvent("Remove Market From Favorites");

  public trackInviteFriendsFromRewards = (): void =>
    this.trackEvent("Invite Friends Press");

  public trackLocationCheckStatus = (
    status: "success" | "error" | "timeout",
  ): void => this.trackEvent("Location Check", { status });
}

export default new MixPanelGeneral();
