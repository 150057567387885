import { FliffException } from "server/common/FliffException";

import { ErrorHelper } from "server/common/ErrorHelper";
import {
  IFliffProtocolResponse,
  IFliffResponse,
  TFliffResult,
} from "server/common/data/objects";
import {
  IFCMProtocolResponseSlots,
  IFCMRequest,
} from "server/core/data/objects";
import {
  IInternalNetworkConnector,
  IInternalProtocolRequestBuilder,
  IInternalProtocolResponseProcessor,
} from "server/common/interfaces";
import { MemoryStorage } from "utils/MemoryStorage";
import { TAnyAlias } from "src/types";

// wrapper over basic primitives, exposes functionality for normal usage
export class ApiBase {
  private _ProtocolRequestBuilder: IInternalProtocolRequestBuilder<IFCMRequest>;
  private _ResponseProcessor: IInternalProtocolResponseProcessor<
    IFCMProtocolResponseSlots,
    IFliffResponse
  >;
  private _NetworkConnector: IInternalNetworkConnector<
    IFCMProtocolResponseSlots,
    IFCMRequest,
    IFliffResponse
  >;

  constructor(
    protocolRequestBuilder: IInternalProtocolRequestBuilder<IFCMRequest>,
    responseProcessor: IInternalProtocolResponseProcessor<
      IFCMProtocolResponseSlots,
      IFliffResponse
    >,
    networkConnector: IInternalNetworkConnector<
      IFCMProtocolResponseSlots,
      IFCMRequest,
      IFliffResponse
    >,
  ) {
    this._ProtocolRequestBuilder = protocolRequestBuilder;
    this._ResponseProcessor = responseProcessor;
    this._NetworkConnector = networkConnector;
  }

  public executeRequest = async <
    Request extends IFCMRequest,
    Response extends IFliffResponse,
  >(
    request: Request,
  ): Promise<Response> => {
    const protocolResponse = await this._executeProtocolRequestImpl<
      Request,
      Response
    >(request, null);

    const result = protocolResponse.result;

    if (result.error) {
      throw new FliffException(
        result.error.errorCode,
        result.error.errorMessage,
      );
    }

    return result.response;
  };

  // wraps 'response message' or FliffException into TFCMResult
  public safeExecuteRequest = async <
    Request extends IFCMRequest,
    Response extends IFliffResponse,
  >(
    request: Request,
  ): Promise<TFliffResult<Response>> => {
    try {
      const protocolResponse = await this._executeProtocolRequestImpl<
        Request,
        Response
      >(request, null);
      return protocolResponse.result;
    } catch (error) {
      // build error holder for any exception
      const ex = this.fliffExceptionFromAnyError(error);
      return {
        error: {
          errorCode: ex.error_code,
          errorMessage: ex.error_message,
          errorSource: ex.debug_error_source,
          incidentTag: "",
        },
        response: null,
      };
    }
  };

  public fliffExceptionFromAnyError = (error: TAnyAlias): FliffException => {
    if (error instanceof FliffException) {
      return error;
    }

    console.log("unexpected exception - error:", error);

    // should not happen normally
    // may want to add some additional info
    return new FliffException(
      FliffException.ERROR_9999__INTERNAL_ERROR,
      "INTERNAL_ERROR - unexpected exception",
    );
  };

  // main internal logic: build meta, execute, import slots
  private _executeProtocolRequestImpl = async <
    Request extends IFCMRequest,
    Response extends IFliffResponse,
  >(
    request: Request,
    xRequests: IFCMRequest[] | null,
  ): Promise<IFliffProtocolResponse<IFCMProtocolResponseSlots, Response>> => {
    try {
      MemoryStorage.is_blocking_operation_in_progress = true;
      // build standard protocol request - creates RequestHeader and RequestMeta from global storage (redux)
      const protocolRequest = this._ProtocolRequestBuilder.buildProtocolRequest(
        request,
        xRequests,
      );
      // execute network call
      const protocolResponse = await this._NetworkConnector.sendProtocolRequest(
        protocolRequest,
      );
      this._ResponseProcessor.processProtocolResponseSlots(protocolResponse);
      return protocolResponse as IFliffProtocolResponse<
        IFCMProtocolResponseSlots,
        Response
      >;
    } catch (error) {
      const nextError = ErrorHelper.apiErrorToDDException(error);
      nextError.debug_error_source = "error in server_v2/ApiBase";
      throw nextError;
    } finally {
      MemoryStorage.is_blocking_operation_in_progress = false;
    }
  };
}
