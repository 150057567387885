import { AuthTokensResponse } from "server/common/data/serverAdapters";

export class AppTokens {
  public readonly accessToken: string;
  public readonly refreshToken: string;
  public readonly tokensSource: string;
  public readonly tokensRefreshCount: number;

  constructor(
    accessToken: string,
    refreshToken: string,
    tokensSource: string,
    tokensRefreshCount: number,
  ) {
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
    this.tokensSource = tokensSource;
    this.tokensRefreshCount = tokensRefreshCount;
  }

  public static createEmpty(): AppTokens {
    return new AppTokens("", "", "", 0);
  }

  public static createForResetAccessToken(inputTokens: AppTokens): AppTokens {
    return new AppTokens(
      "",
      inputTokens.refreshToken,
      inputTokens.tokensSource,
      inputTokens.tokensRefreshCount,
    );
  }

  public static createForDamageAccessToken(inputTokens: AppTokens): AppTokens {
    let accessToken = inputTokens.accessToken;
    if (accessToken) {
      accessToken = "z_" + accessToken;
    }

    return new AppTokens(
      accessToken,
      inputTokens.refreshToken,
      inputTokens.tokensSource,
      inputTokens.tokensRefreshCount,
    );
  }

  public static createForAuthTokensResponse(
    inputTokens: AppTokens,
    response: AuthTokensResponse,
    tokensSource: string | null,
  ): AppTokens {
    // if we have incoming token source and no existing token source - use the new one
    const source =
      tokensSource && !inputTokens.tokensSource
        ? tokensSource
        : inputTokens.tokensSource;

    return new AppTokens(
      response.accessToken,
      response.refreshToken,
      source,
      inputTokens.tokensRefreshCount + 1,
    );
  }
}
