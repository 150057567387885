import * as H from "history";
import { IFCMPublicFeedSyncResponse } from "server/core/data/objects";
import { PublicCoreApi } from "server/core/server/CoreApi";
import { HeraldBackend } from "server/herald/HeraldBackend";
import { Data__SB_SportEvent } from "server/legacyCore/data/objects";
import { TFliffResult } from "server/common/data/objects";
import { MemoryStorage } from "utils/MemoryStorage";

export class FeedFocusUtils {
  public static unsubscribeFromFocusedEvent() {
    const prevFocusedConflictFkey = MemoryStorage.focusedConflictFkey;
    HeraldBackend.unsubscribeForConflictFkeyFromFeed(prevFocusedConflictFkey);
    MemoryStorage.resetFocusedConflictFkey();
  }

  public static resetFocusedEvent = async (
    event: Data__SB_SportEvent,
  ): Promise<TFliffResult<IFCMPublicFeedSyncResponse>> => {
    // 2022-01-12 / Ivan / initial integration with zmq on conflict level
    const prevEventFkey = MemoryStorage.focusedConflictFkey;

    // 2022-01-20 / Ivan / if new event - change subscription
    if (prevEventFkey !== event.conflict_fkey) {
      if (prevEventFkey !== "") {
        HeraldBackend.unsubscribeForConflictFkeyFromFeed(prevEventFkey);
      }
      if (
        !HeraldBackend.activeSubscriptionsTopics.includes(
          HeraldBackend.toConflictFkeyTopic(event.conflict_fkey),
        )
      ) {
        HeraldBackend.subscribeForConflictFkey(event.conflict_fkey);
      }
    }

    // 2021-10-14 / Ivan / update the focus
    MemoryStorage.setFocusedConflictFkey(event.conflict_fkey);

    return await PublicCoreApi.safeBackgroundLoadEventMarkets();
  };

  public static handleMoreMarketsPress(
    eventConflictFkey: string,
    history: H.History,
    initialTabIndex?: number,
  ) {
    history.push(`/markets/${eventConflictFkey}`, {
      initialTabIndex,
    });
  }

  public static async handleSelectChannelFromAllSports(): Promise<boolean> {
    const result =
      await PublicCoreApi.safeBlockingSyncPublicDataOnChannelSelect();

    return !!result.error;
  }
}
