import {
  IFliffRequest,
  IFliffResponse,
  TFliffResult,
} from "server/common/data/objects";

import {
  AppUIShowModalDialogAction,
  AppUIShowModalProgressIndicator,
  AppUIHideModalProgressIndicator,
} from "reduxLocal/appUI/appUI.actions";
import AppConfig from "utils/AppConfig";
import { FliffException } from "server/common/FliffException";
import { staticGetState } from "reduxLocal/store";
import { TAnyAlias } from "src/types";

export default class ApiUIHandler {
  protected safeBlockingTransparentNoErrorModalProgressBarRequest<
    InputRequest extends IFliffRequest,
    InputResponse extends IFliffResponse,
  >(input: (request: InputRequest) => Promise<TFliffResult<InputResponse>>) {
    return async function (
      request: InputRequest,
    ): Promise<TFliffResult<InputResponse>> {
      try {
        AppUIShowModalProgressIndicator.dispatchShowModalProgressIndicator(
          AppConfig.modalProgressBarTransparentMode,
        );
        const result = await input(request);
        AppUIHideModalProgressIndicator.dispatchHideModalProgressIndicator();
        return result;
      } catch (error: TAnyAlias) {
        AppUIHideModalProgressIndicator.dispatchHideModalProgressIndicator();
        if (!(error instanceof FliffException)) {
          console.warn(
            "unexpected error caught ApiUIHandler.blockingDefaultProgressBarRequest",
            error.message,
          );
        }
        // Expected to have here only instances of FliffException.
        throw error;
      }
    };
  }

  protected safeBackgroundRequest<
    InputRequest extends IFliffRequest,
    InputResponse extends IFliffResponse,
  >(input: (request: InputRequest) => Promise<TFliffResult<InputResponse>>) {
    return async (
      request: InputRequest,
    ): Promise<TFliffResult<InputResponse>> => {
      const result = await input(request);
      if (result.error) {
        this._showErrorDialog(result.error, request.__object_class_name);
      }
      return result;
    };
  }

  protected blockingDefaultProgressBarRequest<
    InputRequest extends IFliffRequest,
    InputResponse extends IFliffResponse,
  >(input: (request: InputRequest) => Promise<InputResponse>) {
    return async (request: InputRequest): Promise<InputResponse> => {
      try {
        AppUIShowModalProgressIndicator.dispatchShowModalProgressIndicator(
          AppConfig.modalProgressBarDefaultMode,
        );
        const result = await input(request);
        AppUIHideModalProgressIndicator.dispatchHideModalProgressIndicator();
        return result;
      } catch (error: TAnyAlias) {
        AppUIHideModalProgressIndicator.dispatchHideModalProgressIndicator();
        this._showErrorDialog(error, request.__object_class_name);
        if (!(error instanceof FliffException)) {
          console.warn(
            "unexpected error caught ApiUIHandler.blockingDefaultProgressBarRequest",
            error.message,
          );
        }
        // Expected to have here only instances of FliffException.
        throw error;
      }
    };
  }

  protected safeBlockingDefaultProgressBarRequest<
    InputRequest extends IFliffRequest,
    InputResponse extends IFliffResponse,
  >(input: (request: InputRequest) => Promise<TFliffResult<InputResponse>>) {
    return async (
      request: InputRequest,
    ): Promise<TFliffResult<InputResponse>> => {
      AppUIShowModalProgressIndicator.dispatchShowModalProgressIndicator(
        AppConfig.modalProgressBarDefaultMode,
      );
      const result = await input(request);
      AppUIHideModalProgressIndicator.dispatchHideModalProgressIndicator();
      if (result.error) {
        this._showErrorDialog(result.error, request.__object_class_name);
      }
      return result;
    };
  }

  protected safeBlockingTransparentProgressBarRequest<
    InputRequest extends IFliffRequest,
    InputResponse extends IFliffResponse,
  >(input: (request: InputRequest) => Promise<TFliffResult<InputResponse>>) {
    return async (
      request: InputRequest,
    ): Promise<TFliffResult<InputResponse>> => {
      AppUIShowModalProgressIndicator.dispatchShowModalProgressIndicator(
        AppConfig.modalProgressBarModalTransparent,
      );
      const result = await input(request);
      AppUIHideModalProgressIndicator.dispatchHideModalProgressIndicator();
      if (result.error) {
        this._showErrorDialog(result.error, request.__object_class_name);
      }
      return result;
    };
  }

  private _showErrorDialog = (error: TAnyAlias, debugInfo: string): void => {
    const isFliffException = error instanceof FliffException;
    const errorCode = isFliffException
      ? error.error_code
      : error?.errorCode || error?.code;

    const { error_codes_without_modal_error: errorCodesWithoutErrorModal } =
      staticGetState().sportsBook.config;

    const dismissErrorDialog = errorCodesWithoutErrorModal.includes(errorCode);
    if (dismissErrorDialog) {
      return;
    }
    AppUIShowModalDialogAction.dispatchShowErrorDialogForException(
      isFliffException
        ? error
        : new FliffException(
            error?.errorCode || error?.code,
            error?.errorMessage || error?.message,
            error?.errorSource,
          ),
      debugInfo,
    );
  };
}
