import { staticGetState } from "reduxLocal/store";
import {
  IPersistedWithdrawInfo,
  ISportsMenuItem,
  IStringMap,
  ITempPersistedWithdrawInfo,
  TLocalNotifications,
} from "src/types";
import { AppUtils } from "utils/AppUtils";
import DeviceInfo from "utils/DeviceInfo";
import Logger from "utils/Logger";
import { MemoryStorage } from "utils/MemoryStorage";
import { ChannelSkins } from "server/legacyCore/data/constants";

export class PersistentStorage {
  private static readonly _PROP_INSTALL_TOKEN = "@installToken";
  private static readonly _PROP_OS_DEVICE_ID = "@xosDeviceId";
  private static readonly _PROP_SERVER_CODE = "@serverCode";
  private static readonly _PROP_OAUTH_ACCESS_TOKEN = "@accessToken";
  private static readonly _PROP_OAUTH_REFRESH_TOKEN = "@refreshToken";
  private static readonly _PROP_OAUTH_TOKENS_SOURCE = "@tokensSource";
  private static readonly _PROP_OAUTH_TOKENS_REFRESH_COUNT =
    "@tokensRefreshCount";
  private static readonly _PROP_LAST_LOGGED_ACCOUNT_ID =
    "@lastLoggedAccount_id";
  private static readonly _PROP_LAST_LOGGED_USERNAME =
    "@lastLoggedAccount_username";
  private static readonly _PROP_LAST_LOGGED_STATUS =
    "@lastLoggedAccount_status";
  private static readonly _PROP_MORE_MARKETS_FAVORITES =
    "@PROP_MORE_MARKETS_FAVORITES";
  private static readonly _PROP_REVERSED_MARKETS_FILTERS =
    "@_PROP_REVERSED_MARKETS_FILTERS";
  private static readonly _PROP_WHAT_IS_NEW_BOX_PLAYER_LEVELS =
    "@PROP_WHAT_IS_NEW_BOX_PLAYER_LEVELS_v3";
  private static readonly _PROP_LEADERBOARD_WHAT_IS_NEW_BOX =
    "@PROP_LEADERBOARD_WHAT_IS_NEW_BOX";
  private static readonly _PROP_LAST_DISPLAYED_ID_FOR_NOTIFICATION_TYPE_PREFIX =
    "@PROP_LAST_DISPLAYED_ID_FOR_NOTIFICATION_TYPE_PREFIX__";
  private static readonly _PROP_WITHDRAW_INFO = "@PROP_WITHDRAW_INFO";
  private static readonly _PROP_HIDDEN_ALL_SPORTS_LEAGUES =
    "@PROP_HIDDEN_ALL_SPORTS_LEAGUES_2";
  private static readonly _PROP_MM_SCREEN_SGP_DISCLAIMER =
    "PROP_MM_SCREEN_SGP_DISCLAIMER";
  private static readonly _PROP_HIDDEN_TRANSACTION_IDS =
    "@PROP_HIDDEN_TRANSACTION_IDS";
  private static readonly _CHANNEL_VISIBLE_STATE = 0;
  private static readonly _CHANNEL_INVISIBLE_STATE = -1;
  private static _cachedInstallToken = "unknown_install_token";
  private static _cachedDeviceId = "unknown_device_id";

  private static readonly _PROP_SAVED_APP_PROFILES = "@savedAppProfiles";
  private static readonly _PROP_CLOSED_MENU_ITEMS_KEYS =
    "@CLOSED_MENU_ITEMS_KEYS";

  public static get cachedInstallToken() {
    return PersistentStorage._cachedInstallToken;
  }

  public static get cachedDeviceId() {
    return PersistentStorage._cachedDeviceId;
  }

  public static async initOnStartup(): Promise<void> {
    PersistentStorage._cachedInstallToken =
      PersistentStorage._getInstallToken();
    PersistentStorage._cachedDeviceId = await PersistentStorage._getDeviceId();
  }

  public static get(key: string): string {
    try {
      const res = localStorage.getItem(key);
      return AppUtils.nullToEmpty(res ?? null);
    } catch (error) {
      Logger.warnAny("PersistentStorage.get", error);
      return "";
    }
  }

  public static getNumber(key: string): number {
    try {
      const res = localStorage.getItem(key);
      return res ? parseInt(res, 10) : 0;
    } catch (error) {
      console.warn("PersistentStorage.get", error);
      return 0;
    }
  }

  public static set(...params: Parameters<typeof localStorage.setItem>) {
    try {
      return localStorage.setItem(...params);
    } catch (error) {
      Logger.warnAny("PersistentStorage.set", error);
    }
  }

  public static remove(key: string): void {
    try {
      return localStorage.removeItem(key);
    } catch (error) {
      Logger.warnAny("PersistentStorage.remove", error);
    }
  }

  public static getAllKeys() {
    try {
      return Object.keys(localStorage);
    } catch (error) {
      Logger.warnAny("PersistentStorage.getAll", error);
      return [];
    }
  }

  public static saveAuthTokens(
    serverCode: string,
    accessToken: string,
    refreshToken: string,
    tokensSource: string,
    tokensRefreshCount: number,
  ) {
    PersistentStorage.set(PersistentStorage._PROP_SERVER_CODE, serverCode);
    PersistentStorage.set(
      PersistentStorage._PROP_OAUTH_ACCESS_TOKEN,
      accessToken,
    );
    PersistentStorage.set(
      PersistentStorage._PROP_OAUTH_REFRESH_TOKEN,
      refreshToken,
    );
    PersistentStorage.set(
      PersistentStorage._PROP_OAUTH_TOKENS_SOURCE,
      tokensSource,
    );
    PersistentStorage.set(
      PersistentStorage._PROP_OAUTH_TOKENS_REFRESH_COUNT,
      `${tokensRefreshCount}`,
    );
  }

  public static resetAuthTokens() {
    PersistentStorage.remove(PersistentStorage._PROP_OAUTH_ACCESS_TOKEN);
    PersistentStorage.remove(PersistentStorage._PROP_OAUTH_REFRESH_TOKEN);
    PersistentStorage.remove(PersistentStorage._PROP_OAUTH_TOKENS_SOURCE);
    PersistentStorage.remove(
      PersistentStorage._PROP_OAUTH_TOKENS_REFRESH_COUNT,
    );
  }

  public static loadAuthTokens(): [string, string, string, string, number] {
    const serverCode = PersistentStorage.get(
      PersistentStorage._PROP_SERVER_CODE,
    );
    const accessToken = PersistentStorage.get(
      PersistentStorage._PROP_OAUTH_ACCESS_TOKEN,
    );
    const refreshToken = PersistentStorage.get(
      PersistentStorage._PROP_OAUTH_REFRESH_TOKEN,
    );
    const tokensSource = PersistentStorage.get(
      PersistentStorage._PROP_OAUTH_TOKENS_SOURCE,
    );

    let strTokensRefreshCount = PersistentStorage.get(
      PersistentStorage._PROP_OAUTH_TOKENS_REFRESH_COUNT,
    );
    if (!strTokensRefreshCount) {
      strTokensRefreshCount = "0";
    }
    const tokensRefreshCount = Number(strTokensRefreshCount);

    return [
      serverCode,
      accessToken,
      refreshToken,
      tokensSource,
      tokensRefreshCount,
    ];
  }

  public static getLoggedAccountDebugInfoForSupportEmail(): string {
    const username = PersistentStorage.get(
      PersistentStorage._PROP_LAST_LOGGED_USERNAME,
    );
    const accountId = PersistentStorage.get(
      PersistentStorage._PROP_LAST_LOGGED_ACCOUNT_ID,
    );

    return "username: " + username + "\naccount_id: " + accountId;
  }

  public static getLastLoggedAccountUsername() {
    return PersistentStorage.get(PersistentStorage._PROP_LAST_LOGGED_USERNAME);
  }

  public static getLastLoggedProfileStatus() {
    return PersistentStorage.get(PersistentStorage._PROP_LAST_LOGGED_STATUS);
  }

  public static getPlayerLevelsWhatsNewBoxVisibility(): boolean {
    return (
      PersistentStorage._getBoolean(
        PersistentStorage._PROP_WHAT_IS_NEW_BOX_PLAYER_LEVELS,
      ) ?? true
    );
  }

  public static setPlayerLevelsWhatsNewBoxVisibility(isVisible: boolean): void {
    return PersistentStorage.set(
      PersistentStorage._PROP_WHAT_IS_NEW_BOX_PLAYER_LEVELS,
      `${isVisible}`,
    );
  }

  public static getLeaderBoardWhatIsNewBoxVisibility(
    arenaCode: string | undefined,
  ): boolean {
    const userId = staticGetState().sportsBook.profile!.user_id;
    return (
      PersistentStorage._getBoolean(
        `${PersistentStorage._PROP_LEADERBOARD_WHAT_IS_NEW_BOX}-${userId}-${arenaCode}`,
      ) ?? true
    );
  }

  public static setLeaderBoardWhatIsNewBoxVisibility(
    arenaCode: string | undefined,
    isVisible: boolean,
  ): void {
    const userId = staticGetState().sportsBook.profile!.user_id;

    return PersistentStorage.set(
      `${PersistentStorage._PROP_LEADERBOARD_WHAT_IS_NEW_BOX}-${userId}-${arenaCode}`,
      `${isVisible}`,
    );
  }

  public static saveLastLoggedProfile(accountId: number, username: string) {
    PersistentStorage.set(
      PersistentStorage._PROP_LAST_LOGGED_ACCOUNT_ID,
      `${accountId}`,
    );
    PersistentStorage.set(
      PersistentStorage._PROP_LAST_LOGGED_USERNAME,
      username,
    );
    PersistentStorage.set(PersistentStorage._PROP_LAST_LOGGED_STATUS, "logged");

    PersistentStorage.initMemCacheForUserId(accountId);
  }

  public static getLastLoggedAccountId() {
    return PersistentStorage.getNumber(
      PersistentStorage._PROP_LAST_LOGGED_ACCOUNT_ID,
    );
  }

  public static updateLoggedAccountStatusOnLogout() {
    PersistentStorage.remove(PersistentStorage._PROP_LAST_LOGGED_STATUS);
  }

  public static getMoreMarketsFavorites = (
    channelId: number,
  ): string[] | null => {
    const userId = staticGetState().sportsBook.profile!.user_id;
    const response = PersistentStorage.get(
      PersistentStorage._PROP_MORE_MARKETS_FAVORITES,
    );
    if (response) {
      const parsed = JSON.parse(response);
      if (parsed[userId] && parsed[userId][channelId]) {
        return parsed[userId][channelId];
      }
      return null;
    }
    return null;
  };

  public static setReversedMoreMarketFilter = (filterId: string) => {
    const persistentHiddenFilters = PersistentStorage.get(
      PersistentStorage._PROP_REVERSED_MARKETS_FILTERS,
    );

    if (persistentHiddenFilters) {
      const parsed = JSON.parse(persistentHiddenFilters);
      const filterIndex = parsed.indexOf(filterId);

      if (parsed.includes(filterId)) {
        parsed.splice(filterIndex, 1);
      } else {
        parsed.push(filterId);
      }

      return PersistentStorage.set(
        PersistentStorage._PROP_REVERSED_MARKETS_FILTERS,
        JSON.stringify(parsed),
      );
    }

    return PersistentStorage.set(
      PersistentStorage._PROP_REVERSED_MARKETS_FILTERS,
      JSON.stringify([filterId]),
    );
  };

  public static getReversedMoreMarketFilter = () => {
    const persistentReversedFilters = PersistentStorage.get(
      PersistentStorage._PROP_REVERSED_MARKETS_FILTERS,
    );

    if (persistentReversedFilters) {
      return JSON.parse(persistentReversedFilters);
    }

    return [];
  };

  public static setMoreMarketsFavorites = (
    favorites: string[],
    channelId: number,
  ): void => {
    const userId = staticGetState().sportsBook.profile!.user_id;
    const persistedFavorites = PersistentStorage.get(
      PersistentStorage._PROP_MORE_MARKETS_FAVORITES,
    );
    if (!persistedFavorites) {
      const initResponse = { [userId]: { [channelId]: favorites } };
      return PersistentStorage.set(
        PersistentStorage._PROP_MORE_MARKETS_FAVORITES,
        JSON.stringify(initResponse),
      );
    }
    const parsed = JSON.parse(persistedFavorites);
    const response = {
      ...parsed,
      [userId]: { ...(parsed[userId] || {}), [channelId]: favorites },
    };

    PersistentStorage.set(
      PersistentStorage._PROP_MORE_MARKETS_FAVORITES,
      JSON.stringify(response),
    );
  };

  public static getSavedAppProfiles() {
    return PersistentStorage.get(PersistentStorage._PROP_SAVED_APP_PROFILES);
  }

  public static setSavedAppProfiles(appProfiles: string) {
    PersistentStorage.set(
      PersistentStorage._PROP_SAVED_APP_PROFILES,
      appProfiles,
    );
  }

  public static getTempWithdrawInfo = (
    userId: number | null = null,
  ): ITempPersistedWithdrawInfo | null => {
    if (userId === null) {
      userId = staticGetState().sportsBook.profile!.user_id;
    }
    const response = PersistentStorage.get(
      PersistentStorage._PROP_WITHDRAW_INFO,
    );
    if (response) {
      return JSON.parse(response)[userId];
    }
    return null;
  };

  public static getWithdrawInfo = (
    userId: number | null = null,
  ): IPersistedWithdrawInfo | null => {
    if (userId === null) {
      userId = staticGetState().sportsBook.profile!.user_id;
    }
    const response = PersistentStorage.get(
      PersistentStorage._PROP_WITHDRAW_INFO,
    );
    if (response) {
      return JSON.parse(response)[userId];
    }
    return null;
  };

  public static setWithdrawInfo = (
    withdrawInfo: IPersistedWithdrawInfo,
  ): void => {
    const userId = staticGetState().sportsBook.profile!.user_id;
    const persistedWithdrawInfo = PersistentStorage.get(
      PersistentStorage._PROP_WITHDRAW_INFO,
    );
    if (!persistedWithdrawInfo) {
      const initResponse = {
        [userId]: withdrawInfo,
      };
      return PersistentStorage.set(
        PersistentStorage._PROP_WITHDRAW_INFO,
        JSON.stringify(initResponse),
      );
    }
    const response = {
      ...JSON.parse(persistedWithdrawInfo),
      [userId]: withdrawInfo,
    };
    PersistentStorage.set(
      PersistentStorage._PROP_WITHDRAW_INFO,
      JSON.stringify(response),
    );
  };

  public static getMMScreenSGPDisclaimerStatus = (): boolean => {
    return (
      PersistentStorage._getBoolean(
        PersistentStorage._PROP_MM_SCREEN_SGP_DISCLAIMER,
      ) ?? false
    );
  };

  public static setMMScreenSGPDisclaimerStatus = (status: boolean): void =>
    this.set(this._PROP_MM_SCREEN_SGP_DISCLAIMER, `${status}`);

  public static getAllSportsLeaguesMap = (): IStringMap<number> | null => {
    const response = PersistentStorage.get(
      PersistentStorage._PROP_HIDDEN_ALL_SPORTS_LEAGUES,
    );
    if (response) {
      return JSON.parse(response) as IStringMap<number>;
    }
    return null;
  };

  public static setLastLeagueStamp = (id: number, stamp: number): void => {
    const allSportsHiddenLeaguesMap =
      PersistentStorage.getAllSportsLeaguesMap();

    if (allSportsHiddenLeaguesMap) {
      const response = { ...allSportsHiddenLeaguesMap, [id]: stamp };
      PersistentStorage.set(
        PersistentStorage._PROP_HIDDEN_ALL_SPORTS_LEAGUES,
        JSON.stringify(response),
      );
      return;
    }
    const initMap = { [id]: stamp };
    PersistentStorage.set(
      PersistentStorage._PROP_HIDDEN_ALL_SPORTS_LEAGUES,
      JSON.stringify(initMap),
    );
  };

  public static syncAllSportsLeaguesMap = (
    preMatchChannels: ISportsMenuItem[],
  ): void => {
    const allSportsHiddenLeaguesMap =
      PersistentStorage.getAllSportsLeaguesMap();
    const now = Date.now();

    for (const preMatchChannelConfig of preMatchChannels) {
      for (const channel of preMatchChannelConfig.data) {
        const index = preMatchChannelConfig.data.findIndex(
          el => el.id === channel.id,
        );
        const isEventsPerLeagueEmpty =
          preMatchChannelConfig.eventsCountPerLeague[index] === 0;

        if (!allSportsHiddenLeaguesMap) {
          PersistentStorage._initChannelState(
            channel.id,
            isEventsPerLeagueEmpty,
          );
        } else {
          const persistedStamp = allSportsHiddenLeaguesMap[channel.id];
          const isChannelMissingInMap = typeof persistedStamp === "undefined";

          // Skip current iteration if map is already INIT and NEW channel is appeared (WHICH WASN'T PRESENT WHEN MAP INIT).
          // We simply add it to map and don't apply below checks.
          if (isChannelMissingInMap) {
            PersistentStorage._initChannelState(
              channel.id,
              isEventsPerLeagueEmpty,
            );
            continue;
          }

          if (persistedStamp === PersistentStorage._CHANNEL_VISIBLE_STATE) {
            if (isEventsPerLeagueEmpty) {
              PersistentStorage.setLastLeagueStamp(channel.id, now);
            }
            continue;
          }

          if (persistedStamp === PersistentStorage._CHANNEL_INVISIBLE_STATE) {
            if (!isEventsPerLeagueEmpty) {
              PersistentStorage.setLastLeagueStamp(
                channel.id,
                PersistentStorage._CHANNEL_VISIBLE_STATE,
              );
            }
            continue;
          }

          if (persistedStamp > 0) {
            if (!isEventsPerLeagueEmpty) {
              PersistentStorage.setLastLeagueStamp(
                channel.id,
                PersistentStorage._CHANNEL_VISIBLE_STATE,
              );
            }
          }
        }
      }
    }
  };

  public static getLastDisplayedIdForNotificationType(
    userId: number,
    notificationType: TLocalNotifications,
  ): number {
    return PersistentStorage._getInt(
      PersistentStorage._PROP_LAST_DISPLAYED_ID_FOR_NOTIFICATION_TYPE_PREFIX +
        userId +
        "__" +
        notificationType,
    );
  }

  public static setLastDisplayedIdForNotificationType(
    userId: number,
    notificationType: TLocalNotifications,
    id: number,
  ): void {
    PersistentStorage._syncMemCache(notificationType, id);
    return PersistentStorage.set(
      PersistentStorage._PROP_LAST_DISPLAYED_ID_FOR_NOTIFICATION_TYPE_PREFIX +
        userId +
        "__" +
        notificationType,
      `${id}`,
    );
  }

  public static initMemCacheForUserId(userId: number) {
    MemoryStorage.mem_cache__id_51202 =
      PersistentStorage.getLastDisplayedIdForNotificationType(userId, 51202);
    MemoryStorage.mem_cache__id_51203 =
      PersistentStorage.getLastDisplayedIdForNotificationType(userId, 51203);
    MemoryStorage.mem_cache__id_51204 =
      PersistentStorage.getLastDisplayedIdForNotificationType(userId, 51204);
    MemoryStorage.mem_cache__id_51207 =
      PersistentStorage.getLastDisplayedIdForNotificationType(userId, 51207);
    MemoryStorage.mem_cache__id_51206 =
      PersistentStorage.getLastDisplayedIdForNotificationType(userId, 51206);
    MemoryStorage.mem_cache__id_51231 =
      PersistentStorage.getLastDisplayedIdForNotificationType(userId, 51231);
    MemoryStorage.mem_cache__id_51232 =
      PersistentStorage.getLastDisplayedIdForNotificationType(userId, 51232);
  }

  private static _syncMemCache(notificationType: number, id: number) {
    if (notificationType === 51202) {
      MemoryStorage.mem_cache__id_51202 = id;
    }
    if (notificationType === 51203) {
      MemoryStorage.mem_cache__id_51203 = id;
    }
    if (notificationType === 51204) {
      MemoryStorage.mem_cache__id_51204 = id;
    }
    if (notificationType === 51206) {
      MemoryStorage.mem_cache__id_51206 = id;
    }
    if (notificationType === 51231) {
      MemoryStorage.mem_cache__id_51231 = id;
    }
    if (notificationType === 51232) {
      MemoryStorage.mem_cache__id_51232 = id;
    }
    if (notificationType === 51250) {
      MemoryStorage.mem_cache__id_51250 = id;
    }
    if (notificationType === 51251) {
      MemoryStorage.mem_cache__id_51251 = id;
    }
    if (notificationType === 51252) {
      MemoryStorage.mem_cache__id_51252 = id;
    }
  }

  private static _getBoolean(key: string): boolean | null {
    try {
      const res = PersistentStorage.get(key);
      if (!res) {
        return null;
      }
      const parsed = JSON.parse(res);
      return typeof parsed === "boolean" ? parsed : null;
    } catch (error) {
      console.warn("PersistentStorage.getBoolean", error);
      return null;
    }
  }

  private static _getInt(key: string): number {
    const val = PersistentStorage.get(key);

    // ensure number, default 0
    let int = +val;
    if (isNaN(int)) {
      int = 0;
    }

    // 2020-09-21 / Ivan / temp patch
    int = Math.trunc(int);

    return int;
  }

  private static _initChannelState = (
    id: number,
    isEventsPerLeagueEmpty: boolean,
  ) => {
    const channelVisibilityState = isEventsPerLeagueEmpty
      ? PersistentStorage._CHANNEL_INVISIBLE_STATE
      : PersistentStorage._CHANNEL_VISIBLE_STATE;
    PersistentStorage.setLastLeagueStamp(id, channelVisibilityState);
  };

  private static _getInstallToken(): string {
    let installToken = PersistentStorage.get(
      PersistentStorage._PROP_INSTALL_TOKEN,
    );
    if (!installToken) {
      installToken = AppUtils.randomString(10);
      PersistentStorage.set(
        PersistentStorage._PROP_INSTALL_TOKEN,
        installToken,
      );
    }
    return installToken;
  }

  private static async _getDeviceId(): Promise<string> {
    let value: string = PersistentStorage.get(
      PersistentStorage._PROP_OS_DEVICE_ID,
    );
    if (!value) {
      // 2020-08-17 / Ivan / moving to real device id
      value = await DeviceInfo.getDeviceId();
      PersistentStorage.set(PersistentStorage._PROP_OS_DEVICE_ID, value);
    }
    return value;
  }

  public static getHiddenTransactionIds(): number[] {
    const response = PersistentStorage.get(
      PersistentStorage._PROP_HIDDEN_TRANSACTION_IDS,
    );
    if (response) {
      return JSON.parse(response) as number[];
    }
    return [];
  }

  public static appendHiddenTransactionsId(id: number): void {
    const prevHiddenIds = PersistentStorage.getHiddenTransactionIds();
    const updatedHiddenIds = prevHiddenIds.concat(id);

    PersistentStorage.set(
      PersistentStorage._PROP_HIDDEN_TRANSACTION_IDS,
      JSON.stringify(updatedHiddenIds),
    );
  }

  public static setClosedMenuItemsKey = (filterId: ChannelSkins) => {
    const closedMenuItemsKeys = PersistentStorage.get(
      PersistentStorage._PROP_CLOSED_MENU_ITEMS_KEYS,
    );

    if (closedMenuItemsKeys) {
      const parsed = JSON.parse(closedMenuItemsKeys);
      const filterIndex = parsed.indexOf(filterId);

      if (parsed.includes(filterId)) {
        parsed.splice(filterIndex, 1);
      } else {
        parsed.push(filterId);
      }

      return PersistentStorage.set(
        PersistentStorage._PROP_CLOSED_MENU_ITEMS_KEYS,
        JSON.stringify(parsed),
      );
    }

    return PersistentStorage.set(
      PersistentStorage._PROP_CLOSED_MENU_ITEMS_KEYS,
      JSON.stringify([filterId]),
    );
  };

  public static getClosedMenuItemsKeys = () => {
    const closedMenuItemsKeys = PersistentStorage.get(
      PersistentStorage._PROP_CLOSED_MENU_ITEMS_KEYS,
    );

    if (closedMenuItemsKeys) {
      return JSON.parse(closedMenuItemsKeys);
    }

    return [];
  };
}
