import { SportsBookResponse } from "server/legacyCore/server/x_objects_protocol";
import { DataBIFResponse } from "server/legacyCore/data/objectsCore";
import {
  Data__SB_Points_Transaction_PeriodReport,
  Data__SB_v2_Place_Picks_Operation_Status,
  Data__SB_Public_Challenges_Report,
  Data__SB_Validate_Register_Result,
  IDataSlideAllGiftCardBrands,
  IDataSBInitiatedWithdrawalResponse,
  IDataSBInitiatedPersonaVerificationResponse,
  DataResponse__SB_cancel_withdrawal_data,
  Data__SB_Pick_PeriodReport,
} from "server/legacyCore/data/objects";
import { IDataSBUnfinishedOrder } from "server/common/data/objects";

export const extract__Data__SB_Diamonds_Transaction_PeriodReport = (
  sreponse: SportsBookResponse,
): DataBIFResponse<Data__SB_Points_Transaction_PeriodReport> => {
  if (sreponse.points_transaction_report === null) {
    throw new Error("unexpected (sreponse.points_transaction_report == null)");
  }

  return DataBIFResponse.create_for_data(sreponse.points_transaction_report);
};

export const extract__Data__SB_Pick_PeriodReport = (
  sreponse: SportsBookResponse,
): DataBIFResponse<Data__SB_Pick_PeriodReport> => {
  if (sreponse.picks_period_report === null) {
    throw new Error("unexpected (sreponse.picks_period_report === null)");
  }

  return DataBIFResponse.create_for_data(sreponse.picks_period_report);
};

export const extract__Data__SB_v2_Place_Picks_Operation_Status = (
  sreponse: SportsBookResponse,
): DataBIFResponse<Data__SB_v2_Place_Picks_Operation_Status> => {
  if (sreponse.place_picks_operation_status === null) {
    throw new Error(
      "unexpected (sreponse.place_picks_operation_status == null)",
    );
  }

  return DataBIFResponse.create_for_data(sreponse.place_picks_operation_status);
};

export const extract__Data__SB_Unfinished_Order = (
  sreponse: SportsBookResponse,
): DataBIFResponse<IDataSBUnfinishedOrder> => {
  if (sreponse.new_order === null) {
    throw new Error("unexpected (sreponse.new_order == null)");
  }

  return DataBIFResponse.create_for_data(sreponse.new_order);
};

export const extract__Data__SB_Initiated_Withdrawal_Response = (
  sreponse: SportsBookResponse,
): DataBIFResponse<IDataSBInitiatedWithdrawalResponse> => {
  if (sreponse.initiated_withdrawal_response === null) {
    throw new Error(
      "unexpected (sreponse.initiated_withdrawal_response == null)",
    );
  }

  return DataBIFResponse.create_for_data(
    sreponse.initiated_withdrawal_response,
  );
};

export const extract__Data__SB_Initiated_Persona_Verification_Response = (
  sreponse: SportsBookResponse,
): DataBIFResponse<IDataSBInitiatedPersonaVerificationResponse> => {
  if (sreponse.initiated_persona_verification_response === null) {
    throw new Error(
      "unexpected (sreponse.initiated_withdrawal_response == null)",
    );
  }

  return DataBIFResponse.create_for_data(
    sreponse.initiated_persona_verification_response,
  );
};

export const extract__DataResponse__SB_cancel_withdrawal_data = (
  _sreponse: SportsBookResponse,
): DataBIFResponse<DataResponse__SB_cancel_withdrawal_data> => {
  // 2021-10-27 / Ivan / for the time being - no data is passed back
  const dummy = new DataResponse__SB_cancel_withdrawal_data();
  return DataBIFResponse.create_for_data(dummy);
};

// 2021-01-05 / Ivan / for the time being, return dummy object
export const extract__Data__SB_Validate_Register_Result = (
  _sreponse: SportsBookResponse,
): DataBIFResponse<Data__SB_Validate_Register_Result> => {
  const dummy = new Data__SB_Validate_Register_Result();
  return DataBIFResponse.create_for_data(dummy);
  /*
  if (sreponse.logros_report == null) {
    throw new Error("unexpected (sreponse.logros_report == null)");
  }
  return DataBIFResponse.create_for_data(sreponse.logros_report);
  */
};

export const extract__Data__SB_Public_Challenges_Report = (
  sreponse: SportsBookResponse,
): DataBIFResponse<Data__SB_Public_Challenges_Report> => {
  if (sreponse.public_challenges_report === null) {
    throw new Error("unexpected (sreponse.public_challenges_report == null)");
  }

  return DataBIFResponse.create_for_data(sreponse.public_challenges_report);
};

export const extract__Data__Slide_All_Gift_Card_Brands = (
  sreponse: SportsBookResponse,
): DataBIFResponse<IDataSlideAllGiftCardBrands> => {
  if (sreponse.slide_card_brands === null) {
    throw new Error("unexpected (sreponse.slide_card_brands == null)");
  }

  return DataBIFResponse.create_for_data(sreponse.slide_card_brands);
};

// 2020-11-07 / Ivan / quick & ugly hack - used on ensure feed focus, we are not interested in response
export const extract__nothing = (
  _sreponse: SportsBookResponse,
): DataBIFResponse<string> => {
  return DataBIFResponse.create_for_data("nothing_interesting");
};
