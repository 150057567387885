import { DefaultBIFResponse } from "server/legacyCore/data/objectsCore";
import { AbstractBIF } from "server/legacyCore/AbstractBIF";
import {
  ChangePasswordResponse,
  ForgotPasswordResponse,
  IDataContactUsRequest,
  SendEmailFeedbackResponse,
} from "server/legacyCore/data/objectsCore";
import { EnvUtils } from "utils/EnvUtils";
import { ApiCore } from "server/legacyCore/ApiCore";

export class SportsBookCommonBIF extends AbstractBIF {
  public static async blockingResetForgottenPassword(
    email: string,
  ): Promise<DefaultBIFResponse> {
    return await AbstractBIF.commonBlockingOperation(
      "resetForgottenPassword",
      async (): Promise<DefaultBIFResponse> => {
        await ApiCore.api_request_wrapper_no_auth(
          "forgotPassword",
          {
            method: "POST",
            url: "/api/v1/users/forgot-password/",
            data: { email },
          },
          ForgotPasswordResponse.decode,
        );
        return DefaultBIFResponse.create_for_ok();
      },
    );
  }

  public static async blockingChangePassword(
    oldPassword: string,
    newPassword: string,
    repeatPassword: string,
  ): Promise<DefaultBIFResponse> {
    return await AbstractBIF.commonBlockingOperation(
      "changePassword",
      async (): Promise<DefaultBIFResponse> => {
        await ApiCore.apiRequestWrapper(
          "changePassword",
          {
            method: "PUT",
            url: "/api/v1/users/new-password/",
            data: {
              old_password: oldPassword,
              new_password: newPassword,
              re_password: repeatPassword,
            },
          },
          ChangePasswordResponse.decode,
        );
        return DefaultBIFResponse.create_for_ok();
      },
    );
  }

  public static async blockingSendEmailFeedback(
    data: IDataContactUsRequest,
  ): Promise<DefaultBIFResponse> {
    return await AbstractBIF.commonBlockingOperation(
      "sendEmailFeedback",
      async (): Promise<DefaultBIFResponse> => {
        await ApiCore.apiRequestWrapper(
          "sendEmailFeedback",
          { method: "POST", url: "/api/v1/users/email-feedback/", data },
          SendEmailFeedbackResponse.decode,
        );
        return DefaultBIFResponse.create_for_ok();
      },
    );
  }

  public static async blockingSendPersonalizedEmailFeedback(
    data: IDataContactUsRequest,
  ): Promise<DefaultBIFResponse> {
    // 2019-12-10 / Ivan / quick hack - add some info from OS
    data.body += "\n\n\n";
    data.body += "==============";
    data.body += "\n\n";
    data.body += await EnvUtils.extractEnvData(false);

    return await AbstractBIF.commonBlockingOperation(
      "sendEmailFeedback",
      async (): Promise<DefaultBIFResponse> => {
        await ApiCore.apiRequestWrapper(
          "sendEmailFeedback",
          { method: "POST", url: "/api/v1/users/email-feedback/", data },
          SendEmailFeedbackResponse.decode,
        );
        return DefaultBIFResponse.create_for_ok();
      },
    );
  }
}
