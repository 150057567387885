import { ServerObj_Temp_Data_For_Complete_Profile } from "server/legacyCore/data/objectsCore";
import { SportsBookResponse } from "server/legacyCore/server/x_objects_protocol";
import { staticDispatch } from "reduxLocal/store";

class AppSetUserAccountAction {
  public static readonly type = "@type/SET_USER_ACCOUNT" as const;
  public static readonly payload: {
    shouldSetAuthenticatedFlag: boolean;
    stempUserAccount: ServerObj_Temp_Data_For_Complete_Profile | null;
  };
  public type: typeof AppSetUserAccountAction.type;
  public payload: typeof AppSetUserAccountAction.payload;

  public static dispatchSetIncompleteProfile(response: SportsBookResponse) {
    staticDispatch(
      this._action({
        shouldSetAuthenticatedFlag: false,
        stempUserAccount: response.temp_fliff_account,
      }),
    );
  }

  public static dispatchSetProfileOnSuccessAuth() {
    staticDispatch(
      this._action({
        shouldSetAuthenticatedFlag: true,
        stempUserAccount: null,
      }),
    );
  }

  private static _action(payload: typeof this.payload) {
    return { type: this.type, payload };
  }
}

class AppLogoutAction {
  public static readonly type = "@type/LOGOUT" as const;
  public type: typeof AppLogoutAction.type;

  public static dispatchLogout() {
    staticDispatch(this._action());
  }

  private static _action() {
    return { type: this.type };
  }
}

export { AppSetUserAccountAction, AppLogoutAction };
