import {
  BundleBonusSkin,
  BundleOfferSkin,
  TransactionType,
} from "server/core/data/constants";
import { IDataSlideGiftCardBrand } from "server/legacyCore/data/objects";
import { DevConstants } from "../DevConstants";
import AppConfig from "utils/AppConfig";

class Constants {
  private readonly _liveChannelId = -555 as const;
  private readonly _homeChannelId = -333 as const;
  private readonly _offersChannelId = -777 as const;
  private readonly _pokerChannelId = -789 as const;
  private readonly _mmaChannelId = 4950 as const;
  private readonly _favoriteTabCode = -999 as const;
  private readonly _summaryTabCode = -1000 as const;
  private readonly _chatTabCode = -1001 as const;
  private readonly _boostedTabCode = -1002 as const;
  private readonly _activitySummaryTabCode = "-1000" as const;
  private readonly _minUserIDAsSignUpProd = 95000 as const;
  private readonly _minUserIDAsSignUpDev = 5000 as const;
  private readonly _deleteAccountCustomerEmail =
    "delete-my-account@getfliff.com" as const;
  // private readonly _iOSDevProductSku = "DAMT099" as const;
  // private readonly _iOSStgProductSku = "SAMT099" as const;
  private readonly _androidDevProductSku = "d10kfcb1google" as const;
  private readonly _androidStgProductSku = "s10kfcb1google" as const;
  private readonly _webviewCatchSuffixes = {
    fiserv: "/u/fiserv/opiv_probe",
    persona: "/u/persona_verification_done",
    mazoomaPayment: "u/mazooma_payment_in_done",
    mazoomaCashOut: "/u/mazooma_obv_done",
    paysafe: "/u/paysafe/payment_in__done",
    tou: "u/tou_session_done",
  } as const;
  private readonly _remoteUrls = {
    termsOfUse: "https://getfliff.com/terms-of-use",
    sweepstakes: "https://getfliff.com/sweepstakes-rules",
    privacyPolicy: "https://getfliff.com/privacy-policy",
    sportPredictionRules: "https://getfliff.com/sports-prediction-rules",
    promoVideo: "https://www.getfliff.com/fliff-cash-flex",
    helpCenter:
      "https://fliff.zendesk.com/hc/en-us/articles/21474489587730-Full-Play-Through-Conditions",
  } as const;

  private readonly _withdrawalMethods = [
    {
      value: "mazooma",
      label: "Instant Bank Transfer",
      imageSource: require("assets/img/withdrawalMethods/bankWire.png"),
    },
    {
      value: "cashApp",
      label: "Cash App",
      imageSource: require("assets/img/withdrawalMethods/cashApp.png"),
    },
    {
      value: "skrill",
      label: "Skrill",
      imageSource: require("assets/img/withdrawalMethods/skrill.png"),
    },
    {
      value: "bitcoin",
      label: "Bitcoin",
      imageSource: require("assets/img/withdrawalMethods/bitcoin.png"),
    },
    {
      value: "bankWire",
      label: "Standard Bank Transfer",
      imageSource: require("assets/img/withdrawalMethods/bankWire.png"),
    },
    {
      value: "fiservBank",
      label: "Bank Transfer",
      imageSource: require("assets/img/withdrawalMethods/bankWire.png"),
    },
    {
      value: "fiservCard",
      label: "Push-to-Debit (Visa Cards Only)",
      imageSource: require("assets/img/withdrawalMethods/visa.png"),
    },
  ] as const;
  private readonly _hitSlops = {
    small: { top: 10, bottom: 10, right: 10, left: 10 },
    medium: { top: 20, bottom: 20, right: 20, left: 20 },
  } as const;
  private readonly _offersDescription = [
    {
      title: "Purchase a Gift Card",
      description:
        "Enter the amount you want. A gift card for the exact amount will be generated instantly.",
      iconName: "card",
    },
    {
      title: "Pay The Merchant With Fliff",
      description:
        "Enter your gift card serial number and/or PIN to pay online, or scan the barcode at the register to pay in-store.",
      iconName: "computer",
    },
  ] as const;
  private readonly _slideBrandsLoadingSampleData = [
    { b_uuid: "1" },
    { b_uuid: "2" },
    { b_uuid: "3" },
    { b_uuid: "4" },
    { b_uuid: "5" },
    { b_uuid: "6" },
    { b_uuid: "7" },
    { b_uuid: "8" },
    { b_uuid: "9" },
    { b_uuid: "10" },
  ] as unknown as IDataSlideGiftCardBrand[];
  private readonly _usaStates = [
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Hawaii",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Missouri",
    "Montana",
    "Nebraska",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ] as const;
  private readonly _bundleBonusSkinMap = {
    [BundleBonusSkin.CONST_2001_SKIN_V9_BUNDLES_SELECTOR_L2_SKIN_1]: require("assets/img/fliffCashBundles/CashBundle02.png"),
    [BundleBonusSkin.CONST_2002_SKIN_V9_BUNDLES_SELECTOR_L2_SKIN_2]: require("assets/img/fliffCashBundles/CashBundle03.png"),
    [BundleBonusSkin.CONST_2003_SKIN_V9_BUNDLES_SELECTOR_L2_SKIN_3]: require("assets/img/fliffCashBundles/CashBundle04.png"),
    [BundleBonusSkin.CONST_2004_SKIN_V9_BUNDLES_SELECTOR_L2_SKIN_4]: require("assets/img/fliffCashBundles/CashBundle05.png"),
    [BundleBonusSkin.CONST_2005_SKIN_V9_BUNDLES_SELECTOR_L2_SKIN_5]: require("assets/img/fliffCashBundles/CashBundle06.png"),
  } as const;

  private readonly _bundlePrimarySkinMap = {
    [BundleOfferSkin.CONST_DEFAULT_SKIN]: require("assets/img/fliffCashBundles/CashBundle06.png"),
    [BundleOfferSkin.CONST_DEFAULT_SKIN_2]: require("assets/img/fliffCashBundles/CashBundle06.png"),

    [BundleOfferSkin.CONST_701_SKIN_FREE_HOURLY_NO_FC_BONUS]: require("assets/img/fliffCashBundles/CashBundle06.png"),
    [BundleOfferSkin.CONST_702_SKIN_FREE_DAILY_NO_FC_BONUS]: require("assets/img/fliffCashBundles/CashBundle06.png"),
    [BundleOfferSkin.CONST_703_SKIN_FREE_HOURLY_WITH_FC_BONUS]: require("assets/img/fliffCashBundles/CashBundle06.png"),
    [BundleOfferSkin.CONST_704_SKIN_FREE_DAILY_WITH_FC_BONUS]: require("assets/img/fliffCashBundles/CashBundle06.png"),

    [BundleOfferSkin.CONST_801_SKIN_SOCIAL_COINS_PRICE_LEVEL_1]: require("assets/img/coinsBundles/coin_bundles__bag_skin_101.png"),
    [BundleOfferSkin.CONST_802_SKIN_SOCIAL_COINS_PRICE_LEVEL_2]: require("assets/img/coinsBundles/coin_bundles__bag_skin_102.png"),
    [BundleOfferSkin.CONST_803_SKIN_SOCIAL_COINS_PRICE_LEVEL_3]: require("assets/img/coinsBundles/coin_bundles__bag_skin_103.png"),
    [BundleOfferSkin.CONST_804_SKIN_SOCIAL_COINS_PRICE_LEVEL_4]: require("assets/img/coinsBundles/coin_bundles__bag_skin_104.png"),
    [BundleOfferSkin.CONST_805_SKIN_SOCIAL_COINS_PRICE_LEVEL_5]: require("assets/img/coinsBundles/coin_bundles__bag_skin_105.png"),
    [BundleOfferSkin.CONST_806_SKIN_SOCIAL_COINS_PRICE_LEVEL_6]: require("assets/img/coinsBundles/coin_bundles__bag_skin_106.png"),

    [BundleOfferSkin.CONST_901_SKIN_FLIFF_CASH_PRICE_LEVEL_1]: require("assets/img/fliffCashBundles/CashBundle01.png"),
    [BundleOfferSkin.CONST_902_SKIN_FLIFF_CASH_PRICE_LEVEL_2]: require("assets/img/fliffCashBundles/CashBundle02.png"),
    [BundleOfferSkin.CONST_903_SKIN_FLIFF_CASH_PRICE_LEVEL_3]: require("assets/img/fliffCashBundles/CashBundle03.png"),
    [BundleOfferSkin.CONST_904_SKIN_FLIFF_CASH_PRICE_LEVEL_4]: require("assets/img/fliffCashBundles/CashBundle04.png"),
    [BundleOfferSkin.CONST_905_SKIN_FLIFF_CASH_PRICE_LEVEL_5]: require("assets/img/fliffCashBundles/CashBundle05.png"),
    [BundleOfferSkin.CONST_906_SKIN_FLIFF_CASH_PRICE_LEVEL_6]: require("assets/img/fliffCashBundles/CashBundle06.png"),

    [BundleOfferSkin.CONST_821_SKIN_SOCIAL_COINS_2X_PRICE_LEVEL_1]: require("assets/img/coinsBundles/coin_bundles__bag_skin_101.png"),
    [BundleOfferSkin.CONST_822_SKIN_SOCIAL_COINS_2X_PRICE_LEVEL_2]: require("assets/img/coinsBundles/coin_bundles__bag_skin_102.png"),
    [BundleOfferSkin.CONST_823_SKIN_SOCIAL_COINS_2X_PRICE_LEVEL_3]: require("assets/img/coinsBundles/coin_bundles__bag_skin_103.png"),
    [BundleOfferSkin.CONST_824_SKIN_SOCIAL_COINS_2X_PRICE_LEVEL_4]: require("assets/img/coinsBundles/coin_bundles__bag_skin_104.png"),
    [BundleOfferSkin.CONST_825_SKIN_SOCIAL_COINS_2X_PRICE_LEVEL_5]: require("assets/img/coinsBundles/coin_bundles__bag_skin_105.png"),
    [BundleOfferSkin.CONST_826_SKIN_SOCIAL_COINS_2X_PRICE_LEVEL_6]: require("assets/img/coinsBundles/coin_bundles__bag_skin_106.png"),

    [BundleOfferSkin.CONST_921_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_1]: require("assets/img/fliffCashBundles/CashBundle01.png"),
    [BundleOfferSkin.CONST_922_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_2]: require("assets/img/fliffCashBundles/CashBundle02.png"),
    [BundleOfferSkin.CONST_923_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_3]: require("assets/img/fliffCashBundles/CashBundle03.png"),
    [BundleOfferSkin.CONST_924_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_4]: require("assets/img/fliffCashBundles/CashBundle04.png"),
    [BundleOfferSkin.CONST_925_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_5]: require("assets/img/fliffCashBundles/CashBundle05.png"),
    [BundleOfferSkin.CONST_926_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_6]: require("assets/img/fliffCashBundles/CashBundle06.png"),
  } as const;

  private readonly _bundleSecondarySkinMap = {
    [BundleOfferSkin.CONST_DEFAULT_SKIN]: require("assets/img/fliffCashBundles/CashBundle06.png"),
    [BundleOfferSkin.CONST_DEFAULT_SKIN_2]: require("assets/img/fliffCashBundles/CashBundle06.png"),

    [BundleOfferSkin.CONST_701_SKIN_FREE_HOURLY_NO_FC_BONUS]: require("assets/img/fliffCashBundles/CashBundle06.png"),
    [BundleOfferSkin.CONST_702_SKIN_FREE_DAILY_NO_FC_BONUS]: require("assets/img/fliffCashBundles/CashBundle06.png"),
    [BundleOfferSkin.CONST_703_SKIN_FREE_HOURLY_WITH_FC_BONUS]: require("assets/img/fliffCashBundles/CashBundle06.png"),
    [BundleOfferSkin.CONST_704_SKIN_FREE_DAILY_WITH_FC_BONUS]: require("assets/img/fliffCashBundles/CashBundle06.png"),

    [BundleOfferSkin.CONST_801_SKIN_SOCIAL_COINS_PRICE_LEVEL_1]: require("assets/img/coinsBundles/coin_bundles__bag_skin_101.png"),
    [BundleOfferSkin.CONST_802_SKIN_SOCIAL_COINS_PRICE_LEVEL_2]: require("assets/img/coinsBundles/coin_bundles__bag_skin_102.png"),
    [BundleOfferSkin.CONST_803_SKIN_SOCIAL_COINS_PRICE_LEVEL_3]: require("assets/img/coinsBundles/coin_bundles__bag_skin_103.png"),
    [BundleOfferSkin.CONST_804_SKIN_SOCIAL_COINS_PRICE_LEVEL_4]: require("assets/img/coinsBundles/coin_bundles__bag_skin_104.png"),
    [BundleOfferSkin.CONST_805_SKIN_SOCIAL_COINS_PRICE_LEVEL_5]: require("assets/img/coinsBundles/coin_bundles__bag_skin_105.png"),
    [BundleOfferSkin.CONST_806_SKIN_SOCIAL_COINS_PRICE_LEVEL_6]: require("assets/img/coinsBundles/coin_bundles__bag_skin_106.png"),

    [BundleOfferSkin.CONST_901_SKIN_FLIFF_CASH_PRICE_LEVEL_1]: require("assets/img/coinsBundles/coin_bundles__bag_skin_101.png"),
    [BundleOfferSkin.CONST_902_SKIN_FLIFF_CASH_PRICE_LEVEL_2]: require("assets/img/coinsBundles/coin_bundles__bag_skin_102.png"),
    [BundleOfferSkin.CONST_903_SKIN_FLIFF_CASH_PRICE_LEVEL_3]: require("assets/img/coinsBundles/coin_bundles__bag_skin_103.png"),
    [BundleOfferSkin.CONST_904_SKIN_FLIFF_CASH_PRICE_LEVEL_4]: require("assets/img/coinsBundles/coin_bundles__bag_skin_104.png"),
    [BundleOfferSkin.CONST_905_SKIN_FLIFF_CASH_PRICE_LEVEL_5]: require("assets/img/coinsBundles/coin_bundles__bag_skin_105.png"),
    [BundleOfferSkin.CONST_906_SKIN_FLIFF_CASH_PRICE_LEVEL_6]: require("assets/img/coinsBundles/coin_bundles__bag_skin_106.png"),

    [BundleOfferSkin.CONST_821_SKIN_SOCIAL_COINS_2X_PRICE_LEVEL_1]: require("assets/img/coinsBundles/coin_bundles__bag_skin_101.png"),
    [BundleOfferSkin.CONST_822_SKIN_SOCIAL_COINS_2X_PRICE_LEVEL_2]: require("assets/img/coinsBundles/coin_bundles__bag_skin_102.png"),
    [BundleOfferSkin.CONST_823_SKIN_SOCIAL_COINS_2X_PRICE_LEVEL_3]: require("assets/img/coinsBundles/coin_bundles__bag_skin_103.png"),
    [BundleOfferSkin.CONST_824_SKIN_SOCIAL_COINS_2X_PRICE_LEVEL_4]: require("assets/img/coinsBundles/coin_bundles__bag_skin_104.png"),
    [BundleOfferSkin.CONST_825_SKIN_SOCIAL_COINS_2X_PRICE_LEVEL_5]: require("assets/img/coinsBundles/coin_bundles__bag_skin_105.png"),
    [BundleOfferSkin.CONST_826_SKIN_SOCIAL_COINS_2X_PRICE_LEVEL_6]: require("assets/img/coinsBundles/coin_bundles__bag_skin_106.png"),

    [BundleOfferSkin.CONST_921_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_1]: require("assets/img/coinsBundles/coin_bundles__bag_skin_101.png"),
    [BundleOfferSkin.CONST_922_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_2]: require("assets/img/coinsBundles/coin_bundles__bag_skin_102.png"),
    [BundleOfferSkin.CONST_923_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_3]: require("assets/img/coinsBundles/coin_bundles__bag_skin_103.png"),
    [BundleOfferSkin.CONST_924_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_4]: require("assets/img/coinsBundles/coin_bundles__bag_skin_104.png"),
    [BundleOfferSkin.CONST_925_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_5]: require("assets/img/coinsBundles/coin_bundles__bag_skin_105.png"),
    [BundleOfferSkin.CONST_926_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_6]: require("assets/img/coinsBundles/coin_bundles__bag_skin_106.png"),
  };

  private readonly _cancelableTransactionTypes = [
    TransactionType.CONST_4017_V5_WITHDRAW_LOCK_AMOUNT_BANK_WIRE,
    TransactionType.CONST_4018_V5_WITHDRAW_LOCK_AMOUNT_CASH_APP,
    TransactionType.CONST_4019_V5_WITHDRAW_LOCK_AMOUNT_SKRILL,
    TransactionType.CONST_4020_V5_WITHDRAW_LOCK_AMOUNT_BITCOIN,
    TransactionType.CONST_4023_V6_WITHDRAW_LOCK_AMOUNT_PAYMENT_TOKEN,
    TransactionType.CONST_4024_V6_WITHDRAW_LOCK_AMOUNT_MAZOOMA_DIRECT,
    TransactionType.CONST_4161_V6_FISERV_WITHDRAW_LOCK_AMOUNT_PAYMENT_TOKEN,
  ];
  private readonly _processedTransactionTypes = [
    TransactionType.CONST_4131_ADMIN_OPERATION_WITHDRAWAL_REQUEST_APPROVED,
    TransactionType.CONST_4141_MAZOOMA_TRANSFER_CONFIRMED_SUCCESS,
    TransactionType.CONST_4132_ADMIN_OPERATION_WITHDRAWAL_REQUEST_DECLINED,
    TransactionType.CONST_4022_WITHDRAW_CANCELLED_BY_USER,
    TransactionType.CONST_4142_MAZOOMA_TRANSFER_FAILED,
    TransactionType.CONST_4171_FISERV_TRANSFER_CONFIRMED_SUCCESS,
    TransactionType.CONST_4172_FISERV_TRANSFER_FAILED,
  ];

  private readonly _lobbyChatTab = "lobbyChat" as const;
  private readonly _phoneVerificationCodeLength = 6 as const;

  public get lobbyChatTab() {
    return this._lobbyChatTab;
  }

  public get webviewCatchSuffixes() {
    return this._webviewCatchSuffixes;
  }

  public get cancelableTransactionTypes() {
    return this._cancelableTransactionTypes;
  }

  public get processedTransactionTypes() {
    return this._processedTransactionTypes;
  }

  public get devProductSku() {
    if (!DevConstants.isDevMode) {
      throw new Error("This purchase must be used only in dev mode.");
    }

    return AppConfig.env === "staging"
      ? this._androidStgProductSku
      : this._androidDevProductSku;
  }

  public get bundleBonusSkinMap() {
    return this._bundleBonusSkinMap;
  }

  public get bundlePrimarySkinMap() {
    return this._bundlePrimarySkinMap;
  }

  public get bundleSecondarySkinMap() {
    return this._bundleSecondarySkinMap;
  }

  public get usaStates() {
    return this._usaStates;
  }

  public get slideBrandsLoadingSampleData() {
    return this._slideBrandsLoadingSampleData;
  }

  public get offersDescriptions() {
    return this._offersDescription;
  }

  public get hitSlops() {
    return this._hitSlops;
  }

  public get phoneVerificationCodeLength() {
    return this._phoneVerificationCodeLength;
  }

  public get withdrawalMethods() {
    return this._withdrawalMethods;
  }

  public get remoteUrls() {
    return this._remoteUrls;
  }

  public get deleteAccountCustomerEmail(): string {
    return this._deleteAccountCustomerEmail;
  }

  public get mmaChannelId(): number {
    return this._mmaChannelId;
  }
  public get favoriteTabCode(): number {
    return this._favoriteTabCode;
  }

  public get summaryTabCode(): number {
    return this._summaryTabCode;
  }

  public get activitySummaryTabCode(): string {
    return this._activitySummaryTabCode;
  }

  public get chatTabCode(): number {
    return this._chatTabCode;
  }

  public get boostedTabCode(): number {
    return this._boostedTabCode;
  }

  public get minUserIDAsSignUp(): number {
    if (DevConstants.isDevMode) {
      return this._minUserIDAsSignUpDev;
    }
    return this._minUserIDAsSignUpProd;
  }

  public get liveChannelId(): number {
    return this._liveChannelId;
  }

  public get homeChannelId(): number {
    return this._homeChannelId;
  }

  public get offersChannelId(): number {
    return this._offersChannelId;
  }

  public get pokerChannelId(): number {
    return this._pokerChannelId;
  }
}

export default new Constants();
