import { PrivateCoreApi } from "server/core/server/CoreApi";
import { staticGetState } from "reduxLocal/store";
import { MemoryStorage } from "utils/MemoryStorage";

export class SportsBookUserProfileMonitor {
  private static poll_data_in_progress: boolean;
  public static last_poll_data_start_stamp: number = 0; // public - to prevent typescript warning
  private static last_poll_data_end_stamp: number = 0;
  private static last_poll_data_was_error: boolean = false;

  public static onTick() {
    if (!SportsBookUserProfileMonitor.should_poll_now()) {
      return;
    }

    SportsBookUserProfileMonitor.do_single_poll();
  }

  private static should_poll_now(): boolean {
    if (!MemoryStorage.enableBackgroundRefresh) {
      return false;
    }

    if (!staticGetState().app.authenticated) {
      return false;
    }

    if (SportsBookUserProfileMonitor.poll_data_in_progress) {
      return false;
    }

    // 2020-06-10 / Ivan / for the time being if we check for delta > x or < x - will be enough ?
    // 2019-12-06 / Ivan / we need to use something like OS uptime / realtime - if user changes his device time, this should not affect the logic

    // 2021-02-01 / Ivan / switch to server control for poll intervals
    let interval;

    if (
      MemoryStorage.local_poll_interval_profile_during_monitor_purchase_progress >
      0
    ) {
      // 2021-03-28 / Ivan / enable support for more frequent polling during 'poll for purchase progress'
      interval =
        MemoryStorage.local_poll_interval_profile_during_monitor_purchase_progress;
    } else if (SportsBookUserProfileMonitor.last_poll_data_was_error) {
      interval = MemoryStorage.config__20022__poll_interval_profile_after_error;
    } else {
      interval = MemoryStorage.config__20021__poll_interval_profile_after_ok;
    }

    // 2020-10-28 / Ivan / this wont work well if user changes the settings of the device clock
    const now: number = new Date().getTime();
    if (
      now - SportsBookUserProfileMonitor.last_poll_data_end_stamp <
      interval
    ) {
      return false;
    }
    //    console.log(" * action - interval:", interval + ", was last_error:", SportsBookUserProfileMonitor.last_poll_data_was_error);

    return true;
  }

  private static async do_single_poll() {
    SportsBookUserProfileMonitor.poll_data_in_progress = true;
    SportsBookUserProfileMonitor.last_poll_data_start_stamp =
      new Date().getTime();

    const opres = await PrivateCoreApi.safeBackgroundRefreshUserProfile();
    const isError = !!opres.error;

    // 2020-11-12 / Ivan / this won't work well if user changes device clock
    SportsBookUserProfileMonitor.last_poll_data_end_stamp =
      new Date().getTime();
    SportsBookUserProfileMonitor.last_poll_data_was_error = isError;
    //    SportsBookUserProfileMonitor.last_poll_data_check_immediatelly_for_more = check_immediatelly_for_more;
    SportsBookUserProfileMonitor.poll_data_in_progress = false;
  }
}
