import Button from "components/atoms_new/desktop/Button";
import Inputs from "components/atoms_new/shared/Inputs";
import ModalHeader from "components/molecules_new/desktop/Modal/atoms/ModalHeader";
import useTextInput from "hooks/useTextInput";
import React from "react";
import { useHistory } from "react-router-dom";
import { FliffException } from "server/common/FliffException";
import { SportsBookSignInBIF } from "server/legacyCore/server/SportsBookSignInBIF";
import FormValidationUtils from "utils/FormValidationUtils";
import "./styles.scss";
import { IModalControls } from "components/atoms_new/desktop/Modals/AuthenticationModal/types";
import TextLink from "components/atoms_new/desktop/TextLink";

const SignInWithEmail = React.forwardRef<HTMLDivElement, IModalControls>(
  ({ onClose, openNext, openPrevious }, forwardedRef) => {
    const history = useHistory();

    const validateEmail = () =>
      FormValidationUtils.validateEmail(emailValidationState.value);

    const [emailValidationState, handleEmailChange, handleEmailBlur] =
      useTextInput<string>("", validateEmail);

    const validatePassword = () =>
      FormValidationUtils.validateOldPassword(passwordValidationState.value);

    const [passwordValidationState, handlePasswordChange, handlePasswordBlur] =
      useTextInput<string>("", validatePassword);

    const handleSignIn = async (): Promise<void> => {
      try {
        const { isValid: isValidEmail, value: emailValue } = handleEmailBlur();
        const { isValid: isValidPass, value: passValue } = handlePasswordBlur();

        if (!isValidEmail || !isValidPass) {
          return;
        }

        const opres = await SportsBookSignInBIF.signInWithFliffAccount({
          login_token: "email:" + emailValue.trim(),
          password: passValue.trim(),
        });
        if (opres.is_error) {
          if (FliffException.isEmailSignInRequires2FA(opres.exception)) {
            openNext("signInPhone", { mode: 2.5 });
            return;
          }
          return;
        }
        if (
          opres.resultCode ===
          FliffException.ERROR_1903__SESSION_EXPIRED__PROFILE_NOT_COMPLETED
        ) {
          openNext("completeProfile");
        } else if (
          opres.resultCode ===
          FliffException.ERROR_1905__SESSION_EXPIRED__NEED_VERIFIED_PRIMARY_PHONE_NUMBER
        ) {
          history.replace("/verify-phone", { mode: 3 });
        } else if (
          opres.resultCode ===
          FliffException.ERROR_1906__SESSION_EXPIRED__NEED_ACCEPTED_TERMS_OF_USE
        ) {
          // TODO: history.replace("/terms-of-use");
        } else if (opres.resultCode === 321) {
          onClose();
        } else {
          throw new Error("unexpected case");
        }
      } catch (err) {
        console.warn("SignInWithEmailScreen.handleAuthenticate", err);
      }

      onClose();
    };

    const handleModalClose = () => {
      onClose();
    };

    const handleGoBack = () => {
      openPrevious("signIn");
    };

    const handleForgotPassword = () => {
      openNext("forgotPassword");
    };

    return (
      <div className={"sign-in-email"} ref={forwardedRef}>
        <ModalHeader
          title={"Sign In With Email"}
          onClose={handleModalClose}
          onGoBack={handleGoBack}
        />

        <div className={"sign-in-email__wrapper"}>
          <p className="sign-in-email__description">
            Discover a groundbreaking social sportsbook packed with exciting
            features, including rewards, customizable avatars, and the chance to
            connect with friends!
          </p>
          <Inputs.Label htmlFor="email" label="Email" />
          <Inputs.RegularText
            type={"email"}
            onChangeText={handleEmailChange}
            onBlur={handleEmailBlur}
            validationState={emailValidationState}
            placeholder={"Enter Email"}
            id="email"
            theme="dark"
          />

          <Inputs.Label htmlFor="password" label="Password" />
          <Inputs.RegularText
            type={"password"}
            validationState={passwordValidationState}
            onChangeText={handlePasswordChange}
            onBlur={handlePasswordBlur}
            placeholder={"Enter Password"}
            id="password"
            theme="dark"
          />

          <TextLink
            label={"Forgot Password"}
            onClick={handleForgotPassword}
            className="sign-in-email__forgot-password"
          />

          <Button label={"Sign In"} onClick={handleSignIn} />
        </div>
      </div>
    );
  },
);

SignInWithEmail.displayName = "SignInWithEmail";

export default SignInWithEmail;
