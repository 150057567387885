import { SportsBookUserProfileMonitor } from "server/legacyCore/server/SportsBookUserProfileMonitor";
import { SportsBookPrematchFeedMonitor } from "server/legacyCore/server/SportsBookPrematchFeedMonitor";
import { SportsBookInPlayFeedMonitor } from "server/legacyCore/server/SportsBookInPlayFeedMonitor";
import { HeraldBackend } from "server/herald/HeraldBackend";
import AppStateManager from "utils/AppStateManager";
import { MemoryStorage } from "utils/MemoryStorage";
import { staticGetState } from "reduxLocal/store";
import AppSelectors from "reduxLocal/app/app.selectors";
import { PrivateCoreApi } from "server/core/server/CoreApi";

export class SportsBookMonitor {
  private static _POLLING_INTERVAL_TIMER = 500;

  public static init() {
    console.warn("[SportsBookMonitor] - init");
    AppStateManager.initInterval(
      this._onTick,
      this._POLLING_INTERVAL_TIMER,
      "SportsBookMonitor",
    );
    AppStateManager.runOnAppActivation(() =>
      SportsBookMonitor.sportsBookForceUpdate(),
    );
  }

  public static sportsBookForceUpdate() {
    if (!MemoryStorage.enableSportsBookMonitor) {
      return;
    }

    console.warn("[SportsBookMonitor] - sportsBookForceUpdate");
    const isAuthenticated = AppSelectors.isAuthenticated(staticGetState());

    // TODO: consider to be ONE request with ONE dispatched action.
    if (isAuthenticated) {
      PrivateCoreApi.safeBackgroundRefreshUserProfile();
      PrivateCoreApi.safeBackgroundGetLastTransactions();

      return;
    }
  }

  private static _onTick() {
    SportsBookUserProfileMonitor.onTick();
    SportsBookPrematchFeedMonitor.onTick();
    SportsBookInPlayFeedMonitor.onTick();
    HeraldBackend.onTick();
  }
}
