import * as H from "history";
import debounce from "lodash/debounce";
import { isMobileOnly } from "react-device-detect";

import { RestrictedModalService } from "components/molecules/mobile/Modals/RestrictedStateModal";
import { AuthenticationModalService } from "context/desktop/AuthenticationService";
import {
  AppUIHideModalProgressIndicator,
  AppUIShowModalDialogAction,
  AppUIShowModalProgressIndicator,
} from "reduxLocal/appUI/appUI.actions";
import { IFCMPublicFeedSyncResponse } from "server/core/data/objects";
import { PublicCoreApi } from "server/core/server/CoreApi";
import { HeraldBackend } from "server/herald/HeraldBackend";
import { FliffException } from "server/common/FliffException";
import { SportsBookSignInBIF } from "server/legacyCore/server/SportsBookSignInBIF";
import { DefaultBIFResponse } from "server/legacyCore/data/objectsCore";
import AppTokensManager from "server/common/AppTokensManager";
import { DevConstants } from "src/DevConstants";
import { TAnyAlias } from "src/types";
import { AppProfileUtils } from "utils/AppProfileUtils";
import AppStateManager from "utils/AppStateManager";
import AppUIModesManager from "utils/AppUIModesManager";
import { DeviceUptimeClock } from "utils/DeviceUptimeClock";
import CommonLocationManager from "utils/LocationManagers/Common";
import LegacyLocationManager from "utils/LocationManagers/Legacy";
import CoreLocationManager from "utils/LocationManagers/Core";
import Logger from "utils/Logger";
import { MemoryStorage } from "utils/MemoryStorage";
import { SportsBookMonitor } from "server/legacyCore/server/SportsBookMonitor";

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface String {
    format(...replacements: TAnyAlias[]): string;
  }
}
if (!String.prototype.format) {
  String.prototype.format = function () {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this;

    for (let i = 0; i < arguments.length; i++) {
      // eslint-disable-next-line prefer-rest-params
      self = self.replace(new RegExp("\\{" + i + "\\}", "gm"), arguments[i]);
    }

    return String(self);
  };
}

class InitAppProvider {
  private _isAppInit = false;

  //TODO: consider move this call from Route (Public/Private) BEFORE BrowserRouter Init.
  public handleAppInit = async (
    history: H.History,
    appUiMode: number | undefined,
  ) => {
    if (this._isAppInit) {
      return;
    }
    AppProfileUtils.initializeCurrentAppProfile();
    await this._initDataProvider(history, appUiMode);
  };

  // Used in index.tsx, cannot be applied in handleAppInit, some styles are outside of init route
  public initAppRootStyles = () => {
    const updateRootHeight = debounce(() => {
      document
        .getElementById("root")
        ?.setAttribute("style", `min-height: ${window.innerHeight}px`);
    }, 100);
    if (isMobileOnly) {
      window.addEventListener("resize", updateRootHeight);
      document.body.classList.add("fliff-mobile");
      updateRootHeight();
    } else {
      document.body.classList.add("fliff-desktop");
    }
  };

  private _handleLoadServerData = async (): Promise<
    (DefaultBIFResponse | IFCMPublicFeedSyncResponse)[]
  > => {
    const hasSavedCredentials: boolean = await AppTokensManager.initOnStartup();
    await PublicCoreApi.executeSyncTimeWithServerRequest();
    SportsBookMonitor.init();
    AppStateManager.initInterval(
      () => PublicCoreApi.safeBackgroundPingRequest(),
      60000,
      "pingRequest",
    );
    AppStateManager.runOnAppActivation(() =>
      PublicCoreApi.safeBackgroundPingRequest(),
    );
    MemoryStorage.enableSportsBookMonitor = true;
    if (hasSavedCredentials) {
      Logger.warnMessage(
        "  [splash] - case 11 - enter with private profile data",
      );
      return await Promise.all([
        await SportsBookSignInBIF.authenticateWithSavedTokensOnStartup(),
        await PublicCoreApi.nonBlockingNoErrorModalInitPublicData(),
      ]);
    }

    Logger.warnMessage("  [splash] - case 12 - enter with public data");
    return [await PublicCoreApi.nonBlockingNoErrorModalInitPublicData()];
  };

  private _handleCompleteProfileError = (history: H.History): void => {
    MemoryStorage.enableBackgroundRefresh = true;
    HeraldBackend.enableSubscribe();
    if (isMobileOnly) {
      history.replace("/complete-profile", { isLaunchedOnAppInit: true });
    }

    if (!isMobileOnly && DevConstants.isDevMode) {
      AuthenticationModalService.openAuthModal("completeProfile", {
        isLaunchedOnAppInit: true,
      });
    }
  };

  private _handleVerifyPhoneNumberError = (history: H.History): void => {
    MemoryStorage.enableBackgroundRefresh = true;
    HeraldBackend.enableSubscribe();
    if (isMobileOnly) {
      history.replace("/verify-phone", { mode: 3 });
    }

    if (!isMobileOnly && DevConstants.isDevMode) {
      AuthenticationModalService.openAuthModal("signInPhone", {
        mode: 3,
        preventClose: true,
      });
    }
  };

  public handleOnInitSuccess = (history?: H.History): void => {
    MemoryStorage.enableBackgroundRefresh = true;
    HeraldBackend.enableSubscribe();
    this._isAppInit = true;
    if (history) {
      history.replace("/");
    }
    Logger.logMessage("Success InitAppProvider._handleOnInitSuccess");
  };

  private _handleAcceptTermsOfUse = (history: H.History): void => {
    MemoryStorage.enableBackgroundRefresh = true;
    HeraldBackend.enableSubscribe();
    history.replace("/terms-of-use");
    Logger.logMessage("Success InitAppProvider._handleAcceptTermsOfUse");
  };

  private _initDataProvider = async (
    history: H.History,
    appUIMode: number | undefined,
  ): Promise<void> => {
    try {
      if (window.location.href.includes("/restricted")) {
        return;
      }
      AppUIShowModalProgressIndicator.dispatchShowModalProgressIndicator(0);
      this._handleInitImpl();
      await this._handleLoadServerData();
      if (!appUIMode) {
        if (
          CommonLocationManager.isInternalLocationVendor &&
          !DevConstants.disabledLocationVerification
        ) {
          await LegacyLocationManager.verifyLocationByIp();
        }
        if (!AppUIModesManager.isDefaultMode) {
          RestrictedModalService.show(
            CommonLocationManager.ipCheckResult.regionCode,
            null,
            false,
          );
          AppUIHideModalProgressIndicator.dispatchHideModalProgressIndicator();
          return;
        }
      }
      if (CommonLocationManager.isRadarLocationVendor) {
        await this._handleInitCoreLocationVerification(history);
        AppUIHideModalProgressIndicator.dispatchHideModalProgressIndicator();
        return;
      }
      // we got all the needed data (public data, and also personal data in case of saved login) - just return
      AppUIHideModalProgressIndicator.dispatchHideModalProgressIndicator();
      this.handleOnInitSuccess();
      return;
    } catch (error) {
      if (FliffException.isProfileNotCompletedError(error)) {
        this._handleCompleteProfileError(history);
        AppUIHideModalProgressIndicator.dispatchHideModalProgressIndicator();
        return;
      } else if (FliffException.isPrimaryPhoneNumberRequired(error)) {
        this._handleVerifyPhoneNumberError(history);
        AppUIHideModalProgressIndicator.dispatchHideModalProgressIndicator();
        return;
      } else if (FliffException.isTermsOfUseAcceptanceRequired(error)) {
        this._handleAcceptTermsOfUse(history);
        AppUIHideModalProgressIndicator.dispatchHideModalProgressIndicator();
        return;
      }

      // detected network error - display retry UI in order to allow user to try again
      const [dialogTitle, dialogMessage] =
        FliffException.getVisualErrorTextsForException(error);
      AppUIShowModalDialogAction.dispatchShowInfoDialog(
        dialogTitle,
        dialogMessage,
        () => this.handleAppInit(history, appUIMode),
      );
    }
  };

  private async _handleInitCoreLocationVerification(
    history: H.History,
  ): Promise<void> {
    const permissionGranted =
      await CoreLocationManager.checkPermissionGranted();
    if (permissionGranted) {
      const status = await CoreLocationManager.init(false);
      if (status.status === 2) {
        this.handleOnInitSuccess();
      }
      return;
    }

    const permissionStatus =
      await CoreLocationManager.checkGeolocationPermission();
    const status = permissionStatus === "denied" ? 1 : 2;

    history.push(`/verify-location`, {
      status,
      debugInfo: "",
    });
  }

  private _handleInitImpl = () => {
    Logger.init();
    DeviceUptimeClock.init();
  };
}

export default new InitAppProvider();
