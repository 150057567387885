import Radar from "radar-sdk-js";
import CryptoJS from "crypto-js";

import {
  AppUIHideModalProgressIndicator,
  AppUIShowModalProgressIndicator,
} from "reduxLocal/appUI/appUI.actions";
import AppConfig from "utils/AppConfig";
import { PublicCoreApi } from "server/core/server/CoreApi";
import CommonLocationManager from "utils/LocationManagers/Common";
import AppUIModesManager from "utils/AppUIModesManager";
import { DevConstants } from "src/DevConstants";
import { FliffException } from "server/common/FliffException";
import { TAnyAlias } from "src/types";
import LegacyLocationManager from "utils/LocationManagers/Legacy";
import { PersistentStorage } from "utils/PersistentStorage";
import MixPanelShared from "utils/Analytics/MixPanelWrapper/MixPanelShared";
import { AppUtils } from "utils/AppUtils";

interface IRadarMetadata {
  [key: string]: TAnyAlias;
}

export type TLocationStatus = 0 | 1 | 2 | 3;

export interface ILocationState {
  status: TLocationStatus;
  debugInfo: string;
}

class CoreLocationManager {
  private _debugInfoState = {
    isPermissionGranted: false,
    radarSdkInitialized: false,
    radarResponse: false,
    coreResponse: false,
    regionCode: "",
  };

  private get _debugInfo() {
    const dataInfo = [
      this._debugInfoState.isPermissionGranted,
      this._debugInfoState.radarSdkInitialized,
      this._debugInfoState.radarResponse,
      this._debugInfoState.coreResponse,
    ]
      .map(Number)
      .join("/");

    const deviceHash = CryptoJS.SHA256(PersistentStorage.cachedDeviceId)
      .toString(CryptoJS.enc.Hex)
      .slice(0, 5);

    const deviceChars = PersistentStorage.cachedDeviceId.slice(-5);
    return `${AppConfig.marketingVersion}/${
      this._debugInfoState.regionCode
    }/Web/${deviceHash}/${deviceChars}/${PersistentStorage.getLastLoggedAccountId()}/${dataInfo}`;
  }

  public async init(shouldRequestPermission: boolean): Promise<ILocationState> {
    try {
      Radar.initialize(AppConfig.radarApiKey);

      this._debugInfoState.radarSdkInitialized = true;

      if (shouldRequestPermission) {
        const isPermissionGranted = await this._requestLocationPermission();
        if (!isPermissionGranted) {
          if (await this._checkPermissionNotDetermined()) {
            return { status: 3, debugInfo: "" };
          }
          return { status: 1, debugInfo: "" };
        }
        AppUIShowModalProgressIndicator.dispatchShowModalProgressIndicator(
          AppConfig.modalProgressBarEnforceIndicatorMode,
        );
      }

      const radarTrackCallback = await Promise.race([
        Radar.trackOnce(),
        // Wait radar response for 5 seconds
        AppUtils.sleep(5000),
      ]);

      this._debugInfoState.radarResponse = true;

      if (radarTrackCallback) {
        const result =
          await PublicCoreApi.safeBlockingRegisterRadarSDKCallBackData(
            radarTrackCallback,
          );

        if (result.response) {
          this._debugInfoState.coreResponse = true;

          let regionCode = result.response.usa_state_code;
          if (DevConstants.enforceRadarDetectedState) {
            if (DevConstants.enforceRadarDetectedState === "error") {
              alert("Error occurred and bypassed");
              throw new FliffException(
                123,
                "Dummy error on .enforceRadarDetectedState",
              );
            }
            regionCode = DevConstants.enforceRadarDetectedState;
          }

          this._debugInfoState.regionCode = regionCode;

          CommonLocationManager.setModeByRegionCode(regionCode);
          CommonLocationManager.setIpCheckResult({
            isPassed: true,
            regionCode,
            meta: regionCode
              ? "successGetRegionCode"
              : "unableToParseRegionCode",
          });
          CommonLocationManager.setGeocodeCheckResult({
            isPassed: true,
            regionCode,
            meta: regionCode
              ? "successGetRegionCode"
              : "unableToParseRegionCode",
          });

          MixPanelShared.trackLocationCheckStatus("success");

          if (AppUIModesManager.isDisabledMode) {
            return { status: 0, debugInfo: this._debugInfo };
          }

          return { status: 2, debugInfo: "" };
        }
      }

      if (!radarTrackCallback) {
        MixPanelShared.trackLocationCheckStatus("timeout");
      }

      return { status: 2, debugInfo: "" };
    } catch (err) {
      return this._handleInitError(err as Error);
    } finally {
      AppUIHideModalProgressIndicator.dispatchHideModalProgressIndicator();
    }
  }

  public async checkGeolocationPermission() {
    if ("permissions" in navigator) {
      try {
        const result = await navigator.permissions.query({
          name: "geolocation",
        });
        return result.state; // 'granted', 'denied', or 'prompt'
      } catch (error) {
        console.error("Error checking geolocation permission:", error);
        return null;
      }
    } else {
      console.warn("Permissions API is not supported in this browser.");
      return null;
    }
  }

  public async checkPermissionGranted() {
    const status = await this.checkGeolocationPermission();

    return status === "granted";
  }

  public initUserDataOnAuthSuccess<Data extends IRadarMetadata>(data: Data) {
    Radar.setUserId(`${data.userId}`);
    Radar.setMetadata(data);
  }

  private _handleInitError(error: Error): ILocationState {
    console.warn("CoreLocationManager._handleInitError", error.message);
    MixPanelShared.trackLocationCheckStatus("error");
    CommonLocationManager.setIpCheckResult({
      isPassed: true,
      regionCode: "",
      meta: `unknownRegionCode.${error.message}`,
    });
    CommonLocationManager.setGeocodeCheckResult({
      isPassed: true,
      regionCode: "",
      meta: `unknownRegionCode.${error.message}`,
    });
    return { status: 2, debugInfo: "" };
  }

  private async _checkPermissionNotDetermined(): Promise<boolean> {
    const status = await this.checkGeolocationPermission();
    return status === "prompt";
  }

  private _requestLocationPermission = async (): Promise<boolean> => {
    await LegacyLocationManager._requestLocationPermission();

    return await this.checkPermissionGranted();
  };
}

export default new CoreLocationManager();
