import * as I from "server/core/data/objects";
import { IInternalProtocolRequestBuilder } from "server/common/interfaces";
import { DataReq__SB_Profile_Meta } from "server/legacyCore/data/objects";

import { DevConstants } from "src/DevConstants";
import { PersistentStorage } from "utils/PersistentStorage";
import { staticGetState } from "reduxLocal/store";
import SocialUtils from "utils/SocialUtils";
import AppSelectors from "reduxLocal/app/app.selectors";
import { MemoryStorage } from "utils/MemoryStorage";
import {
  IFliffInvocation,
  IFliffProtocolRequestHeader,
} from "server/common/data/objects";
import CommonLocationManager from "utils/LocationManagers/Common";
import DeviceInfo from "utils/DeviceInfo";

export class InternalProtocolRequestBuilderImpl
  implements IInternalProtocolRequestBuilder<I.IFCMRequest> {
  private get _profileMeta(): DataReq__SB_Profile_Meta {
    return {
      id_51202: MemoryStorage.mem_cache__id_51202,
      id_51203: MemoryStorage.mem_cache__id_51203,
      id_51204: MemoryStorage.mem_cache__id_51204,
      id_51207: MemoryStorage.mem_cache__id_51207,
      id_51206: MemoryStorage.mem_cache__id_51206,
      id_51221: MemoryStorage.mem_cache__id_51221,
      id_51231: MemoryStorage.mem_cache__id_51231,
      id_51232: MemoryStorage.mem_cache__id_51232,
      id_51241: MemoryStorage.mem_cache__id_51241,
      id_51250: MemoryStorage.mem_cache__id_51250,
      id_51251: MemoryStorage.mem_cache__id_51251,
      id_51252: MemoryStorage.mem_cache__id_51252,
      id_51253: MemoryStorage.mem_cache__id_51253,
      id_51254: MemoryStorage.mem_cache__id_51254,
      id_51255: MemoryStorage.mem_cache__id_51255,
    };
  }

  public buildProtocolRequest<Request extends I.IFCMRequest>(
    request: Request,
    xRequests: I.IFCMRequest[] | null,
  ): I.IFCMProtocolRequest<Request> {
    return {
      header: this._buildHeader(),
      invocation: this._buildInvocation(request),
      x_invocations: this._buildInvocations(xRequests),
      x_sb_meta: this._buildSlotMeta(),
    };
  }

  private _buildHeader(): IFliffProtocolRequestHeader {
    const authToken = staticGetState().sportsBook.profile.user_id;

    return {
      device_x_id: `${DeviceInfo.OS}.${PersistentStorage.cachedDeviceId}`,
      app_x_version: DevConstants.appVersionPlusCapability,
      app_install_token: PersistentStorage.cachedInstallToken,
      auth_token:
        authToken === -1 ? "" : SocialUtils.toValidUserFkey(authToken),
      conn_id: 0,
      platform: DevConstants.isDevMode ? "dev" : "prod",
      usa_state_code: CommonLocationManager.lastSetRegionCode,
      usa_state_code_source: CommonLocationManager.debugSourceInfo,
    };
  }

  private _buildInvocation<Request extends I.IFCMRequest>(
    request: Request,
  ): IFliffInvocation<Request> {
    return { request };
  }

  private _buildInvocations<Request extends I.IFCMRequest>(
    xRequests: Request[] | null,
  ): IFliffInvocation<Request>[] | null {
    if (xRequests === null) {
      return null;
    }

    return xRequests.map(this._buildInvocation);
  }

  private _buildSlotMeta(): I.IFCMProtocolRequestSlotsMeta {
    const coreState = staticGetState();
    const isAuthenticated = AppSelectors.isAuthenticated(coreState);

    if (isAuthenticated) {
      return {
        sb_config_version: coreState.sportsBook.config.obj_version,
        sb_user_profile_version: coreState.sportsBook.profile.obj_version,
        sb_user_profile_meta: this._profileMeta,
      };
    }

    return {
      sb_config_version: coreState.sportsBook.config.obj_version,
      sb_user_profile_version: -1,
      sb_user_profile_meta: null,
    };
  }
}
