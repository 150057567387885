import AppConfig from "utils/AppConfig";

/*
2019-12-03 / Ivan / by convention / default value for all boolean properties is false (just ensure that everything is false for 'standard prod build')
when somebody builds a new binary, he is in charge to verify manually all constants
and ensure that only the ones that he explicitly needs for this particular build are True
*/
export class DevConstants {
  private static _isMaintenanceMode = AppConfig.isMaintenanceMode;
  private static readonly _isDevMode = AppConfig.env === "development";
  private static readonly _appCapabilityNumber = Number(
    AppConfig.capabilityNumber,
  );
  private static readonly _appVersion = AppConfig.marketingVersion;
  private static readonly _socialReturnURL = AppConfig.socialReturnUrl;
  private static readonly _dontSendSms = false;
  private static readonly _purchaseTestScenario = 0; // CONST_415_R0_API_REQUIRED_VERIFIED_PHONE_NUMBER
  private static readonly _disableMixPanel = false;
  private static readonly _disableVerifyIdentity = false;
  private static readonly _isAnyNumberEnterEnabled = false;
  private static readonly _enableDebugSuffixInErrorDialogs = false;
  private static readonly _liveChannelId = -555;
  private static readonly _homeChannelId = -333;
  private static readonly _boostedChannelId = -777;

  private static readonly _pokerChannelId = -789;
  private static readonly _favoriteTabCode = -999;
  private static readonly _summaryTabCode = -1000;
  private static readonly _boostedTabCode = -1002 as const;
  private static readonly _activitySummaryTabCode = "-1000";
  private static readonly _minUserIDAsSignUpProd = 95000;
  private static readonly _minUserIDAsSignUpDev = 5000;
  private static readonly _disableLocationVerification = false;

  public static get enforceLegacyIPDetectedState(): string {
    if (DevConstants.isDevMode) {
      return "CA";
    }
    return "";
  }

  public static get enforceLegacyGeoDetectedState(): string {
    if (DevConstants.isDevMode) {
      return "CA";
    }
    return "";
  }

  public static get enforceRadarDetectedState(): string {
    if (DevConstants.isDevMode) {
      return "";
    }
    return "";
  }

  public static get disabledLocationVerification(): boolean {
    if (DevConstants._isDevMode) {
      return this._disableLocationVerification;
    }
    return false;
  }

  public static get purchaseTestScenario(): number {
    if (DevConstants._isDevMode) {
      return DevConstants._purchaseTestScenario;
    }
    return 0;
  }

  public static get boostedTabCode() {
    return this._boostedTabCode;
  }

  public static get minUserIDAsSignUp() {
    if (this._isDevMode) {
      return this._minUserIDAsSignUpDev;
    }
    return this._minUserIDAsSignUpProd;
  }

  public static get favoriteTabCode() {
    return this._favoriteTabCode;
  }

  public static get summaryTabCode() {
    return this._summaryTabCode;
  }

  public static get activitySummaryTabCode(): string {
    return this._activitySummaryTabCode;
  }

  // Allows to enter any phone number (UA, BG, UK etc.).
  // Expected to support  US & CAN number only !
  public static get isAnyNumberEnterEnabled() {
    return this._isDevMode && this._isAnyNumberEnterEnabled;
  }

  public static get isVerifyIdentityDisabled() {
    if (DevConstants._isDevMode) {
      return DevConstants._disableVerifyIdentity;
    }
    return false;
  }

  public static get capabilityNumber(): number {
    if (
      Number(AppConfig.capabilityNumber) !== DevConstants._appCapabilityNumber
    ) {
      throw new Error(
        "Capability mismatch. Don't set capabilityNumber in DevConstants. Make this change in appropriate env file.",
      );
    }
    return DevConstants._appCapabilityNumber;
  }

  public static get appVersion(): string {
    if (AppConfig.marketingVersion !== DevConstants._appVersion) {
      throw new Error(
        "AppVersion mismatch. Don't set appVersion in DevConstants. Make this change in appropriate env file.",
      );
    }
    return DevConstants._appVersion;
  }

  public static get appVersionPlusCapability(): string {
    return DevConstants._appVersion + "." + DevConstants._appCapabilityNumber;
  }

  public static get socialReturnUrl(): string {
    return DevConstants._socialReturnURL;
  }

  public static get getLiveChannelId() {
    return DevConstants._liveChannelId;
  }

  public static get getPokerChannelId(): number {
    return DevConstants._pokerChannelId;
  }

  public static get getHomeChannelId() {
    return DevConstants._homeChannelId;
  }

  public static get getBoostedChannelId() {
    return DevConstants._boostedChannelId;
  }

  public static get isDevMode(): boolean {
    return DevConstants._isDevMode;
  }

  public static get isMaintenanceMode(): boolean {
    return DevConstants._isMaintenanceMode;
  }

  public static get isMixPanelDisabled() {
    if (DevConstants._isDevMode) {
      return DevConstants._disableMixPanel;
    }
    return false;
  }

  public static get dontSendSms(): boolean {
    return DevConstants._isDevMode && DevConstants._dontSendSms;
  }

  public static get enableDebugSuffixInErrorDialogs(): boolean {
    return (
      DevConstants._isDevMode && DevConstants._enableDebugSuffixInErrorDialogs
    );
  }
}

/*
class Purchase_Test_Scenario():
    CONST_401_PENDING_FOREVER                         = 401, # - order stays in pending state forever

    CONST_411_R0_API_NETWORK_ERROR                    = 411, # - r0 api - network error - dns
    CONST_412_R0_API_NETWORK_ERROR                    = 412, # - r0 api - network error - port
    CONST_413_R0_API_LOGICAL_ERROR                    = 413, # - r0 api - logical error
    CONST_414_R0_API_NOT_ACCEPTED                     = 414, # - r0 api - credit card payment declined

    CONST_415_R0_API_REQUIRED_VERIFIED_PHONE_NUMBER   = 415, # - r0 api - requires verified phone number

    CONST_421_SLIDE_API_NETWORK_ERROR_NO_CARD         = 421, # - slide api - network error on create (forever - no gift card)
    CONST_422_SLIDE_API_LOGICAL_ERROR_NO_CARD         = 422, # - slide api - logical error on create (forever - no gift card)

    CONST_431_SLIDE_API_IN_PROGRESS__ERROR_IN_4_SEC          = 431, # - slide api - in-progress for 4+ sec then error
    CONST_432_SLIDE_API_IN_PROGRESS__ERROR_IN_40_SEC         = 432, # - slide api - in-progress for 40+ sec then error
    CONST_433_SLIDE_API_IN_PROGRESS__ERROR_IN_120_SEC        = 433, # - slide api - in-progress for 120+ sec then error

    CONST_441_SLIDE_API_IN_PROGRESS__SUCCES_IN_4_SEC    = 441, # - slide api - stays in-progress (gift card - available in 4 sec)
    CONST_442_SLIDE_API_IN_PROGRESS__SUCCES_IN_40_SEC   = 442, # - slide api - stays in-progress (gift card - available in 40 sec)
    CONST_443_SLIDE_API_IN_PROGRESS__SUCCES_IN_120_SEC  = 443, # - slide api - stays in-progress (gift card - available in 120 sec)
*/
