import { ValidationUtils } from "server/common/ValidationUtils";
import { FliffException } from "server/common/FliffException";
import { IStringMap } from "src/types";

type TDatObjectDecoder<T> = (
  jsonData: unknown,
  debugInfo: string,
  index: number,
) => T;

type TDataObjectDecoder4n<T> = (
  jsonData: unknown,
  debugInfo: string,
  param2: string,
  param3: string,
  param4: number,
) => T;

export class AdapterHelper {
  public static nullOrDecode<T>(
    decoder: TDatObjectDecoder<T>,
    jsonData: unknown,
    debugInfo: string,
    index = -1,
  ): T | null {
    if (jsonData === undefined || jsonData === null) {
      return null;
    }

    return decoder(jsonData, debugInfo, index);
  }

  public static decodeArray<T>(
    decoder: TDatObjectDecoder<T>,
    jsonData: unknown,
    debugInfo: string,
  ): T[] {
    const rawArray = ValidationUtils.assertNonNullArray<T[]>(
      jsonData,
      debugInfo,
    );

    return rawArray.map((rawData, index) =>
      decoder(rawData, debugInfo + "[" + index + "]", index),
    );
  }

  public static decodeArrayWithTypedItems<T extends { type: string }>(
    decoders: { [key in T["type"]]: TDatObjectDecoder<T> },
    jsonData: unknown,
    debugInfo: string,
  ): T[] {
    const rawArray = ValidationUtils.assertNonNullArray<T[]>(
      jsonData,
      debugInfo,
    );

    return rawArray.map((rawData, index) => {
      const decoder = decoders[rawData.type as T["type"]];
      if (!decoder) {
        throw new FliffException(
          FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
          "decode_array_with_typed_items - unknown item type [" +
            rawData.type +
            "] at index [" +
            index +
            "] for [" +
            debugInfo +
            "]",
        );
      }
      return decoder(rawData, debugInfo + "[" + index + "]", index);
    });
  }

  public static decodeMapWithArrayWithTypedItems<
    T extends { type: string }[],
    I extends T[number],
  >(
    decoders: {
      [key in I["type"]]: TDatObjectDecoder<I>;
    },
    jsonData: unknown,
    debugInfo: string,
  ): IStringMap<I[]> {
    const rawObject = ValidationUtils.assertNonNullData<IStringMap<T>>(
      jsonData,
      debugInfo,
    );

    return Object.entries(rawObject).reduce<IStringMap<I[]>>(
      (acc, [key, value]) => {
        acc[key] = AdapterHelper.decodeArrayWithTypedItems(
          decoders,
          value,
          debugInfo + "[" + key + "]",
        );
        return acc;
      },
      {},
    );
  }

  public static decodeStringMap<
    Value,
    JsonData,
    Decoder extends (jsonData: Value, debugInfo: string) => Value,
  >(
    decoder: Decoder,
    jsonData: JsonData,
    debugInfo: string,
  ): IStringMap<Value> {
    const rawObject: IStringMap<Value> = ValidationUtils.assertNoNNullMap(
      jsonData,
      debugInfo,
    );

    return Object.entries(rawObject).reduce<IStringMap<Value>>(
      (acc, [key, value]) => {
        acc[key] = decoder(value, debugInfo + "[" + key + "]");
        return acc;
      },
      {},
    );
  }

  public static decodeArray4n<T>(
    decoder: TDataObjectDecoder4n<T>,
    jsonData: unknown,
    debugInfo: string,
    param2: string,
    param3: string,
    param4: number,
  ): T[] {
    const rawArray = ValidationUtils.assertNonNullArray(jsonData, debugInfo);

    return rawArray.map((rawData, index) =>
      decoder(rawData, debugInfo + "[" + index + "]", param2, param3, param4),
    );
  }

  public static nullOrDecodeArray<T>(
    decoder: TDatObjectDecoder<T>,
    jsonData: unknown,
    debugInfo: string,
  ): T[] | null {
    if (jsonData === undefined || jsonData === null) {
      return null;
    }

    return AdapterHelper.decodeArray<T>(decoder, jsonData, debugInfo);
  }

  public static decodeArrayOfIntegers(
    jsonData: unknown,
    debugInfo: string,
  ): number[] {
    const rawArray = ValidationUtils.assertNonNullArray(jsonData, debugInfo);

    return rawArray.map((rawData, index) =>
      ValidationUtils.assertAnyInteger(rawData, debugInfo + "[" + index + "]"),
    );
  }

  public static decodeArrayOfStrings(
    jsonData: unknown,
    debugInfo: string,
  ): string[] {
    const rawArray = ValidationUtils.assertNonNullArray(jsonData, debugInfo);

    return rawArray.map((rawData, index) =>
      ValidationUtils.assertNonEmptyString(
        rawData,
        debugInfo + "[" + index + "]",
      ),
    );
  }

  public static decodeStringNumberMap(
    jsondata: object,
    debugInfo: string,
  ): IStringMap<number> {
    const rawObject: IStringMap<number> = ValidationUtils.assertNonNullData(
      jsondata,
      debugInfo,
    );

    return Object.entries(rawObject).reduce<IStringMap<number>>(
      (acc, [key, value]) => {
        acc[key] = ValidationUtils.assertPositiveIntegerOrZero(
          value,
          debugInfo + "[" + key + "]",
        );
        return acc;
      },
      {},
    );
  }
}
