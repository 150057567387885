import { AxiosRequestConfig, AxiosResponse } from "axios";
import { Buffer } from "buffer";

import { ApiExecutorNoAuth } from "./ApiExecutorNoAuth";
import { AppProfileUtils } from "utils/AppProfileUtils";
import AppTokensManager from "server/common/AppTokensManager";

import { FliffException } from "server/common/FliffException";
import { AuthTokensResponse } from "server/common/data/serverAdapters";
import { TAnyAlias } from "src/types";

export class ApiExecutorAuth extends ApiExecutorNoAuth {
  public async accountLogin(username: string, password: string): Promise<void> {
    try {
      await this.accountLoginImpl(username, password);
    } catch (error: TAnyAlias) {
      // replace low level error with logical error
      if (
        error.error_code ===
        FliffException.ERROR_11090__ACCOUNT_LOGIN__BAD_CREDENTIALS
      ) {
        throw new FliffException(
          FliffException.ERROR_2001__LOGIN_ERROR__BAD_CREDENTIALS,
          "bad credentials",
          error.debugErrorSource,
        );
      }
      throw error;
    }
  }

  public async socialLogin(
    backendCode: string,
    backendToken: string,
    details: string,
  ): Promise<void> {
    AppTokensManager.resetAuthTokens();

    const requestData = {
      grant_type: "convert_token",
      client_id: AppProfileUtils.coreServerClientId,
      client_secret: AppProfileUtils.coreServerClientSecret,
      backend: backendCode,
      token: backendToken,
      details,
    };

    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      "x-dd-request-code": "social_login",
    };

    const rawResponse = await super.apiRequest(
      {
        headers,
        url: "/api/v1/users/fconvert-token",
        method: "POST",
        data: new URLSearchParams(requestData).toString(),
      },
      "social_login",
    );

    const response = AuthTokensResponse.decode(rawResponse.data);
    AppTokensManager.helper.installAuthTokensResponse(
      response,
      `social_login__${backendCode}`,
    );
  }

  public apiRequestNoAuth = async (
    data: AxiosRequestConfig,
    debugErrorSource: string,
  ): Promise<AxiosResponse> => {
    try {
      return await super.apiRequest(data, debugErrorSource);
    } catch (error) {
      console.warn(
        ` x in api_request_no_auth [${FliffException.getVisualErrorTextsForException(
          error,
        )}]`,
        error,
      );
      throw error;
    }
  };

  public async apiRequest(
    data: AxiosRequestConfig,
    debugErrorSource: string,
  ): Promise<AxiosResponse> {
    try {
      return await AppTokensManager.apiRequestWithAuthImpl(
        (...params) => super.apiRequest(...params),
        data,
        debugErrorSource,
      );
    } catch (error) {
      console.warn(
        ` x in apiRequest [${debugErrorSource}] [${FliffException.getVisualErrorTextsForException(
          error,
        )}]`,
        error,
      );
      throw error;
    }
  }

  public async apiRequestWrapperNoAuth<T>(
    debugErrorSource: string,
    data: AxiosRequestConfig,
    callback: (rawResponse: AxiosResponse) => Promise<T> | T,
  ): Promise<T> {
    try {
      const rawResponse = await this.apiRequestNoAuth(data, debugErrorSource);
      return await callback(rawResponse);
    } catch (error: TAnyAlias) {
      if (error.debugErrorSource !== debugErrorSource) {
        // if source is not attached - either set it or prepend it
        error.debugErrorSource = error.debugErrorSource
          ? `${debugErrorSource} / ${error.debugErrorSource}`
          : debugErrorSource;
      }

      throw error;
    }
  }

  protected async accountLoginImpl(
    username: string,
    password: string,
  ): Promise<void> {
    AppTokensManager.resetAuthTokens();

    const authHeader = `Basic ${Buffer.from(
      `${AppProfileUtils.coreServerClientId}:${AppProfileUtils.coreServerClientSecret}`,
      "utf-8",
    ).toString("base64")}`;

    const requestData = { grant_type: "password", username, password };

    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: authHeader,
      "x-dd-request-code": "account_login",
    };

    const rawResponse = await super.apiRequest(
      {
        headers,
        url: "/api/v1/oauth2/token/",
        method: "POST",
        data: new URLSearchParams(requestData).toString(),
      },
      "account_login",
    );

    const response = AuthTokensResponse.decode(rawResponse.data);
    AppTokensManager.helper.installAuthTokensResponse(
      response,
      `account_login__${username}`,
    );
  }
}
