import { ValidationUtils } from "server/common/ValidationUtils";
import {
  IFliffError,
  IFliffMessage,
  IFliffProtocolResponse,
  IFliffProtocolResponseHeader,
  IFliffResponse,
  TFliffResult,
} from "server/common/data/objects";
import { AdapterSBFeedDiffItemAbstract } from "server/legacyCore/data/serverAdapters";
import {
  Data__SB_FeedDiff_Packed_Shelf_Update,
  Data__SB_FeedDiff_Shelf_Update,
  Data__SB_SportEvent,
  TBetMarket,
} from "server/legacyCore/data/objects";
import { validateDiffUpdateType } from "server/legacyCore/data/serverEnumHelpers";
import { AdapterHelper } from "server/common/AdapterHelper";
import { TAnyAlias } from "src/types";

export class AuthTokensResponse {
  accessToken: string;
  refreshToken: string;
  tokenType: string;
  scope: string;
  expiresIn: number;

  public static decode(jsonData: TAnyAlias): AuthTokensResponse {
    ValidationUtils.assertNonNullData(jsonData, "jsonData");

    return {
      accessToken: ValidationUtils.assertNonEmptyString(
        jsonData.access_token,
        "access_token",
      ),
      refreshToken: ValidationUtils.assertNonEmptyString(
        jsonData.refresh_token,
        "refresh_token",
      ),
      tokenType: ValidationUtils.assertNonEmptyString(
        jsonData.token_type,
        "token_type",
      ),
      scope: ValidationUtils.assertNonEmptyString(jsonData.scope, "scope"),
      expiresIn: ValidationUtils.assertPositiveInteger(
        jsonData.expires_in,
        "expires_in",
      ),
    };
  }
}

class AdapterFliffError {
  public static decode(jsonData: TAnyAlias, debugInfo: string): IFliffError {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      errorCode: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.error_code,
        debugInfo + ".error_code",
      ),
      errorMessage: ValidationUtils.assertNonEmptyString(
        jsonData.error_message,
        debugInfo + ".error_message",
      ),
      errorSource: null,
      incidentTag: ValidationUtils.assertOptionalString(
        jsonData.incident_tag,
        debugInfo + ".incident_tag",
      ),
    };
  }
}

class AdapterFliffResult {
  public static decode<Response extends IFliffResponse>(
    jsonData: TAnyAlias,
    debugInfo: string,
    messageDecoder: (
      nextJsonData: TAnyAlias,
      nextDebugInfo: string,
    ) => IFliffMessage,
  ): TFliffResult<Response> {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      error: AdapterHelper.nullOrDecode<IFliffError>(
        AdapterFliffError.decode,
        jsonData.error,
        debugInfo + ".error",
      ),
      response: AdapterHelper.nullOrDecode<IFliffResponse>(
        messageDecoder,
        jsonData.response,
        debugInfo + ".response",
      ),
    } as TFliffResult<Response>;
  }
}

class AdapterFliffProtocolResponseHeader {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): IFliffProtocolResponseHeader {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      server_instance_id: ValidationUtils.assertNonEmptyString(
        jsonData.server_instance_id,
        debugInfo + ".server_instance_id",
      ),
      server_stamp_millis: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.server_stamp_millis,
        debugInfo + ".server_stamp_millis",
      ),
      build_version: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.build_version,
        debugInfo + ".build_version",
      ),
    };
  }
}

export class AdapterFliffProtocolResponse {
  public static decode<ResponseSlots, Response extends IFliffResponse>(
    jsonData: TAnyAlias,
    debugInfo: string,
    responseSlotsDecoder: (
      nextJsonData: TAnyAlias,
      nextDebugInfo: string,
    ) => ResponseSlots,
    messageDecoder: (
      nextJsonData: TAnyAlias,
      nextDebugInfo: string,
    ) => IFliffMessage,
  ): IFliffProtocolResponse<ResponseSlots, Response> {
    return {
      header: AdapterFliffProtocolResponseHeader.decode(
        jsonData.header,
        debugInfo + ".header",
      ),
      result: AdapterFliffResult.decode<Response>(
        jsonData.result,
        debugInfo + ".result",
        messageDecoder,
      ),
      x_results: null,
      x_slots: AdapterHelper.nullOrDecode(
        responseSlotsDecoder,
        jsonData.x_slots,
        debugInfo + ".x_slots",
      ),
    };
  }
}

export class AdapterEvent {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): Data__SB_SportEvent {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    const conflictFkey = ValidationUtils.assertNonEmptyString(
      jsonData.conflict_fkey,
      debugInfo + ".conflict_fkey",
    );
    const isLive = conflictFkey.endsWith("inplay");

    const filterIds = AdapterHelper.decodeArrayOfIntegers(
      jsonData.filter_ids,
      debugInfo + ".filter_ids",
    );

    // 2022-01-20 / Ivan / introduce display mode per event - will be used to fine tune the game card
    let displayMode = 0;
    //TODO: remove this.
    // ugly magic constant for BOOSTED_OFFERS_CHANNELS_FOR_ROOKIES
    if (filterIds.includes(-801)) {
      displayMode = 1;
    }

    return {
      conflict_fkey: conflictFkey,
      channelId: ValidationUtils.assertPositiveInteger(
        jsonData.channel_id,
        debugInfo + ".channel_id",
      ),
      event_start_timestamp_utc: ValidationUtils.assertPositiveInteger(
        jsonData.event_start_timestamp_utc,
        debugInfo + ".event_start_timestamp_utc",
      ),
      homeTeamName: ValidationUtils.assertNonEmptyString(
        jsonData.home_team_name,
        debugInfo + ".home_team_name",
      ),
      homeTeamLogo: ValidationUtils.assertOptionalString(
        jsonData.home_team_logo,
        debugInfo + ".home_team_logo",
      ),
      awayTeamName: ValidationUtils.assertNonEmptyString(
        jsonData.away_team_name,
        debugInfo + ".away_team_name",
      ),
      awayTeamLogo: ValidationUtils.assertOptionalString(
        jsonData.away_team_logo,
        debugInfo + ".away_team_logo",
      ),
      isLive,
      live_state_desc: ValidationUtils.assertOptionalString(
        jsonData.live_state_desc,
        debugInfo + ".live_state_desc",
      ),
      live_score_home: ValidationUtils.assertAnyInteger(
        jsonData.live_home_score,
        debugInfo + ".live_home_score",
      ),
      live_score_away: ValidationUtils.assertAnyInteger(
        jsonData.live_away_score,
        debugInfo + ".live_away_score",
      ),
      live_x_score_home: ValidationUtils.assertOptionalString(
        jsonData.live_x_score_home,
        debugInfo + ".live_x_score_home",
      ),
      live_x_score_away: ValidationUtils.assertOptionalString(
        jsonData.live_x_score_away,
        debugInfo + ".live_x_score_away",
      ),
      event_note: ValidationUtils.assertOptionalString(
        jsonData.event_note,
        debugInfo + ".event_note",
      ),
      filter_ids: new Set(filterIds),
      displayMode: displayMode,
      estimated_markets_count: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.estimated_markets_count,
        debugInfo + ".estimated_markets_count",
      ),
      live_status: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.live_status,
        debugInfo + ".live_status",
      ),
      conflict_class_code: ValidationUtils.assertPositiveInteger(
        jsonData.conflict_class_code,
        debugInfo + ".conflict_class_code",
      ),
      pcats: AdapterHelper.decodeArrayOfIntegers(
        jsonData.pcats,
        debugInfo + ".pcats",
      ),
      weight: ValidationUtils.assertPositiveInteger(
        jsonData.weight,
        debugInfo + ".weight",
      ),
      offer_type: ValidationUtils.assertAnyInteger(
        jsonData.offer_type,
        debugInfo + ".offer_type",
      ),
    };
  }
}

class AdapterFeedDiffPackedShelfUpdate {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): Data__SB_FeedDiff_Packed_Shelf_Update {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      subfeed_code: ValidationUtils.assertAnyInteger(
        jsonData.subfeed_code,
        debugInfo + ".subfeed_code",
      ),
      revision_id: ValidationUtils.assertAnyInteger(
        jsonData.revision_id,
        debugInfo + ".revision_id",
      ),
      revision_code: ValidationUtils.assertNonEmptyString(
        jsonData.revision_code,
        debugInfo + ".revision_code",
      ),
      update_type: validateDiffUpdateType(
        jsonData.update_type,
        debugInfo + ".update_type",
      ),
      diff_data: AdapterHelper.decodeArray<TBetMarket>(
        AdapterSBFeedDiffItemAbstract.decode,
        jsonData.market_updates,
        debugInfo + ".market_updates",
      ),
      conflict_fkeys: AdapterHelper.decodeArrayOfStrings(
        jsonData.conflict_fkeys,
        debugInfo + ".conflict_fkeys",
      ),
    };
  }
}

export class AdapterFeedDiffUpdate {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): Data__SB_FeedDiff_Shelf_Update[] {
    const packedSubfeedUpdates =
      AdapterHelper.nullOrDecodeArray<Data__SB_FeedDiff_Packed_Shelf_Update>(
        AdapterFeedDiffPackedShelfUpdate.decode,
        jsonData,
        debugInfo,
      );
    if (!packedSubfeedUpdates) {
      return [];
    }

    const updates: Data__SB_FeedDiff_Shelf_Update[] = [];

    for (const shelfUpdate of packedSubfeedUpdates) {
      for (const conflictFkey of shelfUpdate.conflict_fkeys) {
        updates.push({
          update_type: shelfUpdate.update_type,
          revision_code: shelfUpdate.revision_code,
          subfeed_code: shelfUpdate.subfeed_code,
          revision_id: shelfUpdate.revision_id,
          diff_data: shelfUpdate.diff_data,
          conflict_fkey: conflictFkey,
        });
      }
    }

    return updates;
  }
}
