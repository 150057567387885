export class StatsNetwork {
  public static counter_requests_total: number = 0;
  public static counter_requests_private: number = 0;
  public static counter_requests_public_prematch: number = 0;
  public static counter_requests_public_inplay: number = 0;

  public static counter_requests_error_network: number = 0;
  public static counter_requests_error_other: number = 0;

  public static on_request_enter(url: string) {
    const is_public = url.indexOf("sports_book_public") !== -1;
    //    console.log(" * api_request - is_public:", is_public, ", url:", url);
    StatsNetwork.counter_requests_total += 1;
    if (is_public) {
      StatsNetwork.counter_requests_public_prematch += 1;
    } else {
      StatsNetwork.counter_requests_private += 1;
    }
  }

  /*
  public static on_request_private() {
    Stats_Network.counter_requests_private += 1;
  }

  public static on_request_semi_public() {
    Stats_Network.counter_requests_public_prematch += 1;
  }

  public static on_request_public() {
    Stats_Network.counter_requests_public_prematch += 1;
  }
*/
  //  public static counter_requests_public_inplay: number = 0;

  public static on_request_error(is_network_error: boolean) {
    if (is_network_error) {
      StatsNetwork.counter_requests_error_network += 1;
    } else {
      StatsNetwork.counter_requests_error_other += 1;
    }
  }
}
