import { FliffException } from "server/common/FliffException";
import { ValidationUtils } from "server/common/ValidationUtils";
import { InternalAxiosRequestConfig } from "axios";
import { TAnyAlias } from "src/types";
import DeviceInfo from "utils/DeviceInfo";

// 2019-11-15 / Ivan / temporary keep this class on common domain objects
export interface ILocalAxiosRequestConfig extends InternalAxiosRequestConfig {
  inject_network_post_response_error?: boolean | null;
  inject_network_delay_in_millis?: number | null;
  inject_network_error?: boolean | null;
  inject_error_404?: boolean | null;
  damage_home_team_name?: boolean | null;
}

export class DefaultBIFResponse {
  is_error: boolean;
  resultCode: number | null;
  exception: TAnyAlias;

  public static create_for_exception(exception: TAnyAlias): DefaultBIFResponse {
    const obj = new DefaultBIFResponse();
    obj.is_error = true;
    obj.resultCode = null;
    obj.exception = exception;
    return obj;
  }

  public static create_for_ok(): DefaultBIFResponse {
    const obj = new DefaultBIFResponse();
    obj.is_error = false;
    obj.resultCode = 1;
    obj.exception = null;
    return obj;
  }

  public static create_for_resultCode(resultCode: number): DefaultBIFResponse {
    const obj = new DefaultBIFResponse();
    obj.is_error = false;
    obj.resultCode = resultCode;
    obj.exception = null;
    return obj;
  }
}

export class DataBIFResponse<T> {
  is_error: boolean;
  resultCode: number | null;
  resultObject: T | null;
  exception: TAnyAlias;

  public static create_for_ok<T>(): DataBIFResponse<T> {
    return {
      is_error: false,
      resultCode: 1,
      resultObject: null,
      exception: null,
    };
  }

  public static create_for_data<T>(obj: T): DataBIFResponse<T> {
    return {
      is_error: false,
      resultCode: 1,
      resultObject: obj,
      exception: null,
    };
  }

  public static create_for_exception<T>(
    exception: TAnyAlias,
  ): DataBIFResponse<T> {
    return {
      is_error: true,
      resultCode: null,
      resultObject: null,
      exception: exception,
    };
  }

  public static create_for_error_code<T>(
    error_code: number,
  ): DataBIFResponse<T> {
    return {
      is_error: true,
      resultCode: error_code,
      resultObject: null,
      exception: null,
    };
  }
}

export interface IDataContactUsRequest {
  email: string;
  subject: string;
  body: string;
}

export interface IDataReqAuthMeta {
  meta_device_os: typeof DeviceInfo.OS;
  meta_app_version: string;
  meta_app_build: number;
  meta_install_token: string;
  meta_device_id: string;
}

export interface IDataReqSignInUserData {
  // 2021-11-28 / Ivan / add support for multiple types of identifiers
  login_token: string;
  password: string;
}

export type TDataReqSignIn = IDataReqAuthMeta & IDataReqSignInUserData;

export interface IDataSignupViaEmailRequest {
  email: string;
  username: string;
  password: string;
  referrer_hash: string;
}

export interface ISignUpViaEmailFullData
  extends IDataReqAuthMeta,
    IDataSignupViaEmailRequest {
  // 2021-11-28 / Ivan / add support for multiple types of identifiers
  login_token: string;
  bonus_code: string;
}
export type TISignUpViaEmailUserData = Pick<
  ISignUpViaEmailFullData,
  | "login_token"
  | "bonus_code"
  | "email"
  | "username"
  | "password"
  | "referrer_hash"
>;

export class ForgotPasswordResponse {
  public static decode(response: TAnyAlias): ForgotPasswordResponse {
    ValidationUtils.assertNonNullData(response.data, "response");
    return CommonServerResponse.decode(response.data, "response");
  }
}

export class ChangePasswordResponse {
  public static decode(response: TAnyAlias): ChangePasswordResponse {
    ValidationUtils.assertNonNullData(response.data, "response");
    return CommonServerResponse.decode(response.data, "response");
  }
}

export class SendEmailFeedbackResponse {
  public static decode(response: TAnyAlias): SendEmailFeedbackResponse {
    ValidationUtils.assertNonNullData(response.data, "response");
    return CommonServerResponse.decode(response.data, "response");
  }
}

// 2022-01-31 / Ivan / lets try to get rid of ServerObj_FliffApp_UserAccount - we need temp object for complete profile workflow
// 2019-14-13 / introducing the term 'user account'
export class ServerObj_Temp_Data_For_Complete_Profile {
  id: number;
  is_completed: boolean;
  is_primary_phone_number_required: boolean;
  is_accept_terms_of_use_required: boolean;

  firstName: string;
  lastName: string;

  username: string;

  public static create(
    jsondata: TAnyAlias,
  ): ServerObj_Temp_Data_For_Complete_Profile {
    const obj = new ServerObj_Temp_Data_For_Complete_Profile();
    obj.decode(jsondata);
    return obj;
  }

  protected decode(jsondata: TAnyAlias) {
    ValidationUtils.assertNonNullData(jsondata, "jsondata");

    this.id = ValidationUtils.assertPositiveInteger(jsondata.id, "id");

    this.is_completed = ValidationUtils.assertBoolean(
      jsondata.is_completed,
      "is_completed",
    );

    this.is_primary_phone_number_required = ValidationUtils.assertBoolean(
      jsondata.is_primary_phone_number_required,
      "is_primary_phone_number_required",
    );

    this.firstName = ValidationUtils.assertOptionalString(
      jsondata.first_name,
      "first_name",
    );
    this.lastName = ValidationUtils.assertOptionalString(
      jsondata.last_name,
      "last_name",
    );
    this.username = ValidationUtils.assertOptionalString(
      jsondata.username,
      "username",
    );
    this.is_accept_terms_of_use_required = ValidationUtils.assertBoolean(
      jsondata.is_accept_terms_of_use_required,
      "is_accept_terms_of_use_required",
    );
  }
}

/*
2020-05-02 / Ivan / redesign protocol object decoding
temporary keep both versions of the objects, until we can move
all the objects to the new approach
*/
export class XCommonServerRequest {
  header: XCommonServerRequestHeader;
  message_name: string;
}

export class XCommonServerRequestHeader {
  api_version: number;
  os: string;
  version: string;
  build: number;
  lang: string;
  install_token: string;
  device_id: string;
  usa_state_code: string;
  usa_state_code_source: string;
  platform: "dev" | "prod";

  public static create(
    aos: string,
    aversion: string,
    abuild: number,
    alang: string,
    ainstall_token: string,
    adevice_id: string,
  ): XCommonServerRequestHeader {
    const obj = new XCommonServerRequestHeader();
    obj.api_version = 1;
    obj.os = aos;
    obj.version = aversion;
    obj.build = abuild;
    obj.lang = alang;
    obj.install_token = ainstall_token;
    obj.device_id = adevice_id;
    return obj;
  }
}

export class XCommonServerResponseHeader {
  api_version: number;

  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): XCommonServerResponseHeader {
    ValidationUtils.assertNonNullData(jsondata, debugInfo);

    return {
      api_version: ValidationUtils.assertPositiveInteger(
        jsondata.api_version,
        debugInfo + ".api_version",
      ),
    };
  }
}

export class CommonServerResponse {
  status: number;
  status_text: string;
  server_timestamp: number;
  build_version: number;

  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): CommonServerResponse {
    ValidationUtils.assertNonNullData(jsondata, debugInfo);

    const res: CommonServerResponse = {
      status: ValidationUtils.assertPositiveIntegerOrZero(
        jsondata.status,
        debugInfo + ".status",
      ),
      status_text: ValidationUtils.assertNonEmptyString(
        jsondata.status_text,
        debugInfo + ".status_text",
      ),
      server_timestamp: ValidationUtils.assertPositiveInteger(
        jsondata.server_timestamp,
        debugInfo + ".server_timestamp",
      ),
      build_version: ValidationUtils.assertPositiveInteger(
        jsondata.build_version,
        debugInfo + ".build_version",
      ),
    };

    if (res.status !== 0) {
      throw new FliffException(
        FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
        "CommonServerResponse.decode - (res.status != 0) [" +
          res.status +
          "] for [status]",
      );
    }
    if (res.status_text !== "OK") {
      throw new FliffException(
        FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
        "CommonServerResponse.decode - (res.status_text != OK) [" +
          res.status_text +
          "] for [status_text]",
      );
    }

    return res;
  }
}

export class XCommonServerResponse extends CommonServerResponse {
  header: XCommonServerResponseHeader;
  message_name: string;

  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): XCommonServerResponse {
    ValidationUtils.assertNonNullData(jsondata, debugInfo);

    const baseres = CommonServerResponse.decode(jsondata, debugInfo);

    return {
      ...baseres,
      header: XCommonServerResponseHeader.decode(
        jsondata.response.header,
        debugInfo + ".header",
      ),
      message_name: ValidationUtils.assertNonEmptyString(
        jsondata.response.message_name,
        debugInfo + ".message_name",
      ),
    };
  }
}
