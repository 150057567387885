import ApiCore from "server/core/server/ApiCore";
import CoreApiUtils from "./Utils";

import { MemoryStorage } from "utils/MemoryStorage";
import {
  CoreGetPaymentTokensAction,
  CoreGetLastTransactionsAction,
  CoreSetTicketProcessingStateAction,
} from "reduxLocal/core/core.actions";
import { DataReq__SB_Pick } from "server/legacyCore/data/objects";
import { PlacePicksOperationStatus } from "server/core/data/constants";

import * as I from "server/core/data/objects";
import { TActionCode, TFliffResult } from "server/common/data/objects";
import { BundleObtainMethod } from "server/core/data/constants";
import { TCurrencyMode } from "src/types";

class Private {
  private readonly _localRequestMeta = { isPrivate: true } as const;

  public safeBackgroundRefreshUserProfile(): Promise<
    TFliffResult<I.IFCMRefreshUserProfileResponse>
  > {
    return ApiCore.safeExecuteBackgroundRequest<
      I.IFCMRefreshUserProfileRequest,
      I.IFCMRefreshUserProfileResponse
    >({
      __object_class_name: "FCM__Refresh_User_Profile__Request",
      localMeta: this._localRequestMeta,
    });
  }

  public safeBlockingRefreshUserProfile = (): Promise<
    TFliffResult<I.IFCMRefreshUserProfileResponse>
  > => {
    return ApiCore.safeExecuteBlockingRequest<
      I.IFCMRefreshUserProfileRequest,
      I.IFCMRefreshUserProfileResponse
    >({
      __object_class_name: "FCM__Refresh_User_Profile__Request",
      localMeta: this._localRequestMeta,
    });
  };

  public safeBlockingGetReferralReport = (): Promise<
    TFliffResult<I.IFCMGetReferralReportResponse>
  > => {
    return ApiCore.safeExecuteBlockingTransparentNoErrorModalRequest<
      I.IFCMGetReferralReportRequest,
      I.IFCMGetReferralReportResponse
    >({
      __object_class_name: "FCM__Get_Referral_Report__Request",
      localMeta: this._localRequestMeta,
    });
  };

  public safeBackgroundGetLastTransactions = async (): Promise<
    TFliffResult<I.IFCMGetTransactionsReportResponse>
  > => {
    const result = await ApiCore.safeExecuteBackgroundRequest<
      I.IFCMGetTransactionsReportRequest,
      I.IFCMGetTransactionsReportResponse
    >({
      __object_class_name: "FCM__Get_Transactions_Report__Request",
      localMeta: this._localRequestMeta,
      sb_last_transaction_id: MemoryStorage.lastKnownTransactionId(),
    });

    if (result.response) {
      MemoryStorage.setLastKnownTransactionId(
        result.response.sb_last_transaction_id,
      );
      CoreGetLastTransactionsAction.dispatchGetLastTransactions(
        result.response,
      );
    }

    return result;
  };

  public safeBlockingGetPeriodTransactionsReport = (
    reportPeriod: I.IFCMGetTransactionsReportRequest["report_period"],
  ): Promise<TFliffResult<I.IFCMGetTransactionsReportResponse>> => {
    return ApiCore.safeExecuteBlockingTransparentNoErrorModalRequest<
      I.IFCMGetTransactionsReportRequest,
      I.IFCMGetTransactionsReportResponse
    >({
      __object_class_name: "FCM__Get_Transactions_Report__Request",
      localMeta: this._localRequestMeta,
      report_period: reportPeriod,
      sb_last_transaction_id: -1,
    });
  };

  public safeBlockingInitiateTOUSession = (): Promise<
    TFliffResult<I.IFCMInitiateTOUSessionResponse>
  > => {
    return ApiCore.safeExecuteBlockingTransparentNoErrorModalRequest<
      I.IFCMInitiateTOUSessionRequest,
      I.IFCMInitiateTOUSessionResponse
    >({
      __object_class_name: "FCM__Initiate_TOU_Session__Request",
      localMeta: this._localRequestMeta,
    });
  };

  public safeBlockingTransparentGetPaymentTokens = async (): Promise<
    TFliffResult<I.IFCMGetPaymentTokensResponse>
  > => {
    const result =
      await ApiCore.safeExecuteBlockingTransparentNoErrorModalRequest<
        I.IFCMGetPaymentTokensRequest,
        I.IFCMGetPaymentTokensResponse
      >({
        __object_class_name: "FCM__Get_All_Payment_Tokens__Request",
        localMeta: this._localRequestMeta,
      });
    if (result.response) {
      CoreGetPaymentTokensAction.dispatchGetPaymentTokens(
        result.response.payment_tokens,
      );
    }

    return result;
  };

  public safeBlockingTransparentDisablePaymentToken = async (
    paymentToken: string,
  ): Promise<TFliffResult<I.IFCMDisablePaymentTokenResponse>> => {
    const result = await ApiCore.safeExecuteBlockingRequest<
      I.IFCMDisablePaymentTokenRequest,
      I.IFCMDisablePaymentTokenResponse
    >({
      __object_class_name: "FCM__Disable_Payment_Token__Request",
      payment_token: paymentToken,
      localMeta: this._localRequestMeta,
    });
    if (result.response) {
      CoreGetPaymentTokensAction.dispatchGetPaymentTokens(
        result.response.payment_tokens,
      );
    }

    return result;
  };

  public safeBlockingGetContactUsSubjectLine = (): Promise<
    TFliffResult<I.IFCMGetContactUsSubjectLineResponse>
  > => {
    return ApiCore.safeExecuteBlockingRequest<
      I.IFCMGetContactUsSubjectLineRequest,
      I.IFCMGetContactUsSubjectLineResponse
    >({
      __object_class_name: "FCM__Get_Contact_US_Subject_Line__Request",
      localMeta: this._localRequestMeta,
    });
  };

  public safeBlockingObtainBundleWithXP(
    data: Pick<
      I.IFCMObtainBundleRequest,
      "bundle_code" | "serialized_goods" | "price_in_cents"
    >,
  ): Promise<TFliffResult<I.IFCMObtainBundleResponse>> {
    return ApiCore.safeExecuteBlockingRequest<
      I.IFCMObtainBundleRequest,
      I.IFCMObtainBundleResponse
    >({
      __object_class_name: "FCM__Obtain_Bundle__Request",
      obtain_method: BundleObtainMethod.CONST_77092_OBTAIN_BUNDLE_VIA_XP,
      localMeta: this._localRequestMeta,
      ...data,
    });
  }

  public safeBlockingObtainBundleWithFC(
    data: Pick<
      I.IFCMObtainBundleRequest,
      "bundle_code" | "serialized_goods" | "price_in_cents"
    >,
  ): Promise<TFliffResult<I.IFCMObtainBundleResponse>> {
    return ApiCore.safeExecuteBlockingRequest<
      I.IFCMObtainBundleRequest,
      I.IFCMObtainBundleResponse
    >({
      __object_class_name: "FCM__Obtain_Bundle__Request",
      obtain_method:
        BundleObtainMethod.CONST_77093_OBTAIN_BUNDLE_VIA_REDEEMABLE_FC,
      localMeta: this._localRequestMeta,
      ...data,
    });
  }

  public safeBlockingObtainFreeBundle(
    data: Pick<
      I.IFCMObtainBundleRequest,
      "bundle_code" | "serialized_goods" | "price_in_cents"
    >,
  ): Promise<TFliffResult<I.IFCMObtainBundleResponse>> {
    return ApiCore.safeExecuteBlockingRequest<
      I.IFCMObtainBundleRequest,
      I.IFCMObtainBundleResponse
    >({
      __object_class_name: "FCM__Obtain_Bundle__Request",
      obtain_method: BundleObtainMethod.CONST_77091_CLAIM_FREE_BUNDLE,
      localMeta: this._localRequestMeta,
      ...data,
    });
  }

  public safeBlockingInitiateBundlePurchase(
    data: Pick<
      I.IInitiateBundlePurchaseRequest,
      "bundle_code" | "vendor_code" | "price_in_cents" | "payment_token"
    >,
  ): Promise<TFliffResult<I.IInitiateBundlePurchaseResponse>> {
    return ApiCore.safeExecuteBlockingRequest<
      I.IInitiateBundlePurchaseRequest,
      I.IInitiateBundlePurchaseResponse
    >({
      __object_class_name: "FCM__Initiate_Bundle_Purchase__Request",
      localMeta: this._localRequestMeta,
      ...data,
    });
  }

  public safeBackgroundGetOpenPodsReport(): Promise<
    TFliffResult<I.IFCMGetPodsReportResponse>
  > {
    return ApiCore.safeExecuteBackgroundRequest<
      I.IFCMGetPodsReportRequest,
      I.IFCMGetPodsReportResponse
    >({
      __object_class_name: "FCM__Get_Pods_Report__Request",
      localMeta: this._localRequestMeta,
    });
  }

  public safeBackgroundGetPodsReport(
    minKnownPodFkey: string,
    actionCode: TActionCode,
  ): Promise<TFliffResult<I.IFCMGetPodsReportResponse>> {
    return ApiCore.safeExecuteBackgroundRequest<
      I.IFCMGetPodsReportRequest,
      I.IFCMGetPodsReportResponse
    >({
      __object_class_name: "FCM__Get_Pods_Report__Request",
      localMeta: this._localRequestMeta,
      scrolling_load_action: {
        action_code: actionCode,
        max_known_fkey: "",
        min_known_fkey: minKnownPodFkey,
      },
    });
  }

  public safeBlockingPlacePick = async (
    picks: DataReq__SB_Pick[],
    shoppingCartId: string,
    verifyMode: PlacePicksOperationStatus,
    pickDelayInMs = 0,
  ): Promise<TFliffResult<I.IFCMPlacePickResponse> | void> => {
    if (MemoryStorage.is_blocking_operation_in_progress) {
      return;
    }

    MemoryStorage.is_blocking_operation_in_progress = true;
    CoreSetTicketProcessingStateAction.dispatchSetTicketProcessingState(true);
    // We force here to show transparent modal. This is an exception, because the request logic is different and no need to adjust the whole flow,
    // where additional UI is triggered.
    // The Rest of the logic remains the same – it's triggered inside ApiUIHandler class. <= Roman
    const isPickValid = await CoreApiUtils.placePickValidation(pickDelayInMs);
    if (!isPickValid) {
      CoreSetTicketProcessingStateAction.dispatchSetTicketProcessingState(
        false,
      );
      MemoryStorage.is_blocking_operation_in_progress = false;
      return;
    }

    const result = await ApiCore.safeExecuteBlockingTransparentRequest<
      I.IFCMPlacePickRequest,
      I.IFCMPlacePickResponse
    >({
      __object_class_name: "FCM__Place_Pick__Request",
      picks,
      unique_shopping_cart_id: shoppingCartId,
      verify_mode: verifyMode,
      localMeta: this._localRequestMeta,
    });

    if (
      result.response?.place_picks_operation_status.status ===
      PlacePicksOperationStatus.CONST_8301_REQUIRES_DELAYED_RESUBMIT
    ) {
      return await this.safeBlockingPlacePick(
        picks,
        shoppingCartId,
        verifyMode,
        result.response.place_picks_operation_status
          .required_resubmit_delay_millis,
      );
    }
    CoreSetTicketProcessingStateAction.dispatchSetTicketProcessingState(false);
    MemoryStorage.is_blocking_operation_in_progress = false;

    return result;
  };

  public safeBlockingTransparentGetSGPCoeff = async (
    picks: DataReq__SB_Pick[],
    shoppingCartId: string,
    verifyMode: PlacePicksOperationStatus,
  ): Promise<TFliffResult<I.IFCMPlacePickResponse>> => {
    return await ApiCore.safeExecuteBlockingTransparentNoErrorModalRequest<
      I.IFCMPlacePickRequest,
      I.IFCMPlacePickResponse
    >({
      __object_class_name: "FCM__Place_Pick__Request",
      picks,
      unique_shopping_cart_id: shoppingCartId,
      verify_mode: verifyMode,
      localMeta: this._localRequestMeta,
    });
  };

  public safeBlockingGetPodPicksReport(podFkey: string) {
    return ApiCore.safeExecuteBackgroundRequest<
      I.TFCMGetPicksReportRequest,
      I.IFCMGetPicksReportResponse
    >({
      __object_class_name: "FCM__Get_Picks_Report__Request",
      localMeta: this._localRequestMeta,
      report_mode: 6033,
      pod_fkey: podFkey,
    });
  }

  public safeBackgroundGetMyPicksReport(
    minKnownPickFkey: string,
    actionCode: TActionCode,
    mode: Exclude<I.TFCMGetPicksReportRequest["report_mode"], 6033>,
    currencyMode: TCurrencyMode,
  ) {
    return ApiCore.safeExecuteBackgroundRequest<
      I.TFCMGetPicksReportRequest,
      I.IFCMGetPicksReportResponse
    >({
      __object_class_name: "FCM__Get_Picks_Report__Request",
      localMeta: this._localRequestMeta,
      report_mode: mode,
      scrolling_load_action: {
        action_code: actionCode,
        max_known_fkey: "",
        min_known_fkey: minKnownPickFkey,
      },
      currency_code: currencyMode,
    });
  }
}

export default new Private();
