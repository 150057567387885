import {
  IFliffProtocolResponse,
  IFliffResponse,
} from "server/common/data/objects";
import { IInternalProtocolResponseProcessor } from "server/common/interfaces";
import { IFSMProtocolResponseSlots } from "server/social/data/objects";
import { SocialInjectProtocolSlotsAction } from "reduxLocal//social/social.actions";

export class InternalProtocolResponseProcessorImpl
  implements
    IInternalProtocolResponseProcessor<
      IFSMProtocolResponseSlots,
      IFliffResponse
    > {
  public processProtocolResponseSlots<Response extends IFliffResponse>(
    protocolResponse: IFliffProtocolResponse<
      IFSMProtocolResponseSlots,
      Response
    >,
  ): void {
    const slots = protocolResponse.x_slots;

    if (!slots) {
      return;
    }

    SocialInjectProtocolSlotsAction.dispatchInjectProtocolSlots(slots);
  }
}
