import {
  PlayThroughPodCredibleState,
  PlayThroughPodPlayableState,
  PlayThroughPodProgressState,
  TransactionType,
  WithdrawalPaymentInstrumentStatus,
  WithdrawalPaymentInstrumentType,
  PlacePicksOperationStatus,
} from "server/core/data/constants";
import { ValidationUtils } from "server/common/ValidationUtils";
import { TAnyAlias } from "src/types";

export const validateTransactionType = (
  jsonData: TAnyAlias,
  debugInfo: string,
): TransactionType =>
  ValidationUtils.validateEnum<TransactionType>(
    TransactionType as unknown as TransactionType,
    jsonData,
    debugInfo,
  );

export const validatePaymentMethodInstrumentType = (
  jsonData: TAnyAlias,
  debugInfo: string,
): WithdrawalPaymentInstrumentType =>
  ValidationUtils.validateEnum(
    WithdrawalPaymentInstrumentType as unknown as WithdrawalPaymentInstrumentType,
    jsonData,
    debugInfo,
  );

export const validatePaymentMethodInstrumentStatus = (
  jsonData: TAnyAlias,
  debugInfo: string,
): WithdrawalPaymentInstrumentStatus =>
  ValidationUtils.validateEnum(
    WithdrawalPaymentInstrumentStatus as unknown as WithdrawalPaymentInstrumentStatus,
    jsonData,
    debugInfo,
  );

export const validatePodCreditableState = (
  jsonData: TAnyAlias,
  debugInfo: string,
) =>
  ValidationUtils.validateEnum(
    PlayThroughPodCredibleState as unknown as PlayThroughPodCredibleState,
    jsonData,
    debugInfo,
  );

export const validatePodPlayableState = (
  jsonData: TAnyAlias,
  debugInfo: string,
) =>
  ValidationUtils.validateEnum(
    PlayThroughPodPlayableState as unknown as PlayThroughPodPlayableState,
    jsonData,
    debugInfo,
  );

export const validatePodProgressState = (
  jsonData: TAnyAlias,
  debugInfo: string,
) =>
  ValidationUtils.validateEnum(
    PlayThroughPodProgressState as unknown as PlayThroughPodProgressState,
    jsonData,
    debugInfo,
  );

export const validatePlacePicksOperationStatus = (
  jsonData: TAnyAlias,
  debugInfo: string,
): PlacePicksOperationStatus =>
  ValidationUtils.validateEnum(
    PlacePicksOperationStatus as unknown as PlacePicksOperationStatus,
    jsonData,
    debugInfo,
  );
