import { AxiosResponse } from "axios";

import { ApiExecutorAuth } from "server/legacyCore/ApiExecutorAuth";
import { TAnyAlias } from "src/types";

export class ApiCore {
  private static _ApiExecutor = new ApiExecutorAuth();

  public static async apiRequestWrapper<T>(
    debugErrorSource: string,
    data: TAnyAlias,
    callback: (rawResponse: AxiosResponse) => Promise<T> | T,
  ): Promise<T> {
    return await ApiCore._ApiExecutor.apiRequestWrapper(
      debugErrorSource,
      data,
      callback,
    );
  }

  public static async api_request_wrapper_no_auth<T>(
    debugErrorSource: string,
    data: TAnyAlias,
    callback: (rawResponse: AxiosResponse) => Promise<T> | T,
  ): Promise<T> {
    return await ApiCore._ApiExecutor.apiRequestWrapperNoAuth(
      debugErrorSource,
      data,
      callback,
    );
  }

  public static async accountSignIn(
    username: string,
    password: string,
  ): Promise<void> {
    await ApiCore._ApiExecutor.accountLogin(username, password);
  }

  public static async socialSignIn(
    backendCode: string,
    backendToken: string,
    details: string,
  ): Promise<void> {
    await ApiCore._ApiExecutor.socialLogin(backendCode, backendToken, details);
  }

  protected static async api_public_x_request_wrapper<T>(
    url: string,
    debugErrorSource: string,
    request: TAnyAlias,
    callback: (rawResponse: AxiosResponse) => Promise<T> | T,
  ): Promise<T> {
    return await ApiCore._ApiExecutor.apiPublicXRequestWrapper(
      url,
      debugErrorSource,
      request,
      callback,
    );
  }

  protected static async api_alt_x_request_wrapper<T>(
    url: string,
    debugErrorSource: string,
    request: TAnyAlias,
    callback: (rawResponse: AxiosResponse) => Promise<T> | T,
  ): Promise<T> {
    return await ApiCore._ApiExecutor.apiAltXRequestWrapper(
      url,
      debugErrorSource,
      request,
      callback,
    );
  }
}
