import { TokenResponse } from "@react-oauth/google";
import { AppUIShowModalDialogAction } from "reduxLocal/appUI/appUI.actions";
import { TAnyAlias } from "src/types";
import { FliffException } from "server/common/FliffException";
import { SportsBookSignInBIF } from "server/legacyCore/server/SportsBookSignInBIF";

export interface IAppleAuthResponse {
  authorization: { id_token: string; code: string; state?: string };
  user?: { email: string; name: { firstName: string; lastName: string } };
}

export class SportsBookSocialSignInBIF extends SportsBookSignInBIF {
  public static handleErrorOnSocialSignIn = (error: TAnyAlias) => {
    if (error instanceof FliffException) {
      throw error;
    }
    AppUIShowModalDialogAction.dispatchShowErrorDialogForException(
      // Apple JS API doesn't provide error codes to be handled on the client.
      // Only one error can be caught up – "popup_closed_by_user".
      new FliffException(error.code || -1, error.error || error.message),
      "",
    );
  };

  public static handleSignInWithAppleID = (credentials: IAppleAuthResponse) =>
    SportsBookSocialSignInBIF.xloginWithSocialToken(
      "apple-id",
      (): [string, string] => {
        try {
          const familyName =
            credentials.user && credentials.user.name.firstName
              ? credentials.user.name.firstName
              : "";
          const givenName =
            credentials.user && credentials.user.name.lastName
              ? credentials.user.name.lastName
              : "";
          const email = credentials.user?.email || "";
          const detailsdict = {
            provider_user_id: credentials.user,
            provider_email: email,
            provider_givenName: givenName,
            provider_familyName: familyName,
            //Not sure about that
            provider_realUserStatus:
              "" + (2000 + credentials.authorization.code || -1),
            // provider_realUserStatus: "" + (2000 + credentials.realUserStatus),
          };

          const details = JSON.stringify(detailsdict);

          const backend_token = credentials.authorization.id_token || "";

          return [backend_token, details];
        } catch (error) {
          if (error instanceof FliffException) {
            throw error;
          }

          throw new FliffException(
            FliffException.ERROR_2699__SDK_APPLEID__UNKNOWN_ERROR,
            "unknown error in loginWithAppleID",
          );
        }
      },
    );

  public static signInWithGoogle = (
    credentials: Omit<
      TokenResponse,
      "error" | "error_description" | "error_uri"
    >,
  ) =>
    SportsBookSocialSignInBIF.xloginWithSocialToken(
      "google-oauth2",
      (): [string, string] => {
        try {
          // We assume that server will retrieve details from token.
          return [credentials.access_token, ""];
        } catch (error) {
          if (error instanceof FliffException) {
            throw error;
          }

          throw new FliffException(
            FliffException.ERROR_2599__SDK_GOOGLE__UNKNOWN_ERROR,
            "unknown error in loginWithGoogle",
          );
        }
      },
    );
}
