import { FliffException } from "server/common/FliffException";
import { PublicChallengeType } from "server/legacyCore/data/constants";

// 2020-05-27 / Ivan / trying alternative approach, where client PJO objects are the reference point and protocol only decodes them
import {
  IDataSBBetGroup,
  IDataSBBetMarketVisualColumn,
  Data__SB_BetProposal,
  Data__SB_Channel,
  Data__SB_Permissions_And_Common_Data,
  Data__SB_Config,
  IDataSBConflictClassDef,
  IDataSBConlictMarketClassDef,
  Data__SB_FeedDiffItem_BetMarket_Available,
  Data__SB_FeedDiffItem_BetMarket_Deactivated,
  Data__SB_FliffCash_Place_Straight_Pick_Selection_Limit_Def,
  Data__SB_IAP_PaymentsToFinish,
  IDataSBIAPTransaction,
  IDataSBInitiatedWithdrawalResponse,
  IDataSBInitiatedPersonaVerificationResponse,
  IDataSBLogroV2AllDailyChallengesCompleted,
  IDataSBPick,
  Data__SB_v2_Place_Picks_Operation_Status,
  Data__SB_Pick_Selection,
  Data__SB_Points_Transaction_PeriodReport,
  Data__SB_Public_Challenge_Details__17001__PLACE_PICK_TYPE,
  Data__SB_Public_Challenge_Details__17002__PLACE_PICK_SELECTION_NO_CONFLICT,
  Data__SB_Public_Challenge_Details__17003__PLACE_PICK_SELECTION_FOR_CONFLICT,
  Data__SB_Public_Challenges_Report,
  Data__SB_Public_Challenges_Report_Item,
  Data__SB_UserProfile,
  Data__SB_UserProfile_Attribute,
  IDataSBXDeltaProfile,
  Data__SB_User_Personal_Info,
  IDataSlideAllGiftCardBrands,
  IDataSlideGiftCardBrand,
  IDataSlideGiftCardRedemptionMethod,
  IDataSlideUserGiftCard,
  IArenaDef,
  TBetMarket,
  IPlayThroughData,
  IPlayThroughBundle,
  IPlayThroughBundleBonus,
  IOnboardingPromotion,
  Data__SB_Pick_PeriodReport,
  Data__SB_v3_User_Weekly_Stats,
  Data__SB_v3_User_Profile_Summary,
  Data__SB_Social_Picks_Stats,
  Data__SB_Points_Leaderboard_Badge,
} from "server/legacyCore/data/objects";
import { TAnyAlias, TUSAStatesValues } from "src/types";

import {
  validatePublicChallengeType,
  validateBetOrBetGroupStatus,
  validateChannelType,
  validateConflictType,
  validateConflictTypeOrNull,
  validateGiftCardRedemptionConfigType,
  validateMarketOfferSelectionChangeType,
  validatePickCurrency,
  validatePickSelectionState,
  validatePickType,
  validatePickTypeOrNull,
  validateRawPeriodType,
  validateServerUIMode,
  validateIdentityVerificationStatus,
  validatePlacePicksOperationStatus,
  validateUnfinishedOrderState,
  validateBundleOfferSkin,
  validateUserAccountDetailsStatus,
  validateHeraldProtocolVersion,
  validateMoreMarketsChatMode,
  validateBundleBonusSkin,
  validatePlacePickPlayThroughMode,
  validateGiftCardsStatus,
} from "./serverEnumHelpers";
import { IFCMTransaction } from "server/core/data/objects";
import { AdapterFCMTransaction } from "server/core/server/impl/serverAdapters";
import { IDataSBUnfinishedOrder } from "server/common/data/objects";
import { AdapterHelper } from "server/common/AdapterHelper";
import { ValidationUtils } from "server/common/ValidationUtils";
import { validatePollFantasyRequestMode } from "server/common/data/serverEnumHelpers";
import { AdapterEmojiData } from "server/social/server/impl/serverAdapters";

export class AdapterSBChannel {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): Data__SB_Channel {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      skin_id: ValidationUtils.assertPositiveInteger(
        jsonData.skin_id,
        debugInfo + ".skin_id",
      ),
      inplay_pos: ValidationUtils.assertAnyInteger(
        jsonData.inplay_pos,
        debugInfo + ".inplay_pos",
      ),
      // negative is used for 'filter only' channels
      id: ValidationUtils.assertAnyInteger(jsonData.id, debugInfo + ".id"),
      dash_pos: ValidationUtils.assertAnyInteger(
        jsonData.dash_pos,
        debugInfo + ".dash_pos",
      ),
      allsports_pos: ValidationUtils.assertAnyInteger(
        jsonData.allsports_pos,
        debugInfo + ".allsports_pos",
      ),
      visualName: ValidationUtils.assertNonEmptyString(
        jsonData.name_type_1,
        debugInfo + ".name_type_1",
      ),
      name_type_2: ValidationUtils.assertNonEmptyString(
        jsonData.name_type_2,
        debugInfo + ".name_type_2",
      ),
      long_name: ValidationUtils.assertNonEmptyString(
        jsonData.name_type_3,
        debugInfo + ".name_type_3",
      ),
      type: validateChannelType(jsonData.type, debugInfo + ".type"),

      fc_limit_code: ValidationUtils.assertAnyInteger(
        jsonData.fc_limit_code,
        debugInfo + ".fc_limit_code",
      ),
      all_sports_level: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.all_sports_level,
        debugInfo + ".all_sports_level",
      ),
    };
  }
}

class AdapterSBPlayThroughBundleBonus {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): IPlayThroughBundleBonus {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      amount_fliff_cash: ValidationUtils.assertPositiveInteger(
        jsonData.amount_fliff_cash,
        debugInfo + ".amount_fliff_cash",
      ),
      bundle_code: ValidationUtils.assertNonEmptyString(
        jsonData.bundle_code,
        debugInfo + ".bundle_code",
      ),
      multiplier_in_percents: ValidationUtils.assertPositiveInteger(
        jsonData.multiplier_in_percents,
        debugInfo + ".multiplier_in_percents",
      ),
      level_2_skin: validateBundleBonusSkin(
        jsonData.level_2_skin,
        debugInfo + ".level_2_skin",
      ),
      serialized_goods: ValidationUtils.assertOptionalString(
        jsonData.serialized_goods,
        debugInfo + ".serialized_goods",
      ),
    };
  }
}

class AdapterSBPlayThroughBundle {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): IPlayThroughBundle {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      amount_social_coins: ValidationUtils.assertAnyInteger(
        jsonData.amount_social_coins,
        debugInfo + ".amount_social_coins",
      ),
      level_1_skin: validateBundleOfferSkin(
        jsonData.level_1_skin,
        debugInfo + ".level_1_skin",
      ),
      level_2_data: AdapterHelper.nullOrDecodeArray(
        AdapterSBPlayThroughBundleBonus.decode,
        jsonData.level_2_data,
        debugInfo + ".level_2_data",
      ),
      price_in_cents: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.price_in_cents,
        debugInfo + ".price_in_cents",
      ),
      v_bonus_banner: ValidationUtils.assertOptionalString(
        jsonData.v_bonus_banner,
        debugInfo + ".v_bonus_banner",
      ),
      amount_fliff_cash: ValidationUtils.assertAnyInteger(
        jsonData.amount_fliff_cash,
        debugInfo + ".amount_fliff_cash",
      ),
      bundle_code: ValidationUtils.assertNonEmptyString(
        jsonData.bundle_code,
        debugInfo + ".bundle_code",
      ),
      serialized_goods: ValidationUtils.assertOptionalString(
        jsonData.serialized_goods,
        debugInfo + ".serialized_goods",
      ),
      min_next_obtain_stamp_sec: ValidationUtils.assertOptionalInteger(
        jsonData.min_next_obtain_stamp_sec,
        debugInfo + ".min_next_obtain_stamp_sec",
      ),
    };
  }
}

class AdapterSBPlayThroughData {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): IPlayThroughData {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      data: AdapterHelper.decodeArray(
        AdapterSBPlayThroughBundle.decode,
        jsonData.data,
        debugInfo + ".play_through_bundles",
      ),
    };
  }
}

class AdapterSBOnboardingPromotion {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): IOnboardingPromotion {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      promotion_image_url: ValidationUtils.assertNonEmptyString(
        jsonData.promotion_image_url,
        debugInfo + ".promotion_image_url",
      ),
      promotion_image_resized_url: ValidationUtils.assertNonEmptyString(
        jsonData.promotion_image_resized_url,
        debugInfo + ".promotion_image_resized_url",
      ),
      promotion_title: ValidationUtils.assertNonEmptyString(
        jsonData.promotion_title,
        debugInfo + ".promotion_title",
      ),
      promotion_description: ValidationUtils.assertNonEmptyString(
        jsonData.promotion_description,
        debugInfo + ".promotion_description",
      ),
      sticky_bottom_sheet_banner_image_url:
        ValidationUtils.assertNonEmptyString(
          jsonData.sticky_bottom_sheet_banner_image_url,
          debugInfo + ".sticky_bottom_sheet_banner_image_url",
        ),
    };
  }
}

export class Adapter__SB_Permissions_And_Common_Data {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): Data__SB_Permissions_And_Common_Data {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);
    const conflict_pcats = AdapterHelper.decodeArrayOfIntegers(
      jsonData.conflict_pcats,
      debugInfo + ".conflict_pcats",
    );

    // 2022-01-15 / Ivan / introduce concept for default + per user permissions
    return {
      use_place_pick_v1: ValidationUtils.assertBoolean(
        jsonData.use_place_pick_v1,
        debugInfo + ".use_place_pick_v1",
      ),
      ui_mode: validateServerUIMode(jsonData.ui_mode, debugInfo + ".ui_mode"),
      gift_cards_status: validateGiftCardsStatus(
        jsonData.gift_cards_status,
        debugInfo + ".gift_cards_status",
      ),
      onboarding_promotion: AdapterSBOnboardingPromotion.decode(
        jsonData.onboarding_promotion,
        debugInfo + ".onboarding_promotion",
      ),
      conflict_pcats: new Set(conflict_pcats),
      herald_endpoint: ValidationUtils.assertOptionalString(
        jsonData.herald_endpoint,
        debugInfo + ".herald_endpoint",
      ),
      herald_protocol_version: validateHeraldProtocolVersion(
        jsonData.herald_protocol_version,
        debugInfo + ".herald_protocol_version",
      ),
      // 2021-12-23 / Ivan / pass last known ip address from server
      caller_ip_address: ValidationUtils.assertOptionalString(
        jsonData.caller_ip_address,
        debugInfo + ".caller_ip_address",
      ),
      // 2022-12-05 / Ivan / new server side config texts
      cashier_social_banner_skin: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.cashier_social_banner_skin,
        debugInfo + ".cashier_social_banner_skin",
      ),
      cashier_social_banner_title: ValidationUtils.assertOptionalString(
        jsonData.cashier_social_banner_title,
        debugInfo + ".cashier_social_banner_title",
      ),
      cashier_social_banner_text: ValidationUtils.assertOptionalString(
        jsonData.cashier_social_banner_text,
        debugInfo + ".cashier_social_banner_text",
      ),

      cashier_cash_banner_skin: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.cashier_cash_banner_skin,
        debugInfo + ".cashier_cash_banner_skin",
      ),
      cashier_cash_banner_title: ValidationUtils.assertOptionalString(
        jsonData.cashier_cash_banner_title,
        debugInfo + ".cashier_cash_banner_title",
      ),
      cashier_cash_banner_text: ValidationUtils.assertOptionalString(
        jsonData.cashier_cash_banner_text,
        debugInfo + ".cashier_cash_banner_text",
      ),

      // 2022-12-22 / Ivan / added concept for global banner
      global_banner_image_url: ValidationUtils.assertOptionalString(
        jsonData.global_banner_image_url,
        debugInfo + ".global_banner_image_url",
      ),
      global_banner_click_url: ValidationUtils.assertOptionalString(
        jsonData.global_banner_click_url,
        debugInfo + ".global_banner_click_url",
      ),
      active_feed_arenas: AdapterHelper.decodeArray<IArenaDef>(
        AdapterSBArenaDef.decode,
        jsonData.active_feed_arenas,
        debugInfo + ".active_feed_arenas",
      ),
      leaderboard_arenas: AdapterHelper.decodeArray<IArenaDef>(
        AdapterSBArenaDef.decode,
        jsonData.leaderboard_arenas,
        debugInfo + ".leaderboard_arenas",
      ),
      cashier_social_banner_url: ValidationUtils.assertOptionalString(
        jsonData.cashier_social_banner_url,
        debugInfo + ".cashier_social_banner_url",
      ),
      cashier_cash_banner_url: ValidationUtils.assertOptionalString(
        jsonData.cashier_cash_banner_url,
        debugInfo + ".cashier_cash_banner_url",
      ),
      we_are_under_construction_skin: ValidationUtils.assertAnyInteger(
        jsonData.we_are_under_construction_skin,
        debugInfo + ".we_are_under_construction_skin",
      ),
      leaderboard_disabled: ValidationUtils.assertBoolean(
        jsonData.leaderboard_disabled,
        debugInfo + ".leaderboard_disabled",
      ),
      leaderboard_disabled_dialog_text: ValidationUtils.assertOptionalString(
        jsonData.leaderboard_disabled_dialog_text,
        debugInfo + ".leaderboard_disabled_dialog_text",
      ),
      leaderboard_disabled_dialog_title: ValidationUtils.assertOptionalString(
        jsonData.leaderboard_disabled_dialog_title,
        debugInfo + ".leaderboard_disabled_dialog_title",
      ),
      core_sever_private_endpoint: ValidationUtils.assertOptionalString(
        jsonData.core_sever_private_endpoint,
        debugInfo + ".core_sever_private_endpoint",
      ),
      core_sever_public_endpoint: ValidationUtils.assertOptionalString(
        jsonData.core_sever_public_endpoint,
        ".core_sever_public_endpoint",
      ),
      proposals_feed_endpoint: ValidationUtils.assertOptionalString(
        jsonData.proposals_feed_endpoint,
        debugInfo + ".proposals_feed_endpoint",
      ),
      config__personalized__20032: ValidationUtils.assertAnyInteger(
        jsonData.config__personalized__20032,
        debugInfo + ".config__personalized__20032",
      ),
      config__personalized__20031: ValidationUtils.assertAnyInteger(
        jsonData.config__personalized__20031,
        debugInfo + ".config__personalized__20031",
      ),
      config__personalized__20022: ValidationUtils.assertAnyInteger(
        jsonData.config__personalized__20022,
        debugInfo + ".config__personalized__20022",
      ),
      config__personalized__20021: ValidationUtils.assertAnyInteger(
        jsonData.config__personalized__20021,
        debugInfo + ".config__personalized__20021",
      ),
      config__personalized__20041: ValidationUtils.assertAnyInteger(
        jsonData.config__personalized__20041,
        debugInfo + ".config__personalized__20041",
      ),
      config__personalized__20042: ValidationUtils.assertAnyInteger(
        jsonData.config__personalized__20042,
        debugInfo + ".config__personalized__20042",
      ),
      more_markets_screen_chat_mode: validateMoreMarketsChatMode(
        jsonData.more_markets_screen_chat_mode,
        debugInfo + ".more_markets_screen_chat_mode",
      ),
      play_through_data: AdapterSBPlayThroughData.decode(
        jsonData.play_through_data,
        debugInfo + ".play_through_data",
      ),
      affiliate_report_url: ValidationUtils.assertOptionalString(
        jsonData.affiliate_report_url,
        debugInfo + ".affiliate_report_url",
      ),
      poll_fantasy_backend_period: ValidationUtils.assertAnyInteger(
        jsonData.poll_fantasy_backend_period,
        debugInfo + ".poll_fantasy_backend_period",
      ),
      poll_fantasy_backend_request_mode: validatePollFantasyRequestMode(
        jsonData.poll_fantasy_backend_request_mode,
        debugInfo + ".poll_fantasy_backend_request_mode",
      ),
    };
  }
}

export class AdapterSBConfig {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): Data__SB_Config {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      obj_version: ValidationUtils.assertPositiveInteger(
        jsonData.obj_version,
        debugInfo + ".obj_version",
      ),
      reward_cash_per_referral_in_cents:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.reward_cash_per_referral_in_cents,
          debugInfo + ".reward_cash_per_referral_in_cents",
        ),
      min_user_profile_id_which_requires_kyc_before_place_pick:
        ValidationUtils.assertAnyInteger(
          jsonData.min_user_profile_id_which_requires_kyc_before_place_pick,
          debugInfo +
            ".min_user_profile_id_which_requires_kyc_before_place_pick",
        ),
      min_amount_for_withdraw_in_cents:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.min_amount_for_withdraw_in_cents,
          debugInfo + ".min_amount_for_withdraw_in_cents",
        ),
      max_number_of_selections_total:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.max_number_of_selections_total,
          debugInfo + ".max_number_of_selections_total",
        ),
      max_number_of_selections_in_straight_mode:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.max_number_of_selections_in_straight_mode,
          debugInfo + ".max_number_of_selections_in_straight_mode",
        ),
      max_number_of_selections_in_parlay_mode:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.max_number_of_selections_in_parlay_mode,
          debugInfo + ".max_number_of_selections_in_parlay_mode",
        ),
      max_number_of_selections_in_sgp_mode:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.max_number_of_selections_in_sgp_mode,
          debugInfo + ".max_number_of_selections_in_sgp_mode",
        ),

      fliff_cash_straight__min_pick_amount_in_cents:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.fliff_cash_straight__min_pick_amount_in_cents,
          debugInfo + ".fliff_cash_straight__min_pick_amount_in_cents",
        ),
      fliff_cash_straight__max_pick_amount_in_cents:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.fliff_cash_straight__max_pick_amount_in_cents,
          debugInfo + ".fliff_cash_straight__max_pick_amount_in_cents",
        ),
      fliff_cash_straight__max_payout_in_cents:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.fliff_cash_straight__max_payout_in_cents,
          debugInfo + ".fliff_cash_straight__max_payout_in_cents",
        ),

      fliff_cash_parlay__min_pick_amount_in_cents:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.fliff_cash_parlay__min_pick_amount_in_cents,
          debugInfo + ".fliff_cash_parlay__min_pick_amount_in_cents",
        ),
      fliff_cash_parlay__max_pick_amount_in_cents:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.fliff_cash_parlay__max_pick_amount_in_cents,
          debugInfo + ".fliff_cash_parlay__max_pick_amount_in_cents",
        ),
      fliff_cash_parlay__max_payout_in_cents:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.fliff_cash_parlay__max_payout_in_cents,
          debugInfo + ".fliff_cash_parlay__max_payout_in_cents",
        ),

      // 2020-12-21 / Ivan / moving to v2 currencies
      credits_straight__min_pick_amount_in_cents:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.credits_straight__min_pick_amount_in_cents,
          debugInfo + ".credits_straight__min_pick_amount_in_cents",
        ),
      credits_straight__max_pick_amount_in_cents:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.credits_straight__max_pick_amount_in_cents,
          debugInfo + ".credits_straight__max_pick_amount_in_cents",
        ),
      credits_straight__max_payout_in_cents:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.credits_straight__max_payout_in_cents,
          debugInfo + ".credits_straight__max_payout_in_cents",
        ),

      credits_parlay__min_pick_amount_in_cents:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.credits_parlay__min_pick_amount_in_cents,
          debugInfo + ".credits_parlay__min_pick_amount_in_cents",
        ),
      credits_parlay__max_pick_amount_in_cents:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.credits_parlay__max_pick_amount_in_cents,
          debugInfo + ".credits_parlay__max_pick_amount_in_cents",
        ),
      credits_parlay__max_payout_in_cents:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.credits_parlay__max_payout_in_cents,
          debugInfo + ".credits_parlay__max_payout_in_cents",
        ),

      // 2022-06-13 / Ivan / switching from RC to XP, tracking XP progress
      xp_bonus_progress__amount_required_for_bonus:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.xp_bonus_progress__amount_required_for_bonus,
          debugInfo + ".xp_bonus_progress__amount_required_for_bonus",
        ),
      xp_bonus_progress__amount_bonus:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.xp_bonus_progress__amount_bonus,
          debugInfo + ".xp_bonus_progress__amount_bonus",
        ),
      xp_bonus_referral__max_amount_per_child:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.xp_bonus_referral__max_amount_per_child,
          debugInfo + ".xp_bonus_referral__max_amount_per_child",
        ),
      xp_bonus_referral__percent_from_child_purchase:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.xp_bonus_referral__percent_from_child_purchase,
          debugInfo + ".xp_bonus_referral__percent_from_child_purchase",
        ),
      channels: AdapterHelper.decodeArray<Data__SB_Channel>(
        AdapterSBChannel.decode,
        jsonData.channels,
        debugInfo + ".channels",
      ),
      v3_xpoints_for_complete_single_challenge:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.v3_xpoints_for_complete_single_challenge,
          debugInfo + ".v3_xpoints_for_complete_single_challenge",
        ),
      v3_xpoints_for_complete_all_daily_challenges:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.v3_xpoints_for_complete_all_daily_challenges,
          debugInfo + ".v3_xpoints_for_complete_all_daily_challenges",
        ),

      error_codes_without_modal_error: AdapterHelper.decodeArrayOfIntegers(
        jsonData.error_codes_without_modal_error,
        debugInfo + ".error_codes_without_modal_error",
      ),

      // 2022-04-27 / Ivan / add support for SGP quote fatal error
      error_codes_for_fatal_sqp_quote_error:
        AdapterHelper.decodeArrayOfIntegers(
          jsonData.error_codes_for_fatal_sqp_quote_error,
          debugInfo + ".error_codes_for_fatal_sqp_quote_error",
        ),
      // 2022-05-15 / Ivan / add support for enable/disabe in ticket SGP errors
      error_codes_for_hide_in_ticket_sqp_error:
        AdapterHelper.decodeArrayOfIntegers(
          jsonData.error_codes_for_hide_in_ticket_sqp_error,
          debugInfo + ".error_codes_for_hide_in_ticket_sqp_error",
        ),
      onboarding_ad_amount_bonus: ValidationUtils.assertPositiveInteger(
        jsonData.onboarding_ad_amount_bonus,
        debugInfo + ".onboarding_ad_amount_bonus",
      ),
      onboarding_ad_amount_purchase: ValidationUtils.assertPositiveInteger(
        jsonData.onboarding_ad_amount_purchase,
        debugInfo + ".onboarding_ad_amount_purchase",
      ),
      // 2021-03-29 / Ivan / temp hack - protocol is in percent, convert to promiles
      max_cashback_in_promiles_for_slide:
        10 *
        ValidationUtils.assertPositiveInteger(
          jsonData.max_cashback_percent_for_slide,
          debugInfo + ".max_cashback_percent_for_slide",
        ),
      // 2021-05-19 / Ivan / introduce per-event configuration for 'More Markets' screen layout
      conflict_classes: AdapterHelper.decodeArray<IDataSBConflictClassDef>(
        AdapterSBConflictClassDef.decode,
        jsonData.conflict_classes,
        debugInfo + ".conflict_classes",
      ),
      default_pdata: Adapter__SB_Permissions_And_Common_Data.decode(
        jsonData.default_pdata,
        debugInfo + ".default_pdata",
      ),
      // 2022-01-31 / Ivan / introduce support for persona v2
      amount_which_requires_level_2_verification:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.amount_which_requires_level_2_verification,
          debugInfo + ".amount_which_requires_level_2_verification",
        ),

      // 2022-02-10 / Ivan / introduce server control on config/promo messages for referral program
      referral_bonus__percent_from_child_purchase:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.referral_bonus__percent_from_child_purchase,
          debugInfo + ".referral_bonus__percent_from_child_purchase",
        ),
      referral_bonus__text_1: ValidationUtils.assertOptionalString(
        jsonData.referral_bonus__text_1,
        debugInfo + ".referral_bonus__text_1",
      ),
      referral_bonus__text_2: ValidationUtils.assertOptionalString(
        jsonData.referral_bonus__text_2,
        debugInfo + ".referral_bonus__text_2",
      ),
      server_config__text_11: ValidationUtils.assertOptionalString(
        jsonData.server_config__text_11,
        debugInfo + ".server_config__text_11",
      ),
      server_config__text_21: ValidationUtils.assertOptionalString(
        jsonData.server_config__text_21,
        debugInfo + ".server_config__text_21",
      ),
      server_config__text_41: ValidationUtils.assertOptionalString(
        jsonData.server_config__text_41,
        debugInfo + ".server_config__text_41",
      ),

      // 2022-12-20 / Ivan / add global flag which controls how users can register / login
      // 101 => disable sign-in via email
      auth_mode: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.auth_mode,
        debugInfo + ".auth_mode",
      ),

      rpm_status_check_url: ValidationUtils.assertNonEmptyString(
        jsonData.rpm_status_check_url,
        debugInfo + ".rpm_status_check_url",
      ),
      rpm_status_check_substring: ValidationUtils.assertNonEmptyString(
        jsonData.rpm_status_check_substring,
        debugInfo + ".rpm_status_check_substring",
      ),
    };
  }
}

// 2021-04-16 / Ivan / introduce user attributes
export class AdapterSBUserProfileAttribute {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): Data__SB_UserProfile_Attribute {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      code: ValidationUtils.assertAnyInteger(
        jsonData.code,
        debugInfo + ".code",
      ),
      value: ValidationUtils.assertOptionalString(
        jsonData.value,
        debugInfo + ".value",
      ),
      state: ValidationUtils.assertAnyInteger(
        jsonData.state,
        debugInfo + ".state",
      ),
      min_claim_stamp_seconds_utc: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.min_claim_stamp_seconds_utc,
        debugInfo + ".min_claim_stamp_seconds_utc",
      ),
    };
  }
}

export class AdapterSBUserProfile {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): Data__SB_UserProfile {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    // 2021-01-31 / Ivan / introduce qtokens
    const r_5005_v2_tokens = ValidationUtils.assertAnyInteger(
      jsonData.r_5005_v2_tokens,
      debugInfo + ".r_5005_v2_tokens",
    );

    const r_5008_v2_qtokens = ValidationUtils.assertAnyInteger(
      jsonData.r_5008_v2_qtokens,
      debugInfo + ".r_5008_v2_qtokens",
    );

    // 2021-01-31 / Ivan / mobile app considers both types of tokens as identical with one exception (see below)
    const amount_v2_tokens = r_5005_v2_tokens + r_5008_v2_qtokens;

    // 2021-07-02 / Ivan / restore old scale (1000), rename field
    // 2021-01-31 / Ivan / only real tokens can give points
    const amount_max_v3_xpoints_for_place_pick = r_5005_v2_tokens / 1000;

    return {
      r_5011_v5_playable_fliff_cash: ValidationUtils.assertAnyInteger(
        jsonData.r_5011_v5_playable_fliff_cash,
        debugInfo + ".r_5011_v5_playable_fliff_cash",
      ),
      r_5013_v5_redeemable_fliff_cash: ValidationUtils.assertAnyInteger(
        jsonData.r_5013_v5_redeemable_fliff_cash,
        debugInfo + ".r_5013_v5_redeemable_fliff_cash",
      ),
      v_total_balance_on_home_screen: ValidationUtils.assertNonEmptyString(
        jsonData.v_total_balance_on_home_screen,
        debugInfo + ".v_total_balance_on_home_screen",
      ),
      amount_v2_tokens,
      amount_max_v3_xpoints_for_place_pick:
        amount_max_v3_xpoints_for_place_pick,

      obj_version: ValidationUtils.assertPositiveInteger(
        jsonData.obj_version,
        debugInfo + ".obj_version",
      ),
      amount_v3_xpoints: ValidationUtils.assertAnyInteger(
        jsonData.r_5009_v3_xpoints,
        debugInfo + ".r_5009_v3_xpoints",
      ),
      // 2022-06-13 / Ivan / switching from RC to XP, tracking XP progress
      amount_xp_bonus_progress_tokens: ValidationUtils.assertAnyInteger(
        jsonData.r_5010_v4_xp_bonus_progress_tokens,
        debugInfo + ".r_5010_v4_xp_bonus_progress_tokens",
      ),
      sb_affiliate_hash: ValidationUtils.assertNonEmptyString(
        jsonData.sb_affiliate_hash,
        debugInfo + ".sb_affiliate_hash",
      ),
      open_iap_purchases_count: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.open_iap_purchases_count,
        debugInfo + ".open_iap_purchases_count",
      ),
      // 2021-03-25 / Ivan / add monitoring for open orders
      open_orders_count: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.open_orders_count,
        debugInfo + ".open_orders_count",
      ),
      may_display_rate_us_nag: ValidationUtils.assertBoolean(
        jsonData.may_display_rate_us_nag,
        debugInfo + ".may_display_rate_us_nag",
      ),
      may_display_rate_us_reason: ValidationUtils.assertOptionalString(
        jsonData.may_display_rate_us_reason,
        debugInfo + ".may_display_rate_us_reason",
      ),
      fc_limits:
        AdapterHelper.decodeArray<Data__SB_FliffCash_Place_Straight_Pick_Selection_Limit_Def>(
          Adapter__SB_FliffCash_Place_Straight_Pick_Selection_Limit_Def.decode,
          jsonData.fc_limits,
          debugInfo + ".fc_limits",
        ),
      v2_withdraw_limits_allowed_amount: ValidationUtils.assertAnyInteger(
        jsonData.v2_withdraw_limits_allowed_amount,
        debugInfo + ".v2_withdraw_limits_allowed_amount",
      ),
      last_withdraw_visual_date: ValidationUtils.assertOptionalString(
        jsonData.last_withdraw_visual_date,
        debugInfo + ".last_withdraw_visual_date",
      ),
      next_withdraw_min_visual_date: ValidationUtils.assertOptionalString(
        jsonData.next_withdraw_min_visual_date,
        debugInfo + ".next_withdraw_min_visual_date",
      ),
      next_withdraw_days_left: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.next_withdraw_days_left,
        debugInfo + ".next_withdraw_days_left",
      ),
      this_week_stats: Adapter__SB_Social_Picks_Stats.decode(
        jsonData.this_week_stats,
        debugInfo + ".this_week_stats",
      ),
      prev_week_stats: Adapter__SB_Social_Picks_Stats.decode(
        jsonData.prev_week_stats,
        debugInfo + ".prev_week_stats",
      ),
      // 2021-04-16 / Ivan / introduce user attributes
      attributes: AdapterHelper.decodeArray<Data__SB_UserProfile_Attribute>(
        AdapterSBUserProfileAttribute.decode,
        jsonData.attributes,
        debugInfo + ".attributes",
      ),
      // 2021-08-16 / Ivan / introduce referrer info
      referrer_id: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.referrer_id,
        debugInfo + ".referrer_id",
      ),
      referrer_username: ValidationUtils.assertOptionalString(
        jsonData.referrer_username,
        debugInfo + ".referrer_username",
      ),
      own_bonus_code: ValidationUtils.assertOptionalString(
        jsonData.own_bonus_code,
        debugInfo + ".own_bonus_code",
      ),
      survey_title: ValidationUtils.assertOptionalString(
        jsonData.survey_title,
        debugInfo + ".survey_title",
      ),
      survey_logo_url: ValidationUtils.assertOptionalString(
        jsonData.survey_logo_url,
        debugInfo + ".survey_logo_url",
      ),
      survey_description: ValidationUtils.assertOptionalString(
        jsonData.survey_description,
        debugInfo + ".survey_description",
      ),
      survey_button_label: ValidationUtils.assertOptionalString(
        jsonData.survey_button_label,
        debugInfo + ".survey_button_label",
      ),
      survey_url: ValidationUtils.assertOptionalString(
        jsonData.survey_url,
        debugInfo + ".survey_url",
      ),
      survey_webview_title: ValidationUtils.assertOptionalString(
        jsonData.survey_webview_title,
        debugInfo + ".survey_webview_title",
      ),

      d_51071_withdraw__locked_amount:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.d_51071_withdraw__locked_amount,
          debugInfo + ".d_51071_withdraw__locked_amount",
        ),
      d_51072_withdraw__locked_count:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.d_51072_withdraw__locked_count,
          debugInfo + ".d_51072_withdraw__locked_count",
        ),
      // 2022-01-31 / Ivan / introduce support for persona v2
      d_51073_withdraw__total_approved_amount:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.d_51073_withdraw__total_approved_amount,
          debugInfo + ".d_51073_withdraw__total_approved_amount",
        ),
      // 2022-02-04 / Ivan / enforce enum
      identity_verification_status: validateIdentityVerificationStatus(
        jsonData.identity_verification_status,
        debugInfo + ".identity_verification_status",
      ),
      d_51002_tokens_active_picks_count:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.d_51002_tokens_active_picks_count,
          debugInfo + ".d_51002_tokens_active_picks_count",
        ),
      d_51003_fliff_cash_locked_amount:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.d_51003_fliff_cash_locked_amount,
          debugInfo + ".d_51003_fliff_cash_locked_amount",
        ),
      d_51004_fliff_cash_active_picks_count:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.d_51004_fliff_cash_active_picks_count,
          debugInfo + ".d_51004_fliff_cash_active_picks_count",
        ),
      // 2021-12-04 / Ivan / introduce delay per user
      pick_delay_acceptance_timeout_millis:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.pick_delay_acceptance_timeout_millis,
          debugInfo + ".pick_delay_acceptance_timeout_millis",
        ),
      withdraw_7303__CASH_APP__max_amount:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.withdraw_7303__CASH_APP__max_amount,
          debugInfo + ".withdraw_7303__CASH_APP__max_amount",
        ),
      withdraw_7304__SKRILL__max_amount:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.withdraw_7304__SKRILL__max_amount,
          debugInfo + ".withdraw_7304__SKRILL__max_amount",
        ),
      withdraw_7305__BITCOIN__max_amount:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.withdraw_7305__BITCOIN__max_amount,
          debugInfo + ".withdraw_7305__BITCOIN__max_amount",
        ),
      withdraw_7306__MAZOOMA_VERIFIED__max_amount:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.withdraw_7306__MAZOOMA_VERIFIED__max_amount,
          debugInfo + ".withdraw_7306__MAZOOMA_VERIFIED__max_amount",
        ),
      withdraw_7307__MAZOOMA_INSTANT__max_amount:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.withdraw_7307__MAZOOMA_INSTANT__max_amount,
          debugInfo + ".withdraw_7307__MAZOOMA_INSTANT__max_amount",
        ),
      withdraw_7308__FISERV_VERIFIED__max_amount:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.withdraw_7308__FISERV_VERIFIED__max_amount,
          debugInfo + ".withdraw_7308__FISERV_VERIFIED__max_amount",
        ),
      withdraw_min_days_between_withdraws:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.withdraw_min_days_between_withdraws,
          debugInfo + ".withdraw_min_days_between_withdraws",
        ),
      withdraw_max_days_to_process_withdraw:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.withdraw_max_days_to_process_withdraw,
          debugInfo + ".withdraw_max_days_to_process_withdraw",
        ),
      // 2020-12-24 / Ivan / adding user_id - we'd like to deprecate Data_UserAccount
      user_id: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.user_id,
        debugInfo + ".user_id",
      ),

      personal_info: AdapterSBUserPersonalInfo.decode(
        jsonData.personal_info,
        debugInfo + ".personal_info",
      ),
      // 2022-02-11 / Ivan / real username may be missing during 'social login' + 'complete profile'
      // for the time being, server will return 'profile_not_completed', this value is not supposed to be displayed TAnyAliaswhere
      username: ValidationUtils.assertNonEmptyString(
        jsonData.username,
        debugInfo + ".username",
      ),
      // 2022-08-05 / Ivan / introduce server side control over available methods for bundle purchase
      avalable_payment_vendors: AdapterHelper.decodeArrayOfIntegers(
        jsonData.avalable_payment_vendors,
        debugInfo + ".avalable_payment_vendors",
      ),
      // 2022-10-26 / Ivan / added some stats in order to help mobile app to track first pick/purchase
      s_1_total_picks: ValidationUtils.assertAnyInteger(
        jsonData.s_1_total_picks,
        debugInfo + ".s_1_total_picks",
      ),
      s_3_total_money_in_purchase_count: ValidationUtils.assertAnyInteger(
        jsonData.s_3_total_money_in_purchase_count,
        debugInfo + ".s_3_total_money_in_purchase_count",
      ),
      // 2022-12-20 / Ivan / introduce server side control over account details verification
      account_details_status: validateUserAccountDetailsStatus(
        jsonData.account_details_status,
        debugInfo + ".account_details_status",
      ),
      place_pick_play_through_mode: validatePlacePickPlayThroughMode(
        jsonData.place_pick_play_through_mode,
        debugInfo + ".place_pick_play_through_mode",
      ),
    };
  }
}

export class Adapter__SB_FliffCash_Place_Straight_Pick_Selection_Limit_Def {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): Data__SB_FliffCash_Place_Straight_Pick_Selection_Limit_Def {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      limit_code: ValidationUtils.assertAnyInteger(
        jsonData.limit_code,
        debugInfo + ".limit_code",
      ),
      max_amount_in_cents: ValidationUtils.assertAnyInteger(
        jsonData.max_amount_in_cents,
        debugInfo + ".max_amount_in_cents",
      ),
    };
  }
}

export class Adapter__SB_Points_Transaction_PeriodReport {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): Data__SB_Points_Transaction_PeriodReport {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      period_request_id: ValidationUtils.assertNonEmptyString(
        jsonData.period_request_id,
        debugInfo + ".period_request_id",
      ),
      period_start_stamp_seconds_utc: ValidationUtils.assertAnyInteger(
        jsonData.period_start_stamp_seconds_utc,
        debugInfo + ".period_start_stamp_seconds_utc",
      ),
      period_end_stamp_seconds_utc: ValidationUtils.assertAnyInteger(
        jsonData.period_end_stamp_seconds_utc,
        debugInfo + ".period_end_stamp_seconds_utc",
      ),
      data: AdapterHelper.decodeArray<IFCMTransaction>(
        AdapterFCMTransaction.decode,
        jsonData.data,
        debugInfo + ".data",
      ),
    };
  }
}

export class Adapter__SB_Pick_Selection {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): Data__SB_Pick_Selection {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    if (!jsonData.proposal_fkey) {
      jsonData.proposal_fkey = "unknown";
    }
    if (!jsonData.channel_name) {
      jsonData.channel_name = "";
    }

    return {
      id: ValidationUtils.assertPositiveInteger(jsonData.id, debugInfo + ".id"),
      is_conflict_in_play: ValidationUtils.assertBoolean(
        jsonData.is_conflict_in_play,
        debugInfo + ".is_conflict_in_play",
      ),
      in_play_state_desc: ValidationUtils.assertOptionalString(
        jsonData.in_play_state_desc,
        debugInfo + ".in_play_state_desc",
      ),
      settled_timestamp_utc: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.settled_timestamp_utc,
        debugInfo + ".settled_timestamp_utc",
      ),
      state: validatePickSelectionState(jsonData.state, debugInfo + ".state"),
      channel_id: ValidationUtils.assertPositiveInteger(
        jsonData.channel_id,
        debugInfo + ".channel_id",
      ),
      event_start_timestamp_utc: ValidationUtils.assertPositiveInteger(
        jsonData.event_start_timestamp_utc,
        debugInfo + ".event_start_timestamp_utc",
      ),

      coeff: ValidationUtils.assertAnyInteger(
        jsonData.coeff,
        debugInfo + ".coeff",
      ),

      // 2020-10-25 / Ivan / moving to new set of texts
      t_141_selection_name: ValidationUtils.assertNonEmptyString(
        jsonData.t_141_selection_name,
        debugInfo + ".t_141_selection_name",
      ),
      t_142_selection_param_1: ValidationUtils.assertOptionalString(
        jsonData.t_142_selection_param_1,
        debugInfo + ".t_142_selection_param_1",
      ),
      t_131_market_name: ValidationUtils.assertNonEmptyString(
        jsonData.t_131_market_name,
        debugInfo + ".t_131_market_name",
      ),
      t_132_market_note: ValidationUtils.assertOptionalString(
        jsonData.t_132_market_note,
        debugInfo + ".t_132_market_note",
      ),
      t_121_event_info: ValidationUtils.assertNonEmptyString(
        jsonData.t_121_event_info,
        debugInfo + ".t_121_event_info",
      ),
      // 2021-07-06 / Ivan / introduce support for activity feed
      conflict_fkey: ValidationUtils.assertNonEmptyString(
        jsonData.conflict_fkey,
        debugInfo + ".conflict_fkey",
      ),
      market_fkey: ValidationUtils.assertNonEmptyString(
        jsonData.market_fkey,
        debugInfo + ".market_fkey",
      ),
      proposal_fkey: ValidationUtils.assertNonEmptyString(
        jsonData.proposal_fkey,
        debugInfo + ".proposal_fkey",
      ),
      is_copy_available: ValidationUtils.assertBoolean(
        jsonData.is_copy_available,
        debugInfo + ".is_copy_available",
      ),
      channel_name: ValidationUtils.assertOptionalString(
        jsonData.channel_name,
        debugInfo + ".channel_name",
      ),
    };
  }
}

class AdapterPickPlayThoroughPod {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): IDataSBPick["play_through_data"]["pods"][number] {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      risk_amount: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.risk_amount,
        debugInfo + ".risk_amount",
      ),
      pod_fkey: ValidationUtils.assertNonEmptyString(
        jsonData.pod_fkey,
        debugInfo + ".pod_fkey",
      ),
      v_info: ValidationUtils.assertNonEmptyString(
        jsonData.v_info,
        debugInfo + ".v_info",
      ),
      v_sub_info: ValidationUtils.assertOptionalString(
        jsonData.v_sub_info,
        debugInfo + ".v_sub_info",
      ),
    };
  }
}

class AdapterPickPlayThroughData {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): IDataSBPick["play_through_data"] {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      pods: AdapterHelper.decodeArray(
        AdapterPickPlayThoroughPod.decode,
        jsonData.pods,
        debugInfo + ".jsonData",
      ),
    };
  }
}

export class AdapterSBPick {
  public static decode(jsonData: TAnyAlias, debugInfo: string): IDataSBPick {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    // 2021-07-06 / Ivan / those attributes exist only in activity feed response
    if (!jsonData.user_id) {
      jsonData.user_id = 0;
    }
    if (!jsonData.username) {
      jsonData.username = "";
    }
    if (!jsonData.play_through_data) {
      jsonData.play_through_data = { pods: [] };
    }

    return {
      id: ValidationUtils.assertPositiveInteger(jsonData.id, debugInfo + ".id"),
      created_timestamp_utc: ValidationUtils.assertPositiveInteger(
        jsonData.created_timestamp_utc,
        debugInfo + ".created_timestamp_utc",
      ),
      settled_timestamp_utc: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.settled_timestamp_utc,
        debugInfo + ".settled_timestamp_utc",
      ),
      state: validatePickSelectionState(jsonData.state, debugInfo + ".state"),
      type: validatePickType(jsonData.type, debugInfo + ".type"),
      currency: validatePickCurrency(
        jsonData.currency,
        debugInfo + ".currency",
      ),
      risk_amount: ValidationUtils.assertPositiveInteger(
        jsonData.risk_amount,
        debugInfo + ".risk_amount",
      ),
      payout_amount: ValidationUtils.assertAnyInteger(
        jsonData.payout_amount,
        debugInfo + ".payout_amount",
      ),
      coeff: ValidationUtils.assertAnyInteger(
        jsonData.coeff,
        debugInfo + ".coeff",
      ),
      selections: AdapterHelper.decodeArray<Data__SB_Pick_Selection>(
        Adapter__SB_Pick_Selection.decode,
        jsonData.selections,
        debugInfo + ".selections",
      ),
      userId: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.user_id,
        debugInfo + ".user_id",
      ),
      username: ValidationUtils.assertOptionalString(
        jsonData.username,
        debugInfo + ".username",
      ),
      stake_category: ValidationUtils.assertPositiveInteger(
        jsonData.stake_category,
        debugInfo + ".stake_category",
      ),
      copy_count: ValidationUtils.assertOptionalInteger(
        jsonData.copy_count,
        debugInfo + ".copy_count",
      ),
      play_through_data: AdapterPickPlayThroughData.decode(
        jsonData.play_through_data,
        debugInfo + ".play_through_data",
      ),
      emoji_mapping: null,
      reactions_count: 0,
    };
  }
}

export class Adapter__SB_v2_Place_Picks_Operation_Status {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): Data__SB_v2_Place_Picks_Operation_Status {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      status: validatePlacePicksOperationStatus(
        jsonData.status,
        debugInfo + ".status",
      ),
      required_resubmit_delay_millis:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.required_resubmit_delay_millis,
          debugInfo + ".required_resubmit_delay_millis",
        ),
      required_resubmit_after_timestamp_millis:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.required_resubmit_after_timestamp_millis,
          debugInfo + ".required_resubmit_after_timestamp_millis",
        ),
      required_confirmed_server_quote_coeff: ValidationUtils.assertAnyInteger(
        jsonData.required_confirmed_server_quote_coeff,
        debugInfo + ".required_confirmed_server_quote_coeff",
      ),
      picks: AdapterHelper.decodeArray<IDataSBPick>(
        AdapterSBPick.decode,
        jsonData.picks,
        debugInfo + ".picks",
      ),
    };
  }
}

export class Adapter__SB_IAP_OwnTransaction {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): IDataSBIAPTransaction {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      own_transaction_id: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.own_transaction_id,
        debugInfo + ".own_transaction_id",
      ),
      own_invoice_id: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.own_invoice_id,
        debugInfo + ".own_invoice_id",
      ),
      iap_productId: ValidationUtils.assertNonEmptyString(
        jsonData.iap_productId,
        debugInfo + ".iap_productId",
      ),
      iap_transactionDate: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.iap_transactionDate,
        debugInfo + ".iap_transactionDate",
      ),
      iap_transactionId: ValidationUtils.assertNonEmptyString(
        jsonData.iap_transactionId,
        debugInfo + ".iap_transactionId",
      ),
      iap_android_purchaseToken: ValidationUtils.assertOptionalString(
        jsonData.iap_android_purchaseToken,
        debugInfo + ".iap_android_purchaseToken",
      ),
      level_1_skin: validateBundleOfferSkin(
        jsonData.level_1_skin,
        debugInfo + ".level_1_skin",
      ),
      amount_social_coins: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.amount_social_coins,
        debugInfo + ".amount_social_coins",
      ),
      price_in_cents: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.price_in_cents,
        debugInfo + ".price_in_cents",
      ),
    };
  }
}

export class Adapter__SB_IAP_PaymentsToFinish {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): Data__SB_IAP_PaymentsToFinish {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      data: AdapterHelper.decodeArray<IDataSBIAPTransaction>(
        Adapter__SB_IAP_OwnTransaction.decode,
        jsonData.data,
        debugInfo + ".data",
      ),
    };
  }
}

export class Adapter__SB_Public_Challenges_Report_Item {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): Data__SB_Public_Challenges_Report_Item {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      id: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.id,
        debugInfo + ".id",
      ),
      position: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.position,
        debugInfo + ".position",
      ),
      type: validatePublicChallengeType(jsonData.type, debugInfo + ".type"),
      is_completed: ValidationUtils.assertBoolean(
        jsonData.is_completed,
        debugInfo + ".is_completed",
      ),
      details: Adapter__SB_Public_Challenge_Details.decode(
        jsonData.details,
        debugInfo + ".details",
      ),
    } as Data__SB_Public_Challenges_Report_Item;
  }
}

export class Adapter__SB_Public_Challenges_Report {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): Data__SB_Public_Challenges_Report {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      period_type: validateRawPeriodType(
        jsonData.period_type,
        debugInfo + ".period_type",
      ),
      period_start_stamp_seconds_utc: ValidationUtils.assertPositiveInteger(
        jsonData.period_start_stamp_seconds_utc,
        debugInfo + ".period_start_stamp_seconds_utc",
      ),
      period_visual_start_date: ValidationUtils.assertNonEmptyString(
        jsonData.period_visual_start_date,
        debugInfo + ".period_visual_start_date",
      ),
      period_visual_end_date: ValidationUtils.assertNonEmptyString(
        jsonData.period_visual_end_date,
        debugInfo + ".period_visual_end_date",
      ),
      report_anchor_stamp_seconds_utc: ValidationUtils.assertPositiveInteger(
        jsonData.report_anchor_stamp_seconds_utc,
        debugInfo + ".report_anchor_stamp_seconds_utc",
      ),
      report_anchor_offset: ValidationUtils.assertAnyInteger(
        jsonData.report_anchor_offset,
        debugInfo + ".report_anchor_offset",
      ),
      has_prev: ValidationUtils.assertBoolean(
        jsonData.has_prev,
        debugInfo + ".has_prev",
      ),
      has_next: ValidationUtils.assertBoolean(
        jsonData.has_next,
        debugInfo + ".has_next",
      ),
      is_today: ValidationUtils.assertBoolean(
        jsonData.is_today,
        debugInfo + ".is_today",
      ),
      // 2020-12-27 / Ivan / no longer used in v2 mechanics
      //      daily_bonus_amount: ValidationUtils.assert_positive_integer_or_zero(
      //        jsonData.daily_bonus_amount,
      //        debugInfo + ".daily_bonus_amount",
      //      ),
      //      estimated_daily_bonus_amount: ValidationUtils.assert_positive_integer_or_zero(
      //        jsonData.estimated_daily_bonus_amount,
      //        debugInfo + ".estimated_daily_bonus_amount",
      //      ),
      error_message: ValidationUtils.assertOptionalString(
        jsonData.error_message,
        debugInfo + ".error_message",
      ),
      data: AdapterHelper.decodeArray<Data__SB_Public_Challenges_Report_Item>(
        Adapter__SB_Public_Challenges_Report_Item.decode,
        jsonData.data,
        debugInfo + ".data",
      ),
    };
  }
}

export class Adapter__SB_Public_Challenge_Details {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): Data__SB_Public_Challenges_Report_Item["details"] {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    const challenge_type = validatePublicChallengeType(
      jsonData.challenge_type,
      debugInfo + ".challenge_type",
    );

    if (challenge_type === PublicChallengeType.CONST_17001_PLACE_PICK_TYPE) {
      return Adapter__SB_Public_Challenge_Details__17001__PLACE_PICK_TYPE.decode(
        jsonData,
        debugInfo,
      );
    }
    if (
      challenge_type ===
      PublicChallengeType.CONST_17002_PLACE_PICK_SELECTION_NO_CONFLICT
    ) {
      return Adapter__SB_Public_Challenge_Details__17002__PLACE_PICK_SELECTION_NO_CONFLICT.decode(
        jsonData,
        debugInfo,
      );
    }

    if (
      challenge_type ===
      PublicChallengeType.CONST_17003_PLACE_PICK_SELECTION_FOR_CONFLICT
    ) {
      return Adapter__SB_Public_Challenge_Details__17003__PLACE_PICK_SELECTION_FOR_CONFLICT.decode(
        jsonData,
        debugInfo,
      );
    }

    throw new FliffException(
      FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
      "unexpected challenge_type type [" + challenge_type + "]",
    );
  }
}

export class Adapter__SB_Public_Challenge_Details__17001__PLACE_PICK_TYPE {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): Data__SB_Public_Challenge_Details__17001__PLACE_PICK_TYPE["details"] {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      challenge_type: validatePublicChallengeType(
        jsonData.challenge_type,
        debugInfo + ".challenge_type",
      ),
      pick_type: validatePickTypeOrNull(
        jsonData.pick_type,
        debugInfo + ".pick_type",
      ),
      min_selections_count: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.min_selections_count,
        debugInfo + ".min_selections_count",
      ),
    };
  }
}

export class Adapter__SB_Public_Challenge_Details__17002__PLACE_PICK_SELECTION_NO_CONFLICT {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): Data__SB_Public_Challenge_Details__17002__PLACE_PICK_SELECTION_NO_CONFLICT["details"] {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      challenge_type: validatePublicChallengeType(
        jsonData.challenge_type,
        debugInfo + ".challenge_type",
      ),
      conflict_type: validateConflictTypeOrNull(
        jsonData.conflict_type,
        debugInfo + ".conflict_type",
      ),
    };
  }
}

export class Adapter__SB_Public_Challenge_Details__17003__PLACE_PICK_SELECTION_FOR_CONFLICT {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): Data__SB_Public_Challenge_Details__17003__PLACE_PICK_SELECTION_FOR_CONFLICT["details"] {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      challenge_type: validatePublicChallengeType(
        jsonData.challenge_type,
        debugInfo + ".challenge_type",
      ),
      conflict_type: validateConflictType(
        jsonData.conflict_type,
        debugInfo + ".conflict_type",
      ),
      conflict_id: ValidationUtils.assertPositiveInteger(
        jsonData.conflict_id,
        debugInfo + ".conflict_id",
      ),
      channel_id: ValidationUtils.assertPositiveInteger(
        jsonData.channel_id,
        debugInfo + ".channel_id",
      ),
      home_team_name: ValidationUtils.assertNonEmptyString(
        jsonData.home_team_name,
        debugInfo + ".home_team_name",
      ),
      away_team_name: ValidationUtils.assertNonEmptyString(
        jsonData.away_team_name,
        debugInfo + ".away_team_name",
      ),
      related_conflict_fkeys: AdapterHelper.decodeArrayOfStrings(
        jsonData.related_conflict_fkeys,
        debugInfo + ".related_conflict_fkeys",
      ),
    };
  }
}

export class AdapterSBBetProposal {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
    conflictFkey: string,
    defaultGroupingTag: string,
    revisionId: number,
  ): Data__SB_BetProposal {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    const global_parlay_set_check__fkey = conflictFkey;

    // 2020-10-26 / Ivan / provide default grouping tag (used for radio-button-like-checks), allow child to override it
    let grouping_tag = ValidationUtils.assertOptionalString(
      jsonData.grouping_tag,
      debugInfo + ".grouping_tag",
    );

    if (grouping_tag === "") {
      grouping_tag = defaultGroupingTag;
    }

    const global_radio_set_check__fkey = conflictFkey + "__" + grouping_tag;

    // 2020-12-17 / Ivan / those params exist (optionally) only for type: CONST__7801__BOOSTED_OFFER, we may want better check
    if (!jsonData.t_201_sponsor_name) {
      jsonData.t_201_sponsor_name = "";
    }
    if (!jsonData.t_202_sponsor_logo_url) {
      jsonData.t_202_sponsor_logo_url = "";
    }
    if (!jsonData.parlays_not_allowed) {
      jsonData.parlays_not_allowed = 0;
    }
    if (!jsonData.t_301_user_profile_fkey) {
      jsonData.t_301_user_profile_fkey = "";
    }
    if (!jsonData.t_302_boosted_note) {
      jsonData.t_302_boosted_note = "";
    }
    if (!jsonData.t_303_related_conflict_id) {
      jsonData.t_303_related_conflict_id = 0;
    }

    const sgpMode = ValidationUtils.assertAnyInteger(
      jsonData.sgp_mode,
      debugInfo + ".sgp_mode",
    );

    const isUsableForSgp = sgpMode > 0;

    return {
      // switching from id + hash to single identifier - proposal_fkey
      proposal_fkey: ValidationUtils.assertNonEmptyString(
        jsonData.proposal_fkey,
        debugInfo + ".proposal_fkey",
      ),
      t_121_event_info: ValidationUtils.assertNonEmptyString(
        jsonData.t_121_event_info,
        debugInfo + ".t_121_event_info",
      ),
      t_131_market_name: ValidationUtils.assertNonEmptyString(
        jsonData.t_131_market_name,
        debugInfo + ".t_131_market_name",
      ),
      t_132_market_note: ValidationUtils.assertOptionalString(
        jsonData.t_132_market_note,
        debugInfo + ".t_132_market_note",
      ),
      t_141_selection_name: ValidationUtils.assertNonEmptyString(
        jsonData.t_141_selection_name,
        debugInfo + ".t_141_selection_name",
      ),
      t_142_selection_param_1: ValidationUtils.assertOptionalString(
        jsonData.t_142_selection_param_1,
        debugInfo + ".t_142_selection_param_1",
      ),
      t_151_cell_text_1: ValidationUtils.assertOptionalString(
        jsonData.t_151_cell_text_1,
        debugInfo + ".t_151_cell_text_1",
      ),
      t_201_sponsor_name: ValidationUtils.assertOptionalString(
        jsonData.t_201_sponsor_name,
        debugInfo + ".t_201_sponsor_name",
      ),
      t_202_sponsor_logo_url: ValidationUtils.assertOptionalString(
        jsonData.t_202_sponsor_logo_url,
        debugInfo + ".t_202_sponsor_logo_url",
      ),
      t_303_related_conflict_id: ValidationUtils.assertOptionalInteger(
        jsonData.t_303_related_conflict_id,
        debugInfo + ".t_303_related_conflict_id",
      ),
      status: validateBetOrBetGroupStatus(
        jsonData.status,
        debugInfo + ".status",
      ),
      type: ValidationUtils.assertPositiveInteger(
        jsonData.type,
        debugInfo + ".type",
      ),
      coeff: ValidationUtils.assertAnyInteger(
        jsonData.coeff,
        debugInfo + ".coeff",
      ),

      coeff_updated_utc: ValidationUtils.assertPositiveInteger(
        jsonData.coeff_updated_utc,
        debugInfo + ".coeff_updated_utc",
      ),

      prev_coeff: ValidationUtils.assertAnyInteger(
        jsonData.prev_coeff,
        debugInfo + ".prev_coeff",
      ),

      change_type: validateMarketOfferSelectionChangeType(
        jsonData.change_type,
        debugInfo + ".change_type",
      ),

      leadership_flag: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.leadership_flag,
        debugInfo + ".leadership_flag",
      ),
      market_fkey: ValidationUtils.assertNonEmptyString(
        jsonData.market_fkey,
        debugInfo + ".market_fkey",
      ),
      market_proposal_type: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.market_proposal_type,
        debugInfo + ".market_proposal_type",
      ),

      // 2021-04-28 / Ivan / quick hack - allow server to enforce that this selection cannot participate in parlays
      parlays_not_allowed: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.parlays_not_allowed,
        debugInfo + ".parlays_not_allowed",
      ),
      // 2021-09-12 / Ivan / additional param to control parlay checks - see Trello 1599
      parlay_calibrate: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.parlay_calibrate,
        debugInfo + ".parlay_calibrate",
      ),
      global_radio_set_check__fkey,
      global_parlay_set_check__fkey,
      revision_code: "default",
      revision_id: revisionId,
      is_usable_for_sgp: isUsableForSgp,
      t_301_user_profile_fkey: ValidationUtils.assertOptionalString(
        jsonData.t_301_user_profile_fkey,
        debugInfo + ".t_301_user_profile_fkey",
      ),
      t_302_boosted_note: ValidationUtils.assertOptionalString(
        jsonData.t_302_boosted_note,
        debugInfo + ".t_302_boosted_note",
      ),
      group_fkey: ValidationUtils.assertOptionalString(
        jsonData.group_fkey,
        debugInfo + ".group_fkey",
      ),
      player_fkey: ValidationUtils.assertOptionalString(
        jsonData.player_fkey,
        debugInfo + ".player_fkey",
      ),
    };
  }
}

export class AdapterSBBetGroup {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
    conflictFkey: string,
    marketFkey: string,
    revisionId: number,
  ): IDataSBBetGroup {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    // 2020-10-26 / Ivan / provide default grouping tag (used for radio-button-like-checks), allow child to override it
    const groupTag = ValidationUtils.assertOptionalString(
      jsonData.group_tag,
      debugInfo + ".group_tag",
    );

    const defaultGroupingTag = marketFkey + "__" + groupTag;

    return {
      group_tag: ValidationUtils.assertOptionalString(
        jsonData.group_tag,
        debugInfo + ".group_tag",
      ),
      leadership_flag: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.leadership_flag,
        debugInfo + ".leadership_flag",
      ),
      row_header_name: ValidationUtils.assertOptionalString(
        jsonData.visual_name,
        debugInfo + ".visual_name",
      ),
      proposals: AdapterHelper.decodeArray4n<Data__SB_BetProposal>(
        AdapterSBBetProposal.decode,
        jsonData.proposals,
        debugInfo + ".proposals",
        conflictFkey,
        defaultGroupingTag,
        revisionId,
      ),
      player_fkey: ValidationUtils.assertOptionalString(
        jsonData.player_fkey,
        debugInfo + ".player_fkey",
      ),
    };
  }
}

export class AdapterSBBetMarketVisualColumn {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): IDataSBBetMarketVisualColumn {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      proposal_type: ValidationUtils.assertPositiveInteger(
        jsonData.proposal_type,
        debugInfo + ".proposal_type",
      ),
      column_header_name: ValidationUtils.assertOptionalString(
        jsonData.visual_name,
        debugInfo + ".visual_name",
      ),
    };
  }
}

export class AdapterSBFeedDiffItemBetMarketDeactivated {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): Data__SB_FeedDiffItem_BetMarket_Deactivated {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      subfeed_code: ValidationUtils.assertAnyInteger(
        jsonData.subfeed_code,
        debugInfo + ".subfeed_code",
      ),
      revision_id: ValidationUtils.assertAnyInteger(
        jsonData.revision_id,
        debugInfo + ".revision_id",
      ),
      item_type: ValidationUtils.assertAnyInteger(
        jsonData.item_type,
        debugInfo + ".item_type",
      ),
      deactivate_reason: ValidationUtils.assertAnyInteger(
        jsonData.deactivate_reason,
        debugInfo + ".deactivate_reason",
      ) as -1,
      conflict_fkey: ValidationUtils.assertNonEmptyString(
        jsonData.conflict_fkey,
        debugInfo + ".conflict_fkey",
      ),
      market_fkey: ValidationUtils.assertNonEmptyString(
        jsonData.market_fkey,
        debugInfo + ".market_fkey",
      ),
    };
  }
}

export class AdapterSBFeedDiffItemBetMarketAvailable {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): Data__SB_FeedDiffItem_BetMarket_Available {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    const tempConflictFkey = ValidationUtils.assertNonEmptyString(
      jsonData.conflict_fkey,
      debugInfo + ".conflict_fkey",
    );

    const tempMarketFkey = ValidationUtils.assertNonEmptyString(
      jsonData.market_fkey,
      debugInfo + ".market_fkey",
    );

    const revisionId = ValidationUtils.assertAnyInteger(
      jsonData.revision_id,
      debugInfo + ".revision_id",
    );

    const sgpMode = ValidationUtils.assertAnyInteger(
      jsonData.sgp_mode,
      debugInfo + ".sgp_mode",
    );

    const market_class_codes = AdapterHelper.decodeArrayOfIntegers(
      jsonData.market_class_codes,
      debugInfo + ".market_class_codes",
    );

    const isUsableForSgp = sgpMode > 0;

    return {
      revision_id: revisionId,
      item_type: ValidationUtils.assertAnyInteger(
        jsonData.item_type,
        debugInfo + ".item_type",
      ),
      deactivate_reason: ValidationUtils.assertAnyInteger(
        jsonData.deactivate_reason,
        debugInfo + ".deactivate_reason",
      ) as 0,
      conflict_fkey: ValidationUtils.assertNonEmptyString(
        jsonData.conflict_fkey,
        debugInfo + ".conflict_fkey",
      ),
      market_fkey: ValidationUtils.assertNonEmptyString(
        jsonData.market_fkey,
        debugInfo + ".market_fkey",
      ),
      xmarket_fkey: ValidationUtils.assertNonEmptyString(
        jsonData.xmarket_fkey,
        debugInfo + ".xmarket_fkey",
      ),
      weight: ValidationUtils.assertAnyInteger(
        jsonData.weight,
        debugInfo + ".weight",
      ),
      visual_name: ValidationUtils.assertNonEmptyString(
        jsonData.visual_name,
        debugInfo + ".visual_name",
      ),
      note: ValidationUtils.assertOptionalString(
        jsonData.note,
        debugInfo + ".note",
      ),
      status: validateBetOrBetGroupStatus(
        jsonData.status,
        debugInfo + ".status",
      ),
      visual_columns: AdapterHelper.decodeArray<IDataSBBetMarketVisualColumn>(
        AdapterSBBetMarketVisualColumn.decode,
        jsonData.headers,
        debugInfo + ".headers",
      ),
      groups: AdapterHelper.decodeArray4n<IDataSBBetGroup>(
        AdapterSBBetGroup.decode,
        jsonData.groups,
        debugInfo + ".groups",
        tempConflictFkey,
        tempMarketFkey,
        revisionId,
      ),
      market_class_codes: new Set(market_class_codes),
      subfeed_code: ValidationUtils.assertAnyInteger(
        jsonData.subfeed_code,
        debugInfo + ".subfeed_code",
      ),
      is_usable_for_sgp: isUsableForSgp,
      player_fkey: ValidationUtils.assertOptionalString(
        jsonData.player_fkey,
        debugInfo + ".player_fkey",
      ),
    };
  }
}

export class AdapterSBFeedDiffItemAbstract {
  public static decode(jsonData: TAnyAlias, debugInfo: string): TBetMarket {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    const itemType = ValidationUtils.assertPositiveInteger(
      jsonData.item_type,
      debugInfo + ".itemType",
    );
    const deactivateReason = ValidationUtils.assertAnyInteger(
      jsonData.deactivate_reason,
      debugInfo + ".deactivate_reason",
    ) as 0 | -1;

    if (itemType === 101 && deactivateReason !== 0) {
      return AdapterSBFeedDiffItemBetMarketDeactivated.decode(
        jsonData,
        debugInfo,
      );
    }
    if (itemType === 101 && deactivateReason === 0) {
      return AdapterSBFeedDiffItemBetMarketAvailable.decode(
        jsonData,
        debugInfo,
      );
    }

    throw new FliffException(
      FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
      "unexpected itemType [" + itemType + "]",
    );
  }
}

export class AdapterSBLogroV2AllDailyChallengesCompleted {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): IDataSBLogroV2AllDailyChallengesCompleted {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      id: ValidationUtils.assertPositiveInteger(jsonData.id, debugInfo + ".id"),
      type: ValidationUtils.assertPositiveInteger(
        jsonData.type,
        debugInfo + ".type",
      ),
      total_amount_v3_xpoints: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.total_amount_v3_xpoints,
        debugInfo + ".total_amount_v3_xpoints",
      ),
    };
  }
}

export class AdapterSBXDeltaProfile {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): IDataSBXDeltaProfile {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      // 2021-02-12 / Ivan / introduce default vs per-user permissions
      user_pdata: Adapter__SB_Permissions_And_Common_Data.decode(
        jsonData.user_pdata,
        debugInfo + ".user_pdata",
      ),
      d_51202_last_v2_weekly_leaderboard_bonus:
        AdapterHelper.nullOrDecode<Data__SB_Points_Leaderboard_Badge>(
          Adapter__SB_Points_Leaderboard_Badge.decode,
          jsonData.d_51202_last_v2_weekly_leaderboard_bonus,
          debugInfo + ".d_51202_last_v2_weekly_leaderboard_bonus",
        ),
      d_51207_last_v4_xpoints_bonus:
        AdapterHelper.nullOrDecode<IFCMTransaction>(
          AdapterFCMTransaction.decode,
          jsonData.d_51207_last_v4_xpoints_bonus,
          debugInfo + ".d_51207_last_v4_xpoints_bonus",
        ),
      d_51206_last_v2_completed_daily_challenge:
        AdapterHelper.nullOrDecode<IDataSBLogroV2AllDailyChallengesCompleted>(
          AdapterSBLogroV2AllDailyChallengesCompleted.decode,
          jsonData.d_51206_last_v2_completed_daily_challenge,
          debugInfo + ".d_51206_last_v2_completed_daily_challenge",
        ),
      d_51221_last_v2_transaction_id:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.d_51221_last_v2_transaction_id,
          debugInfo + ".d_51221_last_v2_transaction_id",
        ),
      d_51231_last_v3_purchase_error_order:
        AdapterHelper.nullOrDecode<IDataSBUnfinishedOrder>(
          AdapterSBUnfinishedOrder.decode,
          jsonData.d_51231_last_v3_purchase_error_order,
          debugInfo + ".d_51231_last_v3_purchase_error_order",
        ),
      d_51232_last_v3_purchase_success_transaction:
        AdapterHelper.nullOrDecode<IFCMTransaction>(
          AdapterFCMTransaction.decode,
          jsonData.d_51232_last_v3_purchase_success_transaction,
          debugInfo + ".d_51232_last_v3_purchase_success_transaction",
        ),
      d_51241_last_v3_order_updates_tracker:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.d_51241_last_v3_order_updates_tracker,
          debugInfo + ".d_51241_last_v3_order_updates_tracker",
        ),
      d_51250_last_transaction_4115_mail_in_entry:
        AdapterHelper.nullOrDecode<IFCMTransaction>(
          AdapterFCMTransaction.decode,
          jsonData.d_51250_last_transaction__4115_mail_in_entry,
          debugInfo + ".d_51250_last_transaction__4115_mail_in_entry",
        ),
      d_51251_last_transaction_4116_pending_fc_credit:
        AdapterHelper.nullOrDecode<IFCMTransaction>(
          AdapterFCMTransaction.decode,
          jsonData.d_51251_last_transaction__4116_pending_fc_credit,
          debugInfo + ".d_51251_last_transaction__4116_pending_fc_credit",
        ),
      d_51252_last_transaction_4117_cleared_fc_credit:
        AdapterHelper.nullOrDecode<IFCMTransaction>(
          AdapterFCMTransaction.decode,
          jsonData.d_51252_last_transaction__4117_cleared_fc_credit,
          debugInfo + ".d_51252_last_transaction__4117_cleared_fc_credit",
        ),
    };
  }
}

export class AdapterSlideGiftCardRedemptionMethod {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): IDataSlideGiftCardRedemptionMethod {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      m_info: ValidationUtils.assertOptionalString(
        jsonData.m_info,
        debugInfo + ".m_info",
      ),
    };
  }
}

export class AdapterSlideGiftCardBrand {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): IDataSlideGiftCardBrand {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    // 2021-03-28 / Ivan / quick hack for gift card branding info, may be better to fix it on server side
    if (jsonData.is_popular === undefined) {
      jsonData.is_popular = false;
    }
    // 2021-03-28 / Ivan / quick hack for gift card branding info, may be better to fix it on server side
    if (jsonData.cashback_in_promiles === undefined) {
      jsonData.cashback_in_promiles = 40;
    }

    return {
      b_uuid: ValidationUtils.assertNonEmptyString(
        jsonData.b_uuid,
        debugInfo + ".b_uuid",
      ),
      b_name: ValidationUtils.assertNonEmptyString(
        jsonData.b_name,
        debugInfo + ".b_name",
      ),
      b_icon_url: ValidationUtils.assertNonEmptyString(
        jsonData.b_icon_url,
        debugInfo + ".b_icon_url",
      ),
      b_legal_terms: ValidationUtils.assertOptionalString(
        jsonData.b_legal_terms,
        debugInfo + ".b_legal_terms",
      ),
      b_description: ValidationUtils.assertOptionalString(
        jsonData.b_description,
        debugInfo + ".b_description",
      ),
      v_category: ValidationUtils.assertOptionalString(
        jsonData.v_category,
        debugInfo + ".v_category",
      ),
      v_pos: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.v_pos,
        debugInfo + ".v_pos",
      ),
      cvc_denominations: AdapterHelper.decodeArrayOfIntegers(
        jsonData.cvc_denominations,
        debugInfo + ".cvc_denominations",
      ),
      cvc_min_value: ValidationUtils.assertPositiveInteger(
        jsonData.cvc_min_value,
        debugInfo + ".cvc_min_value",
      ),
      cvc_max_value: ValidationUtils.assertPositiveInteger(
        jsonData.cvc_max_value,
        debugInfo + ".cvc_max_value",
      ),
      cvc_increment: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.cvc_increment,
        debugInfo + ".cvc_increment",
      ),
      rc_kind: validateGiftCardRedemptionConfigType(
        jsonData.rc_kind,
        debugInfo + ".rc_kind",
      ),
      rc_disclaimer: ValidationUtils.assertOptionalString(
        jsonData.rc_disclaimer,
        debugInfo + ".rc_disclaimer",
      ),
      rc_methods: AdapterHelper.decodeArray<IDataSlideGiftCardRedemptionMethod>(
        AdapterSlideGiftCardRedemptionMethod.decode,
        jsonData.rc_methods,
        debugInfo + ".rc_methods",
      ),
      is_popular: ValidationUtils.assertBoolean(
        jsonData.is_popular,
        debugInfo + ".is_popular",
      ),
      cashback_in_promiles: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.cashback_in_promiles,
        debugInfo + ".cashback_in_promiles",
      ),
    };
  }
}

export class AdapterSlideUserGiftCard {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): IDataSlideUserGiftCard {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      amount_denomination: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.amount_denomination,
        debugInfo + ".amount_denomination",
      ),
      amount_paid_via_credit_card: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.amount_paid_via_credit_card,
        debugInfo + ".amount_paid_via_credit_card",
      ),
      amount_paid_via_points: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.amount_paid_via_points,
        debugInfo + ".amount_paid_via_points",
      ),

      brand_name: ValidationUtils.assertNonEmptyString(
        jsonData.brand_name,
        debugInfo + ".brand_name",
      ),
      card_barcode_kind: ValidationUtils.assertNonEmptyString(
        jsonData.card_barcode_kind,
        debugInfo + ".card_barcode_kind",
      ),
      card_barcode_value: ValidationUtils.assertOptionalString(
        jsonData.card_barcode_value,
        debugInfo + ".card_barcode_value",
      ),
      card_csc: ValidationUtils.assertOptionalString(
        jsonData.card_csc,
        debugInfo + ".card_csc",
      ),
      card_number: ValidationUtils.assertNonEmptyString(
        jsonData.card_number,
        debugInfo + ".card_number",
      ),
      amount_cashback: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.amount_cashback,
        debugInfo + ".amount_cashback",
      ),
      amount_balance: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.amount_balance,
        debugInfo + ".amount_balance",
      ),
      credit_card_number: ValidationUtils.assertOptionalString(
        jsonData.credit_card_number,
        debugInfo + ".credit_card_number",
      ),
      brand: AdapterSlideGiftCardBrand.decode(
        jsonData.brand,
        debugInfo + ".brand",
      ),
    };
  }
}

export class AdapterSBUnfinishedOrder {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): IDataSBUnfinishedOrder {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      id: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.id,
        debugInfo + ".id",
      ),
      created_stamp_sec_utc: ValidationUtils.assertPositiveInteger(
        jsonData.created_stamp_sec_utc,
        debugInfo + ".created_stamp_sec_utc",
      ),
      updated_stamp_sec_utc: ValidationUtils.assertPositiveInteger(
        jsonData.updated_stamp_sec_utc,
        debugInfo + ".updated_stamp_sec_utc",
      ),
      amount_denomination: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.amount_denomination,
        debugInfo + ".amount_denomination",
      ),
      state_category: validateUnfinishedOrderState(
        jsonData.state_category,
        debugInfo + ".state_category",
      ),
      state_code: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.state_code,
        debugInfo + ".state_code",
      ),
      brand: AdapterSlideGiftCardBrand.decode(
        jsonData.brand,
        debugInfo + ".brand",
      ),
    };
  }
}

export class AdapterSlideAllGiftCardBrands {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): IDataSlideAllGiftCardBrands {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      brands: AdapterHelper.decodeArray<IDataSlideGiftCardBrand>(
        AdapterSlideGiftCardBrand.decode,
        jsonData.brands,
        debugInfo + ".brands",
      ),
    };
  }
}

export class AdapterSBConflictMarketClassDef {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): IDataSBConlictMarketClassDef {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      code: ValidationUtils.assertPositiveInteger(
        jsonData.code,
        debugInfo + ".code",
      ),
      tab_pos: ValidationUtils.assertAnyInteger(
        jsonData.tab_pos,
        debugInfo + ".tab_pos",
      ),
      tab_name: ValidationUtils.assertNonEmptyString(
        jsonData.tab_name,
        debugInfo + ".tab_name",
      ),
      market_class_codes: jsonData.market_class_codes
        ? AdapterHelper.decodeArrayOfIntegers(
            jsonData.market_class_codes,
            debugInfo + ".market_class_codes",
          )
        : null,
    };
  }
}

export class AdapterSBConflictClassDef {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): IDataSBConflictClassDef {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      code: ValidationUtils.assertPositiveInteger(
        jsonData.code,
        debugInfo + ".code",
      ),
      market_classes: AdapterHelper.decodeArray<IDataSBConlictMarketClassDef>(
        AdapterSBConflictMarketClassDef.decode,
        jsonData.market_classes,
        debugInfo + ".market_classes",
      ),
    };
  }
}

export class AdapterSBInitiatedWithdrawalResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): IDataSBInitiatedWithdrawalResponse {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      verification_url: ValidationUtils.assertOptionalString(
        jsonData.verification_url,
        debugInfo + ".verification_url",
      ),
    };
  }
}

export class AdapterSBInitiatedPersonaVerificationResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): IDataSBInitiatedPersonaVerificationResponse {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      verification_url: ValidationUtils.assertOptionalString(
        jsonData.verification_url,
        debugInfo + ".verification_url",
      ),
      persona_template_id: ValidationUtils.assertOptionalString(
        jsonData.persona_template_id,
        debugInfo + ".persona_template_id",
      ),
      persona_reference_id: ValidationUtils.assertOptionalString(
        jsonData.persona_reference_id,
        debugInfo + ".persona_reference_id",
      ),
    };
  }
}

export class AdapterSBUserPersonalInfo {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): Data__SB_User_Personal_Info {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      first_name: ValidationUtils.assertOptionalString(
        jsonData.first_name,
        debugInfo + ".first_name",
      ),
      last_name: ValidationUtils.assertOptionalString(
        jsonData.last_name,
        debugInfo + ".last_name",
      ),
      date_of_birth: ValidationUtils.assertOptionalString(
        jsonData.date_of_birth,
        debugInfo + ".date_of_birth",
      ),
      country_code: ValidationUtils.assertOptionalString(
        jsonData.country_code,
        debugInfo + ".country_code",
      ),
      country_state: ValidationUtils.assertOptionalString(
        jsonData.country_state,
        debugInfo + ".country_state",
      ) as TUSAStatesValues,
      zip_code: ValidationUtils.assertOptionalString(
        jsonData.zip_code,
        debugInfo + ".zip_code",
      ),
      city: ValidationUtils.assertOptionalString(
        jsonData.city,
        debugInfo + ".city",
      ),
      address_1: ValidationUtils.assertOptionalString(
        jsonData.address_1,
        debugInfo + ".address_1",
      ),
      address_2: ValidationUtils.assertOptionalString(
        jsonData.address_2,
        debugInfo + ".address_2",
      ),
    };
  }
}

export class AdapterSBArenaDef {
  public static decode(jsonData: TAnyAlias, debugInfo: string): IArenaDef {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      arena_code: ValidationUtils.assertNonEmptyString(
        jsonData.arena_code,
        debugInfo + ".arena_code",
      ),
      arena_name: ValidationUtils.assertNonEmptyString(
        jsonData.arena_name,
        debugInfo + ".arena_name",
      ),
      arena_icon_url: ValidationUtils.assertNonEmptyString(
        jsonData.arena_icon_url,
        debugInfo + ".arena_icon_url",
      ),
      arena_icon_v2_url: ValidationUtils.assertOptionalString(
        jsonData.arena_icon_v2_url,
        debugInfo + ".arena_icon_v2_url",
      ),
      icon_active: ValidationUtils.assertOptionalString(
        jsonData.icon_active,
        debugInfo + ".icon_active",
      ),
      icon_inactive: ValidationUtils.assertOptionalString(
        jsonData.icon_inactive,
        debugInfo + ".icon_inactive",
      ),
    };
  }
}

export class Adapter__SB_Pick {
  public static decode(jsondata: TAnyAlias, debugInfo: string): IDataSBPick {
    ValidationUtils.assertNonNullData(jsondata, debugInfo);

    // 2021-07-06 / Ivan / those attributes exist only in activity feed response
    if (!jsondata.user_id) {
      jsondata.user_id = 0;
    }
    if (!jsondata.username) {
      jsondata.username = "";
    }
    if (!jsondata.avatar_id) {
      jsondata.avatar_id = "";
    }
    if (!jsondata.play_through_data) {
      jsondata.play_through_data = { pods: [] };
    }

    return {
      id: ValidationUtils.assertPositiveInteger(jsondata.id, debugInfo + ".id"),
      created_timestamp_utc: ValidationUtils.assertPositiveInteger(
        jsondata.created_timestamp_utc,
        debugInfo + ".created_timestamp_utc",
      ),
      settled_timestamp_utc: ValidationUtils.assertPositiveIntegerOrZero(
        jsondata.settled_timestamp_utc,
        debugInfo + ".settled_timestamp_utc",
      ),
      state: validatePickSelectionState(jsondata.state, debugInfo + ".state"),
      type: validatePickType(jsondata.type, debugInfo + ".type"),
      currency: validatePickCurrency(
        jsondata.currency,
        debugInfo + ".currency",
      ),
      risk_amount: ValidationUtils.assertPositiveInteger(
        jsondata.risk_amount,
        debugInfo + ".risk_amount",
      ),
      payout_amount: ValidationUtils.assertAnyInteger(
        jsondata.payout_amount,
        debugInfo + ".payout_amount",
      ),
      coeff: ValidationUtils.assertAnyInteger(
        jsondata.coeff,
        debugInfo + ".coeff",
      ),
      selections: AdapterHelper.decodeArray<Data__SB_Pick_Selection>(
        Adapter__SB_Pick_Selection.decode,
        jsondata.selections,
        debugInfo + ".selections",
      ),
      userId: ValidationUtils.assertPositiveIntegerOrZero(
        jsondata.user_id,
        debugInfo + ".user_id",
      ),
      username: ValidationUtils.assertOptionalString(
        jsondata.username,
        debugInfo + ".username",
      ),
      stake_category: ValidationUtils.assertPositiveInteger(
        jsondata.stake_category,
        debugInfo + ".stake_category",
      ),
      copy_count: ValidationUtils.assertOptionalInteger(
        jsondata.copy_count,
        debugInfo + ".copy_count",
      ),
      play_through_data: AdapterPickPlayThroughData.decode(
        jsondata.play_through_data,
        debugInfo + ".play_through_data",
      ),
      reactions_count: jsondata.reactions_count
        ? ValidationUtils.assertPositiveIntegerOrZero(
            jsondata.reactions_count,
            debugInfo + ".reactions_count",
          )
        : 0,
      emoji_mapping: jsondata.emoji_mapping
        ? AdapterHelper.decodeStringMap(
            AdapterEmojiData.decode,
            jsondata.emoji_mapping,
            debugInfo + ".emoji_mapping",
          )
        : null,
    };
  }
}

export class Adapter__SB_Pick_PeriodReport {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): Data__SB_Pick_PeriodReport {
    ValidationUtils.assertNonNullData(jsondata, debugInfo);

    return {
      period_request_id: ValidationUtils.assertNonEmptyString(
        jsondata.period_request_id,
        debugInfo + ".period_request_id",
      ),
      period_start_stamp_seconds_utc: ValidationUtils.assertAnyInteger(
        jsondata.period_start_stamp_seconds_utc,
        debugInfo + ".period_start_stamp_seconds_utc",
      ),
      period_end_stamp_seconds_utc: ValidationUtils.assertAnyInteger(
        jsondata.period_end_stamp_seconds_utc,
        debugInfo + ".period_end_stamp_seconds_utc",
      ),
      data: AdapterHelper.decodeArray<IDataSBPick>(
        Adapter__SB_Pick.decode,
        jsondata.data,
        debugInfo + ".data",
      ),
      //      pending_picks_stats: Adapter__SB_Social_Picks_Stats.decode(
      //        jsondata.pending_picks_stats,
      //        debugInfo + ".pending_picks_stats",
      //      ),
      v3_profile_summary: Adapter__SB_v3_User_Profile_Summary.decode(
        jsondata.v3_profile_summary,
        debugInfo + ".v3_profile_summary",
      ),
      // 2021-07-13 / Ivan / introduce support for 'other user profile/stats'
      other_user_id: ValidationUtils.assertPositiveIntegerOrZero(
        jsondata.other_user_id,
        debugInfo + ".other_user_id",
      ),
    };
  }
}

export class Adapter__SB_v3_User_Profile_Summary {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): Data__SB_v3_User_Profile_Summary {
    ValidationUtils.assertNonNullData(jsondata, debugInfo);

    return {
      user_id: ValidationUtils.assertPositiveInteger(
        jsondata.user_id,
        debugInfo + ".user_id",
      ),
      user_name: ValidationUtils.assertNonEmptyString(
        jsondata.user_name,
        debugInfo + ".user_name",
      ),
      avatar_id: ValidationUtils.assertNonEmptyString(
        jsondata.avatar_id,
        debugInfo + ".avatar_id",
      ),
      this_week_stats: Adapter__SB_v3_User_Weekly_Stats.decode(
        jsondata.this_week_stats,
        debugInfo + ".this_week_stats",
      ),
      prev_week_stats: Adapter__SB_v3_User_Weekly_Stats.decode(
        jsondata.prev_week_stats,
        debugInfo + ".prev_week_stats",
      ),
    };
  }
}

export class Adapter__SB_v3_User_Weekly_Stats {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): Data__SB_v3_User_Weekly_Stats {
    ValidationUtils.assertNonNullData(jsondata, debugInfo);

    return {
      s_picks_count: ValidationUtils.assertPositiveIntegerOrZero(
        jsondata.s_picks_count,
        debugInfo + ".s_picks_count",
      ),
      s_6011_best_win: ValidationUtils.assertPositiveIntegerOrZero(
        jsondata.s_6011_best_win,
        debugInfo + ".s_6011_best_win",
      ),
      s_6021_skill_coeff: ValidationUtils.assertPositiveIntegerOrZero(
        jsondata.s_6021_skill_coeff,
        debugInfo + ".s_6021_skill_coeff",
      ),
      s_global_rank: ValidationUtils.assertPositiveIntegerOrZero(
        jsondata.s_global_rank,
        debugInfo + ".s_global_rank",
      ),
    };
  }
}

export class Adapter__SB_Social_Picks_Stats {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): Data__SB_Social_Picks_Stats {
    ValidationUtils.assertNonNullData(jsondata, debugInfo);

    return {
      s_picks_count: ValidationUtils.assertPositiveIntegerOrZero(
        jsondata.s_picks_count,
        debugInfo + ".s_picks_count",
      ),
      s_5005_v2_tokens: ValidationUtils.assertPositiveIntegerOrZero(
        jsondata.s_5005_v2_tokens,
        debugInfo + ".s_5005_v2_tokens",
      ),
      // 2021-06-25 / Ivan / moving to xpoints stats
      //
      //      s_5006_v2_points: ValidationUtils.assert_positive_integer_or_zero(
      //        jsondata.s_5006_v2_points,
      //        debugInfo + ".s_5006_v2_points",
      //      ),
      s_5007_v2_credits: ValidationUtils.assertPositiveIntegerOrZero(
        jsondata.s_5007_v2_credits,
        debugInfo + ".s_5007_v2_credits",
      ),
      // 2021-06-25 / Ivan / moving to xpoints stats
      s_5009_v3_xpoints: ValidationUtils.assertPositiveIntegerOrZero(
        jsondata.s_5009_v3_xpoints,
        debugInfo + ".s_5009_v3_xpoints",
      ),
      // 2021-07-11 / Ivan / introduce new user profile stats
      s_6011_best_win: ValidationUtils.assertPositiveIntegerOrZero(
        jsondata.s_6011_best_win,
        debugInfo + ".s_6011_best_win",
      ),
      s_6021_skill_coeff: ValidationUtils.assertPositiveIntegerOrZero(
        jsondata.s_6021_skill_coeff,
        debugInfo + ".s_6021_skill_coeff",
      ),
    };
  }
}

export class Adapter__SB_Points_Leaderboard_Badge {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): Data__SB_Points_Leaderboard_Badge {
    ValidationUtils.assertNonNullData(jsondata, debugInfo);

    // 2020-12-29 / Ivan / temp hack / old leaderboard serves gold coins, new one serves v2 credits
    // temporary decode both
    // 2021-02-12 / Ivan / we no longer support gold coins
    //    if (!jsondata.amount_gold_coins) {
    //      jsondata.amount_gold_coins = 0;
    //    }
    //    if (!jsondata.amount_v2_credits) {
    //      jsondata.amount_v2_credits = 0;
    //    }

    return {
      id: ValidationUtils.assertPositiveInteger(jsondata.id, debugInfo + ".id"),
      amount_v2_credits: ValidationUtils.assertPositiveIntegerOrZero(
        jsondata.amount_v2_credits,
        debugInfo + ".amount_v2_credits",
      ),
      amount_v2_bonus_tokens: ValidationUtils.assertPositiveIntegerOrZero(
        jsondata.amount_v2_bonus_tokens,
        debugInfo + ".amount_v2_bonus_tokens",
      ),
      position: ValidationUtils.assertPositiveIntegerOrZero(
        jsondata.position,
        debugInfo + ".position",
      ),
      period_type: validateRawPeriodType(
        jsondata.period_type,
        debugInfo + ".period_type",
      ),
      period_start_timestamp_utc: ValidationUtils.assertPositiveInteger(
        jsondata.period_start_timestamp_utc,
        debugInfo + ".period_start_timestamp_utc",
      ),
      start_date: ValidationUtils.assertNonEmptyString(
        jsondata.start_date,
        debugInfo + ".start_date",
      ),
      end_date: ValidationUtils.assertNonEmptyString(
        jsondata.end_date,
        debugInfo + ".end_date",
      ),
    };
  }
}
