import dayjs, { Dayjs } from "dayjs";
import { Duration } from "dayjs/plugin/duration";

import { ServerClock } from "utils/ServerClock";
import DateFormatters from "utils/DateFormatters";

export class TimeUtils {
  private static readonly _oneDayInMs = 1000 * 60 * 60 * 24;

  public static now(
    input?: dayjs.ConfigType,
    format?: string,
    strict?: boolean,
  ): Dayjs {
    return dayjs(input, format, strict);
  }

  public static calcCurrentAndNextDayStartStampsInUTC(
    dateString: string,
  ): [number, number, string] {
    const localDate = TimeUtils.now(dateString);
    const dayStart = localDate.startOf("day");
    const nextDayStart = localDate.add(1, "day").startOf("day");

    return [
      dayStart.valueOf(),
      nextDayStart.valueOf(),
      DateFormatters.formatRegularVisualDate(localDate),
    ];
  }

  /*
  calculates concept for 'current day' in client timezone based on last known server time:
  <0 - before today
   0 - today
   1 - tomorrow
  >1 - after tomorrow
  */
  public static dayOffsetFromToday(utcStamp: number): 0 | 1 | 2 {
    const dayStart = new Date(utcStamp);
    dayStart.setHours(0);
    dayStart.setMinutes(0);

    const serverNow = new Date(ServerClock.latestKnownServerUtcStampMillis);
    serverNow.setHours(0);
    serverNow.setMinutes(0);

    const diffInMs = dayStart.valueOf() - serverNow.valueOf();
    const diffInDays = Math.round(diffInMs / TimeUtils._oneDayInMs);

    if (diffInDays === 1) {
      return 1;
    }

    if (diffInDays === 0) {
      return 0;
    }

    return 2;
  }

  public static durationFromStampSecondsUtc(utcStamp: number): string {
    return TimeUtils.now(utcStamp).fromNow(true);
  }

  public static daysFromServerToday(
    serverToday: string,
    date: dayjs.ConfigType,
  ): number {
    const duration =
      TimeUtils.now(date).valueOf() - TimeUtils.now(serverToday).valueOf();

    return Math.trunc(dayjs.duration(duration).asDays());
  }

  public static isToday(secondsUTCStamp: number) {
    const serverTodayStart = TimeUtils.now().startOf("day");
    const timestamp = TimeUtils.now(
      TimeUtils.fromSecondsToMills(secondsUTCStamp),
    );
    return serverTodayStart.isSame(timestamp, "day");
  }

  public static fromMillsToMinutesStamp(timeUtcStamp: number): number {
    return timeUtcStamp / 60000;
  }

  public static fromSecondsToMills(secondsStamp: number): number {
    return secondsStamp * 1000;
  }

  public static fromMicroSecondsToMills(stamp: number | string): number {
    const number = typeof stamp === "string" ? parseInt(stamp) : stamp;
    if (Number.isNaN(number) || !Number.isFinite(number)) {
      return -1;
    }

    return number / 10000;
  }

  public static isSameDay(
    first: dayjs.ConfigType,
    second: dayjs.ConfigType,
  ): boolean {
    const firstDayjs = dayjs(first);
    const secondDayjs = dayjs(second);

    return (
      firstDayjs.get("year") === secondDayjs.get("year") &&
      firstDayjs.get("month") === secondDayjs.get("month") &&
      firstDayjs.get("date") === secondDayjs.get("date") &&
      firstDayjs.get("day") === secondDayjs.get("day")
    );
  }

  public static diffInHoursFromNow(timeUtcStamp: number): number {
    const now = TimeUtils.now();
    const end = TimeUtils.now(timeUtcStamp);
    const duration = dayjs.duration(now.diff(end));
    return duration.asHours();
  }

  public static getMillsDuration(input: number): Duration {
    return dayjs.duration(input, "milliseconds");
  }
}
