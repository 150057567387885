import { IDataHeraldMessageAbstract } from "server/herald/data/objects";
import { ValidationUtils } from "server/common/ValidationUtils";
import { FliffException } from "server/common/FliffException";
import {
  AdapterHeraldMessageFeedDiffUpdate,
  AdapterHeraldMessageHeartbeat,
  AdapterHeraldMessageWelcome,
} from "server/herald/data/serverAdapters";
import { IEndpoint } from "server/herald/types";
import { TAnyAlias } from "src/types";

class HeraldUtils {
  public decodeSerializedMessage(
    serializedMessageData: string,
    endpoint: IEndpoint | undefined | null,
  ): IDataHeraldMessageAbstract | null {
    try {
      return this.decodeMessage(
        JSON.parse(serializedMessageData),
        "serialized_message_data",
      );
    } catch (error) {
      if (endpoint) {
        endpoint.stats.errorHUDecode += 1;
      }
      console.warn(" x in decode_serialized_message", error);
      return null;
    }
  }

  public decodeMessage(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): IDataHeraldMessageAbstract {
    const messageName = ValidationUtils.assertNonEmptyString(
      jsonData.message_name,
      debugInfo + ".message_name",
    );

    if (messageName === "Message_Heartbeat") {
      return AdapterHeraldMessageHeartbeat.decode(jsonData, debugInfo);
    }
    if (messageName === "Message_Welcome") {
      return AdapterHeraldMessageWelcome.decode(jsonData, debugInfo);
    }
    if (messageName === "Message_FeedDiffUpdate") {
      return AdapterHeraldMessageFeedDiffUpdate.decode(jsonData, debugInfo);
    }

    throw new FliffException(
      FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
      "unexpected message_name [" + messageName + "]",
    );
  }
}

export default new HeraldUtils();
